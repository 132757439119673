import { IconCancel, IconCheckCircleOutline, IconSteeringWheel } from '../icons';
import React, { FC, ReactNode } from 'react';
import { Badge, Plate } from './';
import { ServiceStates, VehicleServiceTypes } from '@alfred-co/types';
import { twMerge } from 'tailwind-merge';

interface ServiceCardProps {
  plate: string
  city: string
  vehicleServiceType: VehicleServiceTypes
  brand: string
  model: string
  showIcon?: boolean
  badgetNumber?: number
  state?: ServiceStates
  content?: ReactNode
  onHandleClick: () => void
  serviceType?: 'B2B' | 'B2C'
  responsible?: string
  membership?: string
}

export const ServiceStateIcon: { [key in ServiceStates]: { Icon: ReactNode } } = {
  REQUESTED: {
    Icon: (
      <div />
    ),
  },
  IN_PROGRESS: {
    Icon: (
      <div />
    ),
  },
  COMPLETED: {
    Icon: (
      <div className='p-1 rounded-[5px] bg-success/40 w-fit'>
        <IconCheckCircleOutline />
      </div>
    ),
  },
  CANCELED: {
    Icon: (
      <div className='p-1 rounded-[5px] bg-error/40 w-fit'>
        <IconCancel />
      </div>
    ),
  },
  FAILED: {
    Icon: (
      <div className='p-1 rounded-[5px] bg-error/40 w-fit'>
        <IconCancel />
      </div>
    ),
  },
  TO_BE_CONFIRMED: {
    Icon: (
      <div />
    ),
  },
  FOR_PAID: {
    Icon: (
      <div />
    ),
  },
  TO_BE_PAID: {
    Icon: (
      <div />
    ),
  },
} as const;

export const ServiceCard: FC<ServiceCardProps> = ({
  plate,
  city,
  vehicleServiceType,
  brand,
  model,
  showIcon,
  badgetNumber,
  state,
  content,
  onHandleClick,
  serviceType,
  responsible,
  membership,
}) => {
  return (
    <Badge
      isInvisible={!badgetNumber}
      content={badgetNumber}
      color='danger'
      size='lg'
      showOutline={false}
      className='right-1 top-1'
    >
      <div
        className='flex flex-col relative bg-complementary-gray-main border border-white rounded-[10px] w-[270px] h-fit gap-3 justify-between cursor-pointer'
        onClick={() => onHandleClick()}
      >
        <div className='p-[13px]'>
          <div className='flex flex-row flex-grow gap-5 min-w-0'>
            <Plate plate={plate} city={city} serviceType={vehicleServiceType} />
            <div className='flex flex-col flex-grow min-w-0'>
              <span className='truncate'>{brand}</span>
              <div className='flex flex-row justify-between items-center min-w-0'>
                <span className='truncate'>{model}</span>
                {
                showIcon
                  ? <div><IconSteeringWheel /></div>
                  : <div />
                }
              </div>
            </div>
          </div>
          <div className='pt-2'>
            {content}
          </div>

          {
            state &&
              <div className='absolute bottom-[10px] right-[10px]'>
                {ServiceStateIcon[state].Icon}
              </div>
          }
        </div>
        {
          membership && (
            <div className='flex flex-row gap-1 px-3'>
              <div className='font-gothamBook text-xs'>
                Membresía:
              </div>
              <div className='text-xs'>
                {membership}
              </div>
            </div>
          )
        }
        {
          responsible && (
            <div className='flex flex-row gap-1 px-3'>
              <div className='font-gothamBook text-xs'>
                Asesor:
              </div>
              <div className='text-xs'>
                {responsible}
              </div>
            </div>
          )
        }
        {serviceType && (
          <div className={twMerge('w-full -mt-2 py-1 text-center rounded-b-[10px]', serviceType === 'B2B' ? 'bg-dark-main' : 'bg-secondary-light')}>
            {serviceType}
          </div>
        )}
      </div>
    </Badge>
  );
};

export default ServiceCard;
