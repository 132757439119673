
export const IconTag = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='21'
    height='22'
    viewBox='0 0 21 22'
    fill='none'
    {...props}
  >
    <path d='M5.46875 18.1641L6.40937 14.4453H3.0625L3.39062 13.1328H6.7375L7.72187 9.19531H3.9375L4.26562 7.88281H8.05L8.96875 4.16406H10.2594L9.34063 7.88281H13.3L14.2188 4.16406H15.5094L14.5906 7.88281H17.9375L17.6094 9.19531H14.2625L13.2781 13.1328H17.0625L16.7344 14.4453H12.95L12.0312 18.1641H10.7188L11.6594 14.4453H7.7L6.78125 18.1641H5.46875ZM8.02813 13.1328H11.9875L12.9719 9.19531H9.0125L8.02813 13.1328Z' fill='#FCFCFC' />
  </svg>
);

export default IconTag;
