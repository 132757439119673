import { LaborModel, ServiceLaborsModel, ServiceModel, Shop } from '@alfred-co/types';

interface CurrentLaborInfo {
  serviceLaborUuid?: string
  shop?: Shop
  laborLabel: string
  alfredShopLabel: string
  alfredShopValue: string
  serviceLaborsLength: number
  activeStep: number
  isTransport: boolean
}
export function getLaborsLabel (serviceLabors: ServiceLaborsModel[]): string {
  const labors = serviceLabors.filter((labor) => !labor.labor.slug.includes('transport'));

  if (!labors.length) return '';

  if (labors.length > 1) {
    return `${String(labors[0].labor.laborConfigLabel)} y ${labors.length - 1} más`;
  }
  return labors[0].labor.laborConfigLabel;
}

export function getCurrentLabor (serviceLabors: ServiceLaborsModel[], serviceMode: string): CurrentLaborInfo {
  const getLaborLabel = (labor: LaborModel) => {
    return labor.slug.includes('transport') ? 'Vehículo en Trayecto' : `Vehículo en ${String(labor.laborConfigLabel)}`;
  };

  const getAlfredShopInfo = (serviceLabor: ServiceLaborsModel) => {
    if (serviceLabor.shop ?? serviceMode === 'WITHOUT_DRIVER') {
      return {
        label: 'Taller',
        value: serviceLabor?.shop?.name ?? 'Pendiente por asignación',
      };
    }
    return {
      label: 'Conductor',
      value: serviceLabor.alfred?.firstName ? `${String(serviceLabor.alfred.firstName)} ${String(serviceLabor.alfred.lastName)}` : 'Pendiente por asignación',
    };
  };

  let activeStep = 1;
  let isTransport = false;
  const serviceLaborsLength = serviceLabors.length;

  let laborLabel = '';
  let serviceLaborUuid = '';
  let shop;
  let alfredShopLabel = '';
  let alfredShopValue = '';

  if (serviceLaborsLength > 0) {
    const initLabor = serviceLabors[0];

    laborLabel = getLaborLabel(initLabor.labor);
    serviceLaborUuid = initLabor.uuid;
    shop = initLabor.shop;
    ({ label: alfredShopLabel, value: alfredShopValue } = getAlfredShopInfo(initLabor));
    isTransport = initLabor.labor.slug.includes('transport');

    for (const serviceLabor of serviceLabors) {
      if (serviceLabor?.startDate && serviceLabor?.endDate) {
        const labelIndex = Math.min(activeStep, serviceLaborsLength - 1);

        laborLabel = getLaborLabel(serviceLabors[labelIndex].labor);
        serviceLaborUuid = serviceLabors[labelIndex].uuid;
        shop = serviceLabors[labelIndex].shop;
        ({ label: alfredShopLabel, value: alfredShopValue } = getAlfredShopInfo(serviceLabors[labelIndex]));
        isTransport = serviceLabors[labelIndex].labor.slug.includes('transport');

        activeStep++;
      }
    }
  }

  return {
    serviceLaborUuid,
    shop,
    laborLabel,
    alfredShopValue,
    alfredShopLabel,
    serviceLaborsLength,
    activeStep,
    isTransport,
  };
}

export function getTransportAddresses (service: ServiceModel, labor: ServiceLaborsModel) {
  const startAddress = service.startAddress?.address ?? '-';
  const endAddress = service.endAddress?.address ?? '-';

  if (!labor) {
    return { startAddress, endAddress };
  }

  const laborIndex = service.serviceLabors.findIndex((serviceLabor) => serviceLabor.uuid === labor.uuid);

  let newStartAddress = startAddress;
  let newEndAddress = endAddress;

  if (laborIndex === 0) {
    newEndAddress = service.serviceLabors[laborIndex + 1]?.shop?.address?.address ?? '-';
  } else if (laborIndex === service.serviceLabors.length - 1) {
    newStartAddress = service.serviceLabors[laborIndex - 1]?.shop?.address.address ?? '-';
  } else {
    newStartAddress = service.serviceLabors[laborIndex - 1]?.shop?.address.address ?? '-';
    newEndAddress = service.serviceLabors[laborIndex + 1]?.shop?.address.address ?? '-';
  }

  return {
    startAddress: newStartAddress,
    endAddress: newEndAddress,
  };
}
