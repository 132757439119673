
export const IconWarning = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <circle cx='10.0005' cy='9.99805' r='8.92308' stroke='#F7CE46' strokeWidth='2.15385' />
    <path d='M9.02249 5.19707H11.5145V5.47707L10.8565 11.6651H9.68049L9.02249 5.47707V5.19707ZM11.4025 14.9971H9.13449V12.7571H11.4025V14.9971Z' fill='#F7CE46' />
  </svg>

);

export default IconWarning;
