
export const IconCancel = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width='18'
    height='18'
    viewBox='0 0 18 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <circle cx='9.00014' cy='8.99916' r='7.64835' stroke='#E11900' strokeWidth='1.84615' />
    <path d='M5.82497 12.75L5.24805 12.1731L8.42112 9L5.24805 5.82692L5.82497 5.25L8.99805 8.42308L12.1711 5.25L12.748 5.82692L9.57497 9L12.748 12.1731L12.1711 12.75L8.99805 9.57692L5.82497 12.75Z' fill='#E11900' stroke='#E11900' strokeWidth='0.818011' />
  </svg>

);

export default IconCancel;
