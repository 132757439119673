
export const IconNextNavigate = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='30'
    height='30'
    viewBox='0 0 30 30'
    fill='none'
    {...props}
  >
    <path d='M11.7187 22.5L10.375 21.1562L16.5625 14.9687L10.375 8.78125L11.7187 7.4375L19.25 14.9687L11.7187 22.5Z' fill='url(#paint0_linear_1539_2301)' />
    <defs>
      <linearGradient id='paint0_linear_1539_2301' x1='10.375' y1='7.4375' x2='23.5508' y2='15.2008' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#B1FC00' />
        <stop offset='1' stopColor='#19EB00' />
      </linearGradient>
    </defs>
  </svg>
);

export default IconNextNavigate;
