
export const IconSort = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='20'
    height='16'
    viewBox='0 0 20 16'
    fill='#fff'
    {...props}
  >
    <g clipPath='url(#clip0_972_3228)'>
      <path d='M13.8334 14.6901C12.3101 14.6901 10.7848 14.6939 9.26152 14.6863C9.04271 14.6863 8.81338 14.6691 8.60509 14.6101C7.98233 14.4368 7.58468 13.8407 7.68146 13.2674C7.78666 12.658 8.34631 12.2028 9.01325 12.2009C12.1965 12.199 15.3798 12.1971 18.5651 12.2009C19.2699 12.2009 19.8085 12.5647 19.9579 13.1455C20.1641 13.9454 19.5813 14.6634 18.6682 14.6824C17.8351 14.6996 16.9998 14.6882 16.1666 14.6882C15.3903 14.6882 14.6118 14.6882 13.8355 14.6882L13.8334 14.6901Z' fill='white' />
      <path d='M2.08696 13.5951V2.5074C2.03436 2.55368 2.00491 2.57893 1.97545 2.60839C1.65776 2.92608 1.34006 3.24377 1.02027 3.56147C0.870888 3.70874 0.694157 3.79711 0.477452 3.75713C0.260747 3.71716 0.102952 3.60144 0.0335217 3.38263C-0.038012 3.15751 0.00406667 2.95764 0.168174 2.79143C0.584753 2.36854 1.00764 1.95196 1.42633 1.53117C1.61989 1.33761 1.81345 1.13984 2.00912 0.946277C2.07434 0.881055 2.13956 0.817937 2.21109 0.759027C2.57718 0.451853 2.99586 0.601232 3.22729 0.830561C3.84795 1.44491 4.4623 2.06557 5.07876 2.68202C5.25549 2.85875 5.37962 3.05442 5.3186 3.32162C5.22814 3.71926 4.76317 3.8981 4.43285 3.65825C4.3529 3.59934 4.28136 3.5257 4.21194 3.45417C3.92159 3.15962 3.63125 2.86086 3.34091 2.56631C3.31987 2.54527 3.29462 2.53054 3.25044 2.49688V13.6351C3.29462 13.5972 3.31987 13.5783 3.34301 13.5573C3.66912 13.229 3.99313 12.9008 4.31924 12.5705C4.46862 12.419 4.64955 12.3349 4.86205 12.3685C5.31019 12.4401 5.49533 12.9682 5.18816 13.3195C4.92096 13.6246 4.62851 13.9065 4.34238 14.1948C3.98471 14.5524 3.62494 14.9101 3.25675 15.2572C3.14524 15.3624 3.00848 15.4634 2.86542 15.5097C2.59611 15.5981 2.34575 15.5097 2.14798 15.314C1.57571 14.7502 1.00975 14.1821 0.441685 13.6141C0.357528 13.5299 0.27337 13.45 0.193421 13.3616C-0.0506356 13.0944 -0.0569474 12.7388 0.174485 12.5179C0.414334 12.2886 0.765691 12.3075 1.03079 12.5705C1.34638 12.884 1.65986 13.1996 1.97335 13.5173C2.0028 13.5488 2.02174 13.5909 2.04699 13.6267C2.06171 13.6162 2.07855 13.6057 2.09327 13.5951H2.08696Z' fill='white' />
      <path d='M12.7116 6.73972C13.8771 6.73972 15.0427 6.73592 16.2104 6.74352C16.4292 6.74352 16.6585 6.76253 16.8668 6.82336C17.4622 6.99445 17.8283 7.49252 17.7926 8.04381C17.7568 8.62741 17.3507 9.06274 16.7195 9.18631C16.5765 9.21482 16.425 9.22813 16.2777 9.22813C13.9192 9.23003 11.5607 9.22813 9.20219 9.23193C8.64044 9.23193 8.17757 9.07225 7.87671 8.63312C7.6053 8.23961 7.58847 7.81569 7.83253 7.40697C8.07448 7.00206 8.45319 6.75303 8.97707 6.74922C10.2226 6.74162 11.466 6.74732 12.7116 6.74732C12.7116 6.74542 12.7116 6.74352 12.7116 6.73972Z' fill='white' />
      <path d='M11.6264 3.72718C10.7659 3.72718 9.90749 3.72909 9.04698 3.72718C8.26222 3.72527 7.65839 3.15996 7.67312 2.44951C7.68574 1.84982 8.1991 1.32844 8.85342 1.25778C8.98387 1.24441 9.11431 1.23486 9.24475 1.23486C10.8458 1.23486 12.449 1.23486 14.0501 1.23486C14.3994 1.23486 14.736 1.28261 15.0285 1.47932C15.5103 1.8059 15.7038 2.35211 15.5166 2.8754C15.3314 3.38914 14.8349 3.72336 14.2332 3.72527C13.3643 3.72909 12.4953 3.72527 11.6264 3.72527V3.72718Z' fill='white' />
    </g>
    <defs>
      <clipPath id='clip0_972_3228'>
        <rect width='20' height='14.959' fill='white' transform='translate(0 0.585449)' />
      </clipPath>
    </defs>
  </svg>

);
