import { useMemo } from 'react';
import { useDateTimeStore } from './dateAndTimeStore';
import { toCalendarDateTime } from '@internationalized/date';
import { toWeekDayDateTime } from '../utils/dateUtils';

const useDateTime = () => {
  const {
    selectedDate,
    selectedTime,
    setDate,
    setTime,
    isSlotsLoading,
    setIsSlotsLoading,
    modalStatus,
    setModalStatus,
  } = useDateTimeStore();

  const scheduleLabel = useMemo(() => {
    const date = selectedDate && selectedTime && toCalendarDateTime(selectedDate, selectedTime).toDate('America/Bogota');
    return date ? toWeekDayDateTime(date) : '-';
  }, [selectedDate, selectedTime]);

  return {
    selectedDate,
    selectedTime,
    setDate,
    setTime,
    scheduleLabel,
    isSlotsLoading,
    setIsSlotsLoading,
    modalStatus,
    setModalStatus,
  };
};

export default useDateTime;
