'use client';
import React, { FC, useMemo, useState } from 'react';
import { Card, CardBody, EventDataModal } from '.';
import { IconNextNavigate } from '../icons';
import { EventTrackingModel } from '@alfred-co/types';

interface ProtocolCardProps {
  isRequired?: boolean
  title: string
  description?: string
  labor: string
  event: EventTrackingModel
  onHandlePress?: () => void
  isTransport?: boolean
}

export const ProtocolCard: FC<ProtocolCardProps> = ({
  isRequired,
  labor,
  title,
  description,
  event,
  onHandlePress,
  isTransport,
}: ProtocolCardProps) => {
  const [open, setOpen] = useState(false);

  const clickeable = useMemo(() => {
    return Object.keys(event.data).some((key) => ['photo', 'image'].some((k) => key.includes(k))) || !isTransport;
  }, [event.data, isTransport]);

  return (
    <>
      <Card
        isPressable={isRequired && clickeable}
        fullWidth
        radius='sm'
        onPress={() => isRequired && clickeable && setOpen(true)}
        className='bg-secondary-op-gradient-hover border border-complementary-gray-light'
        isHoverable={isRequired && clickeable}
      >
        <CardBody className='flex flex-row overflow-visible py-3 px-5 justify-between h-14 items-center'>
          <div className='flex flex-row gap-2'>
            <div className='flex flex-col gap-2 justify-center'>
              <div className='text-sm font-bold text-white'>{title}</div>
              {description && <div className='text-xs text-white'>{description}</div>}
            </div>
          </div>
          {
            isRequired && clickeable &&
              <IconNextNavigate className='[&_path]:fill-white' />
          }
        </CardBody>
      </Card>
      <EventDataModal
        isOpen={open}
        onClose={() => setOpen(false)}
        labor={title}
        title={`Protocolo ${labor}`}
        event={event}
        isTransport={isTransport}
      />
    </>
  );
};
