
export const IconFile = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='27' height='34' viewBox='0 0 27 34' fill='none' {...props}>
    <g clipPath='url(#clip0_4483_14600)'>
      <path d='M23.1931 5.28576V6.44125C23.1931 14.5461 23.1931 22.6517 23.1931 30.7566C23.1931 32.9424 22.434 33.7025 20.2525 33.7032C14.4685 33.7047 8.68522 33.7047 2.90121 33.7032C0.801187 33.7032 0.028509 32.9403 0.028509 30.8717C0.0277943 21.8974 0.0721107 12.9237 -0.000796969 3.95079C-0.0200961 1.54114 1.5174 -0.0226332 3.94193 0.000247752C10.3035 0.0617404 16.6657 0.0181235 23.028 0.0224137C25.7735 0.0245588 27.2903 1.8057 26.9021 4.53783C26.8221 5.10271 26.4633 5.27932 25.9665 5.28361C25.0787 5.29148 24.191 5.28576 23.1938 5.28576H23.1931ZM21.6771 5.2879H20.6578C16.1897 5.2879 11.7223 5.2879 7.25423 5.2879C5.99955 5.2879 5.32932 4.65438 5.24355 3.38735C5.22568 3.12994 5.17565 2.85608 5.06343 2.62799C4.70746 1.90295 4.11348 1.50753 3.29363 1.54972C2.47806 1.59119 1.90266 2.02379 1.64177 2.79316C1.52669 3.13137 1.51168 3.51606 1.51096 3.88001C1.50453 12.8772 1.50525 21.8745 1.50596 30.871C1.50596 32.0236 1.67108 32.1859 2.82688 32.1867C8.66163 32.1874 14.4957 32.1874 20.3304 32.1867C21.562 32.1867 21.6756 32.0723 21.6756 30.8331C21.6756 22.6253 21.6756 14.4174 21.6756 6.20958C21.6756 5.94931 21.6756 5.68832 21.6756 5.28862L21.6771 5.2879ZM6.85538 3.72627H25.3518C25.4847 2.35771 24.7235 1.54185 23.3132 1.54042C17.7722 1.53542 12.2312 1.54042 6.69027 1.54257C6.56947 1.54257 6.44796 1.56688 6.35575 1.57689C6.52301 2.29621 6.68097 2.97692 6.85467 3.72627H6.85538Z' fill='white' />
      <path d='M11.5694 7.51926C13.8281 7.51926 16.0861 7.52426 18.3448 7.51568C18.8881 7.51354 19.4142 7.58147 19.4263 8.24573C19.4385 8.91142 18.9231 9.00295 18.3734 9.00223C13.8567 8.99651 9.33932 8.99651 4.82262 9.00223C4.26867 9.00295 3.7626 8.90427 3.78333 8.23501C3.80406 7.56645 4.333 7.51354 4.87337 7.51568C7.10563 7.52426 9.33789 7.51926 11.5702 7.51926H11.5694Z' fill='white' />
      <path d='M11.5697 20.2095C9.33747 20.2095 7.10521 20.206 4.87295 20.2131C4.33829 20.2146 3.79435 20.1724 3.78291 19.4974C3.77076 18.7745 4.32972 18.7259 4.89868 18.7266C9.3632 18.733 13.8277 18.7301 18.2915 18.7301C18.3702 18.7301 18.4488 18.7323 18.5281 18.7301C19.0492 18.7187 19.4338 18.9325 19.4273 19.4838C19.4209 20.0437 19.0135 20.2117 18.5024 20.211C16.1915 20.206 13.8806 20.2088 11.5697 20.2095Z' fill='white' />
      <path d='M8.58133 16.5039C7.32331 16.5039 6.0653 16.5039 4.80728 16.5039C4.2569 16.5039 3.76085 16.3716 3.78086 15.7095C3.79944 15.1067 4.26048 14.9759 4.7887 14.9766C7.33118 14.9823 9.87365 14.9816 12.4161 14.9773C12.9722 14.9766 13.4604 15.1081 13.444 15.766C13.429 16.3716 12.9522 16.5024 12.4347 16.5024C11.1502 16.5024 9.86579 16.5024 8.58204 16.5024L8.58133 16.5039Z' fill='white' />
      <path d='M8.53872 12.7473C7.2807 12.7473 6.02269 12.7402 4.76539 12.7509C4.22787 12.7552 3.789 12.6 3.77899 12.0001C3.76827 11.3852 4.23145 11.2357 4.75395 11.2357C7.32216 11.2343 9.89037 11.2336 12.4579 11.2357C12.9761 11.2357 13.4443 11.3766 13.4457 11.9915C13.4471 12.5993 13.004 12.753 12.47 12.7502C11.1598 12.7437 9.84963 12.748 8.53943 12.748L8.53872 12.7473Z' fill='white' />
      <path d='M16.4409 29.9587C15.7597 29.9587 15.0778 29.9587 14.3966 29.9587C13.8855 29.9587 13.4945 29.7628 13.4874 29.2087C13.4809 28.6466 13.8884 28.4486 14.3873 28.4443C15.7504 28.4343 17.1127 28.4371 18.4758 28.4414C19.0076 28.4429 19.4508 28.6366 19.4258 29.2373C19.4036 29.7771 18.9955 29.9666 18.4844 29.9609C17.8032 29.953 17.1213 29.9587 16.4401 29.9587H16.4409Z' fill='white' />
      <path d='M17.5366 12.745C17.1957 12.745 16.8183 12.8373 16.5245 12.72C16.2107 12.5949 15.9755 12.2717 15.7061 12.035C15.9491 11.7747 16.1657 11.3271 16.4401 11.2892C17.1849 11.1848 17.964 11.1848 18.7088 11.2892C18.9826 11.3278 19.1992 11.7747 19.4415 12.035C19.1735 12.2717 18.9404 12.6042 18.6274 12.7193C18.3043 12.838 17.9033 12.7458 17.5359 12.7458L17.5366 12.745Z' fill='white' />
      <path d='M17.5563 16.4781C17.1632 16.4781 16.7351 16.5754 16.3891 16.4474C16.1103 16.3444 15.7522 15.9647 15.7594 15.718C15.7665 15.4685 16.1582 15.0545 16.4206 15.0209C17.1682 14.9243 17.9459 14.9165 18.6914 15.0194C18.9695 15.0581 19.3397 15.4721 19.3905 15.7638C19.4241 15.9597 19.0109 16.3544 18.7257 16.4538C18.3755 16.5761 17.9502 16.4831 17.5571 16.4831C17.5571 16.4817 17.5571 16.4803 17.5571 16.4788L17.5563 16.4781Z' fill='white' />
    </g>
    <defs>
      <clipPath id='clip0_4483_14600'>
        <rect width='26.963' height='33.7037' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
