import React, { FC } from 'react';
import { NumericFormat } from 'react-number-format';
import { Input } from './Input';

export const PriceFormatInput: FC<any> = (props) => {
  const {
    inputRef, onChange, name, ...other
  } = props;

  return (
    <NumericFormat
      {...other}
      customInput={Input}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name,
            value: values.value,
          },
        });
      }}
      thousandSeparator='.'
      decimalSeparator=','
      prefix='$'
      allowLeadingZeros={false}
      allowNegative={false}
    />
  );
};
