import { CalendarDate, Time } from '@internationalized/date';
import { create } from 'zustand';

interface DateAndTimeStore {
  selectedDate?: CalendarDate
  setDate: (date: CalendarDate | undefined) => void
  selectedTime?: Time
  setTime: (time: Time | undefined) => void
  isSlotsLoading: boolean
  setIsSlotsLoading: (loading: boolean) => void
  modalStatus: boolean
  setModalStatus: (status: boolean) => void
};

export const useDateTimeStore = create<DateAndTimeStore>((set, get) => ({
  setDate: (date) => set({ selectedDate: date }),
  setTime: (time) => set({ selectedTime: time }),
  isSlotsLoading: false,
  setIsSlotsLoading: (loading) => set({ isSlotsLoading: loading }),
  modalStatus: false,
  setModalStatus: (status) => set({ modalStatus: status }),
}));
