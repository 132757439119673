export const IconWhiteAlfred = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width='268' height='100' viewBox='0 0 268 100' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path d='M267.974 6.62443C267.64 10.2888 264.375 13.2489 260.665 13.2489C256.955 13.2489 254.226 10.2888 254.56 6.62443C254.894 2.97109 258.158 -4.3019e-07 261.868 -2.68017e-07C265.579 -1.05845e-07 268.297 2.96009 267.974 6.62443ZM267.328 6.62443C267.629 3.2792 265.166 0.583214 261.813 0.583214C258.459 0.583213 255.507 3.2682 255.206 6.62443C254.905 9.95865 257.379 12.6656 260.71 12.6656C264.041 12.6656 267.016 9.94765 267.317 6.62443M262.905 7.47174L264.086 10.0137L262.236 10.0137L261.334 7.87889L260.498 7.87889L260.298 10.0137L258.593 10.0137L259.206 3.24619L261.746 3.24619C263.283 3.24619 264.286 4.19254 264.175 5.458C264.097 6.37134 263.618 7.06459 262.893 7.47174M260.621 6.41535L261.367 6.41535C262.013 6.41535 262.414 6.08523 262.459 5.61206C262.504 5.08386 262.158 4.80876 261.512 4.80876L260.777 4.80876L260.632 6.41535L260.621 6.41535Z' fill='white' />
    <path d='M57.8696 27.4729L49.3019 75.5606L31.2864 75.5606L32.0663 71.335C28.5567 74.7903 23.9888 76.9141 18.4292 76.9141C4.99277 76.9141 -1.52492 64.7986 0.324542 51.3297C2.17401 38.2459 11.4213 26.1304 25.6377 26.1304C31.8657 26.1304 36.3445 28.4413 38.8848 32.8649L39.8541 27.4839L57.8696 27.4839L57.8696 27.4729ZM35.4755 51.8028L35.5758 51.5167C36.456 46.4219 34.1163 42.4824 28.5679 42.4824C23.0195 42.4824 19.12 46.2348 18.251 51.5167C17.3708 56.6116 19.8108 60.5511 25.3592 60.5511C30.9076 60.5511 34.5062 56.9968 35.4867 51.8028' fill='white' />
    <path d='M60.1318 44.3907L66.8389 5.33734L84.8545 5.33734L78.0583 44.6768L72.599 75.5431L54.5834 75.5431L60.1318 44.3907Z' fill='white' />
    <path d='M104.163 27.485L101.144 44.4092L91.217 100.002L73.2015 100.002L83.1284 44.4092L86.1477 27.485C88.6768 13.3448 98.5146 5.75203 115.36 6.70938L112.542 22.6762C107.773 22.2911 104.754 24.4039 104.163 27.485Z' fill='white' />
    <path d='M129.489 44.4058C131.906 37.4513 136.53 31.6522 142.468 27.7237L113.824 27.5147C111.228 27.4927 109 29.3303 108.543 31.8613L106.303 44.4058L100.755 75.5583L118.771 75.5583L123.628 48.2793C124.029 46.0344 126.001 44.3948 128.308 44.3948L129.489 44.3948L129.489 44.4058Z' fill='white' />
    <path d='M182.387 51.3274C182.097 53.0551 181.507 55.2669 180.537 57.5777L148.796 57.5777C150.066 61.5172 153.865 62.3865 157.464 62.3865C161.062 62.3865 163.981 61.5172 166.421 59.3054L176.839 69.6932C171.19 74.5019 164.472 76.9008 155.804 76.9008C137.688 76.9008 129.51 64.7854 131.95 50.5461C134.29 36.505 146.166 26.1172 160.773 26.1172C176.159 26.1172 184.827 37.7485 182.387 51.3164M166.611 46.1225C166.321 41.7979 163.692 40.3564 160.082 40.3564C156.182 40.3564 152.684 42.084 150.545 46.1225L166.611 46.1225Z' fill='white' />
    <path d='M247.339 8.25104L235.362 75.5627L217.347 75.5627L218.127 71.3372C214.617 74.7924 210.049 76.9162 204.49 76.9162C191.053 76.9162 184.536 64.8008 186.385 51.3318C188.234 38.248 197.482 26.1326 211.698 26.1326C217.926 26.1326 222.405 28.4434 224.945 32.8671L229.324 8.25104L247.339 8.25104ZM221.536 52.1021L221.737 51.1448C222.416 46.237 220.076 42.4956 214.628 42.4956C209.18 42.4956 205.18 46.248 204.311 51.5299C203.431 56.6248 205.871 60.5752 211.42 60.5752C216.678 60.5752 220.478 57.109 221.547 52.1131' fill='white' />
  </svg>
);
