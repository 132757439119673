import { extendVariants } from '@nextui-org/react';
import { DateInputProps, useDateInput, DateInputField, DateInputFieldProps, DateInputGroup, DateInputGroupProps, DateInputSegment, DateInputValue, TimeInput, TimeInputProps, TimeInputValue, useTimeInput, DateInput as NextUiDateInput } from '@nextui-org/date-input';

export const DateInput: React.FC<DateInputProps> = extendVariants(NextUiDateInput, {
  variants: {
    color: {
      default: {
        inputWrapper: [
          'bg-secondary-op-gradient-hover',
          'border-complementary-gray-light border-small',
          'group-hover:border-complementary-gray-light',
          'focus:border-complementary-gray-light',
          'group-focus:border-complementary-gray-light',
          'has-[:disabled]:bg-complementary-gray-light bg-none'
        ],
        label: ['text-white text-md font-medium'],
        innerWrapper: ['has-[:disabled]:bg-complementary-gray-light'],
        description: ['text-white'],
        input: [
          '!text-white',
          'placeholder:text-white text-base font-extralight',
          'disabled:bg-complementary-gray-light',
          'text-sm font-gothamBook'
        ],
      },
    },
  },
  defaultVariants: {
    size: 'md',
    variant: 'bordered',
    labelPlacement: 'outside',
    radius: 'md',
  },
});

export {
  type DateInputProps,
  useDateInput,
  DateInputField,
  type DateInputFieldProps,
  DateInputGroup,
  type DateInputGroupProps,
  DateInputSegment,
  type DateInputValue,
  TimeInput,
  type TimeInputProps,
  type TimeInputValue,
  useTimeInput
};
