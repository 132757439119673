import IconUnderConstruction from '../icons/UnderConstruction';
import React, { FC } from 'react';

export interface UnderConstructionProps {
  title: string
}

export const UnderConstruction: FC<UnderConstructionProps> = ({
  title,
}) => {
  return (
    <div className='flex flex-col rounded-full p-28 bg-blue-radial-gradient items-center text-lg gap-1'>
      <IconUnderConstruction />
      <div>{title}</div>
      <div>EN CONSTRUCCION</div>
    </div>
  );
};
