
export const IconOilChange = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='91'
    height='91'
    viewBox='0 0 91 91'
    fill='none'
    {...props}
  >
    <g clipPath='url(#clip0_1166_40814)'>
      <circle cx='43.5507' cy='45.849' r='57.85' fill='url(#paint0_radial_1166_40814)' />
      <path d='M73.0429 10.9994C71.4059 9.43769 65.4036 6.31423 62.8446 5.01592C62.4683 4.79013 62.0825 4.60197 61.678 4.43262C57.8771 2.84267 52.6463 3.52946 46.8604 6.8693L38.1391 11.9026C36.2387 13.0033 34.6958 15.4306 34.6958 17.331V51.1058V53.3167C34.6958 53.4013 34.6958 53.4954 34.6958 53.5801C34.7522 54.9725 35.7119 56.3555 37.5464 57.4186C39.9549 58.8109 43.3135 59.319 46.4088 58.9521C46.2206 58.5099 46.2112 58.0019 46.3147 57.5409C46.4276 57.0328 46.851 56.5154 47.3025 56.2614C47.7353 56.0074 48.1587 55.9603 48.6197 56.0074C48.5726 56.0074 48.535 55.9979 48.4879 55.9885C48.6197 55.9979 48.742 56.0074 48.8737 55.998C49.3347 55.9039 49.7863 55.7533 50.2472 55.6122C50.6706 55.4805 51.094 55.3582 51.5079 55.2077C51.6114 55.1606 51.7149 55.1042 51.809 55.0477C51.9783 54.7655 52.1288 54.4739 52.317 54.2104C52.6557 53.7306 53.0414 53.4013 53.5965 53.2226C53.7376 53.1755 53.8882 53.1473 54.0293 53.1473C53.8787 51.8396 52.9285 50.5601 51.1975 49.5535C50.1344 48.9419 48.8925 48.4998 47.566 48.2269L65.2154 38.0381C66.6736 37.2007 67.859 35.3285 67.859 33.8703V15.7411C67.859 15.4494 67.859 15.1578 67.8402 14.8755C70.4745 15.6282 72.7512 16.2679 72.8641 16.2491C73.1369 16.2209 75.1409 12.9939 73.0335 10.9994H73.0429Z' fill='url(#paint1_linear_1166_40814)' />
      <path d='M74.3503 43.2706L48.3654 58.267C45.8534 59.7158 43.8213 58.8597 43.8213 56.3477V22.573C43.8213 20.6726 45.3642 18.2453 47.2646 17.1446L55.9858 12.1113C67.5859 5.41278 76.9939 9.39237 76.9939 20.9924V39.1123C76.9939 40.5705 75.8085 42.4333 74.3503 43.28V43.2706Z' fill='#0096FB' />
      <g style={{ mixBlendMode: 'overlay' }} opacity='0.3'>
        <path d='M39.2972 16.5554C37.1051 15.2853 37.378 13.0744 39.9087 11.6067L50.0317 5.76436C52.5719 4.29671 56.401 4.13678 58.6024 5.40686C60.7945 6.67694 60.5217 8.88782 57.9909 10.3555L47.8679 16.2073C45.3372 17.6749 41.4987 17.8254 39.2972 16.5648V16.5554Z' fill='white' />
      </g>
      <path d='M49.9849 13.4444V12.7294L47.6329 12.7388C45.5631 11.845 42.6937 11.8639 40.6616 12.7764H38.4507V13.4256C38.4507 14.3005 39.0246 15.1473 40.1535 15.7964C42.4115 17.1041 46.0618 17.1041 48.3009 15.8152C49.4298 15.1661 49.9849 14.3099 49.9755 13.4538L49.9849 13.4444Z' fill='#D1D4F8' />
      <path d='M48.3103 15.1438C46.0712 16.4421 42.4209 16.4327 40.1629 15.125C37.905 13.8173 37.8956 11.7099 40.1347 10.421C42.3832 9.1227 46.0241 9.13211 48.282 10.4398C50.54 11.7475 50.5494 13.8549 48.3103 15.1438Z' fill='#F7F9FC' />
      <path d='M49.3433 12.3911V10.0109L47.2548 10.0203C45.4108 9.23003 42.8706 9.23944 41.0643 10.0485H39.1074V12.3629C39.1074 13.1344 39.6155 13.887 40.6221 14.4703C42.626 15.6275 45.8718 15.6369 47.8569 14.4797C48.8541 13.9058 49.3527 13.1438 49.3433 12.3817V12.3911Z' fill='url(#paint2_linear_1166_40814)' />
      <path d='M47.8491 12.1595C45.8546 13.3073 42.6182 13.3073 40.6143 12.1501C38.6104 10.9929 38.601 9.12072 40.5955 7.97294C42.59 6.82516 45.8263 6.82516 47.8302 7.98235C49.8341 9.13954 49.8436 11.0117 47.8491 12.1595Z' fill='url(#paint3_linear_1166_40814)' />
      <path d='M65.5931 43.9269L51.3964 52.1683C49.5148 53.2502 47.9907 52.6105 47.9907 50.7289V31.5553C47.9907 30.1347 49.1479 28.3096 50.5685 27.4911L51.8386 26.7196C63.4763 20.3316 67.5782 22.7306 67.5782 33.3711V40.8034C67.5782 41.8947 66.6938 43.2965 65.6025 43.9269H65.5931Z' fill='#0364A6' />
      <path d='M60.1325 14.357L50.4423 19.9548C49.2475 20.651 48.269 22.1751 48.269 23.3793C48.269 24.5836 49.2381 24.9881 50.4423 24.2919L60.1325 18.6941C61.3274 17.9979 62.3058 16.4738 62.3058 15.2696C62.3058 14.0654 61.3368 13.6609 60.1325 14.357Z' fill='#008600' />
      <path d='M59.843 14.2311L50.1527 19.8288C48.9579 20.525 47.9795 22.0491 47.9795 23.2534C47.9795 24.4576 48.9485 24.8621 50.1527 24.1659L59.843 18.5682C61.0378 17.872 62.0162 16.3479 62.0162 15.1436C62.0162 13.9394 61.0472 13.5349 59.843 14.2311Z' fill='#0364A5' />
      <path d='M69.479 26.2588C69.479 27.1338 70.1846 27.4348 71.0596 26.9268L76.996 23.5023V20.9997C76.996 20.7834 76.996 20.5576 76.9866 20.3506L71.0596 23.7751C70.1846 24.2831 69.479 25.3933 69.479 26.2682V26.2588Z' fill='#027B02' />
      <path d='M69.2715 26.1516C69.2715 27.0266 69.9771 27.3276 70.852 26.8196L76.9955 23.2728V20.9866C76.9955 20.695 76.9955 20.4033 76.9766 20.1211L70.852 23.6585C69.9771 24.1665 69.2715 25.2767 69.2715 26.1516Z' fill='#0364A5' />
      <path d='M69.479 33.0088C69.479 33.8838 70.1846 34.1848 71.0596 33.6768L76.996 30.2523V27.7497C76.996 27.5334 76.996 27.3076 76.9866 27.1006L71.0596 30.5251C70.1846 31.0331 69.479 32.1433 69.479 33.0182V33.0088Z' fill='#017401' />
      <path d='M69.2715 32.9143C69.2715 33.7893 69.9771 34.0809 70.852 33.5823L76.9955 30.0355V27.7493C76.9955 27.4577 76.9955 27.166 76.9766 26.8838L70.852 30.4212C69.9771 30.9292 69.2715 32.0394 69.2715 32.9143Z' fill='#0364A5' />
      <path d='M69.479 39.7559C69.479 40.6308 70.1846 40.9319 71.0596 40.4239L76.996 36.9993V34.4968C76.996 34.2804 76.996 34.0546 76.9866 33.8477L71.0596 37.2722C70.1846 37.7802 69.479 38.8904 69.479 39.7653V39.7559Z' fill='#006300' />
      <path d='M69.2715 39.6594C69.2715 40.5344 69.9771 40.8354 70.852 40.3274L76.9955 36.7806V34.4944C76.9955 34.2028 76.9955 33.9111 76.9766 33.6289L70.852 37.1663C69.9771 37.6743 69.2715 38.7845 69.2715 39.6594Z' fill='#0364A5' />
      <path d='M57.8967 26.9059C57.6992 27.1787 53.2021 33.595 53.2021 37.6498C53.2021 40.2088 55.3001 41.0744 57.8967 39.5785C60.4934 38.0732 62.5913 34.7898 62.5913 32.2214C62.5913 28.1572 58.0943 26.9435 57.8967 26.8965V26.9059Z' fill='#191468' />
      <path d='M62.854 39.9561L52.9379 45.6667C52.0912 46.156 51.395 47.2473 51.395 48.094C51.395 48.9407 52.0818 49.2324 52.9379 48.7432L62.854 43.0325C63.7007 42.5433 64.3969 41.452 64.3969 40.6052C64.3969 39.7585 63.7101 39.4669 62.854 39.9561Z' fill='url(#paint4_linear_1166_40814)' />
      <path d='M52.2357 38.7533C50.5987 37.1916 44.5964 34.0681 42.0374 32.7698C41.6611 32.544 41.2754 32.3559 40.8709 32.1865C37.07 30.5966 31.8392 31.2834 26.0532 34.6232L17.332 39.6565C15.4316 40.7572 13.8887 43.1845 13.8887 45.0849V78.8597V81.0706C13.8887 81.1552 13.8887 81.2493 13.8887 81.334C13.9451 82.7264 14.9047 84.1094 16.7393 85.1725C19.1477 86.5648 22.5064 87.0729 25.6016 86.706C25.4135 86.2638 25.4041 85.7558 25.5076 85.2948C25.6205 84.7867 26.0438 84.2693 26.4954 84.0153C26.9282 83.7613 27.3515 83.7142 27.8125 83.7613C27.7655 83.7613 27.7279 83.7519 27.6808 83.7424C27.8125 83.7519 27.9348 83.7613 28.0665 83.7519C28.5275 83.6578 28.9791 83.5072 29.4401 83.3661C29.8635 83.2344 30.2868 83.1121 30.7008 82.9616C30.8043 82.9145 30.9078 82.8581 31.0019 82.8016C31.1712 82.5194 31.3217 82.2278 31.5099 81.9643C31.8486 81.4845 32.2343 81.1552 32.7894 80.9765C32.9305 80.9295 33.081 80.9012 33.2221 80.9012C33.0716 79.5935 32.1214 78.314 30.3903 77.3074C29.3272 76.6958 28.0854 76.2537 26.7588 75.9808L44.4083 65.792C45.8665 64.9546 47.0519 63.0824 47.0519 61.6242V43.495C47.0519 43.2033 47.0519 42.9117 47.0331 42.6294C49.6673 43.3821 51.9441 44.0218 52.057 44.003C52.3298 43.9748 54.3337 40.7478 52.2263 38.7533H52.2357Z' fill='url(#paint5_linear_1166_40814)' />
      <path d='M53.5456 71.0245L27.5607 86.0209C25.0487 87.4697 23.0166 86.6136 23.0166 84.1017V50.3269C23.0166 48.4265 24.5595 45.9992 26.4599 44.8985L35.1812 39.8652C46.7812 33.1667 56.1892 37.1463 56.1892 48.7464V66.8662C56.1892 68.3244 55.0038 70.1872 53.5456 71.0339V71.0245Z' fill='#0096FB' />
      <g style={{ mixBlendMode: 'overlay' }} opacity='0.3'>
        <path d='M18.4901 44.3093C16.298 43.0392 16.5708 40.8283 19.1016 39.3606L29.2246 33.5183C31.7648 32.0506 35.5938 31.8907 37.7953 33.1608C39.9874 34.4308 39.7146 36.6417 37.1838 38.1094L27.0608 43.9612C24.53 45.4288 20.6916 45.5793 18.4901 44.3187V44.3093Z' fill='white' />
      </g>
      <path d='M29.1768 41.2042V40.4892L26.8248 40.4986C24.755 39.6048 21.8856 39.6236 19.8535 40.5362H17.6426V41.1854C17.6426 42.0603 18.2165 42.907 19.3454 43.5562C21.6034 44.8639 25.2537 44.8639 27.4928 43.575C28.6217 42.9258 29.1768 42.0697 29.1674 41.2136L29.1768 41.2042Z' fill='#D1D4F8' />
      <path d='M27.5017 42.9036C25.2626 44.2019 21.6123 44.1925 19.3543 42.8848C17.0964 41.5771 17.087 39.4697 19.3261 38.1808C21.5746 36.8825 25.2155 36.8919 27.4734 38.1996C29.7314 39.5073 29.7408 41.6147 27.5017 42.9036Z' fill='#F7F9FC' />
      <path d='M28.5357 40.1538V37.7736L26.4471 37.783C24.6032 36.9927 22.063 37.0021 20.2567 37.8112H18.2998V40.1256C18.2998 40.8971 18.8078 41.6497 19.8145 42.233C21.8184 43.3902 25.0642 43.3996 27.0493 42.2424C28.0465 41.6685 28.5451 40.9065 28.5357 40.1444V40.1538Z' fill='url(#paint6_linear_1166_40814)' />
      <path d='M27.041 39.9222C25.0465 41.07 21.8101 41.07 19.8062 39.9128C17.8023 38.7556 17.7929 36.8834 19.7874 35.7356C21.7819 34.5879 25.0182 34.5879 27.0221 35.745C29.026 36.9022 29.0355 38.7744 27.041 39.9222Z' fill='url(#paint7_linear_1166_40814)' />
      <path d='M44.7855 71.6818L30.5888 79.9232C28.7072 81.0051 27.1831 80.3654 27.1831 78.4838V59.3102C27.1831 57.8896 28.3403 56.0644 29.7609 55.2459L31.031 54.4745C42.6687 48.0864 46.7706 50.4855 46.7706 61.126V68.5583C46.7706 69.6496 45.8862 71.0514 44.7949 71.6818H44.7855Z' fill='#0364A6' />
      <path d='M39.323 42.1188L29.6327 47.7165C28.4379 48.4127 27.4595 49.9368 27.4595 51.1411C27.4595 52.3453 28.4285 52.7498 29.6327 52.0536L39.323 46.4559C40.5178 45.7597 41.4962 44.2356 41.4962 43.0313C41.4962 41.8271 40.5272 41.4226 39.323 42.1188Z' fill='#008600' />
      <path d='M39.0334 41.9928L29.3432 47.5906C28.1484 48.2867 27.1699 49.8108 27.1699 51.0151C27.1699 52.2193 28.1389 52.6238 29.3432 51.9277L39.0334 46.3299C40.2282 45.6337 41.2067 44.1096 41.2067 42.9054C41.2067 41.7011 40.2376 41.2966 39.0334 41.9928Z' fill='#0364A5' />
      <path d='M48.6709 54.0127C48.6709 54.8877 49.3765 55.1887 50.2514 54.6807L56.1879 51.2562V48.7536C56.1879 48.5373 56.1879 48.3115 56.1785 48.1045L50.2514 51.529C49.3765 52.037 48.6709 53.1472 48.6709 54.0221V54.0127Z' fill='#027B02' />
      <path d='M48.4634 53.9055C48.4634 54.7805 49.169 55.0815 50.0439 54.5735L56.1874 51.0267V48.7405C56.1874 48.4489 56.1874 48.1572 56.1685 47.875L50.0439 51.4124C49.169 51.9204 48.4634 53.0306 48.4634 53.9055Z' fill='#0364A5' />
      <path d='M48.6709 60.7686C48.6709 61.6435 49.3765 61.9446 50.2514 61.4366L56.1879 58.012V55.5095C56.1879 55.2931 56.1879 55.0673 56.1785 54.8604L50.2514 58.2849C49.3765 58.7929 48.6709 59.903 48.6709 60.778V60.7686Z' fill='#017401' />
      <path d='M48.4634 60.6673C48.4634 61.5422 49.169 61.8338 50.0439 61.3352L56.1874 57.7884V55.5023C56.1874 55.2106 56.1874 54.919 56.1685 54.6367L50.0439 58.1741C49.169 58.6822 48.4634 59.7923 48.4634 60.6673Z' fill='#0364A5' />
      <path d='M48.6709 67.5176C48.6709 68.3925 49.3765 68.6936 50.2514 68.1856L56.1879 64.7611V62.2585C56.1879 62.0421 56.1879 61.8164 56.1785 61.6094L50.2514 65.0339C49.3765 65.5419 48.6709 66.6521 48.6709 67.527V67.5176Z' fill='#006300' />
      <path d='M48.4634 67.4212C48.4634 68.2961 49.169 68.5972 50.0439 68.0891L56.1874 64.5423V62.2562C56.1874 61.9645 56.1874 61.6729 56.1685 61.3906L50.0439 64.928C49.169 65.4361 48.4634 66.5462 48.4634 67.4212Z' fill='#0364A5' />
      <path d='M37.0896 54.6598C36.892 54.9326 32.395 61.3489 32.395 65.4037C32.395 67.9627 34.493 68.8283 37.0896 67.3324C39.6862 65.8271 41.7842 62.5437 41.7842 59.9753C41.7842 55.9111 37.2872 54.6974 37.0896 54.6504V54.6598Z' fill='#191468' />
      <path d='M42.0473 67.71L32.1313 73.4207C31.2846 73.9099 30.5884 75.0012 30.5884 75.8479C30.5884 76.6946 31.2752 76.9863 32.1313 76.4971L42.0473 70.7864C42.8941 70.2972 43.5903 69.2059 43.5903 68.3591C43.5903 67.5124 42.9035 67.2208 42.0473 67.71Z' fill='url(#paint8_linear_1166_40814)' />
    </g>
    <defs>
      <radialGradient id='paint0_radial_1166_40814' cx='0' cy='0' r='1' gradientUnits='userSpaceOnUse' gradientTransform='translate(43.5854 45.849) scale(120.908)'>
        <stop offset='0.11' stopColor='#7E7E7E' />
        <stop offset='0.382315' stopColor='#2F2F2F' stopOpacity='0' />
      </radialGradient>
      <linearGradient id='paint1_linear_1166_40814' x1='34.6958' y1='30.8372' x2='73.9869' y2='30.8372' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#03609E' />
        <stop offset='1' stopColor='#0075C5' />
      </linearGradient>
      <linearGradient id='paint2_linear_1166_40814' x1='48.4872' y1='12.3911' x2='36.7931' y2='12.3911' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#191468' />
        <stop offset='1' stopColor='#383097' />
      </linearGradient>
      <linearGradient id='paint3_linear_1166_40814' x1='59.5432' y1='9.34651' x2='39.3254' y2='10.2967' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#191468' />
        <stop offset='1' stopColor='#383097' />
      </linearGradient>
      <linearGradient id='paint4_linear_1166_40814' x1='51.395' y1='44.2654' x2='64.3969' y2='44.2654' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#0096FB' />
        <stop offset='1' stopColor='#0075C5' />
      </linearGradient>
      <linearGradient id='paint5_linear_1166_40814' x1='13.8887' y1='58.5911' x2='53.1798' y2='58.5911' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#03609E' />
        <stop offset='1' stopColor='#0075C5' />
      </linearGradient>
      <linearGradient id='paint6_linear_1166_40814' x1='27.6796' y1='40.1538' x2='15.9854' y2='40.1538' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#191468' />
        <stop offset='1' stopColor='#383097' />
      </linearGradient>
      <linearGradient id='paint7_linear_1166_40814' x1='38.7351' y1='37.1092' x2='18.5173' y2='38.0594' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#191468' />
        <stop offset='1' stopColor='#383097' />
      </linearGradient>
      <linearGradient id='paint8_linear_1166_40814' x1='30.5884' y1='72.0193' x2='43.5903' y2='72.0193' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#0096FB' />
        <stop offset='1' stopColor='#0075C5' />
      </linearGradient>
      <clipPath id='clip0_1166_40814'>
        <rect width='101.111' height='101.111' fill='white' transform='translate(-5.05518 -5.05664)' />
      </clipPath>
    </defs>
  </svg>
);

export default IconOilChange;
