
export const IconAlfredFavicon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='18' height='20' viewBox='0 0 18 20' fill='none' {...props}>
    <g clipPath='url(#clip0_4483_14992)'>
      <path d='M1.30005 8.09484L2.62758 0.296875H6.19283L4.84754 8.15234L3.76864 14.3182H0.201172L1.30005 8.09484Z' fill='#141E3C' />
      <path d='M10.0175 4.71208L9.42031 8.09134L7.45565 19.1934H3.88818L5.85284 8.09134L6.45001 4.71208C6.95172 1.88791 8.89862 0.370783 12.233 0.563189L11.6736 3.75226C10.7279 3.67486 10.1307 4.09726 10.0153 4.71208' fill='#141E3C' />
      <path d='M15.0306 8.09142C15.5101 6.70256 16.4247 5.5437 17.6013 4.76081L11.9293 4.71879C11.4143 4.71437 10.9725 5.08149 10.8837 5.58572L10.4397 8.09142L9.34082 14.3148H12.9083L13.8695 8.86768C13.9494 8.41873 14.3402 8.09363 14.7952 8.09363H15.0283L15.0306 8.09142Z' fill='#141E3C' />
    </g>
    <defs>
      <clipPath id='clip0_4483_14992'>
        <rect width='17.4' height='18.9' fill='white' transform='translate(0.201172 0.296875)' />
      </clipPath>
    </defs>
  </svg>
);
export default IconAlfredFavicon;
