
export const IconUpload = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='30' height='26' viewBox='0 0 30 26' fill='none' {...props}>
    <g clipPath='url(#clip0_4400_14263)'>
      <path d='M16.0891 4.33496C16.0891 4.74893 16.0891 5.01581 16.0891 5.28118C16.0891 9.35266 16.0891 13.4257 16.0891 17.4971C16.0891 17.7473 16.1131 18.0082 16.0539 18.2477C15.929 18.7648 15.5911 19.0924 14.997 19.0954C14.4012 19.0984 14.0601 18.7754 13.9272 18.2614C13.8664 18.0218 13.8904 17.7625 13.8904 17.5108C13.8888 13.4393 13.8904 9.36631 13.8904 5.29483C13.8904 5.02643 13.8904 4.75803 13.8904 4.34103C13.5877 4.60184 13.3939 4.75651 13.2146 4.92483C11.5972 6.45183 9.98456 7.98337 8.36553 9.50733C7.69294 10.1397 7.06519 10.2018 6.57356 9.70901C6.09154 9.2268 6.162 8.68242 6.81217 8.06525C9.19506 5.80433 11.5812 3.54796 13.9705 1.29462C14.7263 0.581922 15.2548 0.578889 16.0058 1.28552C18.3967 3.53886 20.7812 5.79523 23.1641 8.05616C23.8335 8.69152 23.8928 9.28442 23.3611 9.75602C22.8647 10.1942 22.2705 10.1215 21.6252 9.5134C20.0045 7.98943 18.3935 6.45638 16.7761 4.92938C16.5952 4.75955 16.4014 4.60184 16.0923 4.33344L16.0891 4.33496Z' fill='white' />
      <path d='M2.87043 22.9435H27.1125C27.1125 21.7046 27.1125 20.5036 27.1125 19.3042C27.1125 19.0525 27.1077 18.8007 27.1157 18.5505C27.1397 17.903 27.4904 17.5057 28.1726 17.4436C28.762 17.3905 29.2792 17.8409 29.2904 18.4868C29.3208 20.3217 29.3224 22.158 29.2904 23.9928C29.2792 24.6327 28.8564 24.9967 28.1582 25.0255C27.9725 25.0331 27.7867 25.0331 27.6009 25.0331C19.2032 25.0331 10.807 25.0331 2.40923 25.0331C0.951949 25.0331 0.674906 24.7722 0.673305 23.4166C0.671704 21.8835 0.666899 20.349 0.674906 18.8159C0.679711 17.9334 1.09127 17.4345 1.78148 17.4375C2.46688 17.4405 2.86563 17.9425 2.86883 18.825C2.87364 20.1791 2.86883 21.5317 2.86883 22.9435H2.87043Z' fill='white' />
    </g>
    <defs>
      <clipPath id='clip0_4400_14263'>
        <rect width='28.6443' height='24.2818' fill='white' transform='translate(0.670044 0.757812)' />
      </clipPath>
    </defs>
  </svg>
);
