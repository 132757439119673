
export const IconUnderConstruction = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='190'
    height='177'
    viewBox='0 0 190 177'
    fill='none'
    {...props}
  >
    <g clipPath='url(#clip0_1174_41322)'>
      <path d='M189.13 149.74V148.76H116.69V150.55H116.7C116.26 153.82 117.99 157.01 122 159.32L138.63 168.92C146.59 173.52 160.5 172.94 169.69 167.63L181.58 160.77C187.04 157.62 189.6 153.51 189.13 149.73V149.74Z' fill='url(#paint0_linear_1174_41322)' />
      <path d='M138.63 166.07L122 156.47C114.04 151.87 115.04 143.85 124.23 138.54L136.12 131.68C145.31 126.37 159.22 125.8 167.18 130.39L183.81 139.99C191.77 144.59 190.77 152.61 181.58 157.92L169.69 164.78C160.5 170.09 146.59 170.66 138.63 166.07Z' fill='#0075C5' />
      <path style={{ mixBlendMode: 'color-dodge' }} d='M181.58 157.22L169.69 164.08C160.5 169.39 146.59 169.96 138.63 165.37L122 155.77C118.63 153.82 116.88 151.26 116.67 148.55C116.45 151.66 118.18 154.66 122 156.86L138.63 166.46C146.59 171.06 160.5 170.48 169.69 165.17L181.58 158.31C186.88 155.25 189.44 151.28 189.14 147.6C188.9 150.97 186.36 154.46 181.58 157.22Z' fill='url(#paint1_radial_1174_41322)' />
      <path opacity='0.2' d='M189.13 153.919V152.939H189.12C188.69 156.159 186.15 159.459 181.59 162.099L169.7 168.959C160.51 174.269 146.6 174.839 138.64 170.249L122.01 160.649C118.69 158.729 116.95 156.209 116.69 153.549V154.739H116.7C116.26 158.009 117.99 161.199 122 163.509L138.63 173.109C146.59 177.709 160.5 177.129 169.69 171.819L181.58 164.959C187.04 161.809 189.6 157.699 189.13 153.919Z' fill='url(#paint2_linear_1174_41322)' />
      <path d='M160.19 93.2402H145.97L130.5 145.59C130.5 145.65 130.5 145.71 130.5 145.77C130.5 153.03 140.61 158.92 153.08 158.92C165.55 158.92 175.66 153.03 175.66 145.77C175.66 145.71 175.66 145.65 175.66 145.59L160.2 93.2402H160.19Z' fill='url(#paint3_linear_1174_41322)' />
      <path d='M160.19 93.2699C160.19 95.5599 157.01 97.4099 153.08 97.4099C149.15 97.4099 145.97 95.5599 145.97 93.2699C145.97 90.9799 149.15 89.1299 153.08 89.1299C157.01 89.1299 160.19 90.9799 160.19 93.2699Z' fill='url(#paint4_linear_1174_41322)' />
      <path d='M175.65 145.58L172.58 135.19C172.58 135.31 172.6 135.44 172.6 135.56C172.6 141.84 163.86 146.93 153.08 146.93C142.3 146.93 133.56 141.84 133.56 135.56C133.56 135.44 133.56 135.31 133.58 135.19L130.51 145.58C130.51 145.64 130.51 145.7 130.51 145.76C130.51 153.02 140.62 158.91 153.09 158.91C165.56 158.91 175.67 153.02 175.67 145.76C175.67 145.7 175.67 145.64 175.67 145.58H175.65Z' fill='url(#paint5_linear_1174_41322)' />
      <path d='M166.38 114.2C166.38 114.27 166.39 114.34 166.39 114.42C166.39 118.7 160.43 122.17 153.07 122.17C145.71 122.17 139.75 118.7 139.75 114.42C139.75 114.34 139.77 114.26 139.77 114.17L136.64 124.77C136.64 124.87 136.64 124.94 136.64 124.99C136.64 130.27 143.99 134.55 153.06 134.55C162.13 134.55 169.48 130.27 169.48 124.99C169.48 124.94 169.49 124.87 169.5 124.81L166.37 114.2H166.38Z' fill='url(#paint6_linear_1174_41322)' />
      <path d='M160.19 93.2402C160.19 93.2402 160.19 93.2602 160.19 93.2702C160.19 95.5602 157.01 97.4102 153.08 97.4102C149.15 97.4102 145.97 95.5602 145.97 93.2702C145.97 93.2702 145.97 93.2502 145.97 93.2402L142.87 103.74V103.84C142.87 107.13 147.44 109.79 153.08 109.79C158.72 109.79 163.29 107.13 163.29 103.84V103.74L160.19 93.2402Z' fill='url(#paint7_linear_1174_41322)' />
      <path d='M158.92 93.0298C158.92 94.6798 156.3 96.0098 153.08 96.0098C149.86 96.0098 147.24 94.6798 147.24 93.0298C147.24 91.3798 149.86 90.0498 153.08 90.0498C156.3 90.0498 158.92 91.3798 158.92 93.0298Z' fill='url(#paint8_linear_1174_41322)' />
      <path style={{ mixBlendMode: 'multiply' }} d='M158.92 93.0298C158.92 94.6798 156.3 96.0098 153.08 96.0098C149.86 96.0098 147.24 94.6798 147.24 93.0298C147.24 91.3798 149.86 90.0498 153.08 90.0498C156.3 90.0498 158.92 91.3798 158.92 93.0298Z' fill='url(#paint9_linear_1174_41322)' />
      <path style={{ mixBlendMode: 'color-dodge' }} d='M159.01 91.3799L154.41 92.2699L159.31 144.64C159.32 144.7 159.33 144.76 159.34 144.82C160.71 151.95 165.1 157.1 169.13 156.32C173.16 155.54 175.32 149.13 173.94 142C173.93 141.94 173.92 141.88 173.9 141.82L159 91.3799H159.01Z' fill='url(#paint10_radial_1174_41322)' />
      <path style={{ mixBlendMode: 'color-dodge' }} d='M148.12 91.3799L152.72 92.2699L147.82 144.64C147.81 144.7 147.8 144.76 147.79 144.82C146.41 151.95 142.03 157.1 138 156.32C133.97 155.54 131.81 149.13 133.19 142C133.2 141.94 133.22 141.88 133.23 141.82L148.13 91.3799H148.12Z' fill='url(#paint11_radial_1174_41322)' />
      <g opacity='0.2'>
        <path d='M56.94 99.8099L55.77 107.31L62.52 103.42L63.69 95.9199L56.94 99.8099Z' fill='url(#paint12_linear_1174_41322)' />
        <path d='M56.9601 99.8094L55.7901 107.309L49.1001 103.419L50.2601 95.9395L56.9601 99.8094Z' fill='url(#paint13_linear_1174_41322)' />
      </g>
      <g opacity='0.2'>
        <path d='M6.7002 129.55L10.9102 136.15L17.6602 132.27L13.4502 125.66L6.7002 129.55Z' fill='url(#paint14_linear_1174_41322)' />
        <path d='M6.72002 129.55L10.93 136.15L4.23002 132.27L0.0200195 125.68L6.72002 129.55Z' fill='url(#paint15_linear_1174_41322)' />
      </g>
      <path d='M42.0798 7.76965L56.8598 98.7597L63.6098 94.8796L48.8298 3.88965L42.0798 7.76965Z' fill='url(#paint16_linear_1174_41322)' />
      <path d='M42.0999 7.77016L56.8699 98.7602L50.1799 94.8801L35.3999 3.91016L42.0999 7.77016Z' fill='url(#paint17_linear_1174_41322)' />
      <path d='M35.3999 3.91L42.0999 7.77L48.8299 3.89L42.0999 0L35.3999 3.91Z' fill='url(#paint18_linear_1174_41322)' />
      <path opacity='0.02' d='M0 124.73L63.84 86.6797L132.43 124.64L67.25 164.14L0 124.73Z' fill='black' />
      <path opacity='0.04' d='M2.2998 124.389L63.7298 87.7393L130.86 124.649L67.5498 162.829L2.2998 124.389Z' fill='black' />
      <path opacity='0.05' d='M4.6001 124.06L63.6201 88.7998L129.28 124.66L67.8501 161.52L4.6001 124.06Z' fill='black' />
      <path opacity='0.07' d='M6.89014 123.719L63.5101 89.8594L127.71 124.679L68.1501 160.209L6.89014 123.719Z' fill='black' />
      <path opacity='0.09' d='M9.18994 123.39L63.3999 90.9199L126.13 124.69L68.4499 158.9L9.18994 123.39Z' fill='black' />
      <path opacity='0.1' d='M11.4902 123.059L63.2902 91.9795L124.55 124.699L68.7502 157.589L11.4902 123.059Z' fill='black' />
      <path opacity='0.12' d='M13.79 122.72L63.19 93.04L122.98 124.72L69.06 156.29L13.79 122.72Z' fill='black' />
      <path opacity='0.13' d='M16.0901 122.39L63.0801 94.0898L121.4 124.73L69.3601 154.98L16.0901 122.39Z' fill='black' />
      <path opacity='0.15' d='M18.3899 122.049L62.9699 95.1494L119.83 124.749L69.6599 153.669L18.3899 122.049Z' fill='black' />
      <path opacity='0.17' d='M20.6799 121.72L62.8599 96.21L118.25 124.76L69.9599 152.36L20.6799 121.72Z' fill='black' />
      <path opacity='0.18' d='M22.98 121.38L62.75 97.2695L116.67 124.77L70.26 151.05L22.98 121.38Z' fill='black' />
      <path d='M42.0802 7.76965L6.68018 128.61L13.4302 124.73L48.8302 3.88965L42.0802 7.76965Z' fill='url(#paint19_linear_1174_41322)' />
      <path d='M42.1 7.77016L6.7 128.61L0 124.73L35.4 3.91016L42.1 7.77016Z' fill='url(#paint20_linear_1174_41322)' />
      <path d='M35.3999 3.91L42.0999 7.77L48.8299 3.89L42.0999 0L35.3999 3.91Z' fill='url(#paint21_linear_1174_41322)' />
      <path d='M68.8399 133.869L67.7799 136.599L14.1699 106.079L15.2199 103.359L68.8399 133.869Z' fill='url(#paint22_linear_1174_41322)' />
      <path d='M15.22 103.36L68.84 133.87L72.67 131.7L19.04 101.18L15.22 103.36Z' fill='url(#paint23_linear_1174_41322)' />
      <path d='M102.75 42.4601L117.52 133.45L124.27 129.57L109.5 38.5801L102.75 42.4601Z' fill='url(#paint24_linear_1174_41322)' />
      <path d='M102.77 42.4606L117.54 133.451L110.84 129.571L96.0701 38.6006L102.77 42.4606Z' fill='url(#paint25_linear_1174_41322)' />
      <path d='M96.0701 38.6004L102.77 42.4604L109.5 38.5804L102.77 34.6904L96.0701 38.6004Z' fill='url(#paint26_linear_1174_41322)' />
      <path d='M102.75 42.4601L67.3398 163.3L74.0898 159.42L109.5 38.5801L102.75 42.4601Z' fill='url(#paint27_linear_1174_41322)' />
      <path d='M102.77 42.4606L67.3599 163.301L60.6699 159.421L96.0699 38.6006L102.77 42.4606Z' fill='url(#paint28_linear_1174_41322)' />
      <path d='M96.0698 38.6004L102.77 42.4604L109.5 38.5804L102.77 34.6904L96.0698 38.6004Z' fill='url(#paint29_linear_1174_41322)' />
      <g opacity='0.2'>
        <path d='M117.47 134.4L116.3 141.9L123.05 138.01L124.22 130.51L117.47 134.4Z' fill='url(#paint30_linear_1174_41322)' />
        <path d='M117.49 134.399L116.32 141.899L109.62 138.009L110.79 130.529L117.49 134.399Z' fill='url(#paint31_linear_1174_41322)' />
      </g>
      <g opacity='0.2'>
        <path d='M67.23 164.14L71.44 170.74L78.19 166.85L73.98 160.25L67.23 164.14Z' fill='url(#paint32_linear_1174_41322)' />
        <path d='M67.25 164.14L71.46 170.74L64.7601 166.85L60.55 160.27L67.25 164.14Z' fill='url(#paint33_linear_1174_41322)' />
      </g>
      <path d='M91.69 112.44L11.91 66.51C8.53998 64.58 6.82998 59.61 8.07998 55.43L11.91 42.65C12.55 40.53 9.96998 40.4 11.52 39.68C11.65 39.62 15.63 38.1699 15.76 38.1199C17.17 37.5899 18.77 37.7 20.28 38.57L100.06 84.5C103.43 86.43 105.14 91.4 103.89 95.58L100.06 108.36C99.37 110.65 97.94 112.24 96.24 112.88C96.18 112.9 92.13 114.42 92.07 114.44C90.7 114.91 93.16 113.28 91.69 112.44Z' fill='url(#paint34_linear_1174_41322)' />
      <path d='M87.6602 113.95L7.88019 68.0203C4.51019 66.0903 2.80019 61.1303 4.05019 56.9403L7.88019 44.1603C9.13019 39.9703 12.8802 38.1403 16.2502 40.0803L96.0302 86.0103C99.4002 87.9403 101.11 92.9103 99.8602 97.0903L96.0302 109.87C94.7702 114.06 91.0302 115.89 87.6602 113.95Z' fill='url(#paint35_linear_1174_41322)' />
      <path d='M101.3 80.3501L21.5201 34.4201C18.1501 32.4901 16.4401 27.5201 17.6901 23.3401L21.5201 10.5601C22.1601 8.43011 19.5801 8.31011 21.1301 7.59011C21.2601 7.53011 25.2401 6.08011 25.3601 6.03011C26.7701 5.50011 28.3701 5.61011 29.8901 6.48011L109.67 52.4101C113.04 54.3401 114.75 59.3101 113.5 63.4901L109.67 76.2701C108.98 78.5601 107.55 80.1501 105.84 80.7901C105.79 80.8101 101.73 82.3301 101.67 82.3501C100.3 82.8201 102.76 81.1901 101.29 80.3501H101.3Z' fill='url(#paint36_linear_1174_41322)' />
      <path d='M97.2801 81.8605L17.4901 35.9305C14.1201 34.0005 12.4101 29.0305 13.6601 24.8505L17.4901 12.0705C18.7401 7.88049 22.4901 6.05049 25.8601 7.99049L105.64 53.9205C109.01 55.8505 110.72 60.8205 109.47 65.0005L105.64 77.7805C104.39 81.9705 100.64 83.8005 97.2701 81.8605H97.2801Z' fill='url(#paint37_linear_1174_41322)' />
      <path style={{ mixBlendMode: 'color-dodge' }} d='M96.66 85.7404L16.88 39.8104C15.42 38.9704 13.89 38.8504 12.52 39.3104C13.57 39.2104 14.68 39.4404 15.75 40.0504L95.53 85.9804C98.9 87.9104 100.61 92.8804 99.36 97.0604L95.53 109.84C94.91 111.9 93.69 113.38 92.21 114.13C92.61 114.09 93 113.99 93.39 113.85C94.85 113.09 96.06 111.63 96.67 109.59L100.5 96.8104C101.76 92.6204 100.04 87.6604 96.67 85.7304L96.66 85.7404Z' fill='url(#paint38_radial_1174_41322)' />
      <path style={{ mixBlendMode: 'color-dodge' }} d='M106.27 53.6405L26.4901 7.72054C25.0301 6.88054 23.4901 6.76054 22.1201 7.22054C23.1701 7.12054 24.2801 7.35054 25.3501 7.96054L105.13 53.8905C108.5 55.8205 110.21 60.7905 108.96 64.9705L105.13 77.7505C104.51 79.8105 103.29 81.2905 101.81 82.0405C102.21 82.0005 102.6 81.9005 102.99 81.7605C104.45 81.0005 105.66 79.5405 106.27 77.5005L110.1 64.7205C111.35 60.5305 109.64 55.5705 106.27 53.6405Z' fill='url(#paint39_radial_1174_41322)' />
      <path opacity='0.02' d='M84.8304 106.12L14.4404 65.7201L30.3404 13.1201L100.73 53.5201L84.8304 106.12Z' fill='black' />
      <path opacity='0.05' d='M84.5806 105.74L14.8306 65.71L30.5406 13.71L100.291 53.74L84.5806 105.74Z' fill='black' />
      <path opacity='0.07' d='M84.3305 105.37L15.2205 65.71L30.7405 14.29L99.8505 53.95L84.3305 105.37Z' fill='black' />
      <path opacity='0.1' d='M84.0804 104.99L15.6104 65.7001L30.9504 14.8701L99.4104 54.1601L84.0804 104.99Z' fill='black' />
      <path opacity='0.12' d='M83.8305 104.62L16.0105 65.7002L31.1505 15.4502L98.9705 54.3702L83.8305 104.62Z' fill='black' />
      <path opacity='0.15' d='M83.5804 104.24L16.4004 65.6903L31.3504 16.0303L98.5304 54.5803L83.5804 104.24Z' fill='black' />
      <path opacity='0.17' d='M83.3205 103.87L16.7905 65.6904L31.5505 16.6104L98.0905 54.8004L83.3205 103.87Z' fill='black' />
      <path opacity='0.2' d='M83.0704 103.5L17.1804 65.6804L31.7604 17.1904L97.6504 55.0104L83.0704 103.5Z' fill='black' />
      <path opacity='0.22' d='M82.8203 103.12L17.5703 65.6803L31.9603 17.7803L97.2103 55.2203L82.8203 103.12Z' fill='black' />
      <path opacity='0.25' d='M82.5705 102.75L17.9705 65.6703L32.1605 18.3604L96.7705 55.4304L82.5705 102.75Z' fill='black' />
      <path opacity='0.27' d='M82.3204 102.37L18.3604 65.6704L32.3703 18.9404L96.3304 55.6504L82.3204 102.37Z' fill='black' />
      <path opacity='0.3' d='M82.0705 102.001L18.7505 65.6605L32.5705 19.5205L95.8805 55.8605L82.0705 102.001Z' fill='black' />
      <path d='M83.1603 104.79L15.0703 65.7098L29.9303 16.0898L98.0203 55.1698L83.1603 104.79Z' fill='url(#paint40_linear_1174_41322)' />
      <path d='M32.7202 22.3697L92.8802 56.8997L80.4302 98.4697L20.2702 63.9397L32.7202 22.3697ZM32.0402 20.6797L18.9202 64.4797L81.1002 100.17L94.2202 56.3697L32.0402 20.6797Z' fill='url(#paint41_linear_1174_41322)' />
      <path style={{ mixBlendMode: 'color-dodge' }} d='M29.9301 16.0898L29.6201 16.9098L97.1901 55.5198L82.6401 104.32L83.1601 104.79L98.0201 55.1698L29.9301 16.0898Z' fill='url(#paint42_radial_1174_41322)' />
      <path d='M31.1901 45.9298C30.6501 44.7398 30.6101 43.3798 31.0701 41.8498L33.0701 35.1798C33.1101 35.0598 33.1701 34.9798 33.2601 34.9398C33.3501 34.8998 33.4501 34.9198 33.5601 34.9798L34.9701 35.7898C35.0801 35.8498 35.1601 35.9498 35.2101 36.0598C35.2601 36.1698 35.2601 36.2998 35.2301 36.4198L33.2301 43.0898C32.9701 43.9498 32.9701 44.7098 33.2301 45.3698C33.4801 46.0298 33.9601 46.5598 34.6601 46.9598C35.3601 47.3598 35.9701 47.4598 36.4901 47.2398C37.0001 47.0198 37.3901 46.4798 37.6501 45.6198L39.6501 38.9498C39.6901 38.8298 39.7501 38.7498 39.8501 38.7198C39.9401 38.6798 40.0501 38.6998 40.1601 38.7598L41.5701 39.5698C41.6801 39.6298 41.7601 39.7198 41.8001 39.8398C41.8401 39.9498 41.8501 40.0698 41.8101 40.1898L39.8101 46.8598C39.3501 48.3898 38.6301 49.3098 37.6401 49.6298C36.6501 49.9498 35.4701 49.7098 34.1001 48.9198C32.7101 48.1198 31.7501 47.1298 31.2101 45.9398L31.1901 45.9298Z' fill='url(#paint43_linear_1174_41322)' />
      <path d='M41.3001 52.7195C41.2501 52.5995 41.2401 52.4895 41.2801 52.3795L44.4701 41.7195C44.5101 41.5995 44.5701 41.5194 44.6601 41.4794C44.7501 41.4394 44.8501 41.4495 44.9601 41.5195L46.1501 42.2094C46.3401 42.3194 46.4601 42.4995 46.5101 42.7395L48.7101 52.5195L50.8501 45.3795C50.8901 45.2595 50.9501 45.1794 51.0401 45.1394C51.1301 45.0994 51.2301 45.1095 51.3401 45.1795L52.6501 45.9394C52.7601 45.9994 52.8401 46.0894 52.8901 46.2094C52.9401 46.3294 52.9401 46.4495 52.9101 46.5694L49.7201 57.2195C49.6801 57.3395 49.6201 57.4195 49.5201 57.4495C49.4301 57.4895 49.3301 57.4694 49.2301 57.4194L48.0201 56.7295C47.8201 56.6095 47.6901 56.4294 47.6401 56.1894L45.4101 46.5795L43.3101 53.5695C43.2801 53.6795 43.2201 53.7495 43.1301 53.7895C43.0401 53.8295 42.9401 53.8195 42.8301 53.7495L41.5101 52.9995C41.4101 52.9395 41.3301 52.8495 41.2801 52.7395L41.3001 52.7195Z' fill='url(#paint44_linear_1174_41322)' />
      <path d='M52.5002 59.1599C52.4502 59.0399 52.4402 58.9299 52.4802 58.8199L55.6702 48.1599C55.7102 48.0399 55.7702 47.9599 55.8602 47.9199C55.9502 47.8799 56.0502 47.8899 56.1602 47.9599L59.8902 50.0999C62.9002 51.8299 63.9902 54.1899 63.1602 57.1999C62.9902 57.8499 62.8703 58.2799 62.8103 58.4899C62.7502 58.6999 62.6103 59.1099 62.4003 59.7299C61.9003 61.2299 61.1802 62.1399 60.2302 62.4499C59.2802 62.7599 58.0502 62.4699 56.5302 61.5999L52.7202 59.4099C52.6202 59.3499 52.5403 59.2599 52.4903 59.1499L52.5002 59.1599ZM57.0302 59.6599C57.8802 60.1499 58.5502 60.2999 59.0502 60.1099C59.5502 59.9199 59.9502 59.3699 60.2502 58.4399C60.4702 57.7999 60.6003 57.3899 60.6503 57.2299C60.7003 57.0599 60.8102 56.6499 60.9802 55.9999C61.2302 55.0899 61.2202 54.3199 60.9402 53.6699C60.6702 53.0199 60.1002 52.4499 59.2502 51.9599L57.3802 50.8899L55.0803 58.5599L57.0202 59.6799L57.0302 59.6599Z' fill='url(#paint45_linear_1174_41322)' />
      <path d='M63.6401 65.5495C63.5901 65.4295 63.5801 65.3195 63.6201 65.2095L66.8101 54.5495C66.8501 54.4295 66.9101 54.3495 67.0001 54.3095C67.0901 54.2695 67.1901 54.2795 67.3001 54.3495L74.0501 58.2195C74.1601 58.2795 74.2401 58.3795 74.2901 58.4895C74.3401 58.6095 74.3501 58.7295 74.3101 58.8495L73.9801 59.9495C73.9401 60.0695 73.8801 60.1395 73.7901 60.1695C73.7001 60.1995 73.5901 60.1795 73.4801 60.1195L68.4601 57.2395L67.6001 60.1095L72.2801 62.7995C72.3901 62.8595 72.4701 62.9595 72.5201 63.0695C72.5701 63.1895 72.5701 63.3095 72.5401 63.4295L72.2301 64.4595C72.1901 64.5795 72.1301 64.6495 72.0401 64.6795C71.9501 64.7095 71.8401 64.6895 71.7301 64.6295L67.0501 61.9395L66.1601 64.9095L71.3001 67.8595C71.4101 67.9195 71.4901 68.0095 71.5401 68.1195C71.5901 68.2295 71.6001 68.3495 71.5601 68.4695L71.2301 69.5795C71.1901 69.6995 71.1301 69.7795 71.0401 69.7995C70.9501 69.8295 70.8401 69.8095 70.7301 69.7495L63.8601 65.8095C63.7601 65.7495 63.6801 65.6595 63.6301 65.5495H63.6401Z' fill='url(#paint46_linear_1174_41322)' />
      <path d='M73.5601 71.24C73.5101 71.12 73.5001 71.01 73.5401 70.9L76.7301 60.24C76.7701 60.12 76.8301 60.04 76.9201 60C77.0101 59.96 77.1101 59.97 77.2201 60.04L81.1801 62.31C82.4401 63.03 83.3201 63.9 83.8501 64.93C84.3701 65.96 84.4601 67.05 84.1201 68.19C83.8801 68.99 83.5001 69.55 82.9801 69.89C82.4601 70.23 81.8401 70.33 81.1301 70.2L82.1001 75.65C82.1101 75.73 82.1001 75.8 82.1001 75.86C82.0701 75.96 82.0101 76.02 81.9301 76.05C81.8401 76.08 81.7601 76.07 81.6801 76.02L80.2701 75.21C80.1101 75.12 79.9901 75 79.9201 74.87C79.8501 74.74 79.8001 74.58 79.7701 74.41L78.9801 69.4L76.8801 68.2L75.7001 72.13C75.6701 72.24 75.6001 72.31 75.5101 72.35C75.4201 72.39 75.3101 72.37 75.2001 72.31L73.7901 71.5C73.6901 71.44 73.6101 71.35 73.5601 71.24ZM79.5601 67.56C80.1701 67.91 80.6801 68.03 81.0701 67.92C81.4601 67.81 81.7401 67.47 81.9101 66.91C82.0801 66.35 82.0501 65.84 81.8401 65.36C81.6201 64.89 81.2101 64.47 80.6001 64.12L78.4701 62.9L77.4401 66.34L79.5701 67.56H79.5601Z' fill='url(#paint47_linear_1174_41322)' />
      <path d='M26.1901 57.6096C25.9701 57.0496 25.9401 56.4496 26.1101 55.7896C26.1501 55.6496 26.2101 55.4196 26.3001 55.1196C26.4001 54.8096 26.4701 54.5796 26.5101 54.4396C26.7201 53.8096 27.0501 53.4096 27.4901 53.2296C27.9301 53.0496 28.4701 53.1396 29.1001 53.4996C29.5201 53.7396 29.8601 54.0296 30.1001 54.3596C30.3401 54.6896 30.5101 55.0296 30.5801 55.3696C30.6501 55.7196 30.6501 56.0396 30.5801 56.3396C30.5701 56.3896 30.5401 56.4096 30.5001 56.4196C30.4601 56.4196 30.4201 56.4197 30.3801 56.3997L30.0201 56.1996C30.0201 56.1996 29.9401 56.1396 29.9201 56.0996C29.9001 56.0596 29.8901 55.9996 29.8901 55.9296C29.9301 55.4896 29.8601 55.1296 29.7001 54.8496C29.5401 54.5696 29.2701 54.3296 28.9101 54.1196C28.0801 53.6496 27.5101 53.8896 27.1801 54.8596C27.1301 54.9896 27.0701 55.1997 26.9801 55.4897C26.9001 55.7797 26.8301 55.9896 26.7901 56.1396C26.5301 57.1396 26.8101 57.8796 27.6401 58.3496C28.0001 58.5596 28.3101 58.6396 28.5701 58.5996C28.8301 58.5596 29.0601 58.3796 29.2601 58.0396C29.2901 57.9896 29.3301 57.9596 29.3601 57.9496C29.4001 57.9496 29.4401 57.9496 29.4801 57.9796L29.8401 58.1796C29.8401 58.1796 29.9201 58.2396 29.9401 58.2896C29.9601 58.3396 29.9701 58.3796 29.9501 58.4296C29.8501 58.7196 29.6801 58.9496 29.4501 59.1196C29.2201 59.2896 28.9401 59.3796 28.6001 59.3696C28.2601 59.3696 27.8801 59.2397 27.4501 58.9897C26.8201 58.6297 26.3901 58.1696 26.1701 57.6096H26.1901Z' fill='url(#paint48_linear_1174_41322)' />
      <path d='M31.1101 60.4593C30.8801 59.9193 30.8601 59.2893 31.0501 58.5793C31.0901 58.4193 31.1501 58.2093 31.2401 57.9293C31.3201 57.6493 31.3901 57.4393 31.4401 57.2893C31.6701 56.6093 32.0101 56.1893 32.4601 56.0393C32.9201 55.8793 33.4401 55.9793 34.0301 56.3193C34.6201 56.6593 35.0401 57.1093 35.2801 57.6593C35.5201 58.2093 35.5501 58.8393 35.3601 59.5493C35.2801 59.8493 35.2201 60.0693 35.1801 60.1993C35.1401 60.3293 35.0701 60.5393 34.9701 60.8393C34.7401 61.5293 34.4101 61.9493 33.9701 62.1093C33.5301 62.2693 33.0001 62.1693 32.3801 61.8093C31.7601 61.4593 31.3401 61.0093 31.1001 60.4693L31.1101 60.4593ZM33.5901 61.3293C33.8901 61.2093 34.1301 60.9093 34.3001 60.4093C34.4001 60.1093 34.4701 59.9093 34.5001 59.8093C34.5301 59.6993 34.5901 59.4893 34.6701 59.1893C34.8001 58.6693 34.7901 58.2193 34.6401 57.8393C34.4901 57.4593 34.2201 57.1593 33.8401 56.9393C33.4601 56.7193 33.1201 56.6693 32.8101 56.7893C32.5101 56.9093 32.2701 57.2193 32.1001 57.7193C32.0501 57.8693 31.9901 58.0693 31.9101 58.3293C31.8301 58.5793 31.7701 58.7893 31.7301 58.9393C31.5901 59.4593 31.6001 59.8993 31.7501 60.2793C31.9001 60.6593 32.1701 60.9593 32.5601 61.1793C32.9401 61.3993 33.2801 61.4493 33.5801 61.3293H33.5901Z' fill='url(#paint49_linear_1174_41322)' />
      <path d='M35.67 63.5502C35.67 63.5502 35.65 63.4502 35.67 63.4002L37.16 58.4202C37.18 58.3602 37.2 58.3302 37.24 58.3102C37.28 58.2902 37.32 58.3002 37.37 58.3302L37.68 58.5102C37.68 58.5102 37.76 58.5702 37.78 58.6002C37.8 58.6302 37.81 58.6702 37.83 58.7202L39.04 64.0202L40.2 60.1602C40.22 60.1002 40.25 60.0702 40.29 60.0502C40.33 60.0302 40.37 60.0402 40.42 60.0702L40.74 60.2502C40.79 60.2802 40.83 60.3202 40.85 60.3702C40.87 60.4202 40.87 60.4702 40.85 60.5302L39.36 65.5002C39.35 65.5502 39.32 65.5902 39.27 65.6002C39.23 65.6202 39.18 65.6102 39.14 65.5902L38.82 65.4002C38.74 65.3502 38.69 65.2902 38.68 65.2002L37.46 59.9302L36.31 63.7602C36.31 63.7602 36.27 63.8402 36.23 63.8602C36.19 63.8802 36.15 63.8702 36.1 63.8402L35.77 63.6502C35.77 63.6502 35.69 63.5902 35.67 63.5302V63.5502Z' fill='url(#paint50_linear_1174_41322)' />
      <path d='M41.3401 66.7095C41.0901 66.4095 40.9301 66.1095 40.8401 65.8095C40.7501 65.5095 40.7401 65.2295 40.8101 64.9695C40.8201 64.9295 40.8501 64.8995 40.8801 64.8895C40.9201 64.8795 40.9601 64.8895 41.0001 64.9095L41.3501 65.1095C41.4401 65.1595 41.4801 65.2395 41.4801 65.3595C41.4601 65.5995 41.5201 65.8495 41.6801 66.1195C41.8301 66.3895 42.1001 66.6295 42.4701 66.8495C42.8701 67.0795 43.2001 67.1795 43.4401 67.1395C43.6901 67.0995 43.8501 66.9495 43.9301 66.6795C43.9801 66.5095 43.9801 66.3395 43.9101 66.1695C43.8501 65.9995 43.7301 65.8195 43.5601 65.6295C43.3901 65.4395 43.1401 65.1695 42.7901 64.8195C42.4601 64.4995 42.2001 64.2095 42.0301 63.9595C41.8501 63.7095 41.7401 63.4595 41.6801 63.2095C41.6301 62.9595 41.6401 62.6995 41.7301 62.4095C41.8101 62.1395 41.9501 61.9295 42.1601 61.7895C42.3601 61.6495 42.6101 61.5995 42.9101 61.6195C43.2101 61.6495 43.5401 61.7595 43.8901 61.9595C44.2601 62.1695 44.5601 62.4295 44.7801 62.7195C45.0001 63.0095 45.1501 63.2995 45.2301 63.5895C45.3001 63.8795 45.3101 64.1295 45.2601 64.3495C45.2501 64.3995 45.2201 64.4295 45.1801 64.4395C45.1401 64.4495 45.1001 64.4495 45.0601 64.4195L44.7001 64.2095C44.7001 64.2095 44.6301 64.1595 44.6101 64.1095C44.5801 64.0695 44.5701 64.0095 44.5701 63.9495C44.6101 63.7195 44.5501 63.4695 44.3901 63.2095C44.2301 62.9495 44.0001 62.7395 43.7101 62.5695C43.3901 62.3895 43.1101 62.3095 42.8801 62.3295C42.6501 62.3595 42.4901 62.4995 42.4201 62.7595C42.3701 62.9395 42.3701 63.1095 42.4201 63.2695C42.4701 63.4295 42.5801 63.6095 42.7401 63.7995C42.9001 63.9895 43.1301 64.2395 43.4501 64.5595C43.8201 64.9095 44.1001 65.2195 44.2901 65.4695C44.4801 65.7195 44.6101 65.9695 44.6701 66.2195C44.7301 66.4695 44.7201 66.7295 44.6301 67.0195C44.4901 67.4995 44.2101 67.7695 43.7901 67.8395C43.3701 67.9095 42.8701 67.7795 42.2901 67.4495C41.9001 67.2295 41.5801 66.9695 41.3301 66.6695L41.3401 66.7095Z' fill='url(#paint51_linear_1174_41322)' />
      <path d='M46.4201 69.7096C46.4001 69.6596 46.4001 69.6096 46.4201 69.5596L47.7701 65.0496L46.3901 64.2596C46.3901 64.2596 46.3101 64.1896 46.2901 64.1396C46.2701 64.0896 46.2701 64.0396 46.2901 63.9896L46.3801 63.6896C46.4001 63.6296 46.4201 63.5996 46.4701 63.5796C46.5101 63.5596 46.5501 63.5796 46.6001 63.5996L50.0201 65.5596C50.0701 65.5896 50.1101 65.6296 50.1301 65.6796C50.1501 65.7296 50.1501 65.7796 50.1301 65.8396L50.0401 66.1396C50.0201 66.1896 50.0001 66.2196 49.9601 66.2396C49.9201 66.2596 49.8701 66.2496 49.8301 66.2196L48.4601 65.4296L47.1101 69.9396C47.1101 69.9396 47.0601 70.0196 47.0201 70.0396C46.9801 70.0496 46.9301 70.0396 46.8901 70.0196L46.5501 69.8196C46.5501 69.8196 46.4701 69.7496 46.4501 69.6996L46.4201 69.7096Z' fill='url(#paint52_linear_1174_41322)' />
      <path d='M49.6102 71.5502C49.6102 71.5502 49.5902 71.4502 49.6102 71.4002L51.1002 66.4202C51.1202 66.3602 51.1502 66.3302 51.1802 66.3102C51.2202 66.2902 51.2602 66.3002 51.3102 66.3302L53.0502 67.3302C53.5902 67.6402 53.9702 68.0202 54.2002 68.4702C54.4202 68.9202 54.4602 69.4002 54.3002 69.9202C54.1802 70.3102 54.0002 70.5702 53.7402 70.7202C53.4802 70.8702 53.1802 70.8902 52.8302 70.8102L53.3202 73.4702C53.3202 73.4702 53.3202 73.5402 53.3202 73.5702C53.3102 73.6102 53.2802 73.6402 53.2502 73.6502C53.2102 73.6602 53.1702 73.6502 53.1402 73.6402L52.8302 73.4602C52.7602 73.4202 52.7102 73.3702 52.6802 73.3102C52.6502 73.2502 52.6302 73.1802 52.6202 73.0902L52.1602 70.5402L50.8902 69.8102L50.3002 71.7902C50.2802 71.8402 50.2602 71.8702 50.2102 71.8902C50.1702 71.9002 50.1202 71.8902 50.0702 71.8702L49.7302 71.6702C49.7302 71.6702 49.6502 71.6002 49.6302 71.5602L49.6102 71.5502ZM52.2502 69.8602C52.9702 70.2702 53.4202 70.1502 53.6202 69.5102C53.8102 68.8702 53.5502 68.3402 52.8302 67.9202L51.6302 67.2302L51.0502 69.1702L52.2502 69.8602Z' fill='url(#paint53_linear_1174_41322)' />
      <path d='M54.98 74.1602C54.75 73.6202 54.75 72.9902 54.96 72.2702L55.89 69.1702C55.91 69.1102 55.93 69.0802 55.97 69.0602C56.01 69.0402 56.05 69.0502 56.1 69.0802L56.44 69.2802C56.49 69.3102 56.53 69.3502 56.55 69.4002C56.57 69.4502 56.57 69.5002 56.55 69.5602L55.62 72.6702C55.47 73.1702 55.47 73.6002 55.62 73.9702C55.76 74.3402 56.03 74.6402 56.43 74.8702C56.82 75.1002 57.17 75.1502 57.46 75.0302C57.75 74.9102 57.97 74.6002 58.12 74.1102L59.05 71.0002C59.07 70.9502 59.09 70.9102 59.13 70.8902C59.17 70.8702 59.21 70.8902 59.26 70.9102L59.6 71.1102C59.6 71.1102 59.68 71.1802 59.7 71.2302C59.72 71.2802 59.72 71.3302 59.7 71.3902L58.77 74.4902C58.56 75.2102 58.23 75.6502 57.79 75.8102C57.36 75.9702 56.83 75.8802 56.22 75.5302C55.61 75.1802 55.19 74.7302 54.96 74.1902L54.98 74.1602Z' fill='url(#paint54_linear_1174_41322)' />
      <path d='M60.0402 77.0296C59.8202 76.4696 59.7902 75.8696 59.9602 75.2096C60.0002 75.0696 60.0602 74.8396 60.1502 74.5396C60.2402 74.2196 60.3202 73.9996 60.3602 73.8596C60.5702 73.2296 60.9002 72.8296 61.3402 72.6496C61.7802 72.4696 62.3202 72.5596 62.9502 72.9196C63.3702 73.1596 63.7102 73.4496 63.9602 73.7796C64.2102 74.1096 64.3602 74.4496 64.4402 74.7896C64.5102 75.1296 64.5102 75.4596 64.4402 75.7596C64.4302 75.8096 64.4002 75.8296 64.3602 75.8396C64.3202 75.8496 64.2802 75.8396 64.2402 75.8196L63.8802 75.6096C63.8802 75.6096 63.8002 75.5496 63.7802 75.5096C63.7602 75.4696 63.7502 75.4096 63.7502 75.3396C63.7902 74.8996 63.7202 74.5396 63.5602 74.2696C63.4002 73.9896 63.1302 73.7496 62.7702 73.5396C61.9402 73.0696 61.3702 73.3096 61.0402 74.2796C60.9902 74.4196 60.9302 74.6196 60.8402 74.9096C60.7502 75.1996 60.6902 75.4096 60.6502 75.5596C60.3902 76.5596 60.6702 77.2996 61.5002 77.7696C61.8602 77.9796 62.1702 78.0596 62.4302 78.0196C62.6902 77.9896 62.9202 77.7996 63.1202 77.4596C63.1502 77.4096 63.1902 77.3796 63.2202 77.3696C63.2602 77.3696 63.3002 77.3696 63.3502 77.3996L63.7102 77.6096C63.7102 77.6096 63.7802 77.6696 63.8102 77.7096C63.8302 77.7596 63.8402 77.7996 63.8202 77.8496C63.7202 78.1396 63.5502 78.3696 63.3202 78.5396C63.0902 78.7096 62.8102 78.7896 62.4602 78.7896C62.1202 78.7896 61.7402 78.6596 61.3102 78.4096C60.6802 78.0496 60.2502 77.5896 60.0302 77.0296H60.0402Z' fill='url(#paint55_linear_1174_41322)' />
      <path d='M65.6201 80.7398C65.6001 80.6898 65.6001 80.6398 65.6201 80.5898L66.9701 76.0798L65.5901 75.2898C65.5901 75.2898 65.5101 75.2198 65.4901 75.1698C65.4701 75.1198 65.4701 75.0698 65.4901 75.0198L65.5801 74.7198C65.6001 74.6598 65.6301 74.6298 65.6701 74.6098C65.7101 74.5898 65.7501 74.6098 65.8001 74.6298L69.2201 76.5898C69.2701 76.6198 69.3101 76.6598 69.3301 76.7098C69.3501 76.7598 69.3501 76.8098 69.3301 76.8698L69.2401 77.1698C69.2201 77.2198 69.2001 77.2598 69.1501 77.2698C69.1101 77.2898 69.0701 77.2698 69.0201 77.2498L67.6501 76.4598L66.3001 80.9698C66.2801 81.0198 66.2601 81.0498 66.2101 81.0698C66.1701 81.0798 66.1201 81.0698 66.0801 81.0498L65.7401 80.8498C65.7401 80.8498 65.6601 80.7799 65.6401 80.7299L65.6201 80.7398Z' fill='url(#paint56_linear_1174_41322)' />
      <path d='M68.8202 82.5697C68.8202 82.5697 68.8002 82.4697 68.8202 82.4197L70.3102 77.4297C70.3202 77.3797 70.3502 77.3497 70.3902 77.3297C70.4302 77.3097 70.4702 77.3197 70.5202 77.3497L70.8802 77.5597C70.9302 77.5897 70.9702 77.6297 70.9902 77.6797C71.0102 77.7297 71.0102 77.7797 70.9902 77.8297L69.5002 82.8197C69.5002 82.8197 69.4602 82.8997 69.4202 82.9197C69.3802 82.9397 69.3402 82.9197 69.2902 82.8997L68.9302 82.6997C68.9302 82.6997 68.8502 82.6297 68.8302 82.5797L68.8202 82.5697Z' fill='url(#paint57_linear_1174_41322)' />
      <path d='M71.3601 83.5593C71.1301 83.0193 71.1101 82.3893 71.3001 81.6793C71.3401 81.5293 71.4001 81.3093 71.4901 81.0293C71.5701 80.7493 71.6401 80.5393 71.6901 80.3893C71.9201 79.7093 72.2601 79.2893 72.7101 79.1393C73.1701 78.9893 73.6901 79.0793 74.2801 79.4193C74.8701 79.7593 75.2901 80.2093 75.5301 80.7593C75.7701 81.3093 75.8001 81.9393 75.6101 82.6393C75.5301 82.9393 75.4701 83.1593 75.4301 83.2893C75.3901 83.4193 75.3201 83.6293 75.2201 83.9293C74.9901 84.6193 74.6601 85.0393 74.2201 85.1993C73.7801 85.3593 73.2501 85.2593 72.6301 84.8993C72.0101 84.5493 71.5901 84.0993 71.3601 83.5593ZM73.8401 84.4293C74.1401 84.3093 74.3801 84.0093 74.5501 83.5093C74.6501 83.2093 74.7201 83.0094 74.7501 82.9094C74.7801 82.7994 74.8401 82.5893 74.9201 82.2893C75.0501 81.7693 75.0401 81.3193 74.8901 80.9393C74.7401 80.5593 74.4701 80.2593 74.0901 80.0393C73.7101 79.8193 73.3701 79.7693 73.0601 79.8893C72.7601 80.0093 72.5201 80.3193 72.3501 80.8193C72.3001 80.9593 72.2401 81.1693 72.1601 81.4293C72.0801 81.6793 72.0301 81.8893 71.9801 82.0393C71.8401 82.5593 71.8501 82.9993 72.0101 83.3793C72.1601 83.7593 72.4301 84.0593 72.8201 84.2793C73.2001 84.4993 73.5401 84.5493 73.8401 84.4293Z' fill='url(#paint58_linear_1174_41322)' />
      <path d='M75.92 86.6498C75.92 86.6498 75.9 86.5498 75.92 86.4998L77.41 81.5198C77.43 81.4698 77.45 81.4298 77.49 81.4098C77.53 81.3898 77.57 81.3998 77.62 81.4298L77.93 81.6098C77.93 81.6098 78.01 81.6698 78.03 81.6998C78.05 81.7298 78.06 81.7698 78.08 81.8198L79.29 87.1198L80.45 83.2598C80.47 83.1998 80.5 83.1698 80.54 83.1498C80.58 83.1298 80.62 83.1498 80.67 83.1698L80.99 83.3498C81.04 83.3798 81.08 83.4198 81.1 83.4698C81.12 83.5198 81.12 83.5698 81.1 83.6298L79.61 88.5998C79.6 88.6498 79.57 88.6898 79.52 88.6998C79.48 88.7198 79.43 88.7098 79.39 88.6898L79.07 88.5098C78.99 88.4598 78.94 88.3998 78.93 88.2998L77.71 83.0298L76.56 86.8598C76.56 86.8598 76.52 86.9398 76.48 86.9598C76.44 86.9798 76.39 86.9598 76.35 86.9398L76.02 86.7498C76.02 86.7498 75.94 86.6798 75.92 86.6298V86.6498Z' fill='url(#paint59_linear_1174_41322)' />
    </g>
    <defs>
      <linearGradient id='paint0_linear_1174_41322' x1='116.633' y1='148.76' x2='130.162' y2='190.95' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#0096FB' />
        <stop offset='1' stopColor='#0075C5' />
      </linearGradient>
      <radialGradient id='paint1_radial_1174_41322' cx='0' cy='0' r='1' gradientUnits='userSpaceOnUse' gradientTransform='translate(149.17 158.88) scale(20.77 20.77)'>
        <stop stopColor='white' />
        <stop offset='1' />
      </radialGradient>
      <linearGradient id='paint2_linear_1174_41322' x1='116.633' y1='152.939' x2='130.172' y2='195.145' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#0096FB' />
        <stop offset='1' stopColor='#0075C5' />
      </linearGradient>
      <linearGradient id='paint3_linear_1174_41322' x1='136.01' y1='122.15' x2='173.88' y2='130.13' gradientUnits='userSpaceOnUse'>
        <stop offset='0.01' stopColor='#C1A1D5' />
        <stop offset='0.05' stopColor='#C7ABD9' />
        <stop offset='0.11' stopColor='#D9C6E5' />
        <stop offset='0.2' stopColor='#F6F2F9' />
        <stop offset='0.22' stopColor='white' />
        <stop offset='0.25' stopColor='#F8F5FA' />
        <stop offset='0.31' stopColor='#E7DBEF' />
        <stop offset='0.36' stopColor='#D5BFE3' />
        <stop offset='0.4' stopColor='#C7AEE0' />
        <stop offset='0.48' stopColor='#B396DD' />
        <stop offset='0.55' stopColor='#A788DB' />
        <stop offset='0.61' stopColor='#A383DB' />
        <stop offset='0.76' stopColor='#AD8DDB' />
        <stop offset='1' stopColor='#C8A6DE' />
      </linearGradient>
      <linearGradient id='paint4_linear_1174_41322' x1='145.97' y1='89.1299' x2='153.171' y2='101.497' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#0096FB' />
        <stop offset='1' stopColor='#0075C5' />
      </linearGradient>
      <linearGradient id='paint5_linear_1174_41322' x1='130.51' y1='135.19' x2='150.04' y2='172.373' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#0096FB' />
        <stop offset='1' stopColor='#0075C5' />
      </linearGradient>
      <linearGradient id='paint6_linear_1174_41322' x1='136.64' y1='114.17' x2='154.897' y2='143.607' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#0096FB' />
        <stop offset='1' stopColor='#0075C5' />
      </linearGradient>
      <linearGradient id='paint7_linear_1174_41322' x1='142.87' y1='93.2402' x2='159.061' y2='113.218' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#0096FB' />
        <stop offset='1' stopColor='#0075C5' />
      </linearGradient>
      <linearGradient id='paint8_linear_1174_41322' x1='147.24' y1='90.0498' x2='152.066' y2='99.5073' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#0096FB' />
        <stop offset='1' stopColor='#0075C5' />
      </linearGradient>
      <linearGradient id='paint9_linear_1174_41322' x1='153.07' y1='90.7198' x2='153.07' y2='112.83' gradientUnits='userSpaceOnUse'>
        <stop stopColor='white' />
        <stop offset='1' />
      </linearGradient>
      <radialGradient id='paint10_radial_1174_41322' cx='0' cy='0' r='1' gradientUnits='userSpaceOnUse' gradientTransform='translate(161.352 124.303) rotate(-10.9) scale(7.0092 21.24)'>
        <stop stopColor='#10054D' />
        <stop offset='0.1' stopColor='#0C033D' />
        <stop offset='0.32' stopColor='#070222' />
        <stop offset='0.54' stopColor='#03000F' />
        <stop offset='0.76' stopColor='#000003' />
        <stop offset='1' />
      </radialGradient>
      <radialGradient id='paint11_radial_1174_41322' cx='0' cy='0' r='1' gradientUnits='userSpaceOnUse' gradientTransform='translate(135.819 122.78) rotate(-169.1) scale(7.0092 21.24)'>
        <stop stopColor='#972A74' />
        <stop offset='0.04' stopColor='#8A266A' />
        <stop offset='0.19' stopColor='#601A49' />
        <stop offset='0.35' stopColor='#3D112F' />
        <stop offset='0.51' stopColor='#22091A' />
        <stop offset='0.67' stopColor='#0F040B' />
        <stop offset='0.83' stopColor='#030102' />
        <stop offset='1' />
      </radialGradient>
      <linearGradient id='paint12_linear_1174_41322' x1='65.2' y1='107.16' x2='44.85' y2='86.5299' gradientUnits='userSpaceOnUse'>
        <stop offset='0.04' stopColor='#4A4A54' />
        <stop offset='0.91' stopColor='#D4D1D8' />
      </linearGradient>
      <linearGradient id='paint13_linear_1174_41322' x1='41.9501' y1='104.109' x2='68.9901' y2='98.0494' gradientUnits='userSpaceOnUse'>
        <stop offset='0.04' stopColor='#4A4A54' />
        <stop offset='0.91' stopColor='#D4D1D8' />
      </linearGradient>
      <linearGradient id='paint14_linear_1174_41322' x1='17.1302' y1='134.26' x2='-8.09979' y2='117.16' gradientUnits='userSpaceOnUse'>
        <stop offset='0.04' stopColor='#4A4A54' />
        <stop offset='0.91' stopColor='#D4D1D8' />
      </linearGradient>
      <linearGradient id='paint15_linear_1174_41322' x1='-7.57998' y1='133.84' x2='19.11' y2='127.86' gradientUnits='userSpaceOnUse'>
        <stop offset='0.04' stopColor='#4A4A54' />
        <stop offset='0.91' stopColor='#D4D1D8' />
      </linearGradient>
      <linearGradient id='paint16_linear_1174_41322' x1='28.9298' y1='49.4397' x2='222.61' y2='64.6897' gradientUnits='userSpaceOnUse'>
        <stop offset='0.04' stopColor='#9D9EB3' />
        <stop offset='0.58' stopColor='#D4D1D8' />
        <stop offset='1' stopColor='#EEEBF2' />
      </linearGradient>
      <linearGradient id='paint17_linear_1174_41322' x1='28.6799' y1='55.2502' x2='64.3799' y2='47.2502' gradientUnits='userSpaceOnUse'>
        <stop offset='0.04' stopColor='#9D9EB3' />
        <stop offset='0.58' stopColor='#D4D1D8' />
        <stop offset='1' stopColor='#EEEBF2' />
      </linearGradient>
      <linearGradient id='paint18_linear_1174_41322' x1='49.0699' y1='-9.35' x2='41.5899' y2='4.9' gradientUnits='userSpaceOnUse'>
        <stop offset='0.04' stopColor='#9D9EB3' />
        <stop offset='0.58' stopColor='#D4D1D8' />
        <stop offset='1' stopColor='#EEEBF2' />
      </linearGradient>
      <linearGradient id='paint19_linear_1174_41322' x1='27.6102' y1='66.2397' x2='221.3' y2='81.4797' gradientUnits='userSpaceOnUse'>
        <stop offset='0.04' stopColor='#9D9EB3' />
        <stop offset='0.58' stopColor='#D4D1D8' />
        <stop offset='1' stopColor='#EEEBF2' />
      </linearGradient>
      <linearGradient id='paint20_linear_1174_41322' x1='-83.15' y1='89.6202' x2='129.88' y2='41.8602' gradientUnits='userSpaceOnUse'>
        <stop offset='0.04' stopColor='#9D9EB3' />
        <stop offset='0.58' stopColor='#D4D1D8' />
        <stop offset='1' stopColor='#EEEBF2' />
      </linearGradient>
      <linearGradient id='paint21_linear_1174_41322' x1='49.0699' y1='-9.35' x2='41.5899' y2='4.9' gradientUnits='userSpaceOnUse'>
        <stop offset='0.04' stopColor='#9D9EB3' />
        <stop offset='0.58' stopColor='#D4D1D8' />
        <stop offset='1' stopColor='#EEEBF2' />
      </linearGradient>
      <linearGradient id='paint22_linear_1174_41322' x1='-118.609' y1='142.95' x2='106.336' y2='48.9337' gradientUnits='userSpaceOnUse'>
        <stop offset='0.04' stopColor='#9D9EB3' />
        <stop offset='0.58' stopColor='#D4D1D8' />
        <stop offset='1' stopColor='#EEEBF2' />
      </linearGradient>
      <linearGradient id='paint23_linear_1174_41322' x1='-9.36599' y1='105.628' x2='-14.8394' y2='118.334' gradientUnits='userSpaceOnUse'>
        <stop offset='0.04' stopColor='#9D9EB3' />
        <stop offset='0.58' stopColor='#D4D1D8' />
        <stop offset='1' stopColor='#EEEBF2' />
      </linearGradient>
      <linearGradient id='paint24_linear_1174_41322' x1='93.42' y1='80.9601' x2='301.45' y2='133.27' gradientUnits='userSpaceOnUse'>
        <stop offset='0.04' stopColor='#9D9EB3' />
        <stop offset='0.58' stopColor='#D4D1D8' />
        <stop offset='1' stopColor='#EEEBF2' />
      </linearGradient>
      <linearGradient id='paint25_linear_1174_41322' x1='89.3501' y1='89.9406' x2='125.04' y2='81.9406' gradientUnits='userSpaceOnUse'>
        <stop offset='0.04' stopColor='#9D9EB3' />
        <stop offset='0.58' stopColor='#D4D1D8' />
        <stop offset='1' stopColor='#EEEBF2' />
      </linearGradient>
      <linearGradient id='paint26_linear_1174_41322' x1='109.74' y1='25.3404' x2='102.26' y2='39.5904' gradientUnits='userSpaceOnUse'>
        <stop offset='0.04' stopColor='#9D9EB3' />
        <stop offset='0.58' stopColor='#D4D1D8' />
        <stop offset='1' stopColor='#EEEBF2' />
      </linearGradient>
      <linearGradient id='paint27_linear_1174_41322' x1='88.3998' y1='100.93' x2='296.43' y2='153.24' gradientUnits='userSpaceOnUse'>
        <stop offset='0.04' stopColor='#9D9EB3' />
        <stop offset='0.58' stopColor='#D4D1D8' />
        <stop offset='1' stopColor='#EEEBF2' />
      </linearGradient>
      <linearGradient id='paint28_linear_1174_41322' x1='-22.4901' y1='124.311' x2='190.55' y2='76.5506' gradientUnits='userSpaceOnUse'>
        <stop offset='0.04' stopColor='#9D9EB3' />
        <stop offset='0.58' stopColor='#D4D1D8' />
        <stop offset='1' stopColor='#EEEBF2' />
      </linearGradient>
      <linearGradient id='paint29_linear_1174_41322' x1='109.74' y1='25.3404' x2='102.26' y2='39.5904' gradientUnits='userSpaceOnUse'>
        <stop offset='0.04' stopColor='#9D9EB3' />
        <stop offset='0.58' stopColor='#D4D1D8' />
        <stop offset='1' stopColor='#EEEBF2' />
      </linearGradient>
      <linearGradient id='paint30_linear_1174_41322' x1='125.73' y1='141.75' x2='105.38' y2='121.12' gradientUnits='userSpaceOnUse'>
        <stop offset='0.04' stopColor='#4A4A54' />
        <stop offset='0.91' stopColor='#D4D1D8' />
      </linearGradient>
      <linearGradient id='paint31_linear_1174_41322' x1='102.48' y1='138.699' x2='129.52' y2='132.639' gradientUnits='userSpaceOnUse'>
        <stop offset='0.04' stopColor='#4A4A54' />
        <stop offset='0.91' stopColor='#D4D1D8' />
      </linearGradient>
      <linearGradient id='paint32_linear_1174_41322' x1='77.66' y1='168.85' x2='52.43' y2='151.74' gradientUnits='userSpaceOnUse'>
        <stop offset='0.04' stopColor='#4A4A54' />
        <stop offset='0.91' stopColor='#D4D1D8' />
      </linearGradient>
      <linearGradient id='paint33_linear_1174_41322' x1='52.95' y1='168.43' x2='79.64' y2='162.45' gradientUnits='userSpaceOnUse'>
        <stop offset='0.04' stopColor='#4A4A54' />
        <stop offset='0.91' stopColor='#D4D1D8' />
      </linearGradient>
      <linearGradient id='paint34_linear_1174_41322' x1='78.32' y1='119.42' x2='60.38' y2='84.15' gradientUnits='userSpaceOnUse'>
        <stop offset='0.04' stopColor='#9D9EB3' />
        <stop offset='0.58' stopColor='#D4D1D8' />
        <stop offset='1' stopColor='#EEEBF2' />
      </linearGradient>
      <linearGradient id='paint35_linear_1174_41322' x1='-137.436' y1='20.14' x2='228.394' y2='140.9' gradientUnits='userSpaceOnUse'>
        <stop offset='0.04' stopColor='#9D9EB3' />
        <stop offset='0.58' stopColor='#D4D1D8' />
        <stop offset='1' stopColor='#EEEBF2' />
      </linearGradient>
      <linearGradient id='paint36_linear_1174_41322' x1='35.1809' y1='0.517332' x2='95.3222' y2='97.7512' gradientUnits='userSpaceOnUse'>
        <stop offset='0.04' stopColor='#9D9EB3' />
        <stop offset='0.58' stopColor='#D4D1D8' />
        <stop offset='1' stopColor='#EEEBF2' />
      </linearGradient>
      <linearGradient id='paint37_linear_1174_41322' x1='-106.177' y1='0.367902' x2='259.649' y2='121.115' gradientUnits='userSpaceOnUse'>
        <stop offset='0.04' stopColor='#9D9EB3' />
        <stop offset='0.58' stopColor='#D4D1D8' />
        <stop offset='1' stopColor='#EEEBF2' />
      </linearGradient>
      <radialGradient id='paint38_radial_1174_41322' cx='0' cy='0' r='1' gradientUnits='userSpaceOnUse' gradientTransform='translate(95.78 70.4604) scale(23.37 23.37)'>
        <stop stopColor='white' />
        <stop offset='1' />
      </radialGradient>
      <radialGradient id='paint39_radial_1174_41322' cx='0' cy='0' r='1' gradientUnits='userSpaceOnUse' gradientTransform='translate(99.9601 43.0205) scale(25.25 25.25)'>
        <stop stopColor='white' />
        <stop offset='1' />
      </radialGradient>
      <linearGradient id='paint40_linear_1174_41322' x1='-5.96969' y1='61.1298' x2='105.5' y2='59.8998' gradientUnits='userSpaceOnUse'>
        <stop offset='0.04' stopColor='#FFA98C' />
        <stop offset='0.32' stopColor='#FEB892' />
        <stop offset='0.86' stopColor='#FEE0A3' />
        <stop offset='1' stopColor='#FEECA8' />
      </linearGradient>
      <linearGradient id='paint41_linear_1174_41322' x1='19.6702' y1='58.7097' x2='99.3402' y2='62.3997' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#25054D' />
        <stop offset='1' stopColor='#45108A' />
      </linearGradient>
      <radialGradient id='paint42_radial_1174_41322' cx='0' cy='0' r='1' gradientUnits='userSpaceOnUse' gradientTransform='translate(70.3701 50.9698) scale(19.62 19.62)'>
        <stop stopColor='white' />
        <stop offset='1' />
      </radialGradient>
      <linearGradient id='paint43_linear_1174_41322' x1='20.4601' y1='41.6598' x2='100.13' y2='45.3498' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#25054D' />
        <stop offset='1' stopColor='#45108A' />
      </linearGradient>
      <linearGradient id='paint44_linear_1174_41322' x1='20.16' y1='48.2194' x2='99.8201' y2='51.9095' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#25054D' />
        <stop offset='1' stopColor='#45108A' />
      </linearGradient>
      <linearGradient id='paint45_linear_1174_41322' x1='19.9102' y1='53.4899' x2='99.5802' y2='57.1699' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#25054D' />
        <stop offset='1' stopColor='#45108A' />
      </linearGradient>
      <linearGradient id='paint46_linear_1174_41322' x1='19.6201' y1='59.7695' x2='99.2901' y2='63.4495' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#25054D' />
        <stop offset='1' stopColor='#45108A' />
      </linearGradient>
      <linearGradient id='paint47_linear_1174_41322' x1='19.3701' y1='65.25' x2='99.0401' y2='68.93' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#25054D' />
        <stop offset='1' stopColor='#45108A' />
      </linearGradient>
      <linearGradient id='paint48_linear_1174_41322' x1='19.8101' y1='55.8496' x2='99.4701' y2='59.5396' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#25054D' />
        <stop offset='1' stopColor='#45108A' />
      </linearGradient>
      <linearGradient id='paint49_linear_1174_41322' x1='19.6901' y1='58.4493' x2='99.3501' y2='62.1293' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#25054D' />
        <stop offset='1' stopColor='#45108A' />
      </linearGradient>
      <linearGradient id='paint50_linear_1174_41322' x1='19.56' y1='61.1002' x2='99.23' y2='64.7802' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#25054D' />
        <stop offset='1' stopColor='#45108A' />
      </linearGradient>
      <linearGradient id='paint51_linear_1174_41322' x1='19.4401' y1='63.6695' x2='99.1201' y2='67.3595' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#25054D' />
        <stop offset='1' stopColor='#45108A' />
      </linearGradient>
      <linearGradient id='paint52_linear_1174_41322' x1='19.3601' y1='65.5496' x2='99.0201' y2='69.2396' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#25054D' />
        <stop offset='1' stopColor='#45108A' />
      </linearGradient>
      <linearGradient id='paint53_linear_1174_41322' x1='19.2202' y1='68.4502' x2='98.8902' y2='72.1302' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#25054D' />
        <stop offset='1' stopColor='#45108A' />
      </linearGradient>
      <linearGradient id='paint54_linear_1174_41322' x1='19.12' y1='70.7202' x2='98.78' y2='74.4102' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#25054D' />
        <stop offset='1' stopColor='#45108A' />
      </linearGradient>
      <linearGradient id='paint55_linear_1174_41322' x1='18.9902' y1='73.6796' x2='98.6403' y2='77.3596' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#25054D' />
        <stop offset='1' stopColor='#45108A' />
      </linearGradient>
      <linearGradient id='paint56_linear_1174_41322' x1='18.8901' y1='75.6698' x2='98.5501' y2='79.3499' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#25054D' />
        <stop offset='1' stopColor='#45108A' />
      </linearGradient>
      <linearGradient id='paint57_linear_1174_41322' x1='18.7902' y1='77.7597' x2='98.4601' y2='81.4397' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#25054D' />
        <stop offset='1' stopColor='#45108A' />
      </linearGradient>
      <linearGradient id='paint58_linear_1174_41322' x1='18.7001' y1='79.6393' x2='98.3701' y2='83.3193' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#25054D' />
        <stop offset='1' stopColor='#45108A' />
      </linearGradient>
      <linearGradient id='paint59_linear_1174_41322' x1='18.59' y1='82.2898' x2='98.25' y2='85.9698' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#25054D' />
        <stop offset='1' stopColor='#45108A' />
      </linearGradient>
      <clipPath id='clip0_1174_41322'>
        <rect width='189.18' height='176.21' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export default IconUnderConstruction;
