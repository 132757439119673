import { FC, useMemo } from 'react';
import Link from 'next/link';
import { twMerge } from 'tailwind-merge';
import { Tooltip } from '@nextui-org/react';

export interface MenuAlfredItemProps {
  name: string
  Icon: JSX.Element
  href: string
  pathname?: string
  analytics?: () => void
};

export const MenuAlfredItem: FC<MenuAlfredItemProps> = ({
  name,
  Icon,
  href,
  pathname,
  analytics,
}) => {
  const isActive = useMemo(() => pathname?.startsWith(href), [pathname, href]);
  return (
    <Tooltip content={name} placement='right' className='flex md:hidden'>
      <div className='flex relative group'>
        <div className={twMerge(
          'absolute top-0 left-0 h-full rounded w-9 ',
          !isActive && ' bg-secondary-op-gradient-hover group-hover:w-full transition-all hover:bg-secondary '
        )}
        />
        <Link
          className={
            twMerge(
              'w-[200px] flex flex-row items-center rounded-l',
              isActive
                ? 'w-11 md:w-[200px] bg-secondary-gradient'
                : 'w-12 md:w-[204px] cursor-pointer bg-transparent transform active:scale-95 transition-transform',
              ''
            )
          }
          href={href}
          onClick={() => {
            analytics?.();
          }}
        >
          <div className='rounded h-9 min-w-9 flex items-center justify-center'>
            {Icon}
          </div>
          <div className='hidden md:inline ml-3 text-sm'>{name}</div>
        </Link>
        {
          isActive && <div className='w-1 h-auto bg-primary-gradient' />
        }
      </div>
    </Tooltip>
  );
};

export default MenuAlfredItem;
