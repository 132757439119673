
export const IconWranch = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width='108' height='154' viewBox='0 0 108 154' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path d='M20.0692 95.7451C20.5822 94.0573 19.8376 92.3694 17.7691 91.178C14.1452 89.0765 7.80753 89.3412 3.62101 91.7572C1.22163 93.1472 0.0467644 94.9343 0.162598 96.6056V147.34C0.162598 147.489 0.162598 147.638 0.162598 147.787C0.245335 149.028 1.05615 150.219 2.59507 151.113C6.21896 153.215 12.5567 152.95 16.7432 150.534C19.5066 148.929 20.6484 146.794 20.0527 144.924V95.7617L20.0692 95.7451Z' fill='url(#paint0_linear_675_64171)' />
    <path d='M0.0170803 86.9088C0.0170803 86.9088 -0.429699 93.6271 3.2273 94.7358C6.88429 95.8279 13.8839 96.3243 18.0373 93.0148C21.1151 90.5658 20.4698 87.0743 20.0892 84.4102L0.0170803 86.9088Z' fill='url(#paint1_linear_675_64171)' />
    <path d='M16.6123 89.5527C12.4258 91.9686 6.08808 92.2334 2.46418 90.1318C-1.15972 88.0303 -0.712938 84.3733 3.47357 81.9574C7.66009 79.5414 13.9978 79.2767 17.6217 81.3782C21.2456 83.4797 20.7988 87.1367 16.6123 89.5527Z' fill='#0172D9' />
    <g style={{ mixBlendMode: 'overlay' }} opacity='0.39'>
      <path d='M11.4845 81.6663C8.88655 81.6663 6.28859 82.3116 4.33599 83.4368C2.69779 84.38 1.72149 85.5549 1.72149 86.5809C1.72149 87.6068 2.71433 88.3018 3.31004 88.6493C4.63384 89.4105 6.50371 89.8407 8.60524 89.8407C11.2032 89.8407 13.8012 89.1954 15.7538 88.0701C17.392 87.1269 18.3683 85.952 18.3683 84.9261C18.3683 83.9002 17.3754 83.2052 16.7797 82.8577C15.4559 82.0965 13.586 81.6663 11.4845 81.6663ZM11.4845 79.9453C13.7846 79.9453 15.9854 80.4086 17.6236 81.3518C21.2475 83.4534 20.8007 87.1104 16.6142 89.5263C14.3307 90.8501 11.3852 91.5286 8.60524 91.5286C6.30514 91.5286 4.10433 91.0652 2.46613 90.122C-1.15777 88.0205 -0.710987 84.3635 3.47553 81.9476C5.75908 80.6238 8.70453 79.9453 11.4845 79.9453Z' fill='white' />
    </g>
    <g style={{ mixBlendMode: 'overlay' }} opacity='0.2'>
      <path d='M5.19149 147.402C4.34757 146.922 3.66912 145.615 3.66912 144.49V99.9934C3.66912 98.8681 4.34757 98.3552 5.19149 98.8516C6.03541 99.3315 6.71387 100.639 6.71387 101.764V146.26C6.71387 147.385 6.03541 147.898 5.19149 147.402Z' fill='#1D1D1B' />
    </g>
    <g style={{ mixBlendMode: 'overlay' }} opacity='0.2'>
      <path d='M15.0059 147.402C15.8499 146.922 16.5283 145.615 16.5283 144.49V99.9934C16.5283 98.8681 15.8499 98.3552 15.0059 98.8516C14.162 99.3315 13.4836 100.639 13.4836 101.764V146.26C13.4836 147.385 14.162 147.898 15.0059 147.402Z' fill='#1D1D1B' />
    </g>
    <path d='M10.3964 87.7217L11.5713 87.0598L11.1411 28.8126L8.5431 13.0098L7.38478 13.6717L9.68488 77.8759L10.3964 87.7217Z' fill='url(#paint2_linear_675_64171)' />
    <path d='M11.5635 87.0798L8.436 85.2761V20.9561L11.5635 22.7763V87.0798Z' fill='url(#paint3_linear_675_64171)' />
    <path d='M11.6965 14.831L10.1576 13.9374L9.84316 13.7554L8.53591 13.0107L5.55735 20.5895L9.36328 29.3431L9.84316 29.6079L10.1576 29.7899L10.6209 30.0712L14.4434 25.7192L11.6965 14.831Z' fill='url(#paint4_linear_675_64171)' />
    <path d='M10.3936 87.7234L7.26607 85.9197V21.6162L10.3936 23.4199V87.7234Z' fill='url(#paint5_linear_675_64171)' />
    <path d='M10.5265 15.4911L8.98763 14.6141L8.67324 14.4321L7.38254 13.6709L4.38743 21.2496L8.19336 30.0033L8.67324 30.2846L8.98763 30.4666L9.46751 30.7314L13.2734 26.3794L10.5265 15.4911Z' fill='url(#paint6_linear_675_64171)' />
    <path d='M59.083 20.4219C62.7369 24.9892 63.9766 30.682 63.9766 36.4238C63.9766 40.1592 62.9163 42.9648 61.1709 44.5471C61.2199 44.5471 61.1872 44.5471 61.1383 44.5797C61.1709 44.5797 61.1383 44.5797 61.0894 44.6123C60.9262 44.7428 60.8284 44.8244 60.7305 44.906C59.9149 45.7379 58.3653 48.2662 58.3653 55.6228V116.172C58.3653 122.159 59.3929 126.123 60.2248 128.357C60.6 129.075 60.9262 129.825 61.2362 130.576C62.4596 133.577 63.1936 136.807 63.1936 139.841C63.1936 143.739 61.9865 146.578 60.0617 147.997C57.3213 149.873 57.2887 149.889 57.2724 149.905C52.2647 153.478 49.6221 153.526 46.7186 151.863C40.9279 148.519 36.1975 139.498 36.1975 131.766C36.1975 128.732 36.9315 126.351 38.1549 124.769C38.4648 124.377 38.8074 124.018 39.1662 123.725C39.9818 122.436 41.0095 119.647 41.0095 113.677V53.1271C41.0095 46.0478 39.574 41.7904 38.7258 39.8982C38.5301 39.523 38.3343 39.1316 38.1386 38.7564C36.3932 35.1352 35.3493 31.1388 35.3493 27.4034C35.3493 22.7382 36.1812 19.0517 38.4485 17.6489C41.1074 15.8383 41.14 15.8056 41.1726 15.7893C44.8754 13.2773 45.4952 13.0652 46.1967 13C46.9633 13.4404 47.6647 14.8759 47.6647 16.6049V26.9303L51.6448 32.3622V26.4083C51.6448 25.3154 51.9221 24.663 52.3299 24.4346C55.0703 22.5587 55.1029 22.5424 55.1192 22.5261C58.4632 20.2425 58.7568 20.2425 59.0667 20.4056L59.083 20.4219ZM52.8193 142.043C52.8193 141.831 52.8519 141.619 52.8519 141.39C52.8519 136.872 50.0952 131.603 46.7023 129.646C46.6534 129.613 46.6044 129.597 46.5392 129.564C46.5392 129.776 46.5066 129.988 46.5066 130.217C46.5066 134.735 49.2633 140.004 52.6561 141.961C52.7051 141.994 52.754 142.01 52.8193 142.043Z' fill='url(#paint7_linear_675_64171)' />
    <path d='M55.23 134.656C56.4534 137.658 57.1874 140.888 57.1874 143.922C57.1874 151.653 52.4733 155.226 46.6663 151.865C40.8756 148.522 36.1452 139.501 36.1452 131.769C36.1452 128.735 36.8792 126.354 38.1026 124.772C38.4125 124.38 38.7551 124.021 39.1139 123.728C39.9295 122.439 40.9572 119.65 40.9572 113.679V53.1299C40.9572 46.0506 39.5217 41.7932 38.6735 39.901C38.4778 39.5259 38.282 39.1344 38.0863 38.7592C36.3409 35.138 35.297 31.1416 35.297 27.4062C35.297 21.6644 36.5367 17.407 40.1905 17.0645C40.9572 17.5049 41.6586 18.9403 41.6586 20.6694V30.9948L46.6337 37.7805L51.5925 36.7202V26.3948C51.5925 24.6658 52.2939 24.0296 53.0606 24.47C56.7144 29.0373 57.9541 34.7302 57.9541 40.4719C57.9541 44.2073 56.8938 47.013 55.1485 48.5952C55.1974 48.5952 55.1485 48.5952 55.0995 48.6442C54.969 48.7584 54.8385 48.8562 54.7081 48.9541C53.8925 49.786 52.3428 52.3143 52.3428 59.671V120.221C52.3428 126.207 53.3705 130.171 54.2024 132.405C54.5776 133.123 54.9038 133.874 55.2137 134.624L55.23 134.656ZM52.8159 141.393C52.8159 136.875 50.0592 131.606 46.6663 129.649C43.2735 127.691 40.5168 129.779 40.5168 134.298C40.5168 138.816 43.2735 144.085 46.6663 146.042C50.0592 148 52.8159 145.912 52.8159 141.393Z' fill='url(#paint8_linear_675_64171)' />
    <circle cx='83' cy='25' r='25' fill='url(#paint9_linear_675_64171)' />
    <path fillRule='evenodd' clipRule='evenodd' d='M95.3088 20.0188L80.9995 34.3097C80.0779 35.2301 78.586 35.2301 77.6667 34.3097L71.6894 28.3424C70.7702 27.422 70.7702 25.932 71.6894 25.0116C72.611 24.0935 74.103 24.0935 75.0222 25.0116L79.3331 29.3169L91.976 16.6903C92.8953 15.7699 94.3872 15.7699 95.3088 16.6903C96.2304 17.6083 96.2304 19.0984 95.3088 20.0188Z' fill='white' />
    <defs>
      <linearGradient id='paint0_linear_675_64171' x1='20.2256' y1='89.7588' x2='-16.1929' y2='101.403' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#0096FB' />
        <stop offset='1' stopColor='#0075C5' />
      </linearGradient>
      <linearGradient id='paint1_linear_675_64171' x1='20.4189' y1='84.4102' x2='11.0403' y2='101.587' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#0096FB' />
        <stop offset='1' stopColor='#0075C5' />
      </linearGradient>
      <linearGradient id='paint2_linear_675_64171' x1='7.38478' y1='50.374' x2='11.5713' y2='50.374' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#CFD5F7' />
        <stop offset='1' stopColor='#BCC2E6' />
      </linearGradient>
      <linearGradient id='paint3_linear_675_64171' x1='8.436' y1='54.0179' x2='11.5635' y2='54.0179' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#CFD5F7' />
        <stop offset='1' stopColor='#BCC2E6' />
      </linearGradient>
      <linearGradient id='paint4_linear_675_64171' x1='5.55735' y1='21.5327' x2='14.4434' y2='21.5327' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#CFD5F7' />
        <stop offset='1' stopColor='#BCC2E6' />
      </linearGradient>
      <linearGradient id='paint5_linear_675_64171' x1='8.83808' y1='25.6042' x2='8.83808' y2='126.114' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#D0D4F9' />
        <stop offset='1' stopColor='#636C9E' />
      </linearGradient>
      <linearGradient id='paint6_linear_675_64171' x1='8.83871' y1='25.6016' x2='8.83871' y2='126.111' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#D0D4F9' />
        <stop offset='1' stopColor='#636C9E' />
      </linearGradient>
      <linearGradient id='paint7_linear_675_64171' x1='35.3493' y1='82.9615' x2='63.9766' y2='82.9615' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#CFD5F7' />
        <stop offset='1' stopColor='#BCC2E6' />
      </linearGradient>
      <linearGradient id='paint8_linear_675_64171' x1='16.9625' y1='85.7373' x2='98.9784' y2='83.9267' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#D0D4F9' />
        <stop offset='1' stopColor='#636C9E' />
      </linearGradient>
      <linearGradient id='paint9_linear_675_64171' x1='58' y1='24.5455' x2='108' y2='24.5455' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#0096FB' />
        <stop offset='1' stopColor='#0075C5' />
      </linearGradient>
    </defs>
  </svg>
);
