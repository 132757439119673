import { IconAddress, IconAlfredArrow, IconCheckCircle, IconStar } from '../icons';
import { Badge } from '.';
import { ServiceLaborsModel, ServiceModel, Shop } from '@alfred-co/types';
import { calculateDiscountPercentage, formatPriceInCOP, getCurrentLabor, getTransportAddresses, toWeekDayDate } from '../utils';
import { Divider } from '@nextui-org/react';
import Image from 'next/image';
import React, { FC, ReactNode, useMemo } from 'react';
import { twMerge } from 'tailwind-merge';

interface CurrentServiceInfoProps {
  isTransport: boolean
  service: ServiceModel
  shop?: Shop
  serviceLabors?: any
  onLaborClick?: (serviceLabor: ServiceLaborsModel) => void
}

export const CurrentServiceInfo: FC<CurrentServiceInfoProps> = ({
  isTransport,
  service,
  shop,
  serviceLabors,
  onLaborClick,
}: CurrentServiceInfoProps) => {
  const getData = (icon: ReactNode, label: string, value: string) => {
    return (
      <div className='flex flex-col text-sm gap-2'>
        <div className='flex flex-row text-secondary items-center h-[30px] gap-2'>
          {icon && (
            <div className='flex bg-secondary-op-gradient-hover w-[30px] h-[30px] rounded-[3px] items-center justify-center p-[2px]'>
              {icon}
            </div>
          )}
          {label}
        </div>
        <span>{value}</span>
      </div>
    );
  };

  const { activeStep } = getCurrentLabor(serviceLabors ?? [], service.serviceMode);
  const transportServiceLabor = useMemo(() => serviceLabors?.find(
    (serviceLabor: ServiceLaborsModel) => serviceLabor.labor.slug.includes('transport')), [serviceLabors]);
  const { startAddress, endAddress } = getTransportAddresses(service, transportServiceLabor);

  return (
    <div className='flex'>
      {
        isTransport
          ? (
            <div className='flex flex-col w-full gap-7'>
              <span>Servicio de Transporte</span>
              <div className='flex flex-row justify-between'>
                {getData(<IconAddress />, 'Dirección de recogida del vehículo', startAddress)}
                {getData(<IconAddress />, 'Dirección de entrega del vehículo', endAddress)}
                {getData(null, 'Fecha de inicio:', transportServiceLabor.startDate ? toWeekDayDate(new Date(transportServiceLabor.startDate)) : '-')}
                {getData(null, 'Fecha de finalización:', transportServiceLabor.endDate ? toWeekDayDate(new Date(transportServiceLabor.endDate)) : '-')}
              </div>
            </div>
            )
          : (
            <div className='flex flex-row gap-14'>
              <div className='flex flex-col gap-5'>
                Taller Aliado
                <div className='flex flex-row flex-wrap gap-[30px]'>
                  <div className='flex min-w-20 h-20 rounded-md bg-white items-center'>
                    {shop?.allyIcon ? <Image className='rounded-md' width={80} height={80} src={shop.allyIcon} alt='shop' /> : null}
                  </div>
                  <div className='flex flex-col justify-between'>
                    <span className='text-xl'>{shop?.name ?? 'Por asignar'}</span>
                    <span>{shop?.address.address ?? '-'}</span>
                    <div className='flex flex-row text-xs gap-3'>
                      {shop?.score &&
                        <>
                          <div className='flex flex-row items-center gap-1'>
                            <IconStar width={14} height={14} className='fill-warning' />
                            <span>{shop?.score}</span>
                          </div>
                          {(shop.agreement?.duration || shop.distance) && <Divider className='bg-complementary-gray-light' orientation='vertical' />}
                        </>}
                      {shop?.agreement?.duration &&
                        <>
                          <span>{`${String(shop.agreement.duration)} min`}</span>
                          {shop.distance && <Divider className='bg-complementary-gray-light' orientation='vertical' />}
                        </>}
                      {shop?.distance && <span>{`${String(shop.distance)} km`}</span>}
                    </div>
                  </div>
                </div>
              </div>
              <div className='flex items-center'>
                <IconAlfredArrow />
              </div>
              <div className={twMerge('flex flex-col gap-5', serviceLabors?.length === 1 && 'items-center')}>
                Servicios Solicitados
                <div className='flex flex-row '>
                  {
                    serviceLabors?.map((serviceLabor: ServiceLaborsModel, index: number) => {
                      const price = Number(serviceLabor.price);
                      const discount = Number(serviceLabor.discount);
                      const totalPrice = serviceLabor.price ? price - discount : -1;
                      return (
                        <div
                          key={serviceLabor.id}
                          className={
                            twMerge(
                              'mr-10',
                              onLaborClick && 'cursor-pointer hover:shadow-[0_0_15px_0_rgb(0,0,0,0.1)] hover:shadow-secondary'
                            )
                          }
                          onClick={() => onLaborClick?.(serviceLabor)}
                        >
                          <Badge
                            isOneChar
                            content={<IconCheckCircle />}
                            color='success'
                            showOutline={false}
                            isInvisible={!(activeStep - 1 > index)}
                          >
                            <div className={
                              twMerge(
                                'flex flex-col bg-complementary-gray-main w-40 h-32 rounded-md border-[1.5px] justify-evenly',
                                activeStep - 1 < index || !serviceLabor.startDate ? 'border-white' : 'border-primary'
                              )
                            }
                            >
                              <div className='flex flex-row justify-center items-center'>
                                {serviceLabor.labor.categoryIcon
                                  ? <Image className='rounded-md' src={serviceLabor.labor.categoryIcon} width={70} height={70} alt='shop' />
                                  : null}
                                <span className='w-[85px] text-xs text-center'>{serviceLabor.labor.laborConfigLabel}</span>
                              </div>

                              <div className='flex flex-row items-center p-2'>
                                <div className='flex flex-col'>
                                  {discount > 0 &&
                                    <span className='text-xs text-complementary-gray-light line-through'>
                                      {formatPriceInCOP(price)}
                                    </span>}
                                  <span className='bg-primary-gradient bg-clip-text text-transparent text-sm'>
                                    {totalPrice >= 0 ? formatPriceInCOP(totalPrice) : '$-'}
                                  </span>
                                </div>
                                {
                                  discount > 0 && (
                                    <span className='text-secondary-light text-xs min-w-10'>
                                      {`-${calculateDiscountPercentage(price, discount)}%`}
                                    </span>
                                  )
                                }
                              </div>
                            </div>
                          </Badge>
                        </div>
                      );
                    }
                    )
                  }
                </div>
              </div>
            </div>
            )
      }
    </div>
  );
};
