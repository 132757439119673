
export const IconInsurance = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='97'
    height='97'
    viewBox='0 0 97 97'
    fill='none'
    {...props}
  >
    <g clipPath='url(#clip0_1166_40447)'>
      <circle cx='48.6564' cy='48.8024' r='52.1227' fill='url(#paint0_radial_1166_40447)' />
      <path fillRule='evenodd' clipRule='evenodd' d='M27.6157 10.1436C22.2869 13.6704 15.9531 15.8338 9.64194 16.803C9.07176 16.8874 8.65958 17.3889 8.67063 17.9636C8.97556 30.229 11.3522 43.9478 20.61 52.6641C22.8284 54.7489 25.3292 56.4502 28.0389 57.8081C28.1969 57.8925 28.372 57.932 28.5471 57.932H32.0639C35.9418 54.6248 47.7629 44.376 48.4233 17.9636C48.4346 17.3889 48.0224 16.8874 47.4522 16.803C43.5177 16.2002 32.0641 14.7295 32.0636 9.75488H28.7731C28.1929 9.75815 28.1424 9.79986 27.6152 10.1433L27.6157 10.1436Z' fill='#0075C5' />
      <path fillRule='evenodd' clipRule='evenodd' d='M9.64194 16.8027C9.07176 16.8872 8.65958 17.3887 8.67063 17.9633H12.1877L13.1585 16.8027H9.64194Z' fill='#0075C5' />
      <path fillRule='evenodd' clipRule='evenodd' d='M8.67139 17.9639C8.97632 30.2293 11.353 43.9481 20.6108 52.6644C22.8292 54.7492 25.33 56.4505 28.0397 57.8084C28.1977 57.8928 28.3728 57.9322 28.5478 57.9322H32.0646C32.8041 57.2953 33.8372 56.4108 35.0283 55.2052C33.1259 46.9849 22.1462 23.5865 14.7962 17.9639H8.67139Z' fill='#0075C5' />
      <path fillRule='evenodd' clipRule='evenodd' d='M31.426 9.95522C26.0349 13.5949 19.5881 15.8203 13.1583 16.8063C12.5826 16.8908 12.1704 17.3923 12.1875 17.9669C12.4922 30.2324 14.8634 43.9512 24.1269 52.6674C26.3453 54.7522 28.8406 56.4533 31.5558 57.8114C31.8721 57.9748 32.2501 57.9748 32.5721 57.8114C35.2818 56.4536 37.7768 54.7522 39.9955 52.6674C49.2593 43.9514 51.6302 30.2326 51.9349 17.9669C51.952 17.3923 51.54 16.8908 50.9696 16.8063C44.5399 15.8203 38.0875 13.5949 32.6964 9.95522C32.3129 9.69591 31.8103 9.69591 31.4265 9.95522H31.426Z' fill='#0096FB' />
      <path fillRule='evenodd' clipRule='evenodd' d='M32.061 12.8154C26.9128 16.09 20.9729 18.2046 14.9722 19.2826C15.3942 30.3382 17.6661 42.8222 26.005 50.6666C27.8258 52.3795 29.8654 53.8085 32.0635 54.9834C34.2598 53.809 36.2972 52.378 38.1167 50.6666C46.4562 42.8222 48.7279 30.3374 49.1496 19.2816C43.1504 18.2029 37.2082 16.0892 32.061 12.8154Z' fill='#0075C5' />
      <path fillRule='evenodd' clipRule='evenodd' d='M28.6296 44.4522L21.5495 34.3374C21.1936 33.8288 21.317 33.1283 21.8253 32.7725L24.2884 31.047C24.7963 30.6912 25.4971 30.8146 25.8528 31.3232L29.8614 37.0501L39.8757 25.1113C40.2743 24.6359 40.9832 24.5741 41.4581 24.9728L43.7617 26.9064C44.237 27.3051 44.299 28.0142 43.9001 28.4896L30.4439 44.5319C29.9601 45.1085 29.0611 45.0691 28.6293 44.4525L28.6296 44.4522Z' fill='#A5C1AE' />
      <path fillRule='evenodd' clipRule='evenodd' d='M29.5974 44.4522L22.5173 34.3374C22.1614 33.8288 22.2847 33.1283 22.7931 32.7725L25.2562 31.047C25.7641 30.6912 26.4649 30.8146 26.8206 31.3232L30.8291 37.0501L40.8435 25.1113C41.2421 24.6359 41.9509 24.5741 42.4259 24.9728L44.7295 26.9064C45.2047 27.3051 45.2668 28.0142 44.8679 28.4896L31.4116 44.5319C30.9279 45.1085 30.0289 45.0691 29.5971 44.4525L29.5974 44.4522Z' fill='white' />
      <g style={{ mixBlendMode: 'multiply' }} opacity='0.31'>
        <path d='M84.0083 59.4043C84.0083 59.4043 87.6195 60.9865 88.1391 62.5057C88.5429 63.6874 88.1391 65.67 85.6004 67.5699C84.1705 68.6424 54.606 85.2021 54.606 85.2021C54.606 85.2021 47.9265 89.6341 38.7745 87.3171C37.2718 86.9365 30.6023 83.6762 28.7288 79.264C28.0073 77.5693 28.6196 74.5208 31.7508 72.4918C37.7915 68.5894 84.0083 59.4043 84.0083 59.4043Z' fill='#231F20' />
      </g>
      <path d='M43.9258 59.1166C43.9258 59.1166 43.2208 57.5808 42.6614 57.7165C42.0987 57.8489 42.1054 59.0206 42.1186 59.388C42.1484 60.1957 42.7773 61.7712 42.7773 61.7712C42.7773 61.7712 42.4628 62.5292 43.6114 61.3773C44.7864 60.2023 43.9291 59.1166 43.9291 59.1166H43.9258Z' fill='#DFE0E0' />
      <path d='M49.7568 54.7517C49.7568 54.7517 55.7246 50.8195 59.8621 49.1215C63.9995 47.4268 66.8461 46.5463 67.5246 46.4106C68.2031 46.2749 70.0335 45.7983 72.2711 46.6125C74.5086 47.4268 79.5331 50.184 81.5621 51.4616C83.6639 52.7856 84.1405 53.5303 84.5444 54.2089C84.9515 54.8874 85.3586 56.6847 85.3586 56.6847C85.3586 56.6847 85.6631 56.9892 85.7988 57.8035C85.9345 58.6177 86.0007 59.1275 85.9345 59.667C85.8683 60.2098 85.6631 60.4117 85.63 60.8884C85.5638 61.792 85.9246 62.4143 84.9747 63.6356C84.9217 63.7018 84.3954 63.9997 84.4318 63.3179C84.4881 62.2852 84.0247 60.4018 81.8335 61.8019C80.6121 62.5831 78.3415 65.327 77.7523 68.9216C77.6597 69.4942 77.1433 69.7921 76.8487 69.9709C69.4146 74.5353 58.5712 80.2449 58.5712 80.2449C58.5712 80.2449 58.1938 80.4866 57.9257 80.5164C57.5881 80.5561 55.7676 79.3844 55.7676 79.3844L50.3327 83.8031C50.3327 83.8031 50.1705 83.8859 50.1176 84.2897C50.0712 84.6538 49.7667 85.6567 49.4854 85.895C49.2702 86.0738 48.6248 86.4379 47.079 86.6199C46.3773 86.7027 44.1299 86.5173 42.263 86.279C39.9262 85.9811 36.8778 84.9517 35.1433 84.0084C32.8363 82.7539 31.8897 81.0228 31.1416 79.7021C30.3042 78.2193 30.7014 77.7691 30.7014 77.7691C30.7014 77.7691 30.4432 77.0508 30.5657 76.6503C30.7014 76.2101 30.5326 75.4985 30.4631 74.0388C30.4399 73.5555 30.847 71.8642 31.3435 70.987C32.4954 68.9514 34.0213 67.5612 35.8517 66.2737C37.6821 64.9861 40.6676 62.8148 40.6676 62.8148C40.6676 62.8148 45.957 57.6612 49.7535 54.7451L49.7568 54.7517Z' fill='#EEF1F0' />
      <path d='M81.168 51.2176C82.0121 51.9094 82.7965 52.7468 82.7171 53.1903C82.6277 53.6868 82.1743 53.5081 80.9992 53.0083C79.8242 52.5118 79.3707 52.194 79.3707 52.194C79.3707 52.194 79.4601 51.5618 78.8279 50.5225C78.626 50.1915 77.7886 49.4798 77.0803 48.9072C78.626 49.7314 80.1453 50.592 81.168 51.2143V51.2176Z' fill='#5B5D5E' />
      <path d='M62.8209 61.0629C62.8209 61.0629 57.6673 58.938 55.0458 57.5809C52.4244 56.2238 50.21 54.7773 50.21 54.7773C50.21 54.7773 44.1064 59.7059 42.7957 61.106C41.485 62.5061 40.7601 63.0953 40.7601 63.0953C40.7601 63.0953 41.7994 65.0845 45.9137 67.2095C49.4355 69.03 54.0065 70.5989 55.1385 70.781C55.9031 70.9034 56.1778 71.006 56.6743 70.4202C57.1708 69.8343 58.7563 67.2095 59.885 65.4917C61.0137 63.7738 62.8242 61.0596 62.8242 61.0596L62.8209 61.0629Z' fill='#3D3D3D' />
      <path d='M59.2488 70.8942C59.2488 70.8942 58.5471 71.0763 59.4309 68.8719C59.9108 67.6704 62.2774 63.7745 63.9523 61.7853C65.6238 59.796 67.8415 58.6209 68.9238 57.9424C70.0095 57.2639 70.1882 57.2175 72.4489 56.1352C74.7096 55.0495 77.3311 55.0032 78.4631 55.0495C79.5918 55.0959 80.5881 55.1852 80.8595 55.2746C81.1309 55.364 81.313 55.546 80.9058 55.9531C80.4987 56.3603 74.5772 60.5639 72.0451 62.3281C69.513 64.0923 61.9564 69.1069 60.9899 69.8119C60.1524 70.4209 59.5202 70.8512 59.2488 70.8976V70.8942Z' fill='#3D3D3D' />
      <g opacity='0.67'>
        <path d='M30.7017 77.7819C30.7017 77.7819 30.4435 77.0637 30.566 76.6632C30.7017 76.2229 30.5329 75.5113 30.4634 74.0582C30.5892 74.4521 30.7812 74.899 31.0857 75.3955C32.3534 77.4741 33.3795 78.4737 35.1999 79.6918C37.315 81.1051 42.4454 82.214 45.282 81.7704C46.9436 81.5089 47.6123 81.4063 48.7244 79.8672C49.7406 78.4605 50.2503 77.58 50.2503 77.58C50.2503 77.58 62.9605 69.838 67.3925 66.9452C71.8245 64.0523 80.6853 58.2665 82.3138 56.9987C83.9423 55.7343 84.1674 55.1452 84.3494 54.695C84.4057 54.556 84.4487 54.3375 84.4818 54.1025C84.505 54.1423 84.5314 54.182 84.5546 54.2217C84.9617 54.9002 85.3689 56.6975 85.3689 56.6975C85.3689 56.6975 85.6734 57.0021 85.8091 57.8163C85.9448 58.6306 86.011 59.1403 85.9448 59.6798C85.8786 60.2226 85.6734 60.4245 85.6403 60.9012C85.5741 61.8048 85.9349 62.4271 84.9849 63.6484C84.932 63.7146 84.4057 64.0125 84.4421 63.3307C84.4983 62.298 84.035 60.4146 81.8438 61.8147C80.6224 62.5959 78.3518 65.3398 77.7626 68.9344C77.6699 69.5071 77.1536 69.8049 76.859 69.9837C69.4248 74.5481 58.5814 80.2578 58.5814 80.2578C58.5814 80.2578 58.1511 80.5226 58.0916 80.4994C57.7771 80.3637 58.1511 79.9665 58.0849 78.904C57.883 75.7463 55.2053 76.5572 53.6761 77.954C51.4882 79.9566 50.76 82.2934 50.3992 83.3129C50.2867 83.6306 50.1344 84.1304 50.0815 84.5342C50.0351 84.895 50.1046 85.5173 49.5949 85.8913C49.3698 86.0568 48.5655 86.4772 47.086 86.6361C46.381 86.7122 43.968 86.6361 42.1012 86.262C39.953 85.8317 37.8347 85.514 35.286 84.1271C32.979 82.8726 31.8966 81.0422 31.1486 79.7183C30.3111 78.2354 30.7083 77.7852 30.7083 77.7852L30.7017 77.7819Z' fill='#D8D8D8' />
      </g>
      <path d='M32.3273 78.2203C32.3273 78.2203 33.8068 79.8058 35.4121 80.6631C37.4478 81.7487 38.6096 82.0301 39.629 82.3346C40.6452 82.6391 41.3469 82.6623 41.145 82.9337C40.9431 83.2051 40.6352 83.6784 39.9236 83.6453C39.212 83.6122 36.5673 82.9006 35.4817 82.222C34.396 81.5435 34.4324 81.5799 33.5817 80.7293C33.1084 80.2526 32.9595 80.0573 32.5987 79.4053C32.2611 78.7962 32.0559 77.9489 32.3273 78.217V78.2203Z' fill='#7D7D7D' />
      <path d='M32.5007 72.8936C32.5007 72.8936 34.4999 75.4025 36.5686 76.4551C38.9915 77.6864 43.3838 78.6264 43.3838 78.6264L41.6891 80.5263C41.6891 80.5263 39.8587 80.4237 37.9919 79.7816C36.1284 79.1361 35.142 78.7654 34.0232 77.6466C32.9045 76.5279 31.9214 74.8994 31.9214 74.8994L32.4974 72.9002L32.5007 72.8936Z' fill='#7D7D7D' />
      <path d='M36.5686 76.4543C38.9915 77.6856 43.3838 78.6256 43.3838 78.6256L42.6192 79.4829C41.6428 79.3737 39.6634 79.0989 38.1607 78.5594C36.3038 77.8908 35.3472 77.5433 34.2285 76.4245C33.1097 75.3057 32.2955 73.8461 32.2955 73.8461L32.5503 72.959C32.8615 73.3396 34.6852 75.4977 36.5686 76.4543Z' fill='#DFE0E0' />
      <path d='M41.5171 84.3494C41.5171 84.3494 43.4501 84.6904 44.7708 84.7897C46.0948 84.8923 47.2136 84.7566 47.5181 84.5878C47.8226 84.419 47.8557 84.3494 47.6207 83.9787C47.3824 83.6047 46.909 83.3002 46.4688 83.234C46.0286 83.1678 43.4866 83.3366 42.9768 83.3697C42.4671 83.4028 41.9938 83.5054 41.4178 83.9456C40.8419 84.3859 40.5374 84.5911 40.5374 84.5911L41.5204 84.3528L41.5171 84.3494Z' fill='#7D7D7D' />
      <path d='M31.1425 79.7099C30.3051 78.227 30.7023 77.7769 30.7023 77.7769C30.7023 77.7769 30.6725 77.6908 30.6361 77.5684C30.8413 77.9689 31.1326 78.5051 31.5496 79.2366C32.9067 81.6098 34.5683 82.8974 37.7889 84.1188C39.7086 84.8469 46.0902 85.8532 47.8379 85.4394C48.6521 85.2474 49.516 84.8569 50.082 84.4828C50.082 84.4961 50.0754 84.5126 50.0754 84.5259C50.0291 84.8867 50.0986 85.5089 49.5888 85.883C49.3638 86.0485 48.5892 86.5085 47.0799 86.6277C46.3749 86.684 43.9619 86.6277 42.0951 86.2537C39.947 85.8234 37.8286 85.5056 35.2799 84.1188C32.9729 82.8643 31.8905 81.0339 31.1425 79.7099Z' fill='#7D7D7D' />
      <path d='M30.522 74.9603L31.6147 78.4901L30.585 76.5663C30.6545 76.2318 30.585 75.7484 30.5187 74.957L30.522 74.9603Z' fill='#7D7D7D' />
      <path d='M84.2229 53.7061C84.3487 53.8815 84.448 54.0471 84.5474 54.2094C84.9546 54.8882 85.3619 56.6861 85.3619 56.6861C85.3619 56.6861 85.4083 56.7325 85.4712 56.8352C85.1268 56.855 84.6765 56.855 84.2096 56.7656C83.2593 56.5835 83.1235 56.4941 82.9878 56.2226C82.8057 55.8583 82.852 55.365 83.4845 54.4147C83.7461 54.0206 84.0076 53.8186 84.2229 53.7094V53.7061Z' fill='white' />
      <path d='M61.4098 69.4402L60.4859 69.4501C60.4859 69.4501 60.4164 69.1256 60.4032 68.8806C60.3436 67.7449 60.1416 67.331 60.0058 67.6422C59.7674 68.1853 59.7674 67.98 59.2608 69.2713C58.8502 70.3077 58.7674 71.0892 59.3634 70.9005C59.6019 70.8243 60.2177 70.3442 60.2177 70.3442L61.519 70.2912L61.4131 69.4435L61.4098 69.4402Z' fill='#7D7D7D' />
      <path d='M62.2177 68.4266C62.2177 68.4266 64.7432 69.3567 64.7432 69.9326C64.7432 70.5086 63.9488 70.5086 63.588 70.5814C62.7969 70.7403 60.6719 70.5648 60.6719 70.5648C60.6719 70.5648 60.043 70.5119 60.9169 69.1382C61.5921 68.0757 62.2144 68.4266 62.2144 68.4266H62.2177Z' fill='#7D7D7D' />
      <path d='M60.9206 69.1382C61.5958 68.0757 62.2181 68.4266 62.2181 68.4266C62.2181 68.4266 64.1974 69.1548 64.6509 69.7241C64.5052 70.1908 63.7903 70.1014 63.4427 70.1246C62.698 70.1743 60.8577 69.7969 60.5896 69.7407C60.6624 69.5752 60.765 69.3799 60.9173 69.1415L60.9206 69.1382Z' fill='#DFE0E0' />
      <path d='M49.7902 77.8785C49.7902 77.8785 50.3661 76.9285 49.7571 76.5545C49.5618 76.4353 47.2481 76.9947 45.8911 77.2992C44.534 77.6037 42.6374 78.6232 42.025 79.5036C41.416 80.3841 41.4491 80.725 41.8562 80.7581C42.2634 80.7912 44.7392 81.099 45.6892 81.0626C46.6391 81.0295 47.4865 80.7912 48.3338 79.8413C49.1812 78.8913 49.7935 77.8751 49.7935 77.8751L49.7902 77.8785Z' fill='white' />
      <path d='M33.4224 69.5114C33.6243 68.9023 33.7964 68.4257 33.6243 68.3264C33.4555 68.2238 33.2734 68.2801 32.6313 69.0943C31.9858 69.9086 30.9697 71.4344 30.9002 72.113C30.834 72.7915 30.834 73.3013 31.1385 73.9434C31.443 74.5888 32.0553 75.4693 32.0553 75.4693C32.0553 75.4693 32.2606 75.8069 32.3268 74.9595C32.3963 74.1122 32.6975 71.6926 33.4224 69.5114Z' fill='white' />
      <path d='M71.7249 62.5829C72.0989 62.2949 72.0691 62.0169 72.0128 61.6495C71.844 60.5638 71.4038 59.0909 71.1655 58.2766C71 57.7106 70.8908 57.5716 70.8246 57.4127C70.6193 56.903 70.06 57.277 70.06 57.277L71.7712 56.4297C71.7712 56.4297 71.4535 56.6481 71.5494 56.9725C71.8043 57.8199 72.0161 58.3064 72.2611 59.2266C72.506 60.1501 72.7443 60.9875 72.8205 61.3615C72.8867 61.6991 73.2441 61.5138 73.2441 61.5138L71.7183 62.5829H71.7249Z' fill='#DFE0E0' />
      <g opacity='0.45'>
        <path d='M59.4966 79.18C59.6224 79.18 59.7349 79.1171 59.7382 79.1138C59.8078 79.0774 61.4892 78.1705 61.7606 78.005C61.8963 77.9222 62.6245 77.5151 63.6341 76.9524C66.4508 75.3769 71.1675 72.7389 71.5051 72.4376C71.9718 72.0173 72.839 69.5282 73.0873 68.4359C73.7029 65.6953 73.4249 63.4114 73.2892 63.0076C73.1601 62.6203 72.7166 62.0709 72.6437 61.9815C72.5742 61.7134 71.5614 57.8904 71.2999 57.1093C71.1576 56.6856 71.0285 56.3877 70.6512 56.3877C70.3698 56.3877 69.9528 56.5532 69.1683 56.9802C67.5299 57.8706 63.8889 60.7668 63.1806 61.6538C62.4359 62.5872 58.6592 68.4591 58.4772 69.0449C58.219 69.8824 58.2157 70.9316 58.4705 71.4877C58.5533 71.6697 58.646 71.8054 58.7386 71.9312C58.8942 72.1497 59.0266 72.3417 59.0531 72.7289C59.0961 73.3512 59.1425 78.5942 59.1425 78.6471C59.1491 79.1105 59.3708 79.18 59.4999 79.18H59.4966ZM70.6512 56.5201C70.9193 56.5201 71.0219 56.7055 71.1708 57.149C71.4389 57.95 72.5047 61.9881 72.5147 62.0279L72.5279 62.0543C72.5279 62.0543 73.031 62.6601 73.1601 63.0506C73.2925 63.4445 73.5639 65.6953 72.9549 68.4061C72.7199 69.4587 71.856 71.9412 71.4158 72.3383C71.0914 72.6296 66.1993 75.367 63.5712 76.8366C62.5616 77.4026 61.8334 77.8097 61.6944 77.8924C61.4263 78.0546 59.6919 78.988 59.6754 78.998C59.6754 78.998 59.5893 79.0476 59.4999 79.0476C59.3576 79.0476 59.2815 78.9152 59.2782 78.6504C59.2782 78.4353 59.2318 73.3512 59.1888 72.7223C59.159 72.2953 59.0067 72.0835 58.8479 71.8551C58.7618 71.7326 58.6724 71.6035 58.593 71.4314C58.3845 70.9746 58.3348 69.9717 58.6062 69.0847C58.785 68.5021 62.5418 62.6667 63.2865 61.7366C63.9915 60.8561 67.606 57.9831 69.2345 57.096C69.9892 56.6856 70.3996 56.5201 70.6545 56.5201H70.6512Z' fill='#231F20' />
      </g>
      <path d='M77.3718 68.336C77.6796 66.9425 78.0238 65.8734 79.3147 63.9305C80.9399 61.4877 82.7339 60.7596 84.0314 61.4679C85.2792 62.1497 84.7695 63.7849 84.7695 63.7849C84.7695 63.7849 84.6503 64.0728 84.0413 63.9206C83.9089 63.8875 83.4654 63.616 83.4654 63.616C82.4658 64.3277 79.5431 68.5048 77.1798 69.617C77.2294 69.1966 77.2592 68.8391 77.3718 68.3327V68.336Z' fill='#7D7D7D' />
      <path d='M81.5526 61.6801C81.5526 61.6801 82.5555 61.2134 83.4625 62.1633C84.4521 63.196 84.5217 64.8013 83.9259 66.5523C83.3301 68.3033 82.251 69.5644 81.1985 70.2528C80.1459 70.938 79.2357 70.7593 78.6995 70.6732C77.8885 70.5441 77.1769 69.6206 77.1769 69.6206C77.1769 69.6206 77.3225 66.1551 79.6593 63.3781C80.7086 62.1335 81.5493 61.6801 81.5493 61.6801H81.5526Z' fill='black' />
      <path d='M80.9052 63.6735C79.8957 64.4878 79.0583 66.6392 78.9391 67.6786C78.8233 68.7146 79.1377 69.4163 79.8427 69.7208C80.5478 70.0286 82.0108 69.3501 82.8515 67.9268C83.6922 66.5069 83.8378 65.0604 83.7021 64.3852C83.5267 63.4981 82.8813 62.0748 80.9019 63.6735H80.9052Z' fill='#DFE0E0' />
      <path d='M81.0326 67.1248C81.3273 67.1248 81.6353 66.84 81.7809 66.4327C81.9531 65.9427 81.8306 65.4691 81.5061 65.3533C81.4598 65.3367 81.4134 65.3301 81.3637 65.3301C81.069 65.3301 80.7611 65.6148 80.6187 66.0221C80.4465 66.5122 80.569 66.9857 80.8935 67.1016C80.9399 67.1181 80.9863 67.1248 81.0359 67.1248H81.0326ZM81.3604 65.4658C81.3935 65.4658 81.4266 65.4725 81.4598 65.4824C81.7114 65.5718 81.7975 65.9758 81.6518 66.3864C81.5094 66.787 81.1816 67.0585 80.9366 66.9724C80.6882 66.883 80.6022 66.4758 80.7445 66.0652C80.8671 65.7175 81.1286 65.4625 81.3637 65.4625L81.3604 65.4658Z' fill='#FC2D2D' />
      <path d='M78.9526 67.5916C79.1048 66.5258 79.9224 64.4703 80.9054 63.6759C81.0577 63.5534 81.2 63.4508 81.339 63.3615C81.5476 63.2754 81.7296 63.2886 81.9083 63.3449C83.0635 63.7057 83.1165 65.2448 82.5902 66.7409C82.0639 68.237 80.8624 69.177 79.9025 68.8427C79.416 68.6706 79.0883 68.2039 78.9526 67.5883V67.5916Z' fill='black' />
      <g opacity='0.63'>
        <path d='M79.6538 68.7103L79.9716 67.5651C79.9716 67.5651 80.0378 67.0554 79.7697 66.9196C79.6803 66.8766 79.4122 66.8634 79.1209 66.8634C79.1772 66.6714 79.2434 66.4662 79.3195 66.261C79.3824 66.2477 79.4685 66.2312 79.5909 66.2113C79.8226 66.1683 80.1735 65.9333 80.2728 65.781C80.3621 65.6387 80.425 65.1985 80.425 65.1985L80.5541 64.136C80.6236 64.0499 80.5806 63.997 80.6534 63.9208C80.6634 63.9076 80.6733 63.8977 80.6865 63.8844C80.7593 63.8083 80.8355 63.7355 80.9116 63.6759C80.9248 63.666 80.9348 63.6593 80.9447 63.6494C80.9811 64.1327 81.1069 64.6126 81.1069 64.6523C81.1069 64.8377 81.1499 65.3143 81.4544 65.2647C81.6232 65.2382 81.9145 64.9999 82.5533 64.5398C82.646 64.4736 82.742 64.3942 82.8214 64.3313C82.8413 64.3975 82.8578 64.467 82.8711 64.5365C82.8843 64.606 82.8943 64.6821 82.9009 64.7549L81.7358 65.834C81.7358 65.834 81.4478 66.1219 81.4478 66.3934C81.4478 66.5622 81.8384 67.2109 82.1297 67.6776C82.0568 67.7869 81.9807 67.8928 81.9013 67.9888C81.5934 67.7207 81.226 67.4062 81.1201 67.3202C80.9348 67.1679 80.5773 67.4228 80.4912 67.6942C80.4482 67.8332 80.243 68.3694 80.0444 68.8758C79.9981 68.8659 79.9517 68.856 79.9054 68.8394C79.8557 68.8229 79.8127 68.7997 79.7664 68.7765C79.7266 68.7567 79.6902 68.7335 79.6505 68.7103H79.6538Z' fill='#4F4F4F' />
      </g>
      <path d='M81.2418 64.9688C81.2849 65.2336 81.3676 65.4785 81.6721 65.4289C81.8409 65.4024 82.2183 65.0913 82.7843 64.6908C82.8207 64.6676 82.8472 64.6444 82.8803 64.6213C82.8803 64.6213 82.8803 64.6213 82.8803 64.6246C82.9001 64.757 82.9067 64.8993 82.9067 65.0449L82.0859 65.8161C82.0859 65.8161 81.7979 66.1041 81.7979 66.3755C81.7979 66.5112 82.0528 66.9614 82.3043 67.3751C82.2315 67.5108 82.1521 67.6399 82.0693 67.7591C81.8078 67.5009 81.5463 67.2394 81.4603 67.1699C81.2749 67.0177 80.9936 66.9945 80.838 67.3553C80.7751 67.5042 80.4739 68.2589 80.2257 68.8944C80.1893 68.8944 80.1264 68.8845 80.0635 68.8745C80.0105 68.8646 79.9576 68.8514 79.9046 68.8348C79.8848 68.8282 79.8649 68.815 79.845 68.8083L80.2389 67.5671C80.2389 67.5671 80.421 66.9846 80.1198 66.9018C79.9874 66.8654 79.514 66.8422 79.1202 66.8455C79.1235 66.8323 79.1301 66.8191 79.1334 66.8058C79.1632 66.7032 79.1963 66.5973 79.2327 66.4914C79.4445 66.4517 79.9344 66.2828 80.1297 66.1902C80.4375 66.0445 80.5335 65.7599 80.5335 65.7599C80.5335 65.7599 80.5898 65.5646 80.6295 65.2998C80.646 65.1906 80.6924 64.3068 80.7685 63.7706C80.8281 63.711 80.8446 63.7176 80.9075 63.6713C80.9803 63.6117 80.9803 63.5985 81.0465 63.5488C81.1094 64.0519 81.2352 64.9225 81.2418 64.9688Z' fill='#D3D3D3' />
      <path d='M50.3354 83.4168C50.7128 82.0399 51.0338 80.9542 52.4306 79.0874C54.2941 76.595 55.9094 76.1448 57.1705 76.9161C58.3852 77.6575 58.0311 80.2293 58.0311 80.2293C58.0311 80.2293 58.0079 80.3816 58.0509 80.4312C58.1105 80.5007 58.1933 80.4544 58.1866 80.4577C57.9252 80.6265 57.6769 80.6033 57.4915 80.5272C56.8726 80.2756 56.0418 79.9579 56.0418 79.9579C55.0091 80.6199 52.4935 83.6915 50.0806 84.6878C50.1501 84.2708 50.1997 83.9166 50.3354 83.4135V83.4168Z' fill='#7D7D7D' />
      <path d='M54.7918 76.8881C54.7918 76.8881 55.861 76.5538 56.7215 77.5468C57.3372 78.2551 57.5424 79.1885 57.4233 80.2345C57.3604 80.7839 57.2048 81.3632 56.9698 81.9556C56.2879 83.6768 55.1493 84.8816 54.0603 85.5172C52.9747 86.1494 52.124 85.9541 51.6044 85.7886C50.5717 85.4609 50.0487 84.6864 50.0487 84.6864C50.0487 84.6864 50.3664 81.062 53.0574 78.2849C54.2192 77.0867 54.7885 76.8881 54.7885 76.8881H54.7918Z' fill='black' />
      <path d='M54.0956 78.9305C53.0464 79.6951 52.103 81.8002 51.9342 82.8329C51.7654 83.8623 52.0468 84.5773 52.7352 84.9182C53.4237 85.2591 54.9198 84.6534 55.8301 83.2765C56.7403 81.8995 56.9587 80.463 56.8561 79.7812C56.7237 78.8842 56.1511 77.4344 54.0956 78.9305Z' fill='#DFE0E0' />
      <path d='M54.0769 82.3895C54.365 82.3895 54.6762 82.1212 54.8351 81.7338C55.0305 81.2537 54.9345 80.7736 54.6133 80.6444C54.5603 80.6246 54.5073 80.6113 54.4477 80.6113C54.1597 80.6113 53.8484 80.8828 53.6928 81.2669C53.4974 81.7471 53.5934 82.2272 53.9146 82.3563C53.9676 82.3762 54.0206 82.3895 54.0802 82.3895H54.0769ZM54.4477 80.7471C54.4875 80.7471 54.5239 80.7537 54.5603 80.7703C54.8053 80.8696 54.8716 81.2802 54.7093 81.6842C54.5703 82.0186 54.312 82.257 54.0769 82.257C54.0371 82.257 53.9974 82.2504 53.9643 82.2371C53.7193 82.1345 53.653 81.7272 53.8186 81.3232C53.9544 80.9921 54.2193 80.7504 54.4477 80.7504V80.7471Z' fill='#FC2D2D' />
      <path d='M51.9545 82.744C52.1597 81.6849 53.0766 79.6724 54.0994 78.931C54.2582 78.8151 54.4072 78.7191 54.5462 78.6397C54.7051 78.6397 54.9368 78.6364 55.089 78.6827C56.2078 79.0369 56.2277 80.6091 55.6319 82.0787C55.0295 83.545 53.7849 84.4255 52.8449 84.0448C52.3683 83.8496 52.0637 83.3663 51.9578 82.7473L51.9545 82.744Z' fill='black' />
      <g opacity='0.63'>
        <path d='M52.7352 82.2913C52.6459 82.2483 52.3778 82.2351 52.0865 82.2318C52.1494 82.0298 52.2255 81.8147 52.3149 81.5929C52.3745 81.5797 52.3778 81.5499 52.4771 81.5334C52.722 81.4903 53.0265 81.2785 53.1457 81.1329C53.4899 80.6992 53.437 80.2822 53.437 80.2822L53.4998 79.511C53.6819 79.2793 53.7911 79.1833 53.9864 79.0244C54.0361 79.4779 54.1453 79.8982 54.1453 79.9347C54.1453 80.1299 54.1916 80.6231 54.5127 80.5702C54.6914 80.5404 54.9959 80.2954 55.6712 79.8188C55.7605 79.7559 55.8532 79.6831 55.9327 79.6202C55.9724 79.7625 55.9989 79.9115 56.0088 80.0737L54.8073 81.1693C54.8073 81.1693 54.5028 81.4705 54.5028 81.7518C54.5028 81.914 54.847 82.4932 55.1416 82.9599C55.0522 83.0857 54.9595 83.2016 54.8635 83.3075C54.569 83.0559 54.2545 82.7944 54.1552 82.715C53.9599 82.5561 53.5826 82.8209 53.4932 83.1023C53.4535 83.2281 53.2847 83.665 53.106 84.1217C53.0133 84.1085 52.9239 84.0853 52.8379 84.0522C52.7783 84.0291 52.7187 83.9993 52.6657 83.9629L52.9471 82.9666C52.9471 82.9666 53.0199 82.437 52.7319 82.298L52.7352 82.2913Z' fill='#7D7D7D' />
      </g>
      <path d='M53.1099 82.2788C52.9742 82.2424 52.5009 82.2159 52.0905 82.2192C52.1269 82.0968 52.1699 81.971 52.2196 81.8419C52.4479 81.7989 52.7955 81.7161 52.9842 81.6268C53.3085 81.4778 53.7719 81.1799 53.7719 80.3888C53.7719 80.2829 53.815 79.7302 53.8348 79.1675C53.954 79.0417 53.9705 79.0285 54.0963 78.9358C54.1228 78.9159 54.2353 80.177 54.2419 80.22C54.285 80.4948 54.424 80.796 54.7484 80.743C54.9271 80.7132 55.3276 80.3955 55.9234 79.9784C55.9499 79.9586 55.973 79.942 55.9962 79.9255C56.0161 80.0711 56.0227 80.2234 56.0161 80.3822L55.1853 81.1501C55.1853 81.1501 54.8808 81.4513 54.8808 81.7327C54.8808 81.8618 55.1025 82.2623 55.3442 82.6562C55.2581 82.8051 55.1654 82.9441 55.0661 83.0732C54.8278 82.8382 54.6027 82.6264 54.5266 82.5602C54.328 82.4013 54.0334 82.3748 53.8712 82.7522C53.8116 82.8912 53.5535 83.5267 53.3118 84.1324C53.1662 84.1324 53.0239 84.1092 52.8882 84.0596L53.2423 82.9673C53.2423 82.9673 53.4343 82.3616 53.1166 82.2755L53.1099 82.2788Z' fill='#D3D3D3' />
    </g>
    <defs>
      <radialGradient id='paint0_radial_1166_40447' cx='0' cy='0' r='1' gradientUnits='userSpaceOnUse' gradientTransform='translate(48.6876 48.8024) scale(108.938)'>
        <stop stopColor='#7E7E7E' />
        <stop offset='0.382315' stopColor='#2F2F2F' stopOpacity='0' />
      </radialGradient>
      <clipPath id='clip0_1166_40447'>
        <rect width='97' height='97' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export default IconInsurance;
