
export const IconLocation = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='17'
    height='17'
    viewBox='0 0 17 17'
    fill='none'
    {...props}
  >
    <path d='M8.50016 8.32324C8.84252 8.32324 9.13471 8.20224 9.37673 7.96022C9.61874 7.71821 9.73975 7.42602 9.73975 7.08366C9.73975 6.7413 9.61874 6.44911 9.37673 6.2071C9.13471 5.96508 8.84252 5.84408 8.50016 5.84408C8.1578 5.84408 7.86561 5.96508 7.6236 6.2071C7.38159 6.44911 7.26058 6.7413 7.26058 7.08366C7.26058 7.42602 7.38159 7.71821 7.6236 7.96022C7.86561 8.20224 8.1578 8.32324 8.50016 8.32324ZM8.50016 14.1847C10.0703 12.7562 11.2302 11.4606 11.9798 10.2977C12.7295 9.13487 13.1043 8.11074 13.1043 7.22533C13.1043 5.83227 12.6587 4.69303 11.7673 3.80762C10.876 2.9222 9.78697 2.47949 8.50016 2.47949C7.21336 2.47949 6.12429 2.9222 5.23298 3.80762C4.34166 4.69303 3.896 5.83227 3.896 7.22533C3.896 8.11074 4.27968 9.13487 5.04704 10.2977C5.8144 11.4606 6.96544 12.7562 8.50016 14.1847ZM8.50016 15.5837C6.59947 13.9663 5.17985 12.464 4.24131 11.0769C3.30277 9.68974 2.8335 8.40588 2.8335 7.22533C2.8335 5.45449 3.40311 4.04373 4.54235 2.99303C5.68159 1.94234 7.00086 1.41699 8.50016 1.41699C9.99947 1.41699 11.3187 1.94234 12.458 2.99303C13.5972 4.04373 14.1668 5.45449 14.1668 7.22533C14.1668 8.40588 13.6976 9.68974 12.759 11.0769C11.8205 12.464 10.4009 13.9663 8.50016 15.5837Z' fill='#FCFCFC' />
  </svg>
);

export default IconLocation;
