
export const IconClose = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path d='M5.37894 15.46L4.53894 14.62L9.15894 10L4.53894 5.38004L5.37894 4.54004L9.99894 9.16004L14.6189 4.54004L15.4589 5.38004L10.8389 10L15.4589 14.62L14.6189 15.46L9.99894 10.84L5.37894 15.46Z' fill='black' />
    <circle cx='10.0001' cy='10' r='10' fill='black' />
    <path d='M6.61538 14L6 13.3846L9.38461 10L6 6.61538L6.61538 6L10 9.38461L13.3846 6L14 6.61538L10.6154 10L14 13.3846L13.3846 14L10 10.6154L6.61538 14Z' fill='white' stroke='white' strokeWidth='0.818011' />
  </svg>
);

export default IconClose;
