
export const IconAddress = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='30' height='23' viewBox='0 0 30 23' stroke='white' fill='white' {...props}>
    <path d='M6.9726 22.4685C5.1744 22.4685 3.6973 21.0176 3.6973 19.2513V19.2355C2.15599 18.9358 1 17.5953 1 16.0025V10.1989C1 9.11075 1.8991 8.24337 2.99087 8.24337H3.36014L4.43585 4.75806C5.22256 2.219 7.5827 0.5 10.28 0.5H19.6885C22.4018 0.5 24.7459 2.20323 25.5326 4.75806L26.6725 8.44839C26.7849 8.79534 26.5762 9.17383 26.223 9.26846C25.8698 9.37885 25.4844 9.17383 25.3881 8.82688L24.2482 5.13656C23.6381 3.14946 21.7917 1.80896 19.6885 1.80896H10.3115C8.19223 1.80896 6.36192 3.14946 5.75182 5.136L4.56372 8.92094C4.37106 9.29944 3.96967 9.55177 3.52012 9.55177H3.00635C2.65313 9.55177 2.34808 9.83564 2.34808 10.1984V15.9862C2.34808 17.0743 3.24718 17.9733 4.37106 17.9733H5.04538V19.2349C5.04538 20.2758 5.91237 21.1274 6.97203 21.1274C8.03168 21.1274 8.89867 20.2758 8.89867 19.2349V17.9733H15.9951C16.3644 17.9733 16.6695 18.2729 16.6695 18.6356C16.6695 18.9984 16.3644 19.298 15.9951 19.298H10.2467C10.2146 21.0485 8.7536 22.4679 6.97145 22.4679L6.9726 22.4685Z' strokeWidth='0.5' />
    <path d='M5.68823 15.0043C5.68823 16.0908 6.58676 16.9959 7.7158 16.9959C8.84483 16.9959 9.74336 16.1133 9.74336 15.0043C9.74336 13.8953 8.84483 13.0127 7.7158 13.0127C6.58676 13.0127 5.68823 13.8953 5.68823 15.0043Z' />
    <path d='M23.7184 22.5L23.4615 22.4842C21.1977 22.295 18.3398 20.2133 18.3398 18.1473V13.4004C18.3398 12.738 18.8215 12.1545 19.4798 12.0126L22.2734 11.429C23.2046 11.2398 24.1358 11.2398 25.067 11.429L27.8607 12.0126C28.519 12.1545 29.0006 12.738 29.0006 13.4004V18.1473C29.0006 20.2133 26.1428 22.295 23.8789 22.4842L23.719 22.5H23.7184ZM23.6702 21.5222L23.8147 21.5065C25.5648 21.3645 28.0052 19.6455 28.0052 18.1473V13.4004C28.0052 13.1954 27.8607 13.0219 27.668 12.9746L24.8744 12.3911C24.0877 12.2176 23.2849 12.2176 22.4982 12.3911L19.6885 12.9903C19.4958 13.0376 19.3353 13.2111 19.3353 13.4161V18.1631C19.3353 19.6455 21.7757 21.3645 23.5257 21.5222H23.6702Z' strokeWidth='0.5' />
    <path d='M23.1564 18.5569C22.9798 18.5569 22.8032 18.4939 22.6748 18.3519L21.3903 17.0903C21.1977 16.901 21.1977 16.5856 21.3903 16.3964C21.583 16.2071 21.9041 16.2071 22.0968 16.3964L23.1564 17.4372L25.2436 15.3871C25.4363 15.1978 25.7574 15.1978 25.9501 15.3871C26.1427 15.5763 26.1427 15.8917 25.9501 16.081L23.6381 18.3519C23.5097 18.4939 23.333 18.5569 23.1564 18.5569Z' />
  </svg>

);
