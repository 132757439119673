
export const IconCheckCircleOutline = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    {...props}
  >
    <circle cx='10' cy='9.99805' r='8.92308' stroke='#00DD72' strokeWidth='2.15385' />
    <path fillRule='evenodd' clipRule='evenodd' d='M14.4088 8.31223L9.06665 13.9283C8.7226 14.29 8.16559 14.29 7.82241 13.9283L5.59088 11.5833C5.2477 11.2216 5.2477 10.636 5.59088 10.2743C5.93494 9.91352 6.49194 9.91352 6.83512 10.2743L8.44453 11.9662L13.1645 7.00419C13.5077 6.64248 14.0647 6.64248 14.4088 7.00419C14.7528 7.36496 14.7528 7.95053 14.4088 8.31223Z' fill='#00DD72' />
  </svg>
);

export default IconCheckCircleOutline;
