import { Button, CustomRadio, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, RadioGroup, Textarea, useDisclosure } from '.';
import Loading from '../../../apps/bo/src/app/(dashboard)/services/[uuid]/Loading';
import { useState } from 'react';
import { IconCheckCircle } from '../icons';
import { useRouter } from 'next/navigation';
import { CancelReasonModel } from '@alfred-co/types';

interface CancelServiceProps {
  isOpen: boolean
  onClose?: () => void
  onCancelService?: (reasonId: number, reason: string) => void
  isCancelServiceLoading: boolean
  cancelReasons?: CancelReasonModel[]
  isServiceCanceled: boolean
}

export const CancelService = ({
  isOpen: isOpenI,
  onClose: setIsOpen,
  cancelReasons,
  onCancelService,
  isCancelServiceLoading,
  isServiceCanceled,
}: CancelServiceProps) => {
  const [comment, setComment] = useState('');
  const [selectedReason, setSelectedReason] = useState('');

  const router = useRouter();

  const { isOpen, onOpenChange } = useDisclosure({
    ...isOpenI && { isOpen: isOpenI },
    ...setIsOpen && { onClose: setIsOpen },
  });
  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          setSelectedReason('');
          setComment('');
        }}
        onOpenChange={onOpenChange}
        isDismissable={false}
        isKeyboardDismissDisabled
        size='2xl'
        classNames={{
          backdrop: 'z-[100000]',
          wrapper: 'z-[100000]',
          base: ['bg-frosted-gradient backdrop-blur frosty text-white rounded-lg border-1 border-complementary-gray-light'],
          closeButton: ['bg-dark-dark hover:bg-dark-main p-1 m-1 [&>svg]:h-5 [&>svg]:w-5'],
        }}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className='flex flex-col items-center gap-1 text-[22px]'>Cancelar Servicio</ModalHeader>
              <ModalBody>
                <div className='flex flex-col items-center'>
                  {
                    isCancelServiceLoading
                      ? <Loading />
                      : isServiceCanceled
                        ? (
                          <div className='flex flex-col items-center mt-16 mb-6 gap-16'>
                            <IconCheckCircle width={105} height={105} />
                            <div className='text-xl font-gothamBook'>Tu servicio ha sido cancelado</div>
                          </div>
                          )
                        : (

                          <div className='flex flex-col gap-[50px]'>
                            <div className='mt-8 font-gothamBook text-center'>
                              Cuéntanos la razón por la cual deseas cancelar tu servicio
                              o déjanos un comentario compartiendo tu experiencia.
                            </div>
                            <div>
                              <RadioGroup
                                orientation='horizontal'
                                classNames={{ label: 'text-white' }}
                                value={selectedReason}
                                onValueChange={setSelectedReason}
                              >
                                {cancelReasons?.map(option => (
                                  <div key={option.id} className='flex w-[49%]'>
                                    <CustomRadio key={option.id} value={String(option.id)} labelSize='md' fullWidth withoutBg>
                                      {option.description}
                                    </CustomRadio>
                                  </div>
                                ))}
                              </RadioGroup>
                            </div>
                            <Textarea
                              placeholder='Escribe aquí tu comentario'
                              classNames={
                                {
                                  base: 'w-full bg-secondary-op-gradient-hover rounded-md',
                                  inputWrapper: 'bg-secondary-op-gradient-hover border border-complementary-gray-secondary data-[hover=true]:border-complementary-gray-secondary',
                                  input: 'text-base font-gothamBook text-white placeholder:text-white',
                                }
                              }
                              variant='bordered'
                              value={comment}
                              onValueChange={setComment}
                              radius='md'
                            />
                          </div>
                          )
                  }
                </div>

              </ModalBody>
              <ModalFooter className='flex justify-center mt-10 gap-9'>
                {
                  isServiceCanceled
                    ? (
                      <Button
                        color='GradientAlfredBlue'
                        className='w-[262px] mb-10'
                        onPress={() => {
                          onClose();
                          router.push('/services');
                        }}
                        size='lg'
                        radius='sm'
                        isDisabled={isCancelServiceLoading}
                      >
                        Volver
                      </Button>
                      )
                    : (
                      <Button
                        color='danger'
                        onPress={() => {
                          // analytics.event('cancel_service');
                          onCancelService?.(Number(selectedReason), comment);
                        }}
                        size='lg'
                        radius='sm'
                        isDisabled={isCancelServiceLoading}
                      >
                        Cancelar Servicio
                      </Button>
                      )
                }
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};
