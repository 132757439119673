import { FC } from 'react';
import useDateTime from './useDateTime';

export const SelectedScheduleDate: FC = () => {
  const { scheduleLabel } = useDateTime();

  return (
    <div className='flex flex-col bg-complementary-gray-main w-[300px] h-[60px] border-1 border-white rounded-[5px] gap-1 px-4 py-2'>
      <span className='text-white text-sm font-normal'>Fecha seleccionada:</span>
      <span className='bg-primary-gradient inline-block text-transparent bg-clip-text text-sm font-bold'>
        {scheduleLabel}
      </span>
    </div>
  );
};

export default SelectedScheduleDate;
