import { Chip } from '@nextui-org/react';

interface CustomChipProps {
  tag: string
  color: string
}

export const CustomChip = ({ tag, color }: CustomChipProps) => {
  return (
    <Chip
      radius='sm'
      variant='bordered'
      className='bg-complementary-gray-main'
      style={{ border: `1px solid ${color}`, color }}
      startContent={<div className='ml-1 h-2 w-2 rounded-full' style={{ backgroundColor: color }} />}
    >
      {tag}
    </Chip>
  );
};

export default CustomChip;
