// import { extendVariants } from '@nextui-org/react';
import { AutocompleteProps, AutocompleteItemProps, useAutocomplete, AutocompleteSection, AutocompleteSectionProps, MenuTriggerAction, Autocomplete, AutocompleteItem } from '@nextui-org/autocomplete';

/* TODO: this is temporary while  the compilation works with this code */
/* export const AlfredAutocomplete = extendVariants(Autocomplete, {
  variants: {
    color: {
      alfred: {
        base: ['[&>div]:bg-secondary-op-gradient-hover [&>div]:rounded-md [&>div]:border-1 [&>div]:border-complementary-gray-light [&_label]:text-white [&_[data-slot=input-wrapper]]:border-none'],
        listboxWrapper: ['border-complementary-gray-light border-1 rounded-md bg-complementary-gray-light/10'],
        popoverContent: ['frosty'],
        listbox: ['bg-secondary-op-gradient-hover'],
      },
    },
  },
  defaultVariants: {
    size: 'md',
    variant: 'bordered',
    labelPlacement: 'outside',
    radius: 'md',
  },
});

export const AlfredAutocompleteItem = extendVariants(AutocompleteItem, {
  variants: {
    color: {
      alfred: {
        base: 'data-[hover=true]:bg-inherit data-[selectable=true]:focus:bg-inherit',
        wrapper: 'bg-primary',
      },
    },
  },
  defaultVariants: {
    size: 'md',
    variant: 'bordered',
    labelPlacement: 'outside',
    radius: 'md',
  },
}); */

export {
  Autocomplete,
  AutocompleteItem,
  type AutocompleteProps,
  type AutocompleteItemProps,
  useAutocomplete,
  AutocompleteSection,
  type AutocompleteSectionProps,
  type MenuTriggerAction
};
