
export const IconCar = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='25'
    height='26'
    viewBox='0 0 25 26'
    fill='none'
    {...props}
  >
    <path d='M6.66667 18.5368V19.7297C6.66667 19.9065 6.625 20.0832 6.5 20.2158C6.375 20.3483 6.20833 20.3925 6.04167 20.3925H5.625C5.45833 20.3925 5.29167 20.3483 5.16667 20.2158C5.04167 20.0832 5 19.9065 5 19.7297V12.5721L6.75 6.91665C6.79167 6.69574 6.91667 6.56319 7.08333 6.43064C7.25 6.29809 7.45833 6.25391 7.66667 6.25391H17.3333C17.5417 6.25391 17.75 6.29809 17.9167 6.43064C18.0833 6.56319 18.2083 6.69574 18.25 6.91665L20 12.5721V19.7297C20 19.9065 19.9583 20.0832 19.8333 20.2158C19.7083 20.3483 19.5417 20.3925 19.375 20.3925H18.9583C18.7917 20.3925 18.625 20.3483 18.5 20.2158C18.375 20.0832 18.3333 19.9065 18.3333 19.7297V18.5368H6.66667ZM6.70833 11.2466H18.25L17.125 7.5794H7.875L6.70833 11.2466ZM6.25 12.5721V17.2113V12.5721ZM8.45833 16.1067C8.79167 16.1067 9.04167 15.9742 9.25 15.7533C9.45833 15.5323 9.58333 15.2231 9.58333 14.9138C9.58333 14.5603 9.45833 14.2952 9.25 14.0301C9.04167 13.765 8.75 13.6767 8.45833 13.6767C8.125 13.6767 7.875 13.8092 7.625 14.0301C7.41667 14.2952 7.29167 14.5603 7.29167 14.9138C7.29167 15.2673 7.41667 15.5323 7.625 15.7533C7.875 15.9742 8.125 16.1067 8.45833 16.1067ZM16.5833 16.1067C16.9167 16.1067 17.1667 15.9742 17.4167 15.7533C17.6667 15.5323 17.75 15.2231 17.75 14.9138C17.75 14.5603 17.625 14.2952 17.4167 14.0301C17.2083 13.765 16.9167 13.6767 16.5833 13.6767C16.25 13.6767 16 13.8092 15.7917 14.0301C15.5833 14.2952 15.4583 14.5603 15.4583 14.9138C15.4583 15.2673 15.5833 15.5323 15.7917 15.7533C16 15.9742 16.25 16.1067 16.5833 16.1067ZM6.25 17.2113H18.75V12.5721H6.25V17.2113Z' fill='#F8FAFF' />
  </svg>
);

export default IconCar;
