'use client';
import { ModalHeader, Modal, useDisclosure, ModalContent, CircularProgress, Button, ModalBody } from '.';
import Image from 'next/image';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Zoom } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/zoom';
import '../styles/customSwiper.css';
import { useCallback, useEffect, useState } from 'react';
import { IconBackNavigate } from '../icons';
import { EventTrackingModel } from '@alfred-co/types';

interface EventDataModalProps {
  isOpen?: boolean
  onClose?: () => void
  labor: string
  title: string
  event: EventTrackingModel
  isTransport?: boolean
}

export const EventDataModal = ({ isOpen: isOpenI, onClose: onCloseI, labor, title, event, isTransport }: EventDataModalProps) => {
  const { isOpen, onOpenChange } = useDisclosure({
    ...isOpenI && { isOpen: isOpenI },
    ...onCloseI && { onClose: onCloseI },
  });
  const [isImages, setisImages] = useState(false);
  const [images, setImages] = useState<string[]>([]);
  const [subTitle, setSubTitle] = useState('');

  const handleSeeImages = useCallback((images: string[], subTitle: string) => {
    setisImages(true);
    setSubTitle(subTitle);
    setImages(typeof images === 'string' ? [images] : images);
  }, []);

  useEffect(() => {
    if (isTransport) {
      setisImages(true);
      setSubTitle(event.eventType.name);
      const images = Object.entries(event.data).map(([key, value], index) => {
        if (key.includes('photo')) {
          return typeof value === 'string' ? [value] : value;
        }
        return null;
      }).filter((image) => image);
      setImages(images[0]);

      if (images.length === 0) {
        setisImages(false);
      }
    }
  }, [isTransport, event.data, event.eventType.name]);

  return (
    <Modal
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      isDismissable={false}
      isKeyboardDismissDisabled
      size='3xl'
      classNames={{
        backdrop: 'z-[100000]',
        wrapper: 'z-[100001]',
        base: ['bg-frosted-gradient backdrop-blur frosty text-white rounded-lg border-1 border-complementary-gray-light h-full'],
        closeButton: ['bg-dark-dark hover:bg-dark-main p-1 m-1 [&>svg]:h-5 [&>svg]:w-5'],
      }}
      scrollBehavior='inside'
    >
      <ModalContent>
        <ModalHeader className='flex flex-col items-start gap-1'>
          {title}
        </ModalHeader>
        <ModalBody>
          <div className='flex flex-col my-5 h-full'>
            {
              !isImages && (
                <>
                  <div className='bg-secondary text-secondary-50 bg-clip-text px-6 mb-6 font-gothamBold'>
                    {labor}
                  </div>
                  <div className='flex flex-col flex-grow w-full px-6 mt-1 gap-5'>
                    {
                      Object.entries(event.data)
                        .sort(
                          ([key1], [key2]) => {
                            const order1 = event.eventType.schema.properties?.[key1]?.order ?? 0;
                            const order2 = event.eventType.schema.properties?.[key2]?.order ?? 0;
                            return order1 - order2;
                          })
                        .map(([key, value], index) => {
                          const title = event.eventType.schema.properties?.[key]?.title ??
                                        event.eventType.schema.properties?.[key]?.label ??
                                        key;
                          if (key.includes('blocked')) return null;
                          if (event.eventType.schema.properties?.[key]?.widget === 'image' || ['photo', 'picture'].some((k) => key.includes(k))) {
                            return (
                              <div key={index + '-' + title} className='flex flex-row items-center gap-2'>
                                <div className='text-medium'>
                                  {title}:
                                </div>
                                {
                              value.length === 0
                                ? (
                                  <div className='text-medium font-gothamBook'>No hay imágenes</div>
                                  )
                                : (
                                  <Button
                                    color='secondary'
                                    size='sm'
                                    variant='bordered'
                                    className='text-secondary'
                                    onClick={() => handleSeeImages(value, title)}
                                  >
                                    Ver Imágenes
                                  </Button>
                                  )
                                }
                              </div>
                            );
                          }
                          if (key.includes('feature')) {
                            const parseData = JSON.parse(value);
                            return (
                              <div key={index}>
                                {
                                  Object.entries(parseData).map(([key, value]: any) => {
                                    return (
                                      <EventItem title={key} value={value} key={index + '-' + key} />
                                    );
                                  })
                                }
                              </div>
                            );
                          }
                          return (
                            <EventItem title={title} value={value} key={index + '-' + title} />
                          );
                        })
                    }
                  </div>
                </>
              )
            }
            {
              isImages && (
                <>
                  <div className='flex flex-row justify-between items-center gap-10'>
                    {!isTransport &&
                      <div
                        className='ml-3 flex flex-row items-center gap-3 text-secondary cursor-pointer hover:text-secondary-light group'
                        onClick={() => { setisImages(false); setSubTitle(''); }}
                      >
                        <div className='h-8 w-8 bg-complementary-gray-main cursor-pointer rounded-full ml-2 group-hover:bg-complementary-gray-dark'>
                          <IconBackNavigate className='cursor-pointer' />
                        </div>
                        Atrás
                      </div>}
                    <div className='text-secondary'>{subTitle}</div>
                    <div className='min-w-24' />
                  </div>
                  <Swiper
                    navigation
                    pagination={{
                      clickable: true,
                    }}
                    centeredSlides
                    zoom={{ maxRatio: 2 }}
                    modules={[Navigation, Pagination, Zoom]}
                    className='h-full w-full'
                  >
                    {
                      images.map((image: string, index: number) => (
                        <SwiperSlide key={index}>
                          <ImageSlide image={image} />
                        </SwiperSlide>
                      ))
                    }
                  </Swiper>
                </>
              )
            }
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const ImageSlide = ({ image }: { image: string }) => {
  const [loading, setLoading] = useState(true);

  const handleLoadingComplete = () => {
    setLoading(false);
  };

  return (
    <div className='flex h-full w-full items-center justify-center swiper-zoom-container'>
      {
        image.includes('jpg')
          ? (
            <>
              {
                loading && (
                  <CircularProgress aria-label='Loading...' size='lg' color='secondary' />
                )
              }
              <Image
                src={image}
                alt='event-image'
                className='cursor-zoom-in'
                layout='fill'
                objectFit='contain'
                quality={100}
                onLoad={handleLoadingComplete}
              />
            </>
            )
          : (
            <video
              className='h-[90%] w-[90%]'
              controls
              src={image}
            />
            )
      }
    </div>
  );
};

const EventItem = ({ title, value }: { title: string, value: any }) => {
  return (
    <div className='flex flex-row items-center gap-2'>
      <div className='text-medium'>{title}:</div>
      <div className='text-medium font-gothamBook'>{typeof value === 'boolean' ? value ? 'Si' : 'No' : value}</div>
    </div>
  );
};
