import React, { FC, ReactNode } from 'react';
import { Radio, cn } from '@nextui-org/react';

export interface ARadioProps {
  children: string
  description?: string
  value: string
  withoutBg?: boolean
  fullWidth?: boolean
  labelSize?: LabelSize
  Icon?: ReactNode | JSX.Element | React.ComponentType<{ className: string }>
}

type LabelSize = 'sm' | 'md' | 'lg';

const LabelSizeStyle: { [key in LabelSize]: string } = {
  sm: 'text-sm',
  md: 'text-base',
  lg: 'text-lg',
} as const;

export const CustomRadio: FC<ARadioProps> = ({
  children,
  value,
  description,
  withoutBg,
  fullWidth,
  labelSize = 'sm',
  Icon,
}) => {
  return (
    <Radio
      value={value}
      description={description}
      classNames={{
        base: cn(
          `inline-flex m-0 ${withoutBg ? 'bg-transparent' : 'bg-complementary-gray-main'} items-center`,
          `max-w-full ${fullWidth ? 'w-full' : 'w-[235px]'} cursor-pointer rounded-[5px] gap-4 py-3 px-4 ${withoutBg ? 'border-0' : 'border-[0.5px] border-white'}`,
          `${withoutBg ? 'data-[selected=true]:border-0' : 'data-[selected=true]:border-primary'} h-12`
        ),
        labelWrapper: 'w-full',
        label: cn(
          'w-full',
          `${LabelSizeStyle[labelSize]}`,
          `${withoutBg ? 'group-data-[selected=true]:text-white' : 'group-data-[selected=true]:text-primary'}`
        ),
        wrapper: cn(
          'border-white'
        ),
      }}
    >
      <div className='flex flex-row justify-between items-center'>
        {children}
        {(Icon && React.isValidElement(Icon)
          ? React.cloneElement(Icon as React.ReactElement<{ className: string }>, { className: 'stroke-white group-data-[selected=true]:stroke-primary group-data-[selected=true]:fill-primary' })
          : null)}
      </div>
    </Radio>
  );
};
