import React, { FC, useMemo } from 'react';
import { twMerge } from 'tailwind-merge';

export interface PlateProps {
  plate: string
  city: string
  serviceType: ServiceType
  size?: PlateSize
  withoutBackground?: boolean
}

export type ServiceType = 'PUBLIC' | 'PRIVATE';
export type PlateSize = 'xs' | 'sm' | 'md';

type ServiceTypeColor = {
  [key in ServiceType]: { border: string, text: string, dot: string }
};

type PlateSizeStyles = {
  [key in PlateSize]: {
    container: string
    plateText: string
    cityText: string
    dotSize: string
    topPadding: string
  }
};

const serviceTypeColor: ServiceTypeColor = {
  PRIVATE: {
    border: 'border-complementary-yellow',
    text: 'text-complementary-yellow',
    dot: 'bg-complementary-yellow',
  },
  PUBLIC: {
    border: 'border-white',
    text: 'text-white',
    dot: 'bg-white',
  },
};

const plateSizeStyles: PlateSizeStyles = {
  xs: {
    container: 'w-[85px] h-11 border rounded-md p-0.5',
    plateText: 'text-sm leading-4 font-medium',
    cityText: 'text-[7px] leading-normal',
    dotSize: 'w-0.5 h-0.5',
    topPadding: 'pt-1',
  },
  sm: {
    container: 'w-[100px] h-12 border rounded-md p-0.5',
    plateText: 'text-xl',
    cityText: 'text-[8px]',
    dotSize: 'w-1 h-1',
    topPadding: 'pt-2',
  },
  md: {
    container: 'w-[132px] h-[60px] border-2 rounded-lg p-[3px]',
    plateText: 'text-[26px]',
    cityText: 'text-[10px]',
    dotSize: 'w-[5.3px] h-[5.3px]',
    topPadding: 'pt-[12px]',
  },
};

export const Plate: FC<PlateProps> = ({
  plate,
  city,
  serviceType,
  size = 'sm',
  withoutBackground,
}) => {
  const { container, dotSize, cityText, plateText, topPadding } = useMemo(() => plateSizeStyles[size], [size]);

  const { border, dot, text } = useMemo(() => serviceTypeColor[serviceType], [serviceType]);

  return (
    <div className={twMerge(`flex ${container} ${border} justify-between shadow-[-3.69px_3.69px_1.23px_0px_rgba(0,0,0,0.25)`,
      withoutBackground ? 'bg-transparent' : 'bg-complementary-gray-main')}
    >
      <div className={`flex relative flex-col items-center justify-between w-full h-full ${topPadding} px-1`}>
        <div className={`absolute top-1 left-1 ${dotSize} ${dot} rounded-full`} />
        <div className={`absolute top-1 right-1 ${dotSize} ${dot} rounded-full`} />
        <div className={`absolute bottom-1 left-1 ${dotSize} ${dot} rounded-full`} />
        <div className={`absolute bottom-1 right-1 ${dotSize} ${dot} rounded-full`} />

        <div className={`${text} ${plateText} leading-5 font-medium`}>{plate}</div>
        <div className={`${text} ${cityText} font-bold`}>{city}</div>
      </div>
    </div>
  );
};

export default Plate;
