import React, { FC, ReactNode } from 'react';
import Image from 'next/image';
import { Card, CardBody } from '@nextui-org/card';
import { IconAlert, IconAlfredCar, IconCheckCircleOutline, IconFine, IconWarning } from '../icons';
import { Plate, ServiceType } from './Plate';
import { twMerge } from 'tailwind-merge';

export interface GarageCardProps {
  brand: string
  city: string
  expirationStatus: FeatureStatus
  image?: string
  mileage?: number
  model: string
  plate: string
  serviceType: ServiceType
  year: number
  onPress?: () => void
  disableHover?: boolean
  groupsColors?: string[]
  infractionNumber?: number
}

export type FeatureStatus = 0 | 1 | 2;

export const FeatureStatusName: { [key in FeatureStatus]: { name: string, Icon: ReactNode } } = {
  0: {
    name: 'UP_TO_DATE',
    Icon: (
      <div className='p-1 rounded-[5px] bg-success/40 w-fit'>
        <IconCheckCircleOutline />
      </div>
    ),
  },
  1: {
    name: 'SOON_TO_EXPIRE',
    Icon: (
      <div className='p-1 rounded-[5px] bg-warning/40 w-fit'>
        <IconWarning />
      </div>
    ),
  },
  2: {
    name: 'EXPIRED',
    Icon: (
      <div className='p-1 rounded-[5px] bg-error/40 w-fit'>
        <IconAlert />
      </div>
    ),
  },
};

export const GarageCard: FC<GarageCardProps> = ({
  brand,
  city,
  expirationStatus,
  image,
  mileage,
  model,
  plate,
  serviceType,
  year,
  onPress,
  disableHover,
  groupsColors,
  infractionNumber,
}) => {
  return (
    <div className='relative' onClick={() => onPress?.()}>
      <div className='absolute -top-4 left-[14px] z-10'>
        <Plate city={city} plate={plate} serviceType={serviceType ?? 'PRIVATE'} />
      </div>
      <Card
        className={twMerge(
          'border border-white shadow-none w-[280px] h-[131px] rounded-[10px] bg-complementary-gray-main',
          !disableHover && 'hover:border-2 hover:border-secondary hover:shadow-[0_0_15px_0_rgb(0,0,0,0.1)] hover:shadow-secondary cursor-pointer'
        )}
      >
        <CardBody className='overflow-visible py-2'>
          <div className='flex flex-row items-end gap-5'>
            {image
              ? <Image src={image} width={98} height={70} alt='vehicle avatar' />
              : <IconAlfredCar />}
            <div className='flex flex-col flex-grow min-w-0 gap-1 w-full'>
              <div className='flex flex-row justify-between w-full'>
                <div className='flex flex-col gap-1 w-full'>
                  <span className='text-white font-bold h-6 truncate text-ellipsis'>{brand}</span>
                  <span className='text-white font-bold h-6 truncate text-ellipsis'>{model}</span>
                  <span className='text-white font-gothamBook h-6'>{year > 0 ? year : '-'}</span>
                </div>
                <div className='flex flex-col flex-grow justify-between pb-1 gap-[5px]'>
                  <div className='grid grid-cols-2 grid-flow-row gap-2'>
                    {groupsColors?.map((color, index) => (
                      <div
                        key={index}
                        className='w-2 h-2 rounded-full'
                        style={{ background: color }}
                      />
                    ))}
                  </div>
                  {
                    infractionNumber !== undefined &&
                      <IconFine className={twMerge((infractionNumber ?? 0) > 0 && '[&_path]:fill-error')} />
                  }
                </div>
              </div>
              <div className='flex flex-row justify-between'>
                <span className='text-white font-gothamBook h-6'>{mileage ? `${mileage} Km` : '-'}</span>
                {FeatureStatusName[expirationStatus]?.Icon}
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};
