
export const IconGas = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='20'
    height='21'
    viewBox='0 0 20 21'
    fill='none'
    {...props}
  >
    <path d='M3.3335 18.375V3.9375C3.3335 3.5875 3.4585 3.28125 3.7085 3.01875C3.9585 2.75625 4.25016 2.625 4.5835 2.625H10.1877C10.521 2.625 10.8127 2.75625 11.0627 3.01875C11.3127 3.28125 11.4377 3.5875 11.4377 3.9375V10.2375H12.7918C13.0835 10.2375 13.33 10.3432 13.5314 10.5547C13.7328 10.7661 13.8335 11.025 13.8335 11.3313V16.1219C13.8335 16.4427 13.9411 16.7089 14.1564 16.9203C14.3717 17.1318 14.6321 17.2375 14.9377 17.2375C15.2432 17.2375 15.5036 17.1318 15.7189 16.9203C15.9342 16.7089 16.0418 16.4427 16.0418 16.1219V9.66875C15.8891 9.75625 15.7293 9.82187 15.5627 9.86562C15.396 9.90937 15.2293 9.93125 15.0627 9.93125C14.521 9.93125 14.0592 9.73073 13.6772 9.32969C13.2953 8.92865 13.1043 8.44375 13.1043 7.875C13.1043 7.40833 13.2293 6.99271 13.4793 6.62812C13.7293 6.26354 14.0627 6.02292 14.4793 5.90625L12.5002 3.82812L13.2502 3.0625L16.4377 6.40937C16.6321 6.61354 16.7884 6.83594 16.9064 7.07656C17.0245 7.31719 17.0835 7.58333 17.0835 7.875V16.1219C17.0835 16.749 16.8752 17.2812 16.4585 17.7188C16.0418 18.1562 15.5349 18.375 14.9377 18.375C14.3404 18.375 13.8335 18.1562 13.4168 17.7188C13.0002 17.2812 12.7918 16.749 12.7918 16.1219V11.3313H11.4377V18.375H3.3335ZM4.5835 8.925H10.1877V3.9375H4.5835V8.925ZM15.0627 8.8375C15.3127 8.8375 15.5279 8.74271 15.7085 8.55312C15.8891 8.36354 15.9793 8.1375 15.9793 7.875C15.9793 7.6125 15.8891 7.38646 15.7085 7.19688C15.5279 7.00729 15.3127 6.9125 15.0627 6.9125C14.8127 6.9125 14.5974 7.00729 14.4168 7.19688C14.2363 7.38646 14.146 7.6125 14.146 7.875C14.146 8.1375 14.2363 8.36354 14.4168 8.55312C14.5974 8.74271 14.8127 8.8375 15.0627 8.8375ZM4.5835 17.0625H10.1877V10.2375H4.5835V17.0625ZM10.1877 17.0625H4.5835H10.1877Z' fill='#FCFCFC' />
  </svg>
);

export default IconGas;
