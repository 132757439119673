
export const IconFines = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='84'
    height='84'
    viewBox='0 0 84 84'
    fill='none'
    {...props}
  >
    <g clipPath='url(#clip0_1166_40510)'>
      <circle cx='44.177' cy='42.0198' r='52.0998' fill='url(#paint0_radial_1166_40510)' />
      <path opacity='0.1' d='M52.8359 52.5837L61.6793 47.4619L85.8257 61.4035L76.9807 66.5252L52.8359 52.5837Z' fill='black' />
      <path d='M52.8359 52.5813V11.5063L54.1067 10.7734L78.2515 24.715V65.7883L76.9807 66.5229L52.8359 52.5813Z' fill='#0075C5' />
      <path d='M52.8359 52.5789V11.5039L76.9807 25.4455V66.5205L52.8359 52.5789Z' fill='#0096FB' />
      <path d='M54.7603 49.2775V14.748L75.0574 26.4674V60.9969L54.7603 49.2775Z' fill='white' />
      <path d='M56.6484 28.6134V25.6836L59.195 27.1477V30.0842L56.6484 28.6134Z' fill='url(#paint1_linear_1166_40510)' />
      <path d='M73.1682 35.2168V35.9514L60.4604 28.6142V27.8779L73.1682 35.2168Z' fill='#3D3D3D' />
      <path d='M68.0851 33.7559V34.4887L60.4604 30.0864V29.3535L68.0851 33.7559Z' fill='#3D3D3D' />
      <path d='M57.103 27.6514L57.6243 28.7495L59.0789 26.4424' stroke='url(#paint2_linear_1166_40510)' strokeWidth='0.405851' strokeMiterlimit='10' />
      <path d='M56.6484 34.2528V31.3213L59.195 32.7871V35.7219L56.6484 34.2528Z' fill='url(#paint3_linear_1166_40510)' />
      <path d='M73.1682 40.8568V41.593L60.4604 34.2541V33.5195L73.1682 40.8568Z' fill='#3D3D3D' />
      <path d='M68.0851 39.3926V40.1255L60.4604 35.7231V34.9902L68.0851 39.3926Z' fill='#3D3D3D' />
      <path d='M57.1021 33.2891L57.6233 34.3872L59.0779 32.0801' stroke='url(#paint4_linear_1166_40510)' strokeWidth='0.405851' strokeMiterlimit='10' />
      <path d='M56.6479 39.8927V36.9629L59.1945 38.4287V41.3635L56.6479 39.8927Z' fill='url(#paint5_linear_1166_40510)' />
      <path d='M73.1682 46.4942V47.2287L60.4604 39.8915V39.1553L73.1682 46.4942Z' fill='#3D3D3D' />
      <path d='M68.0851 45.0332V45.7661L60.4604 41.3637V40.6309L68.0851 45.0332Z' fill='#3D3D3D' />
      <path d='M57.1016 38.9307L57.6228 40.0288L59.0774 37.7217' stroke='url(#paint6_linear_1166_40510)' strokeWidth='0.405851' strokeMiterlimit='10' />
      <path d='M56.6484 45.5184V42.5869L59.195 44.0527V46.9876L56.6484 45.5184Z' fill='url(#paint7_linear_1166_40510)' />
      <path d='M73.1682 52.1224V52.8586L60.4604 45.5197V44.7852L73.1682 52.1224Z' fill='#3D3D3D' />
      <path d='M68.0851 50.6582V51.3911L60.4604 46.9887V46.2559L68.0851 50.6582Z' fill='#3D3D3D' />
      <path d='M57.1021 44.5547L57.6233 45.6528L59.0779 43.3457' stroke='url(#paint8_linear_1166_40510)' strokeWidth='0.405851' strokeMiterlimit='10' />
      <path d='M71.2625 28.2991V29.0337L58.5547 21.6965V20.9619L71.2625 28.2991Z' fill='#111E3E' />
      <path d='M68.7209 28.457V29.1899L61.0962 24.7876V24.0547L68.7209 28.457Z' fill='#111E3E' />
      <path d='M58.9438 13.133L60.2852 12.4102L63.558 14.1499L62.2166 14.8744L58.9438 13.133Z' fill='#0075C5' />
      <path d='M64.5211 10.3891L65.8629 9.66602C65.683 9.76355 65.5636 9.97038 65.5485 10.2647C65.5199 10.8347 65.8966 11.509 66.3893 11.7713L65.0474 12.4944C64.5547 12.2321 64.178 11.5578 64.2066 10.9877C64.2217 10.6935 64.3411 10.4849 64.5211 10.3891Z' fill='#0075C5' />
      <path d='M67.023 12.0209L65.6811 12.5422C65.5113 12.633 65.2876 12.6263 65.0488 12.4985L66.3907 11.7754C66.6312 11.9032 66.8531 12.1117 67.023 12.0209Z' fill='#0075C5' />
      <path d='M68.558 17.267L69.8999 16.5439L68.8438 17.5731L67.502 18.2961L68.558 17.267Z' fill='#0075C5' />
      <path d='M67.502 18.2902L68.8433 17.5674L72.1161 19.3088L70.7747 20.0316L67.502 18.2902Z' fill='#0075C5' />
      <path d='M70.7739 20.0314L72.1153 19.3086L73.176 24.353L71.8346 25.0758L70.7739 20.0314Z' fill='#0075C5' />
      <path d='M67.0288 10.2772L68.3702 9.55273L69.8981 16.5437L68.5568 17.2665L67.0288 10.2772Z' fill='#0075C5' />
      <path d='M63.4048 8.14272L64.7478 7.41992L68.3702 9.54797L67.0289 10.2724L63.4048 8.14272Z' fill='#0075C5' />
      <path d='M70.7743 20.0308L71.835 25.0769L57.4463 16.8202L58.944 13.1339L62.2167 14.8737L61.3175 12.8045L63.4052 8.1416L67.0292 10.2696L68.5572 17.2589L67.5016 18.2876L70.7743 20.0274V20.0308ZM65.0474 12.4935C65.5416 12.7557 65.9652 12.5086 65.9938 11.9371C66.0224 11.3673 65.6441 10.6932 65.15 10.431C64.6574 10.1688 64.2355 10.4159 64.207 10.9874C64.1784 11.5572 64.5549 12.2313 65.0474 12.4935Z' fill='#0096FB' />
      <g style={{ mixBlendMode: 'multiply' }} opacity='0.31'>
        <path d='M6.63782 48.8428C6.63782 48.8428 3.13319 50.3783 2.62885 51.8527C2.23695 52.9995 2.62885 54.9237 5.0927 56.7675C6.48041 57.8083 35.1728 73.8795 35.1728 73.8795C35.1728 73.8795 41.6552 78.1808 50.5372 75.9322C51.9956 75.5628 58.4684 72.3986 60.2866 68.1166C60.9869 66.4719 60.3926 63.5134 57.3538 61.5442C51.4913 57.7569 6.63782 48.8428 6.63782 48.8428Z' fill='#231F20' />
      </g>
      <path d='M45.5383 48.5643C45.5383 48.5643 46.2225 47.0738 46.7654 47.2055C47.3115 47.334 47.3051 48.4712 47.2922 48.8277C47.2633 49.6115 46.653 51.1406 46.653 51.1406C46.653 51.1406 46.9582 51.8762 45.8435 50.7583C44.7031 49.618 45.5351 48.5643 45.5351 48.5643H45.5383Z' fill='#3594F2' />
      <path d='M39.8803 44.3339C39.8803 44.3339 34.0885 40.5176 30.0731 38.8697C26.0577 37.225 23.2952 36.3705 22.6366 36.2388C21.9781 36.1071 20.2017 35.6446 18.0302 36.4348C15.8587 37.225 10.9824 39.9009 9.01323 41.1408C6.97342 42.4258 6.51084 43.1485 6.11894 43.807C5.72383 44.4656 5.32871 46.2099 5.32871 46.2099C5.32871 46.2099 5.03318 46.5054 4.90148 47.2956C4.76977 48.0858 4.70553 48.5805 4.76977 49.1041C4.83402 49.631 5.03318 49.8269 5.0653 50.2895C5.12955 51.1664 4.77941 51.7704 5.70134 52.9557C5.75274 53.02 6.2635 53.3091 6.22816 52.6473C6.17355 51.6451 6.62327 49.8173 8.74982 51.1761C9.93517 51.9342 12.1388 54.5972 12.7106 58.0858C12.8005 58.6415 13.3017 58.9306 13.5876 59.1041C20.8024 63.5338 31.3259 69.0751 31.3259 69.0751C31.3259 69.0751 31.6921 69.3096 31.9523 69.3385C32.28 69.377 34.0468 68.2399 34.0468 68.2399L39.3214 72.5283C39.3214 72.5283 39.4788 72.6086 39.5302 73.0005C39.5751 73.3539 39.8707 74.3272 40.1437 74.5585C40.3525 74.732 40.9789 75.0853 42.4791 75.262C43.1601 75.3423 45.3412 75.1624 47.153 74.9311C49.4209 74.642 52.3794 73.643 54.0627 72.7275C56.3016 71.51 57.2204 69.83 57.9464 68.5483C58.7591 67.1091 58.3736 66.6723 58.3736 66.6723C58.3736 66.6723 58.6241 65.9752 58.5053 65.5865C58.3736 65.1593 58.5374 64.4686 58.6049 63.052C58.6274 62.583 58.2322 60.9415 57.7504 60.0902C56.6325 58.1147 55.1516 56.7655 53.3752 55.5159C51.5988 54.2663 48.7013 52.1591 48.7013 52.1591C48.7013 52.1591 43.5681 47.1575 39.8835 44.3274L39.8803 44.3339Z' fill='#3594F2' />
      <path d='M9.39499 40.9082C8.57585 41.5796 7.81453 42.3923 7.89163 42.8227C7.97836 43.3046 8.41844 43.1311 9.55881 42.6461C10.6992 42.1642 11.1393 41.8558 11.1393 41.8558C11.1393 41.8558 11.0525 41.2423 11.6661 40.2336C11.862 39.9124 12.6748 39.2217 13.3622 38.666C11.862 39.4659 10.3876 40.3011 9.39499 40.905V40.9082Z' fill='#5B5D5E' />
      <path d='M27.2005 50.4556C27.2005 50.4556 32.202 48.3933 34.7462 47.0763C37.2903 45.7592 39.4394 44.3555 39.4394 44.3555C39.4394 44.3555 45.3629 49.1386 46.635 50.4974C47.907 51.8562 48.6105 52.428 48.6105 52.428C48.6105 52.428 47.6019 54.3586 43.609 56.4209C40.1911 58.1877 35.7549 59.7103 34.6563 59.887C33.9142 60.0058 33.6476 60.1054 33.1657 59.5368C32.6839 58.9683 31.1452 56.4209 30.0498 54.7537C28.9544 53.0865 27.1973 50.4524 27.1973 50.4524L27.2005 50.4556Z' fill='#3D3D3D' />
      <path d='M30.6668 59.9939C30.6668 59.9939 31.3478 60.1706 30.4901 58.0312C30.0244 56.8651 27.7276 53.0842 26.1021 51.1536C24.4799 49.223 22.3277 48.0826 21.2772 47.4241C20.2236 46.7656 20.0501 46.7206 17.8561 45.6702C15.6621 44.6166 13.118 44.5716 12.0194 44.6166C10.924 44.6615 9.95705 44.7483 9.69364 44.835C9.43023 44.9217 9.25356 45.0984 9.64867 45.4935C10.0438 45.8886 15.7906 49.9683 18.248 51.6804C20.7054 53.3926 28.0391 58.2592 28.9771 58.9435C29.7899 59.5345 30.4034 59.9521 30.6668 59.9971V59.9939Z' fill='#3D3D3D' />
      <g opacity='0.67'>
        <path d='M58.372 66.6781C58.372 66.6781 58.6225 65.9811 58.5037 65.5924C58.372 65.1651 58.5358 64.4745 58.6033 63.0643C58.4812 63.4466 58.2949 63.8802 57.9994 64.3621C56.769 66.3794 55.7732 67.3495 54.0065 68.5316C51.9538 69.9033 46.9747 70.9794 44.2218 70.549C42.6092 70.2952 41.9603 70.1956 40.881 68.7019C39.8948 67.3367 39.4001 66.4822 39.4001 66.4822C39.4001 66.4822 27.0648 58.9686 22.7635 56.161C18.4623 53.3535 9.8629 47.7384 8.28244 46.508C6.70199 45.2809 6.48355 44.7091 6.30687 44.2723C6.25226 44.1374 6.21051 43.9253 6.17838 43.6973C6.1559 43.7358 6.1302 43.7744 6.10771 43.8129C5.7126 44.4714 5.31748 46.2157 5.31748 46.2157C5.31748 46.2157 5.02195 46.5112 4.89025 47.3015C4.75854 48.0917 4.69429 48.5864 4.75854 49.11C4.82279 49.6368 5.02195 49.8328 5.05407 50.2954C5.11832 51.1723 4.76818 51.7762 5.69011 52.9616C5.74151 53.0258 6.25227 53.3149 6.21693 52.6532C6.16232 51.6509 6.61204 49.8231 8.73859 51.1819C9.92393 51.9401 12.1276 54.6031 12.6994 58.0916C12.7893 58.6474 13.2904 58.9365 13.5763 59.1099C20.7912 63.5397 31.3147 69.0809 31.3147 69.0809C31.3147 69.0809 31.7323 69.3379 31.7901 69.3154C32.0953 69.1837 31.7323 68.7983 31.7965 67.7671C31.9925 64.7026 34.5913 65.4896 36.0753 66.8452C38.1987 68.7886 38.9054 71.0565 39.2555 72.0459C39.3647 72.3543 39.5125 72.8393 39.5639 73.2312C39.6089 73.5814 39.5414 74.1853 40.0361 74.5483C40.2546 74.7089 41.0352 75.1169 42.4711 75.2711C43.1553 75.3449 45.4971 75.2711 47.3088 74.9081C49.3936 74.4905 51.4495 74.1821 53.9229 72.8361C56.1619 71.6187 57.2123 69.8423 57.9383 68.5573C58.751 67.1182 58.3656 66.6813 58.3656 66.6813L58.372 66.6781Z' fill='#2067AF' />
      </g>
      <path d='M56.7954 67.1084C56.7954 67.1084 55.3595 68.6471 53.8015 69.479C51.8259 70.5327 50.6984 70.8057 49.709 71.1013C48.7228 71.3968 48.0418 71.4193 48.2378 71.6827C48.4337 71.9461 48.7325 72.4055 49.4231 72.3733C50.1138 72.3412 52.6804 71.6506 53.734 70.992C54.7877 70.3335 54.7524 70.3688 55.5779 69.5433C56.0373 69.0807 56.1818 68.8912 56.532 68.2584C56.8596 67.6673 57.0588 66.8449 56.7954 67.1051V67.1084Z' fill='#144270' />
      <path d='M56.627 61.9414C56.627 61.9414 54.6868 64.3763 52.6791 65.3979C50.3277 66.5928 46.0649 67.5051 46.0649 67.5051L47.7096 69.349C47.7096 69.349 49.486 69.2494 51.2978 68.6262C53.1063 67.9998 54.0636 67.64 55.1494 66.5543C56.2351 65.4685 57.1892 63.8881 57.1892 63.8881L56.6302 61.9478L56.627 61.9414Z' fill='#144270' />
      <path d='M52.6791 65.3971C50.3277 66.5921 46.0649 67.5044 46.0649 67.5044L46.807 68.3363C47.7546 68.2303 49.6756 67.9637 51.134 67.4401C52.9361 66.7912 53.8644 66.4539 54.9502 65.3682C56.0359 64.2824 56.8262 62.8658 56.8262 62.8658L56.5788 62.0049C56.2769 62.3743 54.5069 64.4687 52.6791 65.3971Z' fill='#2776C5' />
      <path d='M47.8759 73.0661C47.8759 73.0661 45.9999 73.397 44.7182 73.4933C43.4333 73.5929 42.3475 73.4612 42.052 73.2974C41.7565 73.1336 41.7243 73.0661 41.9524 72.7063C42.1837 72.3433 42.6431 72.0478 43.0703 71.9835C43.4975 71.9193 45.9646 72.0831 46.4593 72.1153C46.954 72.1474 47.4133 72.247 47.9723 72.6742C48.5312 73.1014 48.8267 73.3006 48.8267 73.3006L47.8727 73.0693L47.8759 73.0661Z' fill='#144270' />
      <path d='M57.9463 68.5559C58.759 67.1168 58.3735 66.6799 58.3735 66.6799C58.3735 66.6799 58.4024 66.5964 58.4378 66.4775C58.2386 66.8662 57.9559 67.3866 57.5512 68.0965C56.2341 70.3998 54.6215 71.6494 51.496 72.8347C49.6328 73.5414 43.4395 74.5179 41.7434 74.1164C40.9531 73.9301 40.1147 73.551 39.5654 73.1881C39.5654 73.2009 39.5719 73.217 39.5719 73.2298C39.6168 73.58 39.5494 74.1839 40.0441 74.5469C40.2625 74.7075 41.0142 75.154 42.479 75.2696C43.1632 75.3242 45.505 75.2696 47.3167 74.9066C49.4015 74.489 51.4574 74.1807 53.9309 72.8347C56.1699 71.6172 57.2203 69.8408 57.9463 68.5559Z' fill='#144270' />
      <path d='M58.5463 63.9436L57.4858 67.3693L58.4852 65.5022C58.4178 65.1776 58.4853 64.7085 58.5495 63.9404L58.5463 63.9436Z' fill='#144270' />
      <path d='M6.4283 43.3174C6.30618 43.4877 6.20978 43.6484 6.11337 43.8058C5.71811 44.4646 5.32284 46.2096 5.32284 46.2096C5.32284 46.2096 5.27785 46.2546 5.2168 46.3542C5.551 46.3735 5.98804 46.3735 6.44115 46.2867C7.36344 46.1099 7.49519 46.0232 7.62695 45.7597C7.80369 45.4062 7.7587 44.9274 7.14492 44.0051C6.89105 43.6227 6.63718 43.4266 6.4283 43.3206V43.3174Z' fill='white' />
      <path d='M28.568 58.5932L29.4645 58.6028C29.4645 58.6028 29.532 58.2879 29.5449 58.0501C29.6027 56.9479 29.7987 56.5462 29.9305 56.8483C30.1619 57.3753 30.1619 57.176 30.6535 58.4293C31.052 59.4351 31.1324 60.1935 30.5539 60.0104C30.3225 59.9365 29.7248 59.4705 29.7248 59.4705L28.4619 59.4191L28.5647 58.5964L28.568 58.5932Z' fill='#144270' />
      <path d='M27.7845 57.6037C27.7845 57.6037 25.3335 58.5063 25.3335 59.0653C25.3335 59.6242 26.1045 59.6242 26.4546 59.6949C27.2223 59.8491 29.2846 59.6788 29.2846 59.6788C29.2846 59.6788 29.895 59.6274 29.0469 58.2943C28.3916 57.2632 27.7877 57.6037 27.7877 57.6037H27.7845Z' fill='#144270' />
      <path d='M29.0431 58.2943C28.3878 57.2632 27.7839 57.6037 27.7839 57.6037C27.7839 57.6037 25.8629 58.3104 25.4229 58.8629C25.5642 59.3158 26.2581 59.2291 26.5953 59.2516C27.3181 59.2998 29.1042 58.9336 29.3644 58.879C29.2937 58.7183 29.1941 58.5288 29.0463 58.2975L29.0431 58.2943Z' fill='#3594F2' />
      <path d='M39.8461 66.7754C39.8461 66.7754 39.2872 65.8535 39.8782 65.4905C40.0678 65.3749 42.3132 65.9178 43.6302 66.2133C44.9472 66.5088 46.7879 67.4982 47.3822 68.3527C47.9732 69.2072 47.9411 69.538 47.546 69.5701C47.1509 69.6023 44.7481 69.901 43.8262 69.8657C42.9042 69.8336 42.0819 69.6023 41.2595 68.6803C40.4372 67.7584 39.8429 66.7722 39.8429 66.7722L39.8461 66.7754Z' fill='white' />
      <path d='M55.7322 58.6598C55.5363 58.0688 55.3692 57.6062 55.5363 57.5098C55.7001 57.4103 55.8768 57.4649 56.4999 58.2551C57.1263 59.0453 58.1125 60.5262 58.18 61.1847C58.2442 61.8432 58.2442 62.3379 57.9487 62.9611C57.6532 63.5875 57.0589 64.442 57.0589 64.442C57.0589 64.442 56.8597 64.7697 56.7955 63.9473C56.728 63.125 56.4357 60.7768 55.7322 58.6598Z' fill='white' />
      <path d='M18.5594 51.9307C18.1964 51.6512 18.2253 51.3814 18.2799 51.0248C18.4438 49.9712 18.871 48.5417 19.1023 47.7515C19.2629 47.2021 19.3689 47.0672 19.4332 46.913C19.6323 46.4183 20.1752 46.7813 20.1752 46.7813L18.5144 45.959C18.5144 45.959 18.8228 46.171 18.7297 46.4858C18.4823 47.3082 18.2767 47.7804 18.039 48.6734C17.8013 49.5696 17.57 50.3823 17.4961 50.7453C17.4319 51.073 17.085 50.8931 17.085 50.8931L18.5658 51.9307H18.5594Z' fill='#2776C5' />
      <g opacity='0.45'>
        <path d='M30.4286 68.0379C30.3065 68.0379 30.1973 67.9769 30.1941 67.9737C30.1266 67.9384 28.4947 67.0582 28.2313 66.8976C28.0996 66.8173 27.3929 66.4221 26.4132 65.8761C23.6795 64.347 19.1019 61.7868 18.7743 61.4945C18.3214 61.0865 17.4797 58.6708 17.2388 57.6108C16.6413 54.951 16.9112 52.7345 17.0429 52.3426C17.1681 51.9667 17.5986 51.4335 17.6693 51.3468C17.7367 51.0866 18.7197 47.3764 18.9735 46.6183C19.1116 46.2071 19.2369 45.918 19.6031 45.918C19.8761 45.918 20.2809 46.0786 21.0422 46.493C22.6323 47.3571 26.1658 50.1679 26.8533 51.0288C27.576 51.9346 31.2413 57.6333 31.4179 58.2018C31.6685 59.0146 31.6717 60.0329 31.4244 60.5725C31.3441 60.7492 31.2541 60.8809 31.1642 61.003C31.0132 61.215 30.8847 61.4013 30.859 61.7771C30.8172 62.3811 30.7723 67.4694 30.7723 67.5208C30.7658 67.9705 30.5506 68.0379 30.4253 68.0379H30.4286ZM19.6031 46.0465C19.3429 46.0465 19.2433 46.2264 19.0987 46.6568C18.8385 47.4342 17.8042 51.3532 17.7945 51.3917L17.7817 51.4174C17.7817 51.4174 17.2934 52.0053 17.1681 52.3843C17.0396 52.7666 16.7762 54.951 17.3673 57.5819C17.5954 58.6034 18.4338 61.0126 18.861 61.3981C19.1758 61.6808 23.9236 64.3374 26.4742 65.7636C27.454 66.3129 28.1607 66.708 28.2956 66.7883C28.5558 66.9458 30.239 67.8516 30.2551 67.8613C30.2551 67.8613 30.3386 67.9094 30.4253 67.9094C30.5635 67.9094 30.6374 67.781 30.6406 67.524C30.6406 67.3152 30.6855 62.3811 30.7273 61.7707C30.7562 61.3563 30.904 61.1507 31.0582 60.9291C31.1417 60.8102 31.2284 60.685 31.3055 60.5179C31.5079 60.0746 31.5561 59.1013 31.2927 58.2404C31.1192 57.675 27.4732 52.0117 26.7505 51.1091C26.0662 50.2546 22.5584 47.4663 20.9779 46.6054C20.2455 46.2071 19.8472 46.0465 19.5999 46.0465H19.6031Z' fill='#231F20' />
      </g>
      <path d='M13.0788 57.5174C12.7801 56.165 12.446 55.1275 11.1932 53.2418C9.61597 50.8712 7.8749 50.1644 6.61567 50.8519C5.40463 51.5136 5.89933 53.1005 5.89933 53.1005C5.89933 53.1005 6.01497 53.38 6.60604 53.2322C6.73453 53.2001 7.16498 52.9367 7.16498 52.9367C8.1351 53.6273 10.9716 57.6813 13.2652 58.7606C13.217 58.3526 13.1881 58.0057 13.0788 57.5142V57.5174Z' fill='#144270' />
      <path d='M9.02143 51.0605C9.02143 51.0605 8.0481 50.6075 7.16793 51.5295C6.20745 52.5317 6.13999 54.0897 6.71821 55.789C7.29642 57.4883 8.34363 58.7122 9.36515 59.3804C10.3867 60.0453 11.27 59.8718 11.7904 59.7883C12.5775 59.663 13.2681 58.7668 13.2681 58.7668C13.2681 58.7668 13.1268 55.4035 10.8589 52.7084C9.84057 51.5006 9.02464 51.0605 9.02464 51.0605H9.02143Z' fill='black' />
      <path d='M9.64767 52.994C10.6274 53.7842 11.4401 55.8722 11.5558 56.8809C11.6682 57.8863 11.363 58.5673 10.6788 58.8629C9.9946 59.1616 8.57476 58.5031 7.75883 57.1218C6.94291 55.7437 6.80156 54.3399 6.93327 53.6846C7.10352 52.8237 7.72992 51.4424 9.65088 52.994H9.64767Z' fill='#DFE0E0' />
      <path d='M9.52553 56.3443C9.23953 56.3443 8.94067 56.0679 8.79927 55.6726C8.63217 55.197 8.75107 54.7375 9.066 54.625C9.11098 54.609 9.15597 54.6025 9.20418 54.6025C9.49018 54.6025 9.78904 54.8789 9.92722 55.2742C10.0943 55.7498 9.97542 56.2093 9.6605 56.3218C9.61551 56.3378 9.57052 56.3443 9.52232 56.3443H9.52553ZM9.20739 54.7343C9.17525 54.7343 9.14312 54.7407 9.11098 54.7504C8.86676 54.8371 8.7832 55.2292 8.9246 55.6277C9.06278 56.0165 9.38092 56.28 9.61872 56.1965C9.85974 56.1097 9.94329 55.7144 9.80511 55.3159C9.68621 54.9785 9.43234 54.7311 9.20418 54.7311L9.20739 54.7343Z' fill='#FC2D2D' />
      <path d='M11.543 56.796C11.3952 55.7617 10.6018 53.7668 9.64772 52.9959C9.49995 52.877 9.36182 52.7774 9.22691 52.6907C9.02453 52.6072 8.84785 52.62 8.67439 52.6746C7.55329 53.0248 7.50189 54.5185 8.01265 55.9705C8.52341 57.4224 9.68948 58.3347 10.621 58.0103C11.0933 57.8432 11.4113 57.3903 11.543 56.7928V56.796Z' fill='black' />
      <g opacity='0.63'>
        <path d='M10.8647 57.8843L10.5563 56.7728C10.5563 56.7728 10.492 56.2781 10.7522 56.1464C10.839 56.1047 11.0992 56.0918 11.3818 56.0918C11.3272 55.9055 11.263 55.7063 11.1891 55.5072C11.1281 55.4943 11.0445 55.4783 10.9257 55.459C10.7008 55.4172 10.3603 55.1891 10.264 55.0414C10.1772 54.9033 10.1162 54.476 10.1162 54.476L9.99091 53.4449C9.92345 53.3613 9.96521 53.3099 9.89454 53.2361C9.8849 53.2232 9.87526 53.2136 9.86241 53.2007C9.79174 53.1268 9.71786 53.0562 9.64398 52.9984C9.63113 52.9887 9.62149 52.9823 9.61185 52.9727C9.57652 53.4417 9.45445 53.9074 9.45445 53.946C9.45445 54.1259 9.41269 54.5884 9.11716 54.5403C8.95333 54.5146 8.67065 54.2833 8.05067 53.8368C7.96073 53.7725 7.86757 53.6954 7.79047 53.6344C7.7712 53.6986 7.75514 53.7661 7.74229 53.8336C7.72944 53.901 7.7198 53.9749 7.71338 54.0456L8.84411 55.0928C8.84411 55.0928 9.12358 55.3723 9.12358 55.6357C9.12358 55.7995 8.74453 56.4291 8.46185 56.882C8.53252 56.988 8.6064 57.0908 8.6835 57.184C8.98224 56.9238 9.33881 56.6186 9.4416 56.5351C9.62149 56.3873 9.96842 56.6347 10.0519 56.8981C10.0937 57.033 10.2929 57.5534 10.4856 58.0449C10.5306 58.0353 10.5755 58.0256 10.6205 58.0096C10.6687 57.9935 10.7105 57.971 10.7554 57.9485C10.794 57.9293 10.8293 57.9068 10.8679 57.8843H10.8647Z' fill='#4F4F4F' />
      </g>
      <path d='M9.3238 54.2521C9.28204 54.5091 9.20173 54.7468 8.9062 54.6986C8.74237 54.6729 8.37617 54.371 7.82686 53.9823C7.79153 53.9598 7.76583 53.9373 7.73371 53.9148C7.73371 53.9148 7.73371 53.9148 7.73371 53.918C7.71443 54.0465 7.70801 54.1846 7.70801 54.326L8.50466 55.0745C8.50466 55.0745 8.78413 55.3539 8.78413 55.6173C8.78413 55.749 8.53678 56.1859 8.29265 56.5875C8.36332 56.7192 8.44041 56.8444 8.52072 56.9601C8.77449 56.7095 9.02827 56.4557 9.11179 56.3883C9.29168 56.2405 9.56472 56.218 9.7157 56.5682C9.77674 56.7127 10.0691 57.4451 10.31 58.0619C10.3453 58.0619 10.4063 58.0523 10.4674 58.0426C10.5188 58.033 10.5702 58.0201 10.6216 58.0041C10.6408 57.9977 10.6601 57.9848 10.6794 57.9784L10.2971 56.7738C10.2971 56.7738 10.1205 56.2084 10.4128 56.1281C10.5413 56.0928 11.0006 56.0703 11.3829 56.0735C11.3797 56.0606 11.3733 56.0478 11.37 56.0349C11.3411 55.9354 11.309 55.8326 11.2737 55.7298C11.0681 55.6912 10.5927 55.5274 10.4031 55.4374C10.1044 55.2961 10.0112 55.0198 10.0112 55.0198C10.0112 55.0198 9.95662 54.8303 9.91808 54.5733C9.90202 54.4673 9.85704 53.6096 9.78316 53.0892C9.72534 53.0314 9.70928 53.0379 9.64824 52.9929C9.57757 52.9351 9.57757 52.9222 9.51333 52.874C9.45229 53.3623 9.33022 54.2071 9.3238 54.2521Z' fill='#D3D3D3' />
      <path d='M39.3167 72.1524C38.9505 70.8161 38.6389 69.7625 37.2833 67.9507C35.4748 65.5318 33.9072 65.095 32.6833 65.8434C31.5044 66.563 31.8481 69.059 31.8481 69.059C31.8481 69.059 31.8706 69.2067 31.8288 69.2549C31.771 69.3224 31.6907 69.2774 31.6971 69.2806C31.9509 69.4444 32.1918 69.4219 32.3717 69.3481C32.9724 69.1039 33.7787 68.7955 33.7787 68.7955C34.781 69.438 37.2223 72.419 39.5641 73.3859C39.4966 72.9812 39.4484 72.6375 39.3167 72.1492V72.1524Z' fill='#144270' />
      <path d='M34.9899 65.8201C34.9899 65.8201 33.9524 65.4957 33.1172 66.4594C32.5197 67.1468 32.3205 68.0527 32.4361 69.0678C32.4972 69.601 32.6482 70.1632 32.8762 70.7382C33.538 72.4086 34.643 73.5778 35.6999 74.1946C36.7535 74.8082 37.5791 74.6186 38.0834 74.458C39.0856 74.14 39.5932 73.3883 39.5932 73.3883C39.5932 73.3883 39.2848 69.8708 36.6732 67.1757C35.5457 66.0128 34.9931 65.8201 34.9931 65.8201H34.9899Z' fill='black' />
      <path d='M35.6675 67.7972C36.6858 68.5392 37.6013 70.5823 37.7651 71.5845C37.9289 72.5835 37.6559 73.2774 36.9877 73.6082C36.3196 73.9391 34.8676 73.3513 33.9842 72.0149C33.1008 70.6786 32.8888 69.2845 32.9884 68.6227C33.1169 67.7522 33.6726 66.3452 35.6675 67.7972Z' fill='#DFE0E0' />
      <path d='M35.6873 71.1456C35.4077 71.1456 35.1057 70.8853 34.9514 70.5093C34.7618 70.0433 34.855 69.5774 35.1667 69.4521C35.2181 69.4328 35.2696 69.4199 35.3274 69.4199C35.607 69.4199 35.9091 69.6834 36.0601 70.0562C36.2497 70.5222 36.1565 70.9881 35.8448 71.1135C35.7934 71.1327 35.742 71.1456 35.6841 71.1456H35.6873ZM35.3274 69.5517C35.2888 69.5517 35.2535 69.5581 35.2181 69.5742C34.9803 69.6706 34.9161 70.0691 35.0735 70.4611C35.2085 70.7857 35.4592 71.017 35.6873 71.017C35.7259 71.017 35.7645 71.0106 35.7966 70.9978C36.0344 70.8981 36.0987 70.5029 35.938 70.1108C35.8062 69.7895 35.5491 69.5549 35.3274 69.5549V69.5517Z' fill='#FC2D2D' />
      <path d='M37.7458 71.496C37.5467 70.4681 36.6569 68.515 35.6643 67.7954C35.5101 67.683 35.3655 67.5898 35.2306 67.5127C35.0764 67.5127 34.8516 67.5095 34.7038 67.5545C33.618 67.8982 33.5988 69.4241 34.177 70.8503C34.7616 72.2734 35.9694 73.1279 36.8817 72.7584C37.3443 72.5689 37.6398 72.0999 37.7426 71.4992L37.7458 71.496Z' fill='black' />
      <g opacity='0.63'>
        <path d='M36.988 71.0622C37.0747 71.0204 37.3349 71.0075 37.6176 71.0043C37.5566 70.8084 37.4827 70.5996 37.396 70.3844C37.3382 70.3715 37.3349 70.3426 37.2386 70.3265C37.0009 70.2848 36.7053 70.0792 36.5897 69.9378C36.2556 69.517 36.307 69.1123 36.307 69.1123L36.246 68.3638C36.0693 68.1389 35.9633 68.0458 35.7738 67.8916C35.7256 68.3317 35.6196 68.7397 35.6196 68.775C35.6196 68.9645 35.5746 69.4431 35.263 69.3917C35.0895 69.3628 34.794 69.1251 34.1387 68.6626C34.052 68.6015 33.962 68.5309 33.8849 68.4698C33.8464 68.6079 33.8207 68.7525 33.811 68.9099L34.9771 69.9732C34.9771 69.9732 35.2726 70.2655 35.2726 70.5385C35.2726 70.6959 34.9386 71.2581 34.6527 71.711C34.7394 71.8331 34.8293 71.9455 34.9225 72.0483C35.2084 71.8042 35.5136 71.5504 35.6099 71.4733C35.7995 71.3191 36.1657 71.5761 36.2524 71.8492C36.2909 71.9712 36.4548 72.3953 36.6282 72.8386C36.7182 72.8257 36.8049 72.8032 36.8884 72.7711C36.9462 72.7486 37.0041 72.7197 37.0555 72.6844L36.7824 71.7175C36.7824 71.7175 36.7117 71.2035 36.9912 71.0686L36.988 71.0622Z' fill='#7D7D7D' />
      </g>
      <path d='M36.6241 71.0435C36.7558 71.0082 37.2152 70.9825 37.6135 70.9857C37.5781 70.8668 37.5364 70.7447 37.4882 70.6195C37.2665 70.5777 36.9293 70.4974 36.7462 70.4107C36.4313 70.2661 35.9816 69.977 35.9816 69.2093C35.9816 69.1065 35.9399 68.57 35.9206 68.0239C35.8049 67.9018 35.7889 67.889 35.6668 67.7991C35.6411 67.7798 35.5319 69.0037 35.5255 69.0454C35.4837 69.3121 35.3488 69.6044 35.034 69.553C34.8605 69.5241 34.4718 69.2157 33.8936 68.8109C33.8679 68.7917 33.8454 68.7756 33.823 68.7595C33.8037 68.9009 33.7973 69.0486 33.8037 69.2028L34.61 69.9481C34.61 69.9481 34.9055 70.2404 34.9055 70.5135C34.9055 70.6387 34.6903 71.0274 34.4558 71.4097C34.5393 71.5542 34.6292 71.6892 34.7256 71.8144C34.9569 71.5864 35.1753 71.3808 35.2492 71.3165C35.442 71.1623 35.7279 71.1366 35.8853 71.5028C35.9431 71.6378 36.1936 72.2545 36.4281 72.8424C36.5695 72.8424 36.7076 72.8199 36.8393 72.7717L36.4956 71.7116C36.4956 71.7116 36.3093 71.1238 36.6177 71.0403L36.6241 71.0435Z' fill='#D3D3D3' />
    </g>
    <defs>
      <radialGradient id='paint0_radial_1166_40510' cx='0' cy='0' r='1' gradientUnits='userSpaceOnUse' gradientTransform='translate(44.2082 42.0198) scale(108.89)'>
        <stop stopColor='#7E7E7E' />
        <stop offset='0.382315' stopColor='#2F2F2F' stopOpacity='0' />
      </radialGradient>
      <linearGradient id='paint1_linear_1166_40510' x1='56.6484' y1='27.8439' x2='59.195' y2='27.8439' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#0096FB' />
        <stop offset='1' stopColor='#0075C5' />
      </linearGradient>
      <linearGradient id='paint2_linear_1166_40510' x1='57.103' y1='27.575' x2='59.4521' y2='27.8614' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#B1FC00' />
        <stop offset='1' stopColor='#19EB00' />
      </linearGradient>
      <linearGradient id='paint3_linear_1166_40510' x1='56.6484' y1='33.4816' x2='59.195' y2='33.4816' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#0096FB' />
        <stop offset='1' stopColor='#0075C5' />
      </linearGradient>
      <linearGradient id='paint4_linear_1166_40510' x1='57.1021' y1='33.2127' x2='59.4511' y2='33.4991' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#B1FC00' />
        <stop offset='1' stopColor='#19EB00' />
      </linearGradient>
      <linearGradient id='paint5_linear_1166_40510' x1='56.6479' y1='39.1232' x2='59.1945' y2='39.1232' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#0096FB' />
        <stop offset='1' stopColor='#0075C5' />
      </linearGradient>
      <linearGradient id='paint6_linear_1166_40510' x1='57.1016' y1='38.8543' x2='59.4506' y2='39.1407' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#B1FC00' />
        <stop offset='1' stopColor='#19EB00' />
      </linearGradient>
      <linearGradient id='paint7_linear_1166_40510' x1='56.6484' y1='44.7472' x2='59.195' y2='44.7472' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#0096FB' />
        <stop offset='1' stopColor='#0075C5' />
      </linearGradient>
      <linearGradient id='paint8_linear_1166_40510' x1='57.1021' y1='44.4783' x2='59.4511' y2='44.7647' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#B1FC00' />
        <stop offset='1' stopColor='#19EB00' />
      </linearGradient>
      <clipPath id='clip0_1166_40510'>
        <rect width='84' height='84' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export default IconFines;
