
export const IconTruck = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' {...props}>
    <path d='M16 3H1V16H16V3Z' fill='white' stroke='white' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M16 8H20L23 11V16H16V8Z' stroke='white' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M5.5 21C6.88071 21 8 19.8807 8 18.5C8 17.1193 6.88071 16 5.5 16C4.11929 16 3 17.1193 3 18.5C3 19.8807 4.11929 21 5.5 21Z' stroke='white' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M18.5 21C19.8807 21 21 19.8807 21 18.5C21 17.1193 19.8807 16 18.5 16C17.1193 16 16 17.1193 16 18.5C16 19.8807 17.1193 21 18.5 21Z' stroke='white' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
  </svg>

);
