export const formatPriceInCOP = (price: number): string => {
  return new Intl.NumberFormat('es-CO', {
    style: 'currency',
    currency: 'COP',
  }).format(price).replace(/\s+/g, '');
};

export const formatNumberWithCommas = (number: number): string => {
  return new Intl.NumberFormat('es-CO', {
  }).format(number);
};
