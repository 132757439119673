import { extendVariants, Checkbox as UICheckbox } from '@nextui-org/react';
import { CheckboxGroup, CheckboxGroupProps, CheckboxGroupProvider, CheckboxIcon, CheckboxIconProps, CheckboxProps, useCheckbox, useCheckboxGroup, useCheckboxGroupContext } from '@nextui-org/checkbox';

export const Checkbox = extendVariants(UICheckbox, {
  variants: {
    color: {
      alfred: {
        label: 'text-base',
        wrapper: 'before:border-1 before:border-complementary-gray-dark',
      },
    },
  },

});

export {
  CheckboxGroup,
  CheckboxGroupProvider,
  CheckboxIcon,
  type CheckboxIconProps,
  type CheckboxProps,
  type CheckboxGroupProps,
  useCheckbox,
  useCheckboxGroup,
  useCheckboxGroupContext
};
