
export const IconBackNavigate = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='30'
    height='30'
    viewBox='0 0 30 30'
    fill='none'
    {...props}
  >
    <path d='M18.2808 7.5L19.6245 8.84375L13.437 15.0313L19.6245 21.2187L18.2808 22.5625L10.7495 15.0313L18.2808 7.5Z' fill='#F8FAFF' />
  </svg>
);

export default IconBackNavigate;
