
export const IconBlueAdd = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='52' height='51' viewBox='0 0 52 51' fill='none' {...props}>
    <g filter='url(#filter0_d_4400_14274)'>
      <rect x='6' width='40' height='40' rx='6' fill='#0096FB' />
    </g>
    <path d='M25.1853 21.5739H23.5913C22.2068 21.5739 20.8229 21.5783 19.4384 21.5739C18.6206 21.5702 18.0652 21.0661 18.0776 20.3623C18.0907 19.674 18.6318 19.1911 19.426 19.188C21.1439 19.1811 22.8617 19.188 24.5796 19.188H25.1853V18.6186C25.1853 16.8668 25.1779 15.115 25.1897 13.3638C25.1947 12.6121 25.7022 12.0819 26.3745 12.0781C27.0469 12.0744 27.5463 12.5996 27.5737 13.3464C27.578 13.4628 27.5737 13.5798 27.5737 13.6968V19.1818H28.1216C29.8731 19.1818 31.6239 19.1774 33.3753 19.1818C34.0352 19.1849 34.4606 19.4929 34.6304 20.053C34.7728 20.5216 34.613 21.0798 34.182 21.3244C33.9063 21.4752 33.598 21.5564 33.2839 21.5608C31.5828 21.5832 29.8818 21.572 28.1807 21.572H27.5743V22.1408C27.5743 23.8926 27.5818 25.6444 27.5706 27.3956C27.5656 28.1473 27.0581 28.6775 26.3888 28.6819C25.7196 28.6862 25.2146 28.1573 25.1866 27.4105C25.1822 27.2935 25.1866 27.1765 25.1866 27.0601V21.5739H25.1853Z' fill='white' />
    <defs>
      <filter id='filter0_d_4400_14274' x='0.917102' y='0' width='50.1658' height='50.1658' filterUnits='userSpaceOnUse' colorInterpolationFilters='sRGB'>
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha' />
        <feOffset dy='5.0829' />
        <feGaussianBlur stdDeviation='2.54145' />
        <feComposite in2='hardAlpha' operator='out' />
        <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0' />
        <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_4400_14274' />
        <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_4400_14274' result='shape' />
      </filter>
    </defs>
  </svg>
);
