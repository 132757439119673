
export const IconAddressHome = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='304' height='303' viewBox='0 0 304 303' fill='none' {...props}>
    <ellipse cx='151.882' cy='151.5' rx='151.409' ry='151.5' fill='url(#paint0_radial_1978_19040)' fillOpacity='0.4' />
    <g clipPath='url(#clip0_1978_19040)'>
      <path d='M140.705 237.574V148.119L86.1152 146.293V206.726L140.705 237.574Z' fill='#C7CAC3' />
      <path d='M214.794 142.39L207.474 146.82L208.037 137.934L214.794 142.39Z' fill='#0096FB' />
      <path d='M208.942 197.08L140.705 236.939V154.33L175.186 115.195L208.807 134.588L208.942 197.08Z' fill='#E0DFDC' />
      <path d='M174.727 120.135L139.798 185.383L127.162 192.856L171.496 110.766L220.072 139.206L214.058 142.839L174.727 120.135Z' fill='#40B2FF' />
      <path d='M171.497 110.765L120.765 82.2891L76.9946 163.234L127.163 192.856L171.497 110.765Z' fill='url(#paint1_linear_1978_19040)' />
      <path d='M204.402 163.457L193.815 169.328V196.148L204.402 190.278V163.457Z' fill='white' />
      <path d='M197.081 179.931L200.66 177.193V168.262L197.081 170.884V179.931Z' fill='white' />
      <path d='M200.66 188.909V180.031L197.081 182.779V191.54L200.66 188.909Z' fill='white' />
      <path d='M200.66 180.031V188.909L197.081 191.54V182.779L200.66 180.031Z' fill='#6783AC' />
      <path d='M200.66 177.193L197.081 179.931V170.884L200.66 168.262V177.193Z' fill='#6783AC' />
      <path d='M183.939 138.051L173.352 143.921V170.742L183.939 164.871V138.051Z' fill='white' />
      <path d='M176.502 154.525L181.871 151.787V142.855L176.502 145.478V154.525Z' fill='white' />
      <path d='M181.871 163.503V154.625L176.502 157.372V166.134L181.871 163.503Z' fill='white' />
      <path d='M181.871 154.625V163.503L176.502 166.134V157.372L181.871 154.625Z' fill='#6783AC' />
      <path d='M181.871 151.787L176.502 154.525V145.478L181.871 142.855V151.787Z' fill='#6783AC' />
      <path d='M162.055 187.457L151.477 193.319V220.139L162.055 214.269V187.457Z' fill='white' />
      <path d='M155.022 203.924L159.497 201.185V192.254L155.022 194.885V203.924Z' fill='white' />
      <path d='M159.497 212.909V204.031L155.022 206.77V215.531L159.497 212.909Z' fill='white' />
      <path d='M159.497 204.031V212.909L155.022 215.531V206.77L159.497 204.031Z' fill='#6783AC' />
      <path d='M159.497 201.185L155.022 203.924V194.885L159.497 192.254V201.185Z' fill='#6783AC' />
      <path d='M119.228 191.56V218.38L102.225 208.992V182.172L119.228 191.56Z' fill='white' />
      <path d='M116.543 193.118L112.069 190.496V199.427L116.543 202.166V193.118Z' fill='white' />
      <path d='M104.909 186.781V195.542L109.384 197.995V189.126L104.909 186.781Z' fill='white' />
      <path d='M104.909 207.434L109.384 209.779V200.839L104.909 198.387V207.434Z' fill='white' />
      <path d='M116.543 213.77V205.009L112.069 202.262V211.139L116.543 213.77Z' fill='white' />
      <path d='M109.384 200.839L104.909 198.387V207.434L109.384 209.779V200.839Z' fill='#4D6281' />
      <path d='M112.069 202.262V211.139L116.543 213.77V205.009L112.069 202.262Z' fill='#4D6281' />
      <path d='M112.069 199.427L116.543 202.166V193.118L112.069 190.496V199.427Z' fill='#4D6281' />
      <path d='M109.384 197.995V189.126L104.909 186.781V195.542L109.384 197.995Z' fill='#4D6281' />
      <path d='M189.03 174.832V208.543L171.131 218.173V184.282L189.03 174.832Z' fill='white' />
      <path d='M185.45 210.055V179.422L174.711 185.847V216.668L185.45 210.055Z' fill='#40B2FF' />
      <path d='M177.263 188.767L183.393 185.035V201.573L177.263 205.144V188.767Z' fill='#0075C5' />
      <path d='M226.275 72.5295C226.275 93.1952 199.066 117.816 199.066 117.816C199.066 117.816 171.857 91.7684 171.857 72.5295C171.857 57.5047 184.035 45.3203 199.066 45.3203C214.098 45.3203 226.275 57.5047 226.275 72.5295Z' fill='url(#paint2_linear_1978_19040)' />
      <g>
        <path d='M199.068 45.3203C214.093 45.3203 226.277 57.5047 226.277 72.5295C226.277 93.1952 199.068 117.816 199.068 117.816' fill='#505253' />
      </g>
      <path d='M199.066 91.7668C209.691 91.7668 218.305 83.1533 218.305 72.528C218.305 61.9026 209.691 53.2891 199.066 53.2891C188.441 53.2891 179.827 61.9026 179.827 72.528C179.827 83.1533 188.441 91.7668 199.066 91.7668Z' fill='white' />
      <g>
        <path d='M199.067 94.0366C210.946 94.0366 220.576 84.4069 220.576 72.5281C220.576 60.6492 210.946 51.0195 199.067 51.0195C187.188 51.0195 177.559 60.6492 177.559 72.5281C177.559 84.4069 187.188 94.0366 199.067 94.0366Z' fill='#505253' />
      </g>
    </g>
    <defs>
      <radialGradient id='paint0_radial_1978_19040' cx='0' cy='0' r='1' gradientUnits='userSpaceOnUse' gradientTransform='translate(162.985 151.5) scale(560.487 560.823)'>
        <stop stopColor='#0096FB' />
        <stop offset='0.320726' stopOpacity='0' />
      </radialGradient>
      <linearGradient id='paint1_linear_1978_19040' x1='76.9946' y1='82.2891' x2='186.213' y2='175.639' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#0096FB' />
        <stop offset='1' stopColor='#0075C5' />
      </linearGradient>
      <linearGradient id='paint2_linear_1978_19040' x1='171.857' y1='45.3203' x2='241.47' y2='97.5745' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#0096FB' />
        <stop offset='1' stopColor='#0075C5' />
      </linearGradient>
      <clipPath id='clip0_1978_19040'>
        <rect width='171.098' height='229.93' fill='white' transform='translate(77 14)' />
      </clipPath>
    </defs>
  </svg>

);
