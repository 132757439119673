
export const IconCylinder = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='19'
    height='18'
    viewBox='0 0 19 18'
    fill='none'
    {...props}
  >
    <path d='M9.58758 12.5225C9.29666 12.5225 9.00898 12.6337 8.78918 12.8561C8.34957 13.301 8.34957 14.0304 8.78918 14.4753C9.22878 14.9202 9.94961 14.9202 10.3892 14.4753C10.8288 14.0304 10.8288 13.301 10.3892 12.8561C10.1662 12.6337 9.87526 12.5225 9.58758 12.5225ZM9.18676 13.2617C9.4098 13.036 9.76536 13.036 9.98839 13.2617C10.2114 13.4874 10.2114 13.844 9.98839 14.0697C9.76536 14.2954 9.4098 14.2954 9.18676 14.0697C8.96373 13.844 8.96373 13.4874 9.18676 13.2617Z' fill='#FCFCFC' stroke='white' strokeWidth='0.2' />
    <path d='M9.18994 10.6679C8.81822 10.802 8.57256 11.0474 8.57256 11.0474L6.99838 12.6404C6.99838 12.6404 6.75272 12.8857 6.62342 13.2652C6.49736 13.6446 6.48443 14.1746 6.98545 14.6783L8.58549 16.2975C9.07358 16.7914 9.60046 16.7947 9.98188 16.6639C10.3568 16.533 10.5993 16.2844 10.5993 16.2844L12.1734 14.6881C12.1734 14.6881 12.4191 14.4461 12.5484 14.0666C12.6906 13.6414 12.6551 13.1245 12.1864 12.6502L10.5863 11.0343C10.095 10.5371 9.5649 10.5338 9.18994 10.6679ZM9.37096 11.2109C9.60369 11.1324 9.88168 11.1357 10.1855 11.4399L11.7856 13.0591C12.0829 13.36 12.0894 13.6479 12.0118 13.8834C11.9342 14.1189 11.7726 14.2858 11.7726 14.2858L10.1952 15.8788C10.1952 15.8788 10.0304 16.0391 9.79763 16.1209C9.5649 16.2026 9.28692 16.1994 8.98307 15.8919L7.38627 14.2727C7.08889 13.9717 7.08242 13.6806 7.16 13.4451C7.23758 13.2096 7.3992 13.0427 7.3992 13.0427L8.97337 11.4497C8.97337 11.453 9.13822 11.2894 9.37096 11.2109Z' fill='#FCFCFC' stroke='white' strokeWidth='0.2' />
    <path d='M11.74 3.02344L11.4556 3.52065L17.3353 6.95532L17.6165 6.46138L11.74 3.02344Z' fill='#FCFCFC' stroke='white' strokeWidth='0.2' />
    <path d='M13.5082 7.36328L10.9644 11.8251L11.4557 12.1097L13.9996 7.64787L13.5082 7.36328Z' fill='#FCFCFC' stroke='white' strokeWidth='0.2' />
    <path d='M12.5288 6.79102L9.98486 11.2528L10.473 11.5374L13.0201 7.0756L12.5288 6.79102Z' fill='#FCFCFC' stroke='white' strokeWidth='0.2' />
    <path d='M13.0426 1.67201C12.645 1.64257 12.1311 1.7636 11.7787 2.38511L9.79404 5.85577C9.79404 5.85577 9.65505 6.09129 9.63889 6.40859C9.62273 6.72588 9.71646 7.1315 10.211 7.41936C10.6959 7.70395 11.1032 7.58946 11.365 7.41609C11.6268 7.24272 11.7561 7.00066 11.7561 7.00066L11.8951 6.75205L14.3453 8.18153L14.203 8.43014C14.203 8.43014 14.064 8.66566 14.0479 8.98296C14.0285 9.30025 14.1319 9.71241 14.6168 9.997C15.0984 10.2783 15.5089 10.1638 15.7707 9.99046C16.0326 9.81709 16.1651 9.57503 16.1651 9.57503L18.1433 6.10437C18.4924 5.4894 18.347 4.97256 18.1239 4.63891C17.9009 4.30526 17.597 4.13189 17.597 4.13189L13.7376 1.88136C13.7376 1.88136 13.4402 1.70145 13.0426 1.67201ZM13.4563 2.3753L17.3094 4.6291C17.3094 4.6291 17.5098 4.7534 17.652 4.96275C17.7942 5.1721 17.8653 5.44361 17.652 5.81978L15.6738 9.29044C15.6738 9.29044 15.593 9.42129 15.4572 9.51288C15.3214 9.60447 15.1501 9.64699 14.9012 9.49979C14.6491 9.35259 14.6006 9.18249 14.6103 9.01894C14.62 8.85538 14.6943 8.71799 14.6943 8.71799L14.9756 8.22079C15.0531 8.0834 15.0079 7.91003 14.8721 7.83152L11.9339 6.11419C11.7981 6.03568 11.6268 6.08147 11.5492 6.21886L11.2648 6.71607C11.2648 6.71607 11.184 6.84692 11.0482 6.93851C10.9124 7.0301 10.7379 7.06935 10.4922 6.92542C10.2433 6.78149 10.1916 6.60485 10.2013 6.4413C10.211 6.27774 10.2854 6.14363 10.2854 6.14363L12.2636 2.67297C12.4769 2.29679 12.7484 2.22483 12.9973 2.24445C13.2495 2.26081 13.4563 2.3753 13.4563 2.3753Z' fill='#FCFCFC' stroke='white' strokeWidth='0.2' />
    <path d='M7.43459 3.02344L7.71904 3.52065L1.83932 6.95532L1.55811 6.46138L7.43459 3.02344Z' fill='#FCFCFC' stroke='white' strokeWidth='0.2' />
    <path d='M5.66662 7.36328L8.21051 11.8251L7.71919 12.1097L5.17529 7.64787L5.66662 7.36328Z' fill='#FCFCFC' stroke='white' strokeWidth='0.2' />
    <path d='M6.64288 6.79102L9.18677 11.2528L8.69868 11.5374L6.15479 7.0756L6.64288 6.79102Z' fill='#FCFCFC' stroke='white' strokeWidth='0.2' />
    <path d='M6.13196 1.67201C6.52954 1.64257 7.04349 1.7636 7.39582 2.38511L9.37728 5.85577C9.37728 5.85577 9.51627 6.09129 9.53243 6.40859C9.55183 6.72588 9.45486 7.1315 8.9603 7.41936C8.47544 7.70395 8.06816 7.58946 7.80634 7.41609C7.54451 7.24272 7.41522 7.00066 7.41522 7.00066L7.27622 6.75205L4.82607 8.18153L4.9683 8.43014C4.9683 8.43014 5.10729 8.66566 5.12345 8.98296C5.14285 9.30025 5.03941 9.71241 4.55455 9.997C4.07292 10.2783 3.66241 10.1638 3.40058 9.99046C3.13876 9.81709 3.00623 9.57503 3.00623 9.57503L1.02801 6.10764C0.67891 5.49267 0.824368 4.97584 1.0474 4.64218C1.27044 4.30853 1.57428 4.13516 1.57428 4.13516L5.43053 1.88136C5.43376 1.88136 5.73437 1.70145 6.13196 1.67201ZM5.71498 2.3753L1.86197 4.62583C1.86197 4.62583 1.66156 4.75013 1.51933 4.95948C1.37711 5.16883 1.30599 5.44033 1.51933 5.81651L3.49756 9.28717C3.49756 9.28717 3.57837 9.41801 3.71413 9.50961C3.84989 9.6012 4.0212 9.64372 4.2701 9.49652C4.52223 9.34932 4.57071 9.17922 4.56101 9.01567C4.55132 8.85211 4.47697 8.71472 4.47697 8.71472L4.19575 8.21751C4.11818 8.08013 4.16343 7.90676 4.29919 7.82825L7.23744 6.11091C7.3732 6.03241 7.54451 6.0782 7.62209 6.21559L7.90654 6.7128C7.90654 6.7128 7.98735 6.84364 8.12311 6.93524C8.25887 7.02683 8.43342 7.06608 8.67908 6.92215C8.92798 6.77822 8.9797 6.60158 8.97 6.43803C8.9603 6.27447 8.88596 6.14035 8.88596 6.14035L6.90773 2.6697C6.69439 2.29352 6.42287 2.22155 6.17398 2.24118C5.92185 2.26081 5.71498 2.3753 5.71498 2.3753Z' fill='#FCFCFC' stroke='white' strokeWidth='0.2' />
  </svg>
);

export default IconCylinder;
