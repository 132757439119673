import { FC, useCallback, useMemo } from 'react';
import { Button } from './Button';
import { parseTime, toCalendarDateTime, Time, today, isEqualDay, now, toTime } from '@internationalized/date';
import { toShortHour } from '../utils/dateUtils';
import useDateTime from './useDateTime';
import { twMerge } from 'tailwind-merge';
import { Skeleton } from '@nextui-org/react';

export interface ListTimePickerProps {
  minTime: string
  maxTime: string
  timeIntervals?: number
  excludeTimes?: string[]
  requestServiceMinTime?: number
};

export const ListTimePicker: FC<ListTimePickerProps> = ({
  minTime,
  maxTime,
  timeIntervals,
  excludeTimes,
  requestServiceMinTime,
}) => {
  const { setTime, selectedDate, selectedTime, isSlotsLoading } = useDateTime();

  const onChangeTime = useCallback((time: Time) => {
    setTime(time);
  }, [setTime]);

  const times = useMemo(() => {
    const times = [];
    let currentTime = parseTime(minTime);
    const endTime = parseTime(maxTime);
    const interval = timeIntervals ?? 60;

    while (currentTime <= endTime) {
      times.push(currentTime);
      currentTime = currentTime.add({ minutes: interval });
    }
    return times;
  }, [minTime, maxTime, timeIntervals]);

  const firstColumn = useMemo(() => times.slice(0, Math.round(times.length / 2)), [times]);
  const secondColumn = useMemo(() => times.slice(Math.round(times.length / 2)), [times]);

  const timeLabel = useMemo(() => (time: Time) => {
    const date = toCalendarDateTime(selectedDate ?? today('America/Bogota'), time).toDate('America/Bogota');
    return toShortHour(date).replace(/\.\s/g, '.').replace(/\./g, '');
  }, [selectedDate]);

  const disableTime = useMemo(() => (time: Time) => {
    const currentTime = toTime(now('America/Bogota'));

    const timeExcluded = excludeTimes?.includes(time.toString().split(':').slice(0, 2).join(':'));

    if (selectedDate && isEqualDay(selectedDate, today('America/Bogota'))) {
      const minTime = currentTime.add({ minutes: requestServiceMinTime });
      return time.compare(minTime) < 0 || timeExcluded;
    }
    return timeExcluded;
  }, [excludeTimes, requestServiceMinTime, selectedDate]);

  return (
    <div className='flex flex-row gap-4'>
      <div className='flex flex-col gap-4'>
        {
          firstColumn.map((time) => (
            <div key={time.toString()}>
              {
                isSlotsLoading
                  ? (
                    <Skeleton className='w-[150px] h-[34px] rounded-md' />
                    )
                  : (
                    <Button
                      onPress={() => onChangeTime(time)}
                      color='secondary'
                      variant='ghost'
                      radius='sm'
                      isDisabled={disableTime(time)}
                      className={
                        twMerge(
                          'w-[150px] h-[34px] text-balance tracking-tighter text-white',
                          selectedTime && selectedTime.compare(time) === 0 ? 'bg-secondary' : ''
                        )
                      }
                    >
                      {timeLabel(time)}
                    </Button>
                    )
              }

            </div>
          ))
        }
      </div>
      <div className='flex flex-col gap-4'>
        {
          secondColumn.map((time) => (
            <div key={time.toString()}>
              {
                isSlotsLoading
                  ? (
                    <Skeleton className='w-[150px] h-[34px] rounded-md' />
                    )
                  : (
                    <Button
                      key={time.toString()}
                      onPress={() => onChangeTime(time)}
                      color='secondary'
                      variant='ghost'
                      radius='sm'
                      isDisabled={disableTime(time)}
                      className={
                        twMerge(
                          'w-[150px] h-[34px] text-balance tracking-tighter text-white',
                          selectedTime && selectedTime.compare(time) === 0 ? 'bg-secondary' : ''
                        )
                      }
                    >
                      {timeLabel(time)}
                    </Button>
                    )
              }
            </div>

          ))
        }
      </div>
    </div>
  );
};

export default ListTimePicker;
