import React, { FC, useMemo } from 'react';
import { twMerge } from 'tailwind-merge';

interface ConfirmationCodeProps {
  code: number
  size?: CardSize
  backgroundColor?: string
}

type CardSize = 'xs' | 'sm' | 'md';
const cardSizeStyles: { [key in CardSize]: string } = {
  xs: 'w-[30px] h-[30px]',
  sm: 'w-[38px] h-[38px]',
  md: 'w-[60px] h-[60px]',
};

export const ConfirmationCode: FC<ConfirmationCodeProps> = ({
  code,
  size = 'xs',
  backgroundColor,
}) => {
  const cardSize = useMemo(() => cardSizeStyles[size], [size]);
  return (
    <div className='flex flex-row items-center gap-[6px]'>
      {
        String(code).split('').map((number, index) => (
          <div key={`${index}-number`} className={twMerge('flex rounded-[5px] text-xl justify-center items-center', backgroundColor ?? 'bg-dark-main', cardSize)}>
            <span className='text-xl bg-primary-gradient inline-block text-transparent bg-clip-text'>{number}</span>
          </div>
        ))
      }
    </div>
  );
};
