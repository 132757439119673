import { FC } from 'react';
import { twMerge } from 'tailwind-merge';
import { IconRrCards, IconSrList } from '../icons';
import { Button, ButtonGroup } from '@nextui-org/react';

interface LayoutSwitchProps {
  view?: 'grid' | 'list'
  setView?: (view: 'grid' | 'list') => void
}

export const LayoutSwitch: FC<LayoutSwitchProps> = ({ view, setView }) => {
  return (
    <>
      <ButtonGroup className='rounded' size='sm'>
        <Button
          className={
            twMerge(
              'border-1 border-complementary-gray-light min-w-9',
              view !== 'grid' || !view
                ? 'bg-secondary-gradient'
                : 'bg-dark-main'
            )
          }
          onClick={() => {
            setView?.('list');
          }}
          color='secondary'
          variant={view !== 'grid' || !view ? 'shadow' : 'solid'}
        >
          <IconSrList />
        </Button>
        <Button
          className={
            twMerge(
              'border-1 border-complementary-gray-light min-w-9',
              view === 'grid'
                ? 'bg-secondary-gradient'
                : 'bg-dark-main'
            )
          }
          onClick={() => {
            setView?.('grid');
          }}
          variant={view === 'grid' ? 'shadow' : 'solid'}
          color='secondary'
        >
          <IconRrCards />
        </Button>
      </ButtonGroup>
    </>
  );
};

export default LayoutSwitch;
