
export const IconShare = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='23' height='22' viewBox='0 0 23 22' fill='none' {...props}>
      <g id='Group 1597884201'>
        <path id='Arrow 1' d='M7.68282 13.1387C7.29548 13.5324 7.29548 14.1707 7.68282 14.5644C8.07016 14.9581 8.69815 14.9581 9.08549 14.5644L7.68282 13.1387ZM21.6341 1.39255C21.6341 0.83579 21.19 0.384449 20.6422 0.384449L11.7157 0.38445C11.1679 0.38445 10.7238 0.83579 10.7238 1.39255C10.7238 1.9493 11.1679 2.40064 11.7157 2.40064L19.6504 2.40064L19.6504 10.4654C19.6504 11.0222 20.0944 11.4735 20.6422 11.4735C21.19 11.4735 21.6341 11.0222 21.6341 10.4654L21.6341 1.39255ZM9.08549 14.5644L21.3436 2.10538L19.9409 0.679714L7.68282 13.1387L9.08549 14.5644Z' fill='white' />
        <path id='Vector 179' d='M6.5 1H3.44259C2.10262 1 1.01636 2.08626 1.01636 3.42623V18.5738C1.01636 19.9137 2.10262 21 3.44259 21H18.5901C19.9301 21 21.0164 19.9137 21.0164 18.5738V16' stroke='white' strokeWidth='2' strokeLinecap='round' />
      </g>
    </svg>
  );
};
