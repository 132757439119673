import { Skeleton } from '../components';
import { FC } from 'react';

interface RequestColumnProps {
  children: React.ReactNode
  title: string
  isLoading?: boolean
}

export const RequestColumn: FC<RequestColumnProps> = ({ children, title, isLoading }) => {
  if (isLoading) {
    return (
      <div className='frosty w-[280px] min-h-dvh h-auto rounded-md flex flex-col items-center gap-4'>
        <Skeleton className='my-5 h-3 w-3/5 rounded-lg' />
        <Skeleton className='flex flex-col relative rounded-[10px] p-[13px] w-[270px] min-h-[165px] gap-3 justify-between' />
        <Skeleton className='flex flex-col relative rounded-[10px] p-[13px] w-[270px] min-h-[165px] gap-3 justify-between' />
        <Skeleton className='flex flex-col relative rounded-[10px] p-[13px] w-[270px] min-h-[165px] gap-3 justify-between' />
        <Skeleton className='flex flex-col relative rounded-[10px] p-[13px] w-[270px] min-h-[165px] gap-3 justify-between' />
      </div>
    );
  }

  return (
    <div className='frosty w-[280px] min-h-dvh h-auto rounded-md flex flex-col items-center gap-4'>
      <div className='my-5'>{title}</div>
      {children}
    </div>
  );
};

export default RequestColumn;
