import { DateFormatter } from '@internationalized/date';

export const toShortTimestamp = (date: Date) => new DateFormatter(
  'es-CO',
  {
    year: 'numeric',
    month: 'numeric',
    day: '2-digit',
    hour: 'numeric',
    minute: 'numeric',
  }
).format(date);

export const toShortDate = (date: Date) => new DateFormatter(
  'es-CO',
  {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  }
).format(date);

export const toShortHour = (date: Date) => new DateFormatter(
  'es-CO',
  {
    hour: 'numeric',
    minute: 'numeric',
  }
).format(date);

export const toDayAndMonth = (date: Date) => new DateFormatter(
  'es-CO',
  {
    day: '2-digit',
    month: 'short',
  }
).format(date);

export const toLongDate = (date: Date) => new DateFormatter(
  'es-CO',
  {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
  }
).format(date);

export const toLongOnlyDate = (date: Date) => new DateFormatter(
  'es-CO',
  {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
  }
).format(date);

export const toLongDateWithMinutes = (date: Date) => new DateFormatter(
  'es-CO',
  {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  }
).format(date);

export const toMediumDate = (date: Date) => new DateFormatter(
  'es-CO',
  {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  }
).format(date);

export const toWeekDayDateTime = (date: Date) => new DateFormatter(
  'es-CO',
  {
    weekday: 'long',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
  }
).format(date).replace(/\.\s/g, '.').replace(/\./g, '');

export const toWeekDayDate = (date: Date) => new DateFormatter(
  'es-CO',
  {
    year: 'numeric',
    month: 'long',
    weekday: 'long',
    day: 'numeric',
  }
).format(date);

export function getDaysLeft (date: string) {
  const newDate = new Date(date);
  return Math.ceil((new Date().getTime() - newDate.getTime()) / (1000 * 3600 * 24));
}
