
export const IconMileage = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='17'
    height='25'
    viewBox='0 0 17 25'
    fill='none'
    {...props}
  >
    <path d='M16.5184 0.0078125H0.317383V24.7859H16.5184V0.0078125Z' fill='url(#paint0_linear_1166_40259)' />
    <path d='M15.5658 0.0078125H0.953125V24.7859H15.5658V0.0078125Z' fill='url(#paint1_linear_1166_40259)' />
    <path d='M15.8831 0.0078125H15.5654V24.7859H15.8831V0.0078125Z' fill='url(#paint2_linear_1166_40259)' />
    <path d='M16.8362 0.0078125H16.5186V24.7859H16.8362V0.0078125Z' fill='url(#paint3_linear_1166_40259)' />
    <path d='M1.49316 0.34082V9.1148C1.49316 9.64849 1.92837 10.0837 2.46205 10.0837H15.1148C15.6484 10.0837 16.0836 9.64849 16.0836 9.1148V0.34082' fill='url(#paint4_linear_1166_40259)' />
    <g style={{ mixBlendMode: 'multiply' }} opacity='0.2'>
      <path d='M15.5657 0.0078125H15.248V24.7859H15.5657V0.0078125Z' fill='#141313' />
    </g>
    <g style={{ mixBlendMode: 'multiply' }} opacity='0.2'>
      <path d='M1.27079 0.0078125H0.953125V24.7859H1.27079V0.0078125Z' fill='#141313' />
    </g>
    <path d='M0.317668 0.0078125H0V24.7859H0.317668V0.0078125Z' fill='url(#paint5_linear_1166_40259)' />
    <defs>
      <linearGradient id='paint0_linear_1166_40259' x1='0.317383' y1='12.3969' x2='16.5184' y2='12.3969' gradientUnits='userSpaceOnUse'>
        <stop />
        <stop offset='0.04' stopColor='#0F0F0F' />
        <stop offset='0.09' stopColor='#1B1B1B' />
        <stop offset='0.17' stopColor='#222222' />
        <stop offset='0.38' stopColor='#242424' />
        <stop offset='0.58' stopColor='#1F1E1E' />
        <stop offset='0.78' stopColor='#141313' />
        <stop offset='0.83' stopColor='#181717' />
        <stop offset='0.88' stopColor='#262626' />
        <stop offset='0.93' stopColor='#3D3D3D' />
        <stop offset='0.94' stopColor='#414141' />
        <stop offset='0.95' stopColor='#3E3E3E' />
        <stop offset='0.96' stopColor='#171717' />
        <stop offset='0.97' />
      </linearGradient>
      <linearGradient id='paint1_linear_1166_40259' x1='8.25948' y1='0.0078125' x2='8.25948' y2='25.0718' gradientUnits='userSpaceOnUse'>
        <stop />
        <stop offset='0.04' stopColor='#0F0F0F' />
        <stop offset='0.09' stopColor='#1B1B1B' />
        <stop offset='0.17' stopColor='#222222' />
        <stop offset='0.38' stopColor='#242424' />
        <stop offset='0.6' stopColor='#1F1E1E' />
        <stop offset='0.82' stopColor='#141313' />
        <stop offset='0.86' stopColor='#1A1A1A' />
        <stop offset='0.9' stopColor='#2E2D2D' />
        <stop offset='0.94' stopColor='#414141' />
        <stop offset='0.95' stopColor='#3E3E3E' />
        <stop offset='0.96' stopColor='#171717' />
        <stop offset='0.97' />
      </linearGradient>
      <linearGradient id='paint2_linear_1166_40259' x1='15.7243' y1='0.0078125' x2='15.7243' y2='25.0718' gradientUnits='userSpaceOnUse'>
        <stop />
        <stop offset='0.06' stopColor='#121212' />
        <stop offset='0.13' stopColor='#212121' />
        <stop offset='0.22' stopColor='#2B2B2B' />
        <stop offset='0.33' stopColor='#2E2E2E' />
        <stop offset='0.97' stopColor='#010101' />
        <stop offset='0.99' />
      </linearGradient>
      <linearGradient id='paint3_linear_1166_40259' x1='16.6774' y1='0.0078125' x2='16.6774' y2='25.0718' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#010101' />
        <stop offset='0.04' stopColor='#181818' />
        <stop offset='0.08' stopColor='#2B2B2B' />
        <stop offset='0.13' stopColor='#383838' />
        <stop offset='0.19' stopColor='#3F3F3F' />
        <stop offset='0.33' stopColor='#424242' />
        <stop offset='0.61' stopColor='#3F3F3F' />
        <stop offset='0.73' stopColor='#383838' />
        <stop offset='0.83' stopColor='#2B2B2B' />
        <stop offset='0.91' stopColor='#191919' />
        <stop offset='0.98' stopColor='#020202' />
        <stop offset='0.99' />
      </linearGradient>
      <linearGradient id='paint4_linear_1166_40259' x1='8.78999' y1='0.223283' x2='8.78999' y2='11.2019' gradientUnits='userSpaceOnUse'>
        <stop offset='0.04' stopColor='#171717' />
        <stop offset='0.06' stopColor='#333333' />
        <stop offset='0.08' stopColor='#525252' />
        <stop offset='0.1' stopColor='#6B6B6B' />
        <stop offset='0.12' stopColor='#7F7F7F' />
        <stop offset='0.15' stopColor='#8D8D8D' />
        <stop offset='0.18' stopColor='#959595' />
        <stop offset='0.24' stopColor='#989898' />
        <stop offset='0.39' stopColor='#8A8A8A' />
        <stop offset='0.66' stopColor='#686868' />
        <stop offset='0.87' stopColor='#4A4A4A' />
      </linearGradient>
      <linearGradient id='paint5_linear_1166_40259' x1='0.158834' y1='0.0078125' x2='0.158834' y2='0.0078125' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#010101' />
        <stop offset='0.04' stopColor='#181818' />
        <stop offset='0.08' stopColor='#2B2B2B' />
        <stop offset='0.13' stopColor='#383838' />
        <stop offset='0.19' stopColor='#3F3F3F' />
        <stop offset='0.33' stopColor='#424242' />
        <stop offset='0.61' stopColor='#3F3F3F' />
        <stop offset='0.73' stopColor='#383838' />
        <stop offset='0.83' stopColor='#2B2B2B' />
        <stop offset='0.91' stopColor='#191919' />
        <stop offset='0.98' stopColor='#020202' />
        <stop offset='0.99' />
      </linearGradient>
    </defs>
  </svg>
);

export default IconMileage;
