'use client';
import React, { useCallback, useMemo, useState } from 'react';
import { Button } from './Button';
import { IconArrowLeft, IconArrowLeftDouble, IconArrowRight, IconArrowRightDouble, IconSrSearch } from '../icons/index';
import { Checkbox } from '@nextui-org/react';
import { Input } from './Input';
import { twMerge } from 'tailwind-merge';

export interface TransferListLists {
  list1: Array<{ id: number, label: string, checked?: boolean }>
  list2: Array<{ id: number, label: string, checked?: boolean }>
}

interface TransferListProps extends TransferListLists {
  onChange?: (value: TransferListLists) => void
  labels?: {
    leftTitle?: string
    rightTitle?: string
    leftLabel?: string
    rightLabel?: string
  }
}

type DirectionProps = 'left' | 'right';

export const TransferList = ({ list1, list2, onChange, labels }: TransferListProps) => {
  const [propsVal, setStatePropsFun] = useState<TransferListLists>({ list1, list2 });
  const [filterValue, setFilterValue] = useState({ left: '', right: '' });

  const handleSearch = useCallback((list: DirectionProps, value: string) => {
    setFilterValue((prev) => ({ ...prev, [list]: value }));
  }, []);

  // Handeling Array to set value to checkbox from false to true
  const handleChange = useCallback((value: number, checked: boolean) => {
    const updatedList1 = propsVal.list1.map((res1) => {
      if (res1.id === value) {
        return { ...res1, checked };
      }
      return res1;
    });

    const updatedList2 = propsVal.list2.map((res2) => {
      if (res2.id === value) {
        return { ...res2, checked };
      }
      return res2;
    });

    setStatePropsFun({
      list1: updatedList1,
      list2: updatedList2,
    });
  }, [propsVal.list1, propsVal.list2]);

  const handleMove = useCallback((direction: DirectionProps) => {
    const currentProps = structuredClone(propsVal);
    if (direction === 'left') {
      const updatedList1 = currentProps.list1.filter((res) => !res.checked);
      const checkedItems = currentProps.list1.filter((res) => res.checked);
      const updatedList2 = [...currentProps.list2, ...checkedItems];
      setStatePropsFun({
        list1: updatedList1.map((item) => ({ ...item, checked: false })),
        list2: updatedList2.map((item) => ({ ...item, checked: false })),
      });
      onChange?.({
        list1: updatedList1.map((item) => ({ ...item, checked: false })),
        list2: updatedList2.map((item) => ({ ...item, checked: false })),
      });
    } else if (direction === 'right') {
      const updatedList2 = currentProps.list2.filter((res) => !res.checked);
      const checkedItems = currentProps.list2.filter((res) => res.checked);
      const updatedList1 = [...currentProps.list1, ...checkedItems];
      setStatePropsFun({
        list1: updatedList1.map((item) => ({ ...item, checked: false })),
        list2: updatedList2.map((item) => ({ ...item, checked: false })),
      });
      onChange?.({
        list1: updatedList1.map((item) => ({ ...item, checked: false })),
        list2: updatedList2.map((item) => ({ ...item, checked: false })),
      });
    }
    setFilterValue({ left: '', right: '' });
  }, [propsVal, onChange]);

  const moveAllData = useCallback((direction: DirectionProps) => {
    if (direction === 'left') {
      const filteredList2 = propsVal.list2.filter((res) => res.label.toLowerCase().includes((filterValue.right ?? '').toLowerCase()));
      setStatePropsFun({
        list1: [...propsVal.list1, ...filteredList2],
        list2: propsVal.list2.filter((res) => !filteredList2.includes(res)),
      });
      onChange?.({
        list1: [...propsVal.list1, ...filteredList2],
        list2: propsVal.list2.filter((res) => !filteredList2.includes(res)),
      });
    } else {
      const filteredList1 = propsVal.list1.filter((res) => res.label.toLowerCase().includes((filterValue.left ?? '').toLowerCase()));
      setStatePropsFun({
        list2: [...propsVal.list2, ...filteredList1],
        list1: propsVal.list1.filter((res) => !filteredList1.includes(res)),
      });
      onChange?.({
        list1: propsVal.list1.filter((res) => !filteredList1.includes(res)),
        list2: [...propsVal.list2, ...filteredList1],
      });
    }
    setFilterValue({ left: '', right: '' });
  }, [propsVal.list1, propsVal.list2, filterValue, onChange]);

  const LeftlistDisplay = useMemo(() => {
    const filteredList = propsVal.list1.filter((res) => res.label.toLowerCase().includes((filterValue?.left ?? '').toLowerCase()));
    return filteredList.length
      ? (
          filteredList.map(leftRes => {
            return (
              <div className='flex items-center' key={leftRes.id}>
                <Checkbox
                  className='my-1'
                  size='lg'
                  isSelected={leftRes.checked ?? false}
                  onValueChange={(v) => handleChange(leftRes.id, v)}
                  value={leftRes.id.toString()}
                  color='secondary'
                  classNames={{
                    label: 'text-base',
                    wrapper: 'before:border-1 before:border-complementary-gray-dark',

                  }}
                >
                  {leftRes.label}
                </Checkbox>
              </div>
            );
          })
        )
      : (
        <div className='mt-2'>Lista Vacía</div>
        );
  }, [propsVal.list1, handleChange, filterValue.left]);

  const RightlistDisplay = useMemo(() => {
    const filteredList = propsVal.list2.filter((res) => res.label.toLowerCase().includes((filterValue.right ?? '').toLowerCase()));
    return filteredList.length
      ? (
          filteredList.map(rightRes => {
            return (
              <div key={rightRes.id}>
                <div className='flex items-center'>
                  <Checkbox
                    className='my-1'
                    size='lg'
                    isSelected={rightRes.checked ?? false}
                    onValueChange={(v) => handleChange(rightRes.id, v)}
                    value={rightRes.id.toString()}
                    color='secondary'
                    classNames={{
                      label: 'text-base',
                      wrapper: 'before:border-1 before:border-complementary-gray-dark',

                    }}
                  >
                    {rightRes.label}
                  </Checkbox>
                </div>
              </div>
            );
          })
        )
      : (
        <div className='mt-2'>Lista Vacía</div>
        );
  }, [propsVal.list2, handleChange, filterValue.right]);

  return (

    <div className='flex w-full'>
      <div className='flex flex-col w-full'>
        {
          labels?.leftTitle && (
            <div className='text-center py-2 bg-alfred text-white'>
              {labels.leftTitle}
            </div>
          )
        }
        <div className='flex-1 p-0 rounded-b-md capitalize bg-frosted-gradient'>
          <Input
            size='lg'
            color='alfred'
            placeholder={labels?.leftLabel ?? 'Buscar'}
            startContent={<IconSrSearch />}
            onChange={(e) => handleSearch('left', e.target.value)}
            value={filterValue.left}
          />
          <div className='px-5 h-64 max-h-64 overflow-auto'>
            {LeftlistDisplay}
          </div>
        </div>
      </div>
      <div className='m-3 flex flex-col justify-center items-center gap-2'>
        <Button
          color='GradientAlfredBlue'
          className={twMerge('min-w-8 p-0', labels?.leftLabel ? 'mt-8' : 'mt-0')}
          onClick={() => { handleMove('left'); }}
          startContent={<IconArrowRight />}
        />
        <Button
          color='GradientAlfredBlue'
          className='min-w-8 p-0 mb-5'
          onClick={() => { moveAllData('right'); }}
          startContent={<IconArrowRightDouble />}
        />
        <Button
          color='GradientAlfredBlue'
          className='min-w-8 p-0'
          onClick={() => { handleMove('right'); }}
          startContent={<IconArrowLeft />}
        />
        <Button
          color='GradientAlfredBlue'
          className='min-w-8 p-0'
          onClick={() => { moveAllData('left'); }}
          startContent={<IconArrowLeftDouble transform='scale(-1 1)' />}
        />
      </div>
      <div className='flex flex-col w-full'>
        {
          labels?.rightTitle && (
            <div className='text-center py-2 bg-alfred text-white'>
              {labels.rightTitle}
            </div>
          )
        }
        <div className='flex-1 p-0 rounded-b-md capitalize bg-frosted-gradient'>
          <Input
            size='lg'
            color='alfred'
            placeholder={labels?.rightLabel ?? 'Buscar'}
            startContent={<IconSrSearch />}
            onChange={(e) => handleSearch('right', e.target.value)}
            value={filterValue.right}
          />
          <div className='px-5 h-64 max-h-64 overflow-auto'>
            {RightlistDisplay}
          </div>
        </div>
      </div>
    </div>
  );
};
export default TransferList;
