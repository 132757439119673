
export const IconAlfredArrow = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='9'
    height='17'
    viewBox='0 0 9 17'
    fill='none'
    {...props}
  >
    <path d='M9 8.9043L9 12.6965L-4.32951e-07 16.9995L-2.29531e-07 13.196L9 8.9043Z' fill='url(#paint0_linear_1661_20287)' />
    <path d='M9 8.09524L9 4.29309L0 -3.14306e-07L-2.02789e-07 3.79171L9 8.09524Z' fill='url(#paint1_linear_1661_20287)' />
    <defs>
      <linearGradient id='paint0_linear_1661_20287' x1='9' y1='8.9043' x2='0.949985' y2='17.854' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#B1FC00' />
        <stop offset='1' stopColor='#19EB00' />
      </linearGradient>
      <linearGradient id='paint1_linear_1661_20287' x1='9' y1='0' x2='0.949984' y2='8.94972' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#B1FC00' />
        <stop offset='1' stopColor='#19EB00' />
      </linearGradient>
    </defs>
  </svg>
);

export default IconAlfredArrow;
