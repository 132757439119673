
export const IconMyLocation = (props: React.SVGProps<SVGSVGElement>) => (

  <svg xmlns='http://www.w3.org/2000/svg' width='22' height='21' viewBox='0 0 22 21' fill='none' {...props}>
    <circle cx='10.9586' cy='10.4586' r='7.18905' stroke='white' strokeWidth='1.5' />
    <circle cx='10.9997' cy='10.4987' r='3.78' fill='white' />
    <line x1='11.0186' y1='2.66406' x2='11.0186' y2='0.749416' stroke='white' strokeWidth='1.5' strokeLinecap='round' />
    <line x1='3.16455' y1='10.4805' x2='1.2499' y2='10.4805' stroke='white' strokeWidth='1.5' strokeLinecap='round' />
    <line x1='20.7495' y1='10.4805' x2='18.8349' y2='10.4805' stroke='white' strokeWidth='1.5' strokeLinecap='round' />
    <line x1='11.0186' y1='20.25' x2='11.0186' y2='18.3354' stroke='white' strokeWidth='1.5' strokeLinecap='round' />
  </svg>);
