import { extendVariants, Button as NextuiButton, ButtonGroup as NextuiButtonGroup } from '@nextui-org/react';
import { ButtonGroupProps, ButtonProps, useButton, ButtonGroupProvider, useButtonGroup, useButtonGroupContext } from '@nextui-org/button';

export const Button = extendVariants(NextuiButton, {
  variants: {
    color: {
      GradientAlfredGreen: 'text-[#000] bg-primary-gradient',
      GradientAlfredBlue: 'text-white bg-secondary-gradient',
      DarkMain: 'bg-dark-main text-white border-1 border-complementary-gray-light',
      GradientAlfredBlueOp: 'bg-secondary-op-gradient-hover font-bold border-1 border-complementary-gray-light',
      GrayMain: 'bg-complementary-gray-main text-white',
    },
    isDisabled: {
      true: 'bg-complementary-gray-dark text-white cursor-not-allowed opacity-50 ',
    },
    size: {
      xs: 'px-2 min-w-12 h-6 text-tiny gap-1 rounded-small',
      md: 'px-4 min-w-20 h-10 text-small gap-2 rounded-small',
      xl: 'px-8 min-w-28 h-14 text-large gap-4 rounded-medium',
      group: 'px-4 min-w-12 h-8 text-small',
    },
  },
  defaultVariants: {
    color: 'GradientAlfredGreen',
    size: 'md',
  },
  compoundVariants: [
    {
      isDisabled: true,
      color: 'GradientAlfredGreen',
      class: 'bg-complementary-gray-dark opacity-50 text-white cursor-not-allowed',
    }
  ],
});

export const ButtonGroup = extendVariants(NextuiButtonGroup, {
  variants: {
    size: {
      xs: 'gap-1',
      md: 'gap-2',
      xl: 'gap-4',
    },
  },
  defaultVariants: {
    size: 'md',
  },
});

export { type ButtonProps, type ButtonGroupProps, useButton, ButtonGroupProvider, useButtonGroup, useButtonGroupContext };
