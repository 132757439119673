
export const IconVisibility = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='23' height='17' viewBox='0 0 23 17' fill='none' {...props}>
    <g clipPath='url(#clip0_4483_13752)'>
      <path d='M11.4761 16.5533C6.30375 16.3916 2.71146 13.7651 0.244553 9.40178C-0.112813 8.76965 -0.0662325 8.08558 0.304176 7.46124C2.63022 3.54086 5.84018 0.862841 10.5813 0.494841C14.9018 0.159486 18.373 1.85889 21.097 5.13082C21.6977 5.85236 22.1963 6.66329 22.6938 7.46273C23.0769 8.07779 23.1082 8.77336 22.7456 9.40103C20.2485 13.7247 16.6823 16.3993 11.4761 16.5533ZM11.1794 14.4332C14.7922 14.4084 17.4786 12.9957 19.495 10.4212C21.2513 8.17832 21.2714 8.77744 19.4898 6.5149C17.2796 3.70816 14.3305 2.30034 10.689 2.59786C6.86198 2.91058 4.2598 5.08149 2.32577 8.20207C2.23932 8.34118 2.2233 8.61681 2.30416 8.75147C4.37271 12.1933 7.28157 14.3123 11.1794 14.4332Z' fill='white' />
      <path d='M11.4735 3.95313C14.0104 3.94942 16.0581 5.95153 16.0727 8.44963C16.0872 10.9752 14.019 13.0474 11.4902 13.0407C8.98048 13.0344 6.92609 10.9908 6.92237 8.49674C6.91864 5.98974 8.95477 3.95684 11.4739 3.95313H11.4735ZM11.4809 6.05874C10.1334 6.06468 9.04309 7.14049 9.03377 8.473C9.02446 9.82258 10.1469 10.9303 11.5137 10.921C12.8571 10.9117 13.9519 9.82926 13.9605 8.50157C13.9691 7.14605 12.859 6.05244 11.4809 6.05837V6.05874Z' fill='white' />
    </g>
    <defs>
      <clipPath id='clip0_4483_13752'>
        <rect width='23' height='16.1' fill='white' transform='translate(0 0.453125)' />
      </clipPath>
    </defs>
  </svg>
);
