
export const IconSteeringWheel =
 (props: React.SVGProps<SVGSVGElement>) => (
   <svg
     xmlns='http://www.w3.org/2000/svg'
     width='16'
     height='16'
     viewBox='0 0 16 16'
     fill='none'
     {...props}
   >
     <g clipPath='url(#clip0_1408_15449)'>
       <path d='M9.56663e-07 7.99788C0.00213904 3.58416 3.60267 -0.009957 8.01212 2.07244e-05C12.4223 0.00999845 16.0029 3.5977 16 8.00358C15.9979 12.4166 12.3966 16.0114 7.98789 16.0014C3.57987 15.9922 -0.00213713 12.4031 9.56663e-07 7.99788ZM7.9665 1.14105C7.5959 1.18309 7.19109 1.20162 6.79768 1.27788C4.41657 1.74256 2.73319 3.10024 1.7233 5.29819C1.48597 5.8149 1.87225 6.34942 2.43884 6.31806C3.35537 6.26675 4.27189 6.20973 5.18913 6.16768C5.51982 6.15272 5.79279 6.03084 6.04294 5.82345C7.2082 4.8549 8.85381 4.86131 10.0098 5.83913C10.2457 6.03868 10.5087 6.14701 10.8166 6.16341C11.7039 6.20973 12.5912 6.25035 13.4778 6.31165C14.1506 6.35797 14.5397 5.83984 14.2468 5.23049C12.9882 2.61347 10.8814 1.29071 7.9665 1.14176V1.14105ZM6.61452 13.7315C6.6131 13.7144 6.61238 13.6724 6.60597 13.6317C6.50619 12.991 6.33087 12.3717 6.04579 11.788C5.24544 10.1509 3.98397 9.02488 2.26851 8.41338C1.66343 8.19744 1.16597 8.61864 1.28143 9.25151C1.53087 10.6177 2.15091 11.7958 3.1216 12.7879C3.83644 13.5184 4.67457 14.0622 5.63385 14.4157C6.1306 14.5989 6.61809 14.2667 6.61524 13.7315H6.61452ZM9.38833 13.708C9.37408 14.234 9.82735 14.5832 10.3006 14.4385C10.4246 14.4007 10.5465 14.3516 10.6648 14.2981C12.852 13.311 14.2054 11.6483 14.7157 9.3014C14.7813 8.99993 14.7293 8.7341 14.4855 8.52884C14.2425 8.32501 13.9752 8.31931 13.6852 8.42692C12.0146 9.04554 10.7745 10.1445 9.98129 11.7417C9.66771 12.3724 9.47742 13.0416 9.38904 13.7087L9.38833 13.708ZM9.29212 8.1504C9.2914 7.42203 8.72481 6.85828 7.99644 6.85971C7.2759 6.86113 6.7029 7.44056 6.70789 8.16109C6.71287 8.87093 7.28873 9.44679 7.99644 9.44964C8.70985 9.45249 9.29283 8.86808 9.29212 8.1504Z' fill='#40B2FF' />
     </g>
     <defs>
       <clipPath id='clip0_1408_15449'>
         <rect width='16' height='16.0021' fill='white' />
       </clipPath>
     </defs>
   </svg>
 );

export default IconSteeringWheel
;
