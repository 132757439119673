
export const IconResume = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='22' height='21' viewBox='0 0 22 21' fill='none' {...props}>
    <path d='M12.752 1.75H5.75195C5.28782 1.75 4.8427 1.93437 4.51452 2.26256C4.18633 2.59075 4.00195 3.03587 4.00195 3.5V17.5C4.00195 17.9641 4.18633 18.4092 4.51452 18.7374C4.8427 19.0656 5.28782 19.25 5.75195 19.25H16.252C16.7161 19.25 17.1612 19.0656 17.4894 18.7374C17.8176 18.4092 18.002 17.9641 18.002 17.5V7L12.752 1.75Z' stroke='white' strokeWidth='1.59969' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M12.752 1.75V7H18.002' stroke='white' strokeWidth='1.59969' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M14.502 11.375H7.50195' stroke='white' strokeWidth='1.59969' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M14.502 14.875H7.50195' stroke='white' strokeWidth='1.59969' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M9.25195 7.875H8.37695H7.50195' stroke='white' strokeWidth='1.59969' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
);
