import { extendVariants, Input as NextUiInput } from '@nextui-org/react';
import { InputProps, TextAreaProps, Textarea, useInput } from '@nextui-org/input';

export const Input = extendVariants(NextUiInput, {
  variants: {
    color: {
      alfred: {
        inputWrapper: [
          'bg-secondary-op-gradient-hover',
          'border-complementary-gray-light border-small',
          'group-hover:border-complementary-gray-light',
          'focus:border-complementary-gray-light',
          'group-focus:border-complementary-gray-light',
          'bg-none'
        ],
        mainWrapper: 'has-[:disabled]:bg-complementary-gray-dark rounded',
        label: ['text-white text-md font-medium'],
        description: ['text-white'],
        input: [
          '!text-white',
          'placeholder:text-complementary-gray-light placeholder:font-gothamBook text-base font-extralight',
          'text-sm font-gothamBook'
        ],
      },
    },
  },
  defaultVariants: {
    size: 'md',
    variant: 'bordered',
    labelPlacement: 'outside',
    radius: 'md',
  },
});

export { type InputProps, type TextAreaProps, Textarea, useInput };
