
export const IconSrRectangleList = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    id='Filled'
    viewBox='0 0 24 24'
    fill='#fff'
    height={20}
    width={20}
    {...props}
  >
    <path d='m19,1H5C2.243,1,0,3.243,0,6v12c0,2.757,2.243,5,5,5h14c2.757,0,5-2.243,5-5V6c0-2.757-2.243-5-5-5ZM6.5,18.5c-.828,0-1.5-.672-1.5-1.5s.672-1.5,1.5-1.5,1.5.672,1.5,1.5-.672,1.5-1.5,1.5Zm0-5c-.828,0-1.5-.672-1.5-1.5s.672-1.5,1.5-1.5,1.5.672,1.5,1.5-.672,1.5-1.5,1.5Zm0-5c-.828,0-1.5-.672-1.5-1.5s.672-1.5,1.5-1.5,1.5.672,1.5,1.5-.672,1.5-1.5,1.5Zm11.5,9.5h-7c-.552,0-1-.448-1-1s.448-1,1-1h7c.552,0,1,.448,1,1s-.448,1-1,1Zm0-5h-7c-.552,0-1-.448-1-1s.448-1,1-1h7c.552,0,1,.448,1,1s-.448,1-1,1Zm0-5h-7c-.552,0-1-.448-1-1s.448-1,1-1h7c.552,0,1,.448,1,1s-.448,1-1,1Z' />
  </svg>
);

export const IconSrList = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    id='Filled'
    viewBox='0 0 24 24'
    width='20'
    height='20'
    fill='#fff'
    {...props}
  >
    <path d='M7,6H23a1,1,0,0,0,0-2H7A1,1,0,0,0,7,6Z' />
    <path d='M23,11H7a1,1,0,0,0,0,2H23a1,1,0,0,0,0-2Z' />
    <path d='M23,18H7a1,1,0,0,0,0,2H23a1,1,0,0,0,0-2Z' />
    <circle cx='2' cy='5' r='2' />
    <circle cx='2' cy='12' r='2' />
    <circle cx='2' cy='19' r='2' />
  </svg>
);
