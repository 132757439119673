
export const IconCheckCircle = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='50'
    height='50'
    viewBox='0 0 50 50'
    fill='none'
    {...props}
  >
    <circle cx='25' cy='25' r='25' fill='url(#paint0_linear_763_23826)' />
    <path fillRule='evenodd' clipRule='evenodd' d='M37.3088 20.0188L22.9995 34.3097C22.0779 35.2301 20.586 35.2301 19.6667 34.3097L13.6894 28.3424C12.7702 27.422 12.7702 25.932 13.6894 25.0116C14.611 24.0935 16.103 24.0935 17.0222 25.0116L21.3331 29.3169L33.976 16.6903C34.8953 15.7699 36.3872 15.7699 37.3088 16.6903C38.2304 17.6083 38.2304 19.0984 37.3088 20.0188Z' fill='#111E3E' />
    <defs>
      <linearGradient id='paint0_linear_763_23826' x1='6.17627e-07' y1='24.5455' x2='59.1301' y2='32.9653' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#B1FC00' />
        <stop offset='1' stopColor='#19EB00' />
      </linearGradient>
    </defs>
  </svg>
);

export default IconCheckCircle;
