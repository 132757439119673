
export const IconTecnomecanica = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='106'
    height='106'
    viewBox='0 0 106 106'
    fill='none'
    {...props}
  >
    <g clipPath='url(#clip0_1166_40315)'>
      <circle cx='53.0198' cy='52.8729' r='65.5936' fill='url(#paint0_radial_1166_40315)' />
      <path d='M27.8227 42.1747V83.2306C27.8227 84.3768 28.564 85.5161 30.067 86.3809L45.5643 95.1384C48.5498 96.8611 53.4227 96.8473 56.4013 95.1178L75.893 83.7454C77.3137 82.9218 78.048 81.8443 78.1167 80.7599C78.1235 80.7599 78.1235 39.5117 78.1235 39.5117L27.8227 42.1747Z' fill='#636C9E' />
      <path d='M27.8227 41.4315V82.4875C27.8227 83.6337 28.564 84.773 30.067 85.6378L45.5643 94.3953C48.5498 96.1179 53.4227 96.1042 56.4013 94.3747L75.893 83.0023C77.3137 82.1787 78.048 81.1011 78.1167 80.0167C78.1235 80.0099 78.1235 38.7617 78.1235 38.7617L27.8227 41.4247V41.4315Z' fill='url(#paint1_linear_1166_40315)' />
      <path d='M76.2156 42.4669L56.717 53.8393C55.1728 54.7384 53.1276 55.2325 50.9519 55.2325C48.7763 55.2325 46.7722 54.7452 45.2417 53.8599L29.7445 45.1024C28.9003 44.6151 28.2551 44.0386 27.8227 43.3934V41.4168C27.8296 42.5561 28.5777 43.6885 30.067 44.5464L45.5643 53.304C47.0467 54.1619 49.0027 54.5874 50.9588 54.5874C52.9148 54.5874 54.9051 54.1481 56.4013 53.2765L75.893 41.9178C77.3823 41.053 78.1235 39.9069 78.1235 38.7676V40.7648C77.6912 41.4031 77.0529 41.9796 76.2156 42.4669Z' fill='white' />
      <path d='M56.4007 53.295C53.4221 55.0314 48.5492 55.0383 45.5636 53.3156L30.0664 44.5581C27.0809 42.8354 27.074 40.0077 30.0527 38.2713L49.5512 26.8989C52.5298 25.1625 57.4028 25.1488 60.3883 26.8783L75.8786 35.6358C78.8642 37.3585 78.871 40.1862 75.8924 41.9226L56.3939 53.295H56.4007Z' fill='#EEEEF6' />
      <path d='M56.2979 42.8398L59.7982 40.822C60.6218 40.3484 61.9738 40.3484 62.7906 40.822L64.1495 41.6181L68.7959 40.8975V41.7828C68.7959 42.0985 68.59 42.4142 68.1782 42.6545L57.149 49.0236C56.3254 49.4971 54.9733 49.4971 54.1566 49.0236L48.7689 45.8802C48.3777 45.6537 48.1718 45.3517 48.1581 45.0566V44.1232L56.3048 42.8535L56.2979 42.8398Z' fill='#636C9E' />
      <path d='M56.2979 42.6386L59.7982 40.6208C60.6218 40.1472 61.9738 40.1472 62.7906 40.6208L64.1495 41.4169L68.7959 40.6963V41.5817C68.7959 41.8974 68.59 42.2131 68.1782 42.4533L57.149 48.8224C56.3254 49.2959 54.9733 49.2959 54.1566 48.8224L48.7689 45.679C48.3777 45.4525 48.1718 45.1505 48.1581 44.8554V43.922L56.3048 42.6523L56.2979 42.6386Z' fill='url(#paint2_linear_1166_40315)' />
      <path d='M68.2674 41.6974L57.2382 48.0665C56.8127 48.3136 56.2567 48.444 55.6596 48.444C55.0625 48.444 54.4929 48.3067 54.0674 48.0596L48.6797 44.9163C48.4464 44.779 48.2748 44.6211 48.1512 44.4427V43.8936C48.1512 44.2094 48.3571 44.5251 48.7689 44.7653L54.1566 47.9086C54.5684 48.1489 55.1174 48.2724 55.6596 48.2724C56.2018 48.2724 56.7372 48.1557 57.149 47.9155L68.1782 41.5464C68.59 41.3062 68.7959 40.9974 68.7959 40.6816V41.2238C68.6792 41.4023 68.4939 41.5601 68.2674 41.6974Z' fill='white' />
      <path d='M54.1502 47.909C54.9738 48.3894 56.319 48.3894 57.1426 47.909L68.1719 41.5399C68.9954 41.0663 68.9954 40.2839 68.1719 39.8035L62.7842 36.6601C61.9606 36.1797 60.6154 36.1797 59.7918 36.6601L48.7626 43.0292C47.939 43.5028 47.939 44.2852 48.7626 44.7656L54.1502 47.909Z' fill='url(#paint3_linear_1166_40315)' />
      <path d='M61.1589 38.5242C61.3236 38.6203 61.5983 38.6203 61.7631 38.5242L62.2299 38.2564C62.3947 38.1603 62.3947 38.0024 62.2299 37.9063L61.7699 37.6385C61.6051 37.5424 61.3305 37.5424 61.1657 37.6385L60.6988 37.9063C60.5341 38.0024 60.5341 38.1603 60.6988 38.2564L61.1589 38.5242Z' fill='#636C9E' />
      <path d='M59.6471 39.3972C59.8119 39.4934 60.0866 39.4934 60.2513 39.3972L60.7182 39.1295C60.883 39.0333 60.883 38.8754 60.7182 38.7793L60.2582 38.5115C60.0934 38.4154 59.8188 38.4154 59.654 38.5115L59.1871 38.7793C59.0223 38.8754 59.0223 39.0333 59.1871 39.1295L59.6471 39.3972Z' fill='#636C9E' />
      <path d='M58.1383 40.2644C58.3031 40.3606 58.5777 40.3606 58.7494 40.2644L59.2163 39.9967C59.381 39.9005 59.381 39.7426 59.2163 39.6465L58.7562 39.3787C58.5915 39.2826 58.3168 39.2826 58.1452 39.3787L57.6783 39.6465C57.5135 39.7426 57.5135 39.9005 57.6783 39.9967L58.1383 40.2644Z' fill='#636C9E' />
      <path d='M56.6349 41.1316C56.7997 41.2277 57.0743 41.2277 57.2391 41.1316L57.706 40.8638C57.8708 40.7677 57.8708 40.6098 57.706 40.5137L57.246 40.2459C57.0812 40.1498 56.8066 40.1498 56.6349 40.2459L56.168 40.5137C56.0033 40.6098 56.0033 40.7677 56.168 40.8638L56.6281 41.1316H56.6349Z' fill='#636C9E' />
      <path d='M55.1247 42.0047C55.2895 42.1008 55.5641 42.1008 55.7289 42.0047L56.1958 41.7369C56.3605 41.6408 56.3605 41.4829 56.1958 41.3867L55.7357 41.119C55.571 41.0228 55.2963 41.0228 55.1315 41.119L54.6647 41.3867C54.4999 41.4829 54.4999 41.6408 54.6647 41.7369L55.1247 42.0047Z' fill='#636C9E' />
      <path d='M53.6222 42.8758C53.787 42.9719 54.0616 42.9719 54.2264 42.8758L54.6933 42.608C54.8581 42.5119 54.8581 42.354 54.6933 42.2578L54.2333 41.9901C54.0685 41.8939 53.7939 41.8939 53.6291 41.9901L53.1622 42.2578C52.9974 42.354 52.9974 42.5119 53.1622 42.608L53.6222 42.8758Z' fill='#636C9E' />
      <path d='M52.1125 43.7429C52.2772 43.8391 52.5519 43.8391 52.7167 43.7429L53.1835 43.4752C53.3483 43.3791 53.3483 43.2211 53.1835 43.125L52.7235 42.8572C52.5588 42.7611 52.2841 42.7611 52.1125 42.8572L51.6456 43.125C51.4808 43.2211 51.4808 43.3791 51.6456 43.4752L52.1056 43.7429H52.1125Z' fill='#636C9E' />
      <path d='M50.6017 44.6101C50.7665 44.7063 51.0411 44.7063 51.2059 44.6101L51.6728 44.3424C51.8376 44.2462 51.8376 44.0883 51.6728 43.9922L51.2128 43.7244C51.048 43.6283 50.7734 43.6283 50.6086 43.7244L50.1417 43.9922C49.9769 44.0883 49.9769 44.2462 50.1417 44.3424L50.6017 44.6101Z' fill='#636C9E' />
      <path d='M62.7082 39.4168C62.873 39.5129 63.1476 39.5129 63.3124 39.4168L63.7792 39.149C63.944 39.0529 63.944 38.895 63.7792 38.7988L63.3192 38.5311C63.1545 38.435 62.8798 38.435 62.715 38.5311L62.2482 38.7988C62.0834 38.895 62.0834 39.0529 62.2482 39.149L62.7082 39.4168Z' fill='#636C9E' />
      <path d='M61.1984 40.2967C61.3632 40.3928 61.6378 40.3928 61.8026 40.2967L62.2695 40.0289C62.4343 39.9328 62.4343 39.7748 62.2695 39.6787L61.8095 39.411C61.6447 39.3148 61.3701 39.3148 61.1984 39.411L60.7315 39.6787C60.5667 39.7748 60.5667 39.9328 60.7315 40.0289L61.1915 40.2967H61.1984Z' fill='#636C9E' />
      <path d='M59.6891 41.157C59.8539 41.2531 60.1285 41.2531 60.2933 41.157L60.7602 40.8892C60.925 40.7931 60.925 40.6352 60.7602 40.5391L60.3002 40.2713C60.1354 40.1752 59.8608 40.1752 59.696 40.2713L59.2291 40.5391C59.0643 40.6352 59.0643 40.7931 59.2291 40.8892L59.6891 41.157Z' fill='#636C9E' />
      <path d='M58.1867 42.031C58.3515 42.1272 58.6261 42.1272 58.7909 42.031L59.2578 41.7633C59.4225 41.6671 59.4225 41.5092 59.2578 41.4131L58.7977 41.1453C58.633 41.0492 58.3583 41.0492 58.1936 41.1453L57.7267 41.4131C57.5619 41.5092 57.5619 41.6671 57.7267 41.7633L58.1867 42.031Z' fill='#636C9E' />
      <path d='M56.6774 42.9021C56.8422 42.9982 57.1168 42.9982 57.2884 42.9021L57.7553 42.6344C57.9201 42.5382 57.9201 42.3803 57.7553 42.2842L57.2953 42.0164C57.1305 41.9203 56.8559 41.9203 56.6911 42.0164L56.2242 42.2842C56.0595 42.3803 56.0595 42.5382 56.2242 42.6344L56.6842 42.9021H56.6774Z' fill='#636C9E' />
      <path d='M55.1725 43.7605C55.3373 43.8566 55.6119 43.8566 55.7767 43.7605L56.2436 43.4928C56.4084 43.3966 56.4084 43.2387 56.2436 43.1426L55.7836 42.8748C55.6188 42.7787 55.3442 42.7787 55.1794 42.8748L54.7125 43.1426C54.5477 43.2387 54.5477 43.3966 54.7125 43.4928L55.1725 43.7605Z' fill='#636C9E' />
      <path d='M53.6633 44.6404C53.828 44.7365 54.1027 44.7365 54.2674 44.6404L54.7343 44.3726C54.8991 44.2765 54.8991 44.1186 54.7343 44.0225L54.2743 43.7547C54.1095 43.6586 53.8349 43.6586 53.6701 43.7547L53.2032 44.0225C53.0385 44.1186 53.0385 44.2765 53.2032 44.3726L53.6633 44.6404Z' fill='#636C9E' />
      <path d='M52.1535 45.5154C52.3183 45.6115 52.5929 45.6115 52.7577 45.5154L53.2246 45.2476C53.3893 45.1515 53.3893 44.9936 53.2246 44.8975L52.7645 44.6297C52.5998 44.5336 52.3251 44.5336 52.1603 44.6297L51.6935 44.8975C51.5287 44.9936 51.5287 45.1515 51.6935 45.2476L52.1535 45.5154Z' fill='#636C9E' />
      <path d='M62.7082 39.4168C62.873 39.5129 63.1476 39.5129 63.3124 39.4168L63.7792 39.149C63.944 39.0529 63.944 38.895 63.7792 38.7988L63.3192 38.5311C63.1545 38.435 62.8798 38.435 62.715 38.5311L62.2482 38.7988C62.0834 38.895 62.0834 39.0529 62.2482 39.149L62.7082 39.4168Z' fill='#636C9E' />
      <path d='M61.1984 40.2967C61.3632 40.3928 61.6378 40.3928 61.8026 40.2967L62.2695 40.0289C62.4343 39.9328 62.4343 39.7748 62.2695 39.6787L61.8095 39.411C61.6447 39.3148 61.3701 39.3148 61.1984 39.411L60.7315 39.6787C60.5667 39.7748 60.5667 39.9328 60.7315 40.0289L61.1915 40.2967H61.1984Z' fill='#636C9E' />
      <path d='M59.6891 41.157C59.8539 41.2531 60.1285 41.2531 60.2933 41.157L60.7602 40.8892C60.925 40.7931 60.925 40.6352 60.7602 40.5391L60.3002 40.2713C60.1354 40.1752 59.8608 40.1752 59.696 40.2713L59.2291 40.5391C59.0643 40.6352 59.0643 40.7931 59.2291 40.8892L59.6891 41.157Z' fill='#636C9E' />
      <path d='M58.1867 42.031C58.3515 42.1272 58.6261 42.1272 58.7909 42.031L59.2578 41.7633C59.4225 41.6671 59.4225 41.5092 59.2578 41.4131L58.7977 41.1453C58.633 41.0492 58.3583 41.0492 58.1936 41.1453L57.7267 41.4131C57.5619 41.5092 57.5619 41.6671 57.7267 41.7633L58.1867 42.031Z' fill='#636C9E' />
      <path d='M56.6774 42.9021C56.8422 42.9982 57.1168 42.9982 57.2884 42.9021L57.7553 42.6344C57.9201 42.5382 57.9201 42.3803 57.7553 42.2842L57.2953 42.0164C57.1305 41.9203 56.8559 41.9203 56.6911 42.0164L56.2242 42.2842C56.0595 42.3803 56.0595 42.5382 56.2242 42.6344L56.6842 42.9021H56.6774Z' fill='#636C9E' />
      <path d='M55.1725 43.7605C55.3373 43.8566 55.6119 43.8566 55.7767 43.7605L56.2436 43.4928C56.4084 43.3966 56.4084 43.2387 56.2436 43.1426L55.7836 42.8748C55.6188 42.7787 55.3442 42.7787 55.1794 42.8748L54.7125 43.1426C54.5477 43.2387 54.5477 43.3966 54.7125 43.4928L55.1725 43.7605Z' fill='#636C9E' />
      <path d='M53.6633 44.6404C53.828 44.7365 54.1027 44.7365 54.2674 44.6404L54.7343 44.3726C54.8991 44.2765 54.8991 44.1186 54.7343 44.0225L54.2743 43.7547C54.1095 43.6586 53.8349 43.6586 53.6701 43.7547L53.2032 44.0225C53.0385 44.1186 53.0385 44.2765 53.2032 44.3726L53.6633 44.6404Z' fill='#636C9E' />
      <path d='M52.1535 45.5154C52.3183 45.6115 52.5929 45.6115 52.7577 45.5154L53.2246 45.2476C53.3893 45.1515 53.3893 44.9936 53.2246 44.8975L52.7645 44.6297C52.5998 44.5336 52.3251 44.5336 52.1603 44.6297L51.6935 44.8975C51.5287 44.9936 51.5287 45.1515 51.6935 45.2476L52.1535 45.5154Z' fill='#636C9E' />
      <path d='M64.2604 40.3162C64.4252 40.4123 64.6998 40.4123 64.8714 40.3162L65.3383 40.0484C65.5031 39.9523 65.5031 39.7944 65.3383 39.6983L64.8783 39.4305C64.7135 39.3344 64.4389 39.3344 64.2741 39.4305L63.8072 39.6983C63.6425 39.7944 63.6425 39.9523 63.8072 40.0484L64.2673 40.3162H64.2604Z' fill='#636C9E' />
      <path d='M62.7502 41.1902C62.9149 41.2863 63.1896 41.2863 63.3544 41.1902L63.8212 40.9224C63.986 40.8263 63.986 40.6684 63.8212 40.5723L63.3612 40.3045C63.1964 40.2084 62.9218 40.2084 62.757 40.3045L62.2902 40.5723C62.1254 40.6684 62.1254 40.8263 62.2902 40.9224L62.7502 41.1902Z' fill='#636C9E' />
      <path d='M61.2414 42.0564C61.4062 42.1525 61.6808 42.1525 61.8456 42.0564L62.3124 41.7887C62.4772 41.6925 62.4772 41.5346 62.3124 41.4385L61.8524 41.1707C61.6877 41.0746 61.413 41.0746 61.2482 41.1707L60.7814 41.4385C60.6166 41.5346 60.6166 41.6925 60.7814 41.7887L61.2414 42.0564Z' fill='#636C9E' />
      <path d='M59.737 42.9275C59.9018 43.0236 60.1764 43.0236 60.3412 42.9275L60.8081 42.6597C60.9728 42.5636 60.9728 42.4057 60.8081 42.3096L60.348 42.0418C60.1833 41.9457 59.9086 41.9457 59.737 42.0418L59.2701 42.3096C59.1053 42.4057 59.1053 42.5636 59.2701 42.6597L59.7301 42.9275H59.737Z' fill='#636C9E' />
      <path d='M58.2277 43.7947C58.3925 43.8908 58.6671 43.8908 58.8319 43.7947L59.2988 43.5269C59.4636 43.4308 59.4636 43.2729 59.2988 43.1768L58.8388 42.909C58.674 42.8129 58.3993 42.8129 58.2277 42.909L57.7608 43.1768C57.596 43.2729 57.596 43.4308 57.7608 43.5269L58.2208 43.7947H58.2277Z' fill='#636C9E' />
      <path d='M56.7228 44.6658C56.8876 44.7619 57.1622 44.7619 57.327 44.6658L57.7939 44.398C57.9587 44.3019 57.9587 44.144 57.7939 44.0479L57.3339 43.7801C57.1691 43.684 56.8945 43.684 56.7297 43.7801L56.2628 44.0479C56.098 44.144 56.098 44.3019 56.2628 44.398L56.7228 44.6658Z' fill='#636C9E' />
      <path d='M55.214 45.5418C55.3788 45.6379 55.6534 45.6379 55.8251 45.5418L56.2919 45.274C56.4567 45.1779 56.4567 45.02 56.2919 44.9238L55.8319 44.6561C55.6671 44.56 55.3925 44.56 55.2277 44.6561L54.7609 44.9238C54.5961 45.02 54.5961 45.1779 54.7609 45.274L55.2209 45.5418H55.214Z' fill='#636C9E' />
      <path d='M53.7106 46.409C53.8754 46.5051 54.15 46.5051 54.3216 46.409L54.7885 46.1412C54.9533 46.0451 54.9533 45.8872 54.7885 45.791L54.3285 45.5233C54.1637 45.4271 53.8891 45.4271 53.7243 45.5233L53.2574 45.791C53.0927 45.8872 53.0927 46.0451 53.2574 46.1412L53.7174 46.409H53.7106Z' fill='#636C9E' />
      <path d='M64.2604 40.3162C64.4252 40.4123 64.6998 40.4123 64.8714 40.3162L65.3383 40.0484C65.5031 39.9523 65.5031 39.7944 65.3383 39.6983L64.8783 39.4305C64.7135 39.3344 64.4389 39.3344 64.2741 39.4305L63.8072 39.6983C63.6425 39.7944 63.6425 39.9523 63.8072 40.0484L64.2673 40.3162H64.2604Z' fill='#636C9E' />
      <path d='M62.7502 41.1902C62.9149 41.2863 63.1896 41.2863 63.3544 41.1902L63.8212 40.9224C63.986 40.8263 63.986 40.6684 63.8212 40.5723L63.3612 40.3045C63.1964 40.2084 62.9218 40.2084 62.757 40.3045L62.2902 40.5723C62.1254 40.6684 62.1254 40.8263 62.2902 40.9224L62.7502 41.1902Z' fill='#636C9E' />
      <path d='M61.2414 42.0564C61.4062 42.1525 61.6808 42.1525 61.8456 42.0564L62.3124 41.7887C62.4772 41.6925 62.4772 41.5346 62.3124 41.4385L61.8524 41.1707C61.6877 41.0746 61.413 41.0746 61.2482 41.1707L60.7814 41.4385C60.6166 41.5346 60.6166 41.6925 60.7814 41.7887L61.2414 42.0564Z' fill='#636C9E' />
      <path d='M59.737 42.9275C59.9018 43.0236 60.1764 43.0236 60.3412 42.9275L60.8081 42.6597C60.9728 42.5636 60.9728 42.4057 60.8081 42.3096L60.348 42.0418C60.1833 41.9457 59.9086 41.9457 59.737 42.0418L59.2701 42.3096C59.1053 42.4057 59.1053 42.5636 59.2701 42.6597L59.7301 42.9275H59.737Z' fill='#636C9E' />
      <path d='M58.2277 43.7947C58.3925 43.8908 58.6671 43.8908 58.8319 43.7947L59.2988 43.5269C59.4636 43.4308 59.4636 43.2729 59.2988 43.1768L58.8388 42.909C58.674 42.8129 58.3993 42.8129 58.2277 42.909L57.7608 43.1768C57.596 43.2729 57.596 43.4308 57.7608 43.5269L58.2208 43.7947H58.2277Z' fill='#636C9E' />
      <path d='M56.7228 44.6658C56.8876 44.7619 57.1622 44.7619 57.327 44.6658L57.7939 44.398C57.9587 44.3019 57.9587 44.144 57.7939 44.0479L57.3339 43.7801C57.1691 43.684 56.8945 43.684 56.7297 43.7801L56.2628 44.0479C56.098 44.144 56.098 44.3019 56.2628 44.398L56.7228 44.6658Z' fill='#636C9E' />
      <path d='M55.214 45.5418C55.3788 45.6379 55.6534 45.6379 55.8251 45.5418L56.2919 45.274C56.4567 45.1779 56.4567 45.02 56.2919 44.9238L55.8319 44.6561C55.6671 44.56 55.3925 44.56 55.2277 44.6561L54.7609 44.9238C54.5961 45.02 54.5961 45.1779 54.7609 45.274L55.2209 45.5418H55.214Z' fill='#636C9E' />
      <path d='M53.7106 46.409C53.8754 46.5051 54.15 46.5051 54.3216 46.409L54.7885 46.1412C54.9533 46.0451 54.9533 45.8872 54.7885 45.791L54.3285 45.5233C54.1637 45.4271 53.8891 45.4271 53.7243 45.5233L53.2574 45.791C53.0927 45.8872 53.0927 46.0451 53.2574 46.1412L53.7174 46.409H53.7106Z' fill='#636C9E' />
      <path d='M65.8112 41.2156C65.976 41.3117 66.2506 41.3117 66.4154 41.2156L66.8823 40.9478C67.0471 40.8517 67.0471 40.6938 66.8823 40.5977L66.4223 40.3299C66.2575 40.2338 65.9828 40.2338 65.8181 40.3299L65.3512 40.5977C65.1864 40.6938 65.1864 40.8517 65.3512 40.9478L65.8112 41.2156Z' fill='#636C9E' />
      <path d='M64.3019 42.0886C64.4667 42.1848 64.7413 42.1848 64.913 42.0886L65.3798 41.8209C65.5446 41.7248 65.5446 41.5668 65.3798 41.4707L64.9198 41.203C64.755 41.1068 64.4804 41.1068 64.3156 41.203L63.8487 41.4707C63.684 41.5668 63.684 41.7248 63.8487 41.8209L64.3088 42.0886H64.3019Z' fill='#636C9E' />
      <path d='M62.7975 42.9539C62.9623 43.05 63.2369 43.05 63.4086 42.9539L63.8754 42.6861C64.0402 42.59 64.0402 42.4321 63.8754 42.336L63.4154 42.0682C63.2506 41.9721 62.976 41.9721 62.8044 42.0682L62.3375 42.336C62.1727 42.4321 62.1727 42.59 62.3375 42.6861L62.7975 42.9539Z' fill='#636C9E' />
      <path d='M61.2887 43.825C61.4535 43.9211 61.7282 43.9211 61.8929 43.825L62.3598 43.5572C62.5246 43.4611 62.5246 43.3032 62.3598 43.2071L61.8998 42.9393C61.735 42.8432 61.4604 42.8432 61.2956 42.9393L60.8287 43.2071C60.6639 43.3032 60.6639 43.4611 60.8287 43.5572L61.2887 43.825Z' fill='#636C9E' />
      <path d='M59.779 44.701C59.9438 44.7971 60.2184 44.7971 60.3832 44.701L60.85 44.4332C61.0148 44.3371 61.0148 44.1791 60.85 44.083L60.39 43.8153C60.2253 43.7191 59.9506 43.7191 59.7858 43.8153L59.319 44.083C59.1542 44.1791 59.1542 44.3371 59.319 44.4332L59.779 44.701Z' fill='#636C9E' />
      <path d='M58.2765 45.5593C58.4413 45.6555 58.7159 45.6555 58.8807 45.5593L59.3476 45.2916C59.5124 45.1955 59.5124 45.0375 59.3476 44.9414L58.8876 44.6737C58.7228 44.5775 58.4482 44.5775 58.2765 44.6737L57.8097 44.9414C57.6449 45.0375 57.6449 45.1955 57.8097 45.2916L58.2697 45.5593H58.2765Z' fill='#636C9E' />
      <path d='M56.7648 46.4343C56.9296 46.5305 57.2042 46.5305 57.369 46.4343L57.8359 46.1666C58.0007 46.0705 58.0007 45.9125 57.8359 45.8164L57.3759 45.5487C57.2111 45.4525 56.9365 45.4525 56.7648 45.5487L56.2979 45.8164C56.1332 45.9125 56.1332 46.0705 56.2979 46.1666L56.7579 46.4343H56.7648Z' fill='#636C9E' />
      <path d='M55.2633 47.3054C55.4281 47.4016 55.7027 47.4016 55.8744 47.3054L56.3413 47.0377C56.506 46.9416 56.506 46.7836 56.3413 46.6875L55.8812 46.4197C55.7165 46.3236 55.4418 46.3236 55.277 46.4197L54.8102 46.6875C54.6454 46.7836 54.6454 46.9416 54.8102 47.0377L55.2702 47.3054H55.2633Z' fill='#636C9E' />
      <path d='M65.8112 41.2156C65.976 41.3117 66.2506 41.3117 66.4154 41.2156L66.8823 40.9478C67.0471 40.8517 67.0471 40.6938 66.8823 40.5977L66.4223 40.3299C66.2575 40.2338 65.9828 40.2338 65.8181 40.3299L65.3512 40.5977C65.1864 40.6938 65.1864 40.8517 65.3512 40.9478L65.8112 41.2156Z' fill='#636C9E' />
      <path d='M64.3019 42.0886C64.4667 42.1848 64.7413 42.1848 64.913 42.0886L65.3798 41.8209C65.5446 41.7248 65.5446 41.5668 65.3798 41.4707L64.9198 41.203C64.755 41.1068 64.4804 41.1068 64.3156 41.203L63.8487 41.4707C63.684 41.5668 63.684 41.7248 63.8487 41.8209L64.3088 42.0886H64.3019Z' fill='#636C9E' />
      <path d='M62.7975 42.9539C62.9623 43.05 63.2369 43.05 63.4086 42.9539L63.8754 42.6861C64.0402 42.59 64.0402 42.4321 63.8754 42.336L63.4154 42.0682C63.2506 41.9721 62.976 41.9721 62.8044 42.0682L62.3375 42.336C62.1727 42.4321 62.1727 42.59 62.3375 42.6861L62.7975 42.9539Z' fill='#636C9E' />
      <path d='M61.2887 43.825C61.4535 43.9211 61.7282 43.9211 61.8929 43.825L62.3598 43.5572C62.5246 43.4611 62.5246 43.3032 62.3598 43.2071L61.8998 42.9393C61.735 42.8432 61.4604 42.8432 61.2956 42.9393L60.8287 43.2071C60.6639 43.3032 60.6639 43.4611 60.8287 43.5572L61.2887 43.825Z' fill='#636C9E' />
      <path d='M59.779 44.701C59.9438 44.7971 60.2184 44.7971 60.3832 44.701L60.85 44.4332C61.0148 44.3371 61.0148 44.1791 60.85 44.083L60.39 43.8153C60.2253 43.7191 59.9506 43.7191 59.7858 43.8153L59.319 44.083C59.1542 44.1791 59.1542 44.3371 59.319 44.4332L59.779 44.701Z' fill='#636C9E' />
      <path d='M58.2765 45.5593C58.4413 45.6555 58.7159 45.6555 58.8807 45.5593L59.3476 45.2916C59.5124 45.1955 59.5124 45.0375 59.3476 44.9414L58.8876 44.6737C58.7228 44.5775 58.4482 44.5775 58.2765 44.6737L57.8097 44.9414C57.6449 45.0375 57.6449 45.1955 57.8097 45.2916L58.2697 45.5593H58.2765Z' fill='#636C9E' />
      <path d='M56.7648 46.4343C56.9296 46.5305 57.2042 46.5305 57.369 46.4343L57.8359 46.1666C58.0007 46.0705 58.0007 45.9125 57.8359 45.8164L57.3759 45.5487C57.2111 45.4525 56.9365 45.4525 56.7648 45.5487L56.2979 45.8164C56.1332 45.9125 56.1332 46.0705 56.2979 46.1666L56.7579 46.4343H56.7648Z' fill='#636C9E' />
      <path d='M55.2633 47.3054C55.4281 47.4016 55.7027 47.4016 55.8744 47.3054L56.3413 47.0377C56.506 46.9416 56.506 46.7836 56.3413 46.6875L55.8812 46.4197C55.7165 46.3236 55.4418 46.3236 55.277 46.4197L54.8102 46.6875C54.6454 46.7836 54.6454 46.9416 54.8102 47.0377L55.2702 47.3054H55.2633Z' fill='#636C9E' />
      <path d='M75.2273 48.1828L57.4858 58.4022V70.7286L75.2273 60.5092V48.1896V48.1828ZM75.5361 47.6543V60.6808L75.3851 60.77L57.6436 70.9894L57.1838 71.257V58.2306L57.3348 58.1413L75.0694 47.922L75.5293 47.6543H75.5361Z' fill='#636C9E' />
      <path d='M57.4797 70.728L75.228 60.5087V50.4814L57.4797 60.7008V70.728Z' fill='url(#paint4_linear_1166_40315)' />
      <path d='M75.2211 48.1846L76.2163 48.7885L75.2211 50.5661L58.4748 61.3895L57.4796 60.7855V58.404L75.2211 48.1846Z' fill='#0075C5' />
      <path d='M58.4748 61.3871L76.2163 51.1677V48.7861L58.4748 59.0055V61.3871Z' fill='#0096FB' />
      <path d='M75.2273 65.0871L57.4858 75.3065V87.626L75.2273 77.4066V65.0802V65.0871ZM75.5361 64.5586V77.5851L75.3851 77.6743L57.6436 87.8937L57.1838 88.1613V75.1349L57.3348 75.0456L75.0763 64.8263L75.5361 64.5586Z' fill='#636C9E' />
      <path d='M57.4797 87.6255L75.228 77.4061V67.3789L57.4797 77.5983V87.6255Z' fill='url(#paint5_linear_1166_40315)' />
      <path d='M75.2211 65.0889L76.2163 65.686L75.2211 67.4704L58.4748 78.2869L57.4796 77.6898V75.3082L75.2211 65.0889Z' fill='#0075C5' />
      <path d='M58.4748 78.2855L76.2163 68.0661V65.6846L58.4748 75.904V78.2855Z' fill='#0096FB' />
      <path d='M59.9971 32.2492L36.765 45.6668V23.2995L59.9971 9.88867V32.2492Z' fill='#111E3E' />
      <path d='M35.8102 45.0988L36.7642 45.6822V23.3217L35.8102 22.7314V45.0988Z' fill='#636C9E' />
      <path d='M59.0499 9.3584L59.9971 9.88687L36.7649 23.3251L35.811 22.7349L59.0499 9.3584Z' fill='#F4F4FC' />
      <path d='M59.0499 11.5212L37.7121 23.8407V44.0187L59.0499 31.6992V11.5212ZM59.9971 9.88086V32.2413L36.765 45.659V23.2917L59.9971 9.88086Z' fill='#BDC1E6' />
      <path d='M39.7295 25.7575C41.8914 24.5084 41.8914 28.8391 44.0533 27.59C46.2153 26.3409 46.2153 22.017 48.3772 20.7679C50.5391 19.5188 50.5391 23.8495 52.7011 22.6004C54.863 21.3513 54.863 17.0274 57.0249 15.7783V22.9504L39.7295 32.9365V25.7644V25.7575Z' fill='#0096FB' />
      <path d='M43.3273 27.8998C43.5538 27.8998 43.8077 27.8312 44.1028 27.6596C45.2078 27.0213 45.7637 25.5869 46.2991 24.2074C46.8276 22.8485 47.3697 21.4484 48.4267 20.8375C49.4425 20.2542 49.9435 20.9679 50.4788 21.7298C51.0073 22.4847 51.6044 23.3358 52.7505 22.67C53.8555 22.0317 54.4114 20.5973 54.9468 19.2178C55.4753 17.8589 56.0174 16.4588 57.0744 15.848C57.1156 15.8274 57.1293 15.7725 57.1087 15.7313C57.0881 15.6901 57.0332 15.6764 56.992 15.697C55.887 16.3352 55.3311 17.7697 54.7958 19.1492C54.2673 20.5081 53.7251 21.9082 52.6682 22.519C51.6524 23.1024 51.1514 22.3886 50.6161 21.6268C50.0876 20.8787 49.4905 20.0208 48.3443 20.6865C47.2393 21.3248 46.6834 22.7592 46.1481 24.1388C45.6196 25.4977 45.0774 26.8978 44.0205 27.5086C43.0047 28.092 42.5037 27.3782 41.9684 26.6164C41.4399 25.8614 40.8428 25.0104 39.6966 25.6761C39.6555 25.6967 39.6417 25.7516 39.6623 25.7928C39.6829 25.834 39.7378 25.8477 39.779 25.8271C40.7948 25.2437 41.2958 25.9575 41.8311 26.7193C42.2223 27.2821 42.6616 27.8998 43.3342 27.8998H43.3273Z' fill='#0075C5' />
      <path d='M54.9855 31.6841C56.1111 31.032 57.0308 29.4466 57.0308 28.1563C57.0308 26.866 56.1111 26.3376 54.9855 26.9896C54.7933 27.0994 54.6355 27.3671 54.6355 27.5867C54.6355 27.8063 54.7933 27.8955 54.9855 27.7857C55.7336 27.3533 56.3376 27.7034 56.3376 28.5544C56.3376 29.4055 55.7336 30.4555 54.9855 30.8879C54.2374 31.3203 53.6335 30.9703 53.6335 30.1192C53.6335 29.8996 53.4756 29.8104 53.2834 29.9202C53.0913 30.03 52.9334 30.2977 52.9334 30.5173C52.9334 31.8145 53.8531 32.3361 54.9786 31.6841H54.9855Z' fill='#0096FB' />
      <path d='M45.3024 37.186C46.428 36.534 47.3477 34.9486 47.3477 33.6583C47.3477 32.368 46.428 31.8395 45.3024 32.4915C44.8906 32.7318 44.4994 33.0955 44.1562 33.5553C43.9984 33.7681 43.9572 34.0426 44.0602 34.1593C44.17 34.2829 44.3827 34.2005 44.5406 33.9877C44.7671 33.6857 45.0279 33.4455 45.2955 33.2877C46.0436 32.8553 46.6476 33.2053 46.6476 34.0564C46.6476 34.9074 46.0436 35.9575 45.2955 36.3899C44.5474 36.8223 43.9435 36.4722 43.9435 35.6212C43.9435 35.4016 43.7856 35.3123 43.5935 35.4222C43.4013 35.532 43.2434 35.7996 43.2434 36.0193C43.2434 37.3164 44.1631 37.838 45.2887 37.186H45.3024Z' fill='#0075C5' />
      <path d='M49.1594 31.5017L52.1392 29.7852V29.0918L49.1594 30.8083V31.5017Z' fill='#D0D4F9' />
      <path d='M49.1594 32.8475L52.1392 31.131V30.4307L49.1594 32.1471V32.8475Z' fill='#D0D4F9' />
      <path d='M49.1594 34.2L52.1392 32.4835V31.7832L49.1594 33.4997V34.2Z' fill='#D0D4F9' />
      <path d='M39.8581 36.6219L42.8311 34.9054V34.2051L39.8581 35.9215V36.6219Z' fill='#D0D4F9' />
      <path d='M39.8581 37.9607L42.8311 36.2442V35.5508L39.8581 37.2672V37.9607Z' fill='#D0D4F9' />
      <path d='M39.8581 39.3133L42.8311 37.5968V36.8965L39.8581 38.613V39.3133Z' fill='#D0D4F9' />
    </g>
    <defs>
      <radialGradient id='paint0_radial_1166_40315' cx='0' cy='0' r='1' gradientUnits='userSpaceOnUse' gradientTransform='translate(53.0592 52.8729) scale(137.092)'>
        <stop stopColor='#7E7E7E' />
        <stop offset='0.382315' stopColor='#2F2F2F' stopOpacity='0' />
      </radialGradient>
      <linearGradient id='paint1_linear_1166_40315' x1='67.1561' y1='67.2923' x2='50.9656' y2='66.8599' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#CFD5F7' />
        <stop offset='1' stopColor='#BCC2E6' />
      </linearGradient>
      <linearGradient id='paint2_linear_1166_40315' x1='58.8099' y1='44.7113' x2='52.5506' y2='44.828' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#CFD5F7' />
        <stop offset='1' stopColor='#BCC2E6' />
      </linearGradient>
      <linearGradient id='paint3_linear_1166_40315' x1='66.7855' y1='40.6271' x2='50.1627' y2='43.942' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#C4CAEC' />
        <stop offset='1' stopColor='#B1B7DB' />
      </linearGradient>
      <linearGradient id='paint4_linear_1166_40315' x1='72.4141' y1='57.4751' x2='42.8129' y2='72.7596' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#F4F4FC' />
        <stop offset='1' stopColor='#BCC2E6' />
      </linearGradient>
      <linearGradient id='paint5_linear_1166_40315' x1='72.4141' y1='74.3726' x2='42.8129' y2='89.657' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#F4F4FC' />
        <stop offset='1' stopColor='#BCC2E6' />
      </linearGradient>
      <clipPath id='clip0_1166_40315'>
        <rect width='106' height='106' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export default IconTecnomecanica;
