
export const IconAlfred = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='120' height='45' viewBox='0 0 120 45' fill='none' {...props}>
    <path d='M119.988 2.98118C119.839 4.63024 118.377 5.96237 116.716 5.96237C115.054 5.96237 113.832 4.63024 113.982 2.98118C114.131 1.33708 115.593 -1.93598e-07 117.254 -1.20615e-07C118.916 -4.76332e-08 120.133 1.33212 119.988 2.98118ZM119.699 2.98118C119.834 1.47574 118.731 0.262463 117.229 0.262463C115.728 0.262463 114.406 1.47078 114.271 2.98118C114.136 4.48168 115.244 5.6999 116.736 5.6999C118.227 5.6999 119.559 4.47673 119.694 2.98118M117.718 3.3625L118.247 4.50644L117.419 4.50644L117.015 3.54573L116.641 3.54573L116.551 4.50644L115.788 4.50644L116.062 1.46088L117.199 1.46088C117.888 1.46088 118.337 1.88676 118.287 2.45626C118.252 2.86728 118.038 3.17927 117.713 3.3625M116.696 2.88709L117.03 2.88709C117.319 2.88709 117.499 2.73853 117.519 2.52559C117.539 2.28788 117.384 2.16408 117.095 2.16408L116.765 2.16408L116.701 2.88709L116.696 2.88709Z' fill='url(#paint0_linear_675_50930)' />
    <path d='M25.9062 12.3656L22.0697 34.0064L14.0026 34.0064L14.3518 32.1048C12.7803 33.6598 10.7348 34.6156 8.24531 34.6156C2.22862 34.6156 -0.689925 29.1633 0.138244 23.1019C0.966411 17.2138 5.10725 11.7615 11.4732 11.7615C14.262 11.7615 16.2676 12.8014 17.4051 14.7922L17.8391 12.3706L25.9062 12.3706L25.9062 12.3656ZM15.8784 23.3148L15.9233 23.186C16.3175 20.8932 15.2698 19.1203 12.7853 19.1203C10.3008 19.1203 8.55463 20.809 8.16549 23.186C7.77136 25.4789 8.86394 27.2517 11.3484 27.2517C13.833 27.2517 15.4444 25.6522 15.8834 23.3148' fill='url(#paint1_linear_675_50930)' />
    <path d='M26.9192 19.9816L29.9226 2.40649L37.9897 2.40649L34.9465 20.1104L32.5019 34.0011L24.4347 34.0011L26.9192 19.9816Z' fill='url(#paint2_linear_675_50930)' />
    <path d='M46.6355 12.3657L45.2835 19.9821L40.8383 45.0002L32.7712 45.0002L37.2163 19.9821L38.5683 12.3657C39.7008 6.00221 44.1061 2.58524 51.6494 3.01608L50.3872 10.2016C48.2519 10.0283 46.8999 10.9791 46.6355 12.3657Z' fill='url(#paint3_linear_675_50930)' />
    <path d='M57.9755 19.982C59.0581 16.8522 61.1285 14.2425 63.7876 12.4745L50.961 12.3805C49.7985 12.3706 48.8007 13.1976 48.5962 14.3366L47.5934 19.982L45.1089 34.0015L53.1761 34.0015L55.3513 21.7251C55.5309 20.7149 56.4139 19.977 57.4466 19.977L57.9755 19.977L57.9755 19.982Z' fill='url(#paint4_linear_675_50930)' />
    <path d='M81.663 23.097C81.5333 23.8745 81.2689 24.8699 80.8348 25.9099L66.6213 25.9098C67.19 27.6827 68.8913 28.0739 70.5027 28.0739C72.1141 28.0739 73.4212 27.6827 74.5138 26.6873L79.1785 31.3621C76.6491 33.5262 73.6408 34.6058 69.7593 34.6058C61.6473 34.6058 57.9854 29.1535 59.078 22.7454C60.1256 16.4265 65.4439 11.7517 71.9844 11.7517C78.8742 11.7517 82.7556 16.9861 81.663 23.0921M74.5986 20.7547C74.4689 18.8085 73.2915 18.1598 71.6751 18.1598C69.929 18.1598 68.3624 18.9373 67.4045 20.7547L74.5986 20.7547Z' fill='url(#paint5_linear_675_50930)' />
    <path d='M110.749 3.70898L105.385 34.0012L97.3182 34.0012L97.6675 32.0996C96.0959 33.6545 94.0505 34.6103 91.561 34.6103C85.5443 34.6103 82.6257 29.158 83.4539 23.0966C84.2821 17.2085 88.4229 11.7562 94.7888 11.7562C97.5777 11.7562 99.5832 12.7961 100.721 14.7869L102.681 3.70898L110.749 3.70898ZM99.1941 23.4432L99.2839 23.0124C99.5882 20.8037 98.5405 19.12 96.1009 19.12C93.6613 19.12 91.8703 20.8087 91.4811 23.1857C91.087 25.4786 92.1796 27.2564 94.6641 27.2564C97.0189 27.2564 98.7201 25.6964 99.1991 23.4482' fill='url(#paint6_linear_675_50930)' />
    <defs>
      <linearGradient id='paint0_linear_675_50930' x1='115.039' y1='2.58996' x2='6.44377' y2='-19.6323' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#B4FB00' />
        <stop offset='0.12' stopColor='#ADFA00' />
        <stop offset='0.3' stopColor='#9AF800' />
        <stop offset='0.5' stopColor='#7AF500' />
        <stop offset='0.74' stopColor='#4FF000' />
        <stop offset='0.99' stopColor='#18EB00' />
        <stop offset='1' stopColor='#15EB00' />
      </linearGradient>
      <linearGradient id='paint1_linear_675_50930' x1='107.032' y1='41.7169' x2='-1.5636' y2='19.4947' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#B4FB00' />
        <stop offset='0.12' stopColor='#ADFA00' />
        <stop offset='0.3' stopColor='#9AF800' />
        <stop offset='0.5' stopColor='#7AF500' />
        <stop offset='0.74' stopColor='#4FF000' />
        <stop offset='0.99' stopColor='#18EB00' />
        <stop offset='1' stopColor='#15EB00' />
      </linearGradient>
      <linearGradient id='paint2_linear_675_50930' x1='108.648' y1='33.8179' x2='0.0530476' y2='11.5957' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#B4FB00' />
        <stop offset='0.12' stopColor='#ADFA00' />
        <stop offset='0.3' stopColor='#9AF800' />
        <stop offset='0.5' stopColor='#7AF500' />
        <stop offset='0.74' stopColor='#4FF000' />
        <stop offset='0.99' stopColor='#18EB00' />
        <stop offset='1' stopColor='#15EB00' />
      </linearGradient>
      <linearGradient id='paint3_linear_675_50930' x1='107.94' y1='37.2601' x2='-0.650515' y2='15.0379' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#B4FB00' />
        <stop offset='0.12' stopColor='#ADFA00' />
        <stop offset='0.3' stopColor='#9AF800' />
        <stop offset='0.5' stopColor='#7AF500' />
        <stop offset='0.74' stopColor='#4FF000' />
        <stop offset='0.99' stopColor='#18EB00' />
        <stop offset='1' stopColor='#15EB00' />
      </linearGradient>
      <linearGradient id='paint4_linear_675_50930' x1='108.579' y1='34.15' x2='-0.0119314' y2='11.9328' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#B4FB00' />
        <stop offset='0.12' stopColor='#ADFA00' />
        <stop offset='0.3' stopColor='#9AF800' />
        <stop offset='0.5' stopColor='#7AF500' />
        <stop offset='0.74' stopColor='#4FF000' />
        <stop offset='0.99' stopColor='#18EB00' />
        <stop offset='1' stopColor='#15EB00' />
      </linearGradient>
      <linearGradient id='paint5_linear_675_50930' x1='109.232' y1='30.9561' x2='0.641604' y2='8.73386' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#B4FB00' />
        <stop offset='0.12' stopColor='#ADFA00' />
        <stop offset='0.3' stopColor='#9AF800' />
        <stop offset='0.5' stopColor='#7AF500' />
        <stop offset='0.74' stopColor='#4FF000' />
        <stop offset='0.99' stopColor='#18EB00' />
        <stop offset='1' stopColor='#15EB00' />
      </linearGradient>
      <linearGradient id='paint6_linear_675_50930' x1='111.213' y1='21.2791' x2='2.62207' y2='-0.943065' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#B4FB00' />
        <stop offset='0.12' stopColor='#ADFA00' />
        <stop offset='0.3' stopColor='#9AF800' />
        <stop offset='0.5' stopColor='#7AF500' />
        <stop offset='0.74' stopColor='#4FF000' />
        <stop offset='0.99' stopColor='#18EB00' />
        <stop offset='1' stopColor='#15EB00' />
      </linearGradient>
    </defs>
  </svg>);
export default IconAlfred;
