import { FC, useCallback, useMemo } from 'react';
import { I18nProvider } from '@react-aria/i18n';
import { twMerge } from 'tailwind-merge';
import { Button } from './Button';
import { IconCaretDown } from '../icons';
import { Calendar, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Spacer, useDisclosure } from '@nextui-org/react';
import ListTimePicker from './ListTimePicker';
import useDateTime from './useDateTime';
import { DateValue, isEqualDay, now, parseDate, parseTime, toTime, today } from '@internationalized/date';

const dates = [
  { label: 'today', value: 'Hoy' },
  { label: 'tomorrow', value: 'Mañana' },
  { label: 'otherDay', value: 'Otro día' }
];

export interface DateTimeProps {
  minTime: string
  maxTime: string
  timeIntervals?: number
  excludeTimes?: string[]
  excludeDates?: string[]
  requestServiceMinTime?: number
};

const currentDate = today('America/Bogota');
const tomorrow = currentDate.add({ days: 1 });

export const DateAndTime: FC<DateTimeProps> = ({
  minTime,
  maxTime,
  timeIntervals,
  excludeTimes,
  excludeDates,
  requestServiceMinTime,
}) => {
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const { setDate, selectedDate, selectedTime, scheduleLabel, setModalStatus } = useDateTime();

  const handleClick = useCallback((label: string) => {
    setModalStatus(true);
    onOpen();

    if (label === 'today') {
      setDate(currentDate);
    } else if (label === 'tomorrow') {
      setDate(tomorrow);
    }
  }, [onOpen, setDate, setModalStatus]);

  const disableDateButton = useMemo(() => (day: string) => {
    if (day === 'otherDay') return false;

    const date = day === 'today' ? currentDate : tomorrow;
    const isDayOff = excludeDates?.some((excludeDate) => (
      isEqualDay(date, parseDate(excludeDate))));

    const currentTime = toTime(now('America/Bogota'));

    if (isEqualDay(date, today('America/Bogota'))) {
      return parseTime(maxTime).compare(currentTime) < 0 || !!isDayOff;
    }
    return !!isDayOff;
  }, [excludeDates, maxTime]);

  const isDateUnavailable = useMemo(() => (date: DateValue) => {
    const isDayOff = excludeDates?.some((excludeDate) => (
      isEqualDay(date, parseDate(excludeDate))));

    const currentTime = toTime(now('America/Bogota'));

    if (isEqualDay(date, today('America/Bogota'))) {
      return parseTime(maxTime).compare(currentTime) < 0 || !!isDayOff;
    }
    return !!isDayOff;
  }, [excludeDates, maxTime]);

  const isSelected = useMemo(() => (date: string) => {
    let selected = false;
    if (date === 'today' && selectedDate) {
      selected = isEqualDay(currentDate, selectedDate);
    } else if (date === 'tomorrow' && selectedDate) {
      selected = isEqualDay(tomorrow, selectedDate);
    } else {
      selected = selectedDate !== undefined && !isEqualDay(currentDate, selectedDate) && !isEqualDay(tomorrow, selectedDate);
    }
    return selected;
  }, [selectedDate]);

  return (
    <>
      <div className='flex flex-col gap-5'>
        <span className='text-base font-bold'>¿Cuando?</span>
        <div className='flex gap-6'>
          {
            dates.map((date) => (
              <Button
                key={date.label}
                onPress={() => handleClick(date.label)}
                variant='bordered'
                color='GradientAlfredBlueOp'
                radius='sm'
                endContent={<IconCaretDown />}
                isDisabled={disableDateButton(date.label)}
                className={twMerge(isSelected(date.label) ? 'border-primary text-primary' : '')}
              >
                {date.value}
              </Button>
            ))
          }
        </div>
      </div>
      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        isDismissable={false}
        isKeyboardDismissDisabled
        size='3xl'
        classNames={{
          base: ['bg-frosted-gradient backdrop-blur frosty text-white rounded-lg border-1 border-complementary-gray-light'],
          closeButton: ['bg-dark-dark hover:bg-dark-main p-1 m-1 [&>svg]:h-5 [&>svg]:w-5'],
        }}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className='flex flex-col gap-1'>
                Selecciona fecha y hora para tu servicio
              </ModalHeader>
              <ModalBody>
                <span className='text-base font-normal'>Escoge el día y la hora para la cual deseas solicitar tu servicio.</span>
                <Spacer y={5} />
                <div className='flex flex-row justify-between'>
                  <I18nProvider locale='es-419'>
                    <Calendar
                      aria-label='Date and time'
                      classNames={{
                        base: ['w-[376px] h-[280px] bg-secondary-op-gradient-hover border-1 border-complementary-gray-light'],
                        content: ['w-full h-full'],
                        headerWrapper: ['bg-transparent'],
                        header: ['justify-start'],
                        title: ['text-white text-2xl'],
                        prevButton: ['text-white order-2'],
                        nextButton: ['text-white order-3'],
                        gridWrapper: [' h-[inherit] pt-4'],
                        gridHeader: ['bg-transparent shadow-none'],
                        gridBodyRow: ['gap-x-6'],
                        gridHeaderRow: ['gap-x-4'],
                        gridHeaderCell: ['text-white text-base'],
                        cellButton: ['text-sm w-6 h-6 data-[disabled=true]:text-dark-main data-[unavailable=true]:!text-dark-main data-[selected=true]:bg-secondary data-[selected=true]:text-white data-[hover=true]:bg-secondary data-[hover=true]:text-white data-[selected=true]:data-[hover=true]:bg-secondary data-[selected=true]:data-[hover=true]:text-white data-[selected=true]:drop-shadow-lg data-[outside-month=true]:!text-transparent'],
                      }}
                      minValue={today('America/Bogota')}
                      maxValue={today('America/Bogota').add({ months: 2 })}
                      isDateUnavailable={(date) => isDateUnavailable(date)}
                      value={selectedDate}
                      onChange={setDate}
                    />
                  </I18nProvider>
                  {
                    selectedDate &&
                      <ListTimePicker
                        maxTime={maxTime}
                        minTime={minTime}
                        excludeTimes={excludeTimes}
                        requestServiceMinTime={requestServiceMinTime}
                        timeIntervals={timeIntervals}
                      />
                  }
                </div>
                <Spacer y={6} />
                <span className='text-base font-bold'>
                  {`Fecha seleccionada: ${scheduleLabel}`}
                </span>
              </ModalBody>
              <ModalFooter className='flex justify-end'>
                <Button
                  color='GradientAlfredGreen'
                  onPress={() => {
                    setModalStatus(false);
                    onClose();
                  }}
                  size='xl'
                  className='w-[262px]'
                  isDisabled={!selectedDate || !selectedTime}
                >
                  Aceptar
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

export default DateAndTime;
