
export const IconPaint = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='18'
    height='19'
    viewBox='0 0 18 19'
    fill='none'
    {...props}
  >
    <path d='M8.19375 17.1641C7.89375 17.1641 7.63125 17.0547 7.40625 16.8359C7.18125 16.6172 7.06875 16.3516 7.06875 16.0391V12.2891H4.125C3.825 12.2891 3.5625 12.1766 3.3375 11.9516C3.1125 11.7266 3 11.4641 3 11.1641V5.48281C3 4.79531 3.24688 4.19531 3.74063 3.68281C4.23438 3.17031 4.83125 2.91406 5.53125 2.91406H15V11.1641C15 11.4641 14.8875 11.7266 14.6625 11.9516C14.4375 12.1766 14.175 12.2891 13.875 12.2891H10.9312V16.0391C10.9312 16.3516 10.8187 16.6172 10.5938 16.8359C10.3688 17.0547 10.1062 17.1641 9.80625 17.1641H8.19375ZM4.125 8.27656H13.875V4.03906H12.825V7.24531H11.7V4.03906H10.3687V5.63281H9.24375V4.03906H5.53125C5.13125 4.03906 4.79687 4.18281 4.52812 4.47031C4.25937 4.75781 4.125 5.09531 4.125 5.48281V8.27656ZM4.125 11.1641H13.875V9.40156H4.125V11.1641ZM4.125 9.40156V11.1641V9.40156Z' fill='#FCFCFC' />
  </svg>
);

export default IconPaint;
