import { FC, useEffect, useState } from 'react';
import { Button } from './Button';
import { IconArrowLeft, IconFilter, IconSrSearch, IconX } from '../icons';
import { Badge, Checkbox, Dropdown, DropdownItem, DropdownMenu, DropdownTrigger, SharedSelection, Popover, PopoverTrigger, PopoverContent, RangeValue, DateValue, RangeCalendar } from '@nextui-org/react';
import { FilterSchemaProperties, SchemaDate } from '../types';
import { parseDate } from '@internationalized/date';
import InputDebounce from './InputDebounce';

export interface FilterQuery {
  id: string
  value: string
}

interface CustomFiltersProps {
  filters: FilterSchemaProperties[]
  onFilterChange: (filter: FilterSchemaProperties[], filterQuery: FilterQuery[], filterRemove?: string) => void
}

export const CustomFilters: FC<CustomFiltersProps> = ({ filters, onFilterChange }) => {
  const [subFilters, setSubFilters] = useState<string[]>([]);
  const [lastCreated, setLastCreated] = useState('');

  const onMainSelection = (keys: SharedSelection) => {
    setSubFilters((prev) => [...prev, keys?.currentKey ?? '']);
    setLastCreated(keys?.currentKey ?? '');
  };

  const onSelectValue = (keys: SharedSelection, filter: string, multiple: boolean) => {
    const currentFilter = filters.find((item) => item.label === filter);
    if (!currentFilter) return;
    currentFilter.value = multiple ? Array.from(keys).map((key) => key as string) : keys?.currentKey ?? '';
    onFilterChange(
      filters.map((item) => {
        if (item.label === filter) return currentFilter;
        return item;
      }),
      filters.map((item) => ({
        id: item.id,
        value: item.multiple && item.value ? (item.value as string[]).join(',') : item.value as string,
      })).filter((item) => !!item.value)
    );
  };

  const onSelectDate = (value: RangeValue<DateValue>, filter: string) => {
    const currentFilter = filters.find((item) => item.label === filter);
    if (!currentFilter) return;
    currentFilter.value = {
      start: value.start?.toString() ?? '',
      end: value.end?.toString() ?? '',
    };
    onFilterChange(
      filters.map((item) => {
        if (item.label === filter) return currentFilter;
        return item;
      }),
      filters.map((item) => ({
        id: item.id,
        value: item.value as string,
      })).filter((item) => !!item.value)
    );
  };

  const onInputChange = (value: string, filter: string) => {
    const currentFilter = filters.find((item) => item.label === filter);
    if (!currentFilter) return;
    currentFilter.value = value;
    onFilterChange(
      filters.map((item) => {
        if (item.label === filter) return currentFilter;
        return item;
      }),
      filters.map((item) => ({
        id: item.id,
        value: item.value as string,
      })).filter((item) => !!item.value)
    );
  };

  const removeFilter = (filter: string) => {
    setSubFilters((prev) => prev.filter((item) => item !== filter));
    onFilterChange(
      filters.map((item) => {
        if (item.label === filter) {
          item.value = '';
        }
        return item;
      }),
      filters.map((item) => {
        if (item.label === filter) {
          return { id: item.id, value: '' };
        }
        return { id: item.id, value: item.value as string };
      }).filter((item) => !!item.value),
      filters.find((item) => item.label === filter)?.id
    );
  };

  useEffect(() => {
    if (filters.some((item) => item.value)) {
      setSubFilters(filters.filter((item) => item.value).map((item) => item.label));
    }
  }, [filters]);

  const itemsFiltered = filters.filter((item) => !subFilters?.some((sub) => sub === item.label));

  return (
    <div className='flex flex-wrap gap-3'>
      <Dropdown
        radius='sm'
        classNames={{
          content: 'bg-frosted-dark-gradient min-w-[300px] frosty backdrop-blur p-0',
        }}
        placement='bottom-start'
      >
        <DropdownTrigger>
          <Button
            onClick={() => {}}
            color='GradientAlfredBlue'
            startContent={<IconFilter />}
          >
            Filtros
          </Button>
        </DropdownTrigger>
        <DropdownMenu
          aria-label='Custom item styles'
          className='p-3 w-full max-w-full '
          selectionMode='single'
          onSelectionChange={onMainSelection}
          itemClasses={{
            wrapper: ['p-0'],
            description: ['p-0'],
            base: [
              'rounded-md',
              'data-[hover=true]:bg-[#000B2533]',
              'data-[selectable=true]:focus:bg-[#000B2533]'
            ],
          }}
        >
          {
            itemsFiltered.filter((i) => i.type !== 'hidden').map((item, i) => (
              <DropdownItem
                textValue={item.label}
                key={item.label}
                hideSelectedIcon
                className='flex items-start rounded-[5px] mb-1 py-3 px-4 text-white font-medium'
              >
                {item.label}
              </DropdownItem>
            ))
          }
        </DropdownMenu>
      </Dropdown>

      {
        subFilters.map((subFilter) => {
          const currentSubFilter = filters.find((item) => item.label === subFilter);
          if (!currentSubFilter) return null;

          const setKeys = currentSubFilter.value
            ? new Set([currentSubFilter.value].flat())
            : undefined;

          // DATE FILTER
          if (currentSubFilter.type === 'date') {
            const valueDates = currentSubFilter?.value
              ? (
                  {
                    start: parseDate((currentSubFilter.value as SchemaDate)?.start ?? ''),
                    end: parseDate((currentSubFilter.value as SchemaDate)?.end ?? ''),
                  }
                )
              : undefined;
            return (
              <Popover
                key={subFilter}
                radius='sm'
                classNames={{
                  content: 'bg-frosted-dark-gradient min-w-[300px] frosty backdrop-blur p-0 py-5',
                }}
                placement='bottom-start'
                isOpen={lastCreated === subFilter}
                onOpenChange={(x) => setLastCreated(x ? subFilter : '')}
              >
                <Badge
                  content={<IconX height={10} width={10} />}
                  color='danger'
                  className='h-5 w-5 right-1 cursor-pointer before:rounded-full before:content-[""] before:absolute before:top-0 before:left-0 before:w-full before:h-full border-none before:bg-error/40 bg-complementary-gray-main text-[#E11900]'
                  onClick={() => removeFilter(subFilter)}
                >
                  <PopoverTrigger>
                    <Button
                      color='GrayMain'
                      variant='bordered'
                      endContent={<IconArrowLeft className='-rotate-90' width={20} height={20} />}
                    >
                      {subFilter}
                      {currentSubFilter.value && currentSubFilter.value !== '' && ': '}
                      {
                        (typeof currentSubFilter.value === 'object' && 'start' in currentSubFilter.value ? currentSubFilter.value.start : '') + ' - ' + (typeof currentSubFilter.value === 'object' && 'end' in currentSubFilter.value ? currentSubFilter.value.end : '')
                      }
                    </Button>
                  </PopoverTrigger>
                </Badge>
                <PopoverContent
                  aria-label='Custom item styles'
                  className='p-3 w-full max-w-full rounded-md flex flex-col gap-5 '
                >
                  <RangeCalendar
                    color='secondary'
                    className='bg-frosted-dark-gradient border-complementary-gray-dark text-red-500'
                    hideDisabledDates
                    value={valueDates}
                    onChange={(d) => onSelectDate(d, subFilter)}
                    classNames={{
                      title: 'text-secondary',
                      gridHeaderCell: 'text-secondary',
                      nextButton: 'text-secondary',
                      prevButton: 'text-secondary',
                    }}
                  />
                </PopoverContent>
              </Popover>
            );
          }

          // INPUT FILTER
          if (currentSubFilter.type === 'input') {
            return (
              <Popover
                key={subFilter}
                radius='sm'
                classNames={{
                  content: 'bg-frosted-dark-gradient min-w-[300px] frosty backdrop-blur p-0 py-5',
                }}
                placement='bottom-start'
                isOpen={lastCreated === subFilter}
                onOpenChange={(x) => setLastCreated(x ? subFilter : '')}
              >
                <Badge
                  content={<IconX height={10} width={10} />}
                  color='danger'
                  className='h-5 w-5 right-1 cursor-pointer before:rounded-full before:content-[""] before:absolute before:top-0 before:left-0 before:w-full before:h-full border-none before:bg-error/40 bg-complementary-gray-main text-[#E11900]'
                  onClick={() => removeFilter(subFilter)}
                >
                  <PopoverTrigger>
                    <Button
                      color='GrayMain'
                      variant='bordered'
                      endContent={<IconArrowLeft className='-rotate-90' width={20} height={20} />}
                    >
                      {subFilter}
                      {currentSubFilter.value && currentSubFilter.value !== '' && ': '}
                      {currentSubFilter.value as string}
                    </Button>
                  </PopoverTrigger>
                </Badge>
                <PopoverContent
                  aria-label='Custom item styles'
                  className='p-3 w-full max-w-full rounded-md flex flex-col gap-5 '
                >
                  <InputDebounce
                    placeholder='Buscar responsable'
                    startContent={
                      <IconSrSearch />
                    }
                    debouncedValue={(v) => onInputChange(v, subFilter)}
                  />
                </PopoverContent>
              </Popover>
            );
          }

          // HIDDEN FILTER
          if (currentSubFilter.type === 'hidden') {
            return null;
          }

          // ENUM FILTER
          if (!currentSubFilter.enum) return null;
          const labelsSelected = typeof currentSubFilter.value === 'string'
            ? [currentSubFilter.enum.find((enumItem) => enumItem.value === currentSubFilter.value)?.label]
            : Array.isArray(currentSubFilter.value)
              ? currentSubFilter.value.map((item) => currentSubFilter.enum?.find((enumItem) => enumItem.value === item)?.label)
              : [];

          return (
            <Dropdown
              key={subFilter}
              radius='sm'
              className=''
              classNames={{
                content: 'bg-frosted-dark-gradient min-w-[300px] frosty backdrop-blur p-0',
              }}
              placement='bottom-start'
              isOpen={lastCreated === subFilter}
              onOpenChange={(x) => setLastCreated(x ? subFilter : '')}
            >
              <Badge
                content={<IconX height={10} width={10} />}
                color='danger'
                className='h-5 w-5 right-1 cursor-pointer before:rounded-full before:content-[""] before:absolute before:top-0 before:left-0 before:w-full before:h-full border-none before:bg-error/40 bg-complementary-gray-main text-[#E11900]'
                onClick={() => removeFilter(subFilter)}
              >
                <DropdownTrigger>
                  <Button
                    color='GrayMain'
                    variant='bordered'
                    endContent={<IconArrowLeft className='-rotate-90' width={20} height={20} />}
                  >
                    {subFilter}
                    {currentSubFilter.value && currentSubFilter.value !== '' && ': '}
                    {
                      (labelsSelected?.join(', ') ?? '').slice(0, 10) + ((labelsSelected?.join(', ')?.length ?? 0) > 10 ? ' ...' : '')
                    }
                  </Button>
                </DropdownTrigger>
              </Badge>
              <DropdownMenu
                aria-label='Custom item styles'
                className='p-3 w-full max-w-full max-h-[50dvh] overflow-y-auto'
                selectionMode={currentSubFilter.multiple ? 'multiple' : 'single'}
                closeOnSelect={!currentSubFilter.multiple}
                disallowEmptySelection
                onSelectionChange={(k) => onSelectValue(k, subFilter, !!currentSubFilter?.multiple)}
                selectedKeys={setKeys ? new Set(Array.from(setKeys).filter((key): key is string => typeof key === 'string')) : new Set()}
                itemClasses={{
                  wrapper: ['p-0'],
                  description: ['p-0'],
                  base: [
                    'rounded-md flex flex-row-reverse gap-5',
                    'data-[hover=true]:bg-[#000B2533]',
                    'data-[selectable=true]:focus:bg-[#000B2533]',
                    'data-[selected=true]:bg-complementary-gray-dark'
                  ],
                }}
                topContent={
                  currentSubFilter.selectAll && (
                    <Button
                      className='overflow-visible min-h-8'
                      color='GradientAlfredBlue'
                      onPress={() => onSelectValue(
                        new Set(currentSubFilter.enum?.map((item) => item.value) ?? []), subFilter, !!currentSubFilter?.multiple
                      )}
                    >
                      Seleccionar Todos
                    </Button>
                  )
                }
              >
                {
                  currentSubFilter.enum.map((item, i) => (
                    <DropdownItem
                      textValue={item.label}
                      key={item.value}
                      className='flex items-start rounded-[5px] mb-1 py-3 px-4 text-white font-medium'
                      title={item.label}
                      hideSelectedIcon={!currentSubFilter.multiple}
                      classNames={{

                      }}
                      selectedIcon={
                        <Checkbox
                          color='secondary'
                          isSelected={setKeys?.has(item.value)}
                          classNames={{ wrapper: 'before:border-1 before:border-complementary-gray-dark' }}
                        />
                      }
                    />
                  ))
                }
              </DropdownMenu>
            </Dropdown>
          );
        })
      }

    </div>
  );
};

export default CustomFilters;
