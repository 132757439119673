
export const IconEvent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    {...props}
  >
    <path d='M7.21667 12.1997L4.91667 9.91634L5.61667 9.21634L7.21667 10.783L10.2833 7.71634L10.9833 8.43301L7.21667 12.1997ZM3 14.6663C2.73333 14.6663 2.5 14.5663 2.3 14.3663C2.1 14.1663 2 13.933 2 13.6663V3.33301C2 3.06634 2.1 2.83301 2.3 2.63301C2.5 2.43301 2.73333 2.33301 3 2.33301H4.08333V1.33301H5.16667V2.33301H10.8333V1.33301H11.9167V2.33301H13C13.2667 2.33301 13.5 2.43301 13.7 2.63301C13.9 2.83301 14 3.06634 14 3.33301V13.6663C14 13.933 13.9 14.1663 13.7 14.3663C13.5 14.5663 13.2667 14.6663 13 14.6663H3ZM3 13.6663H13V6.49967H3V13.6663ZM3 5.49967H13V3.33301H3V5.49967ZM3 5.49967V3.33301V5.49967Z' fill='#FCFCFC' />
  </svg>
);

export default IconEvent;
