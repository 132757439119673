
export const IconSoat = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='200'
    height='201'
    viewBox='0 0 200 201'
    fill='none'
    {...props}
  >
    <circle cx='100' cy='100.123' r='100' fill='url(#paint0_radial_1517_17526)' />
    <path d='M32.6152 101.609C34.4195 101.512 36.2243 101.42 38.0283 101.315C39.2483 101.244 40.47 101.183 41.6852 101.06C42.2768 101.001 42.3959 101.304 42.5636 101.766C42.9346 102.786 43.2298 103.892 43.8476 104.755C46.1601 107.985 50.8047 106.905 52.4664 104.554C53.247 103.45 53.176 102.221 52.1659 101.318C51.5419 100.76 50.798 100.233 50.0141 99.974C47.4204 99.1166 44.7615 98.4538 42.1725 97.5832C40.6089 97.0574 39.0498 96.42 37.6294 95.5927C35.4139 94.3026 34.3902 92.1682 33.9842 89.6918C33.1475 84.5873 36.4804 81.5279 40.3336 80.1108C42.6078 79.2746 44.9586 78.9285 47.3768 78.9117C50.0566 78.893 52.7094 79.028 55.2508 79.9785C59.1088 81.4216 61.1163 84.2675 61.5529 88.2918C61.563 88.3852 61.5543 88.4808 61.5543 88.6312C59.9712 88.738 58.4166 88.8543 56.8603 88.9446C55.6392 89.0153 54.413 89.0142 53.1958 89.1215C52.5196 89.1811 52.2064 88.8976 51.881 88.3547C51.3834 87.525 50.8576 86.6703 50.1707 86.0033C48.5646 84.4439 46.0279 84.4249 44.3436 85.8387C43.0171 86.9521 43.0352 88.0486 44.414 89.0874C45.7506 90.0944 47.36 90.3538 48.9401 90.6622C51.6585 91.1928 54.3166 91.914 56.8513 93.0409C60.0878 94.4801 62.1579 96.9212 62.6407 100.425C63.2124 104.575 61.7506 107.972 58.1968 110.324C55.8952 111.847 53.285 112.582 50.5741 112.982C49.6859 113.113 48.7933 113.215 47.9026 113.33H46.5798C46.2974 113.27 46.0167 113.201 45.7324 113.152C44.2731 112.896 42.768 112.793 41.3618 112.366C37.0351 111.05 34.0874 108.319 33.0343 103.803C32.8758 103.125 32.7539 102.438 32.6152 101.755C32.6152 101.706 32.6152 101.658 32.6152 101.609Z' fill='white' />
    <path d='M83.6158 113.24C81.3159 113.137 79.0721 112.854 76.8983 112.116C72.7105 110.695 69.7198 107.956 68.1375 103.847C66.4397 99.439 66.1272 94.8819 67.3226 90.2898C68.6362 85.2438 71.784 81.7557 76.7342 80.0812C81.5071 78.4667 86.3829 78.2758 91.188 79.9766C96.6707 81.9173 99.7271 85.953 100.754 91.5722C101.446 95.3613 101.436 99.1457 100.171 102.831C98.2527 108.421 94.2639 111.644 88.4843 112.683C86.8799 112.971 85.2395 113.06 83.6155 113.24H83.6158ZM91.1798 95.5813C90.9981 94.6675 90.8041 93.1134 90.3661 91.6312C89.8212 89.7886 88.772 88.2342 87.0513 87.2264C84.1802 85.5449 78.983 86.6712 77.5488 91.0972C76.794 93.4268 76.7613 95.8069 77.0473 98.1843C77.1963 99.4233 77.5256 100.684 78.019 101.829C79.0336 104.185 80.9217 105.506 83.509 105.563C86.0771 105.62 88.2615 104.791 89.611 102.398C90.6965 100.472 91.0462 98.3808 91.1798 95.581V95.5813Z' fill='white' />
    <path d='M151.379 79.5207C156.228 79.5207 161.077 79.5332 165.925 79.5067C166.626 79.5028 166.859 79.7038 166.852 80.4125C166.833 82.5354 166.867 84.6592 166.913 86.7819C166.925 87.3257 166.755 87.497 166.206 87.4962C163.316 87.4909 160.426 87.5172 157.537 87.5423C156.354 87.5527 156.353 87.5639 156.352 88.7103C156.35 96.2777 156.349 103.845 156.347 111.413C156.347 112.417 156.345 112.423 155.351 112.408C152.585 112.367 149.819 112.301 147.052 112.287C146.427 112.283 146.281 112.062 146.281 111.475C146.281 105.47 146.26 99.465 146.233 93.46C146.226 91.703 146.16 89.946 146.165 88.189C146.167 87.6385 145.986 87.4778 145.447 87.4817C142.533 87.5021 139.618 87.4669 136.705 87.5074C135.971 87.5177 135.801 87.2762 135.824 86.5879C135.894 84.442 135.915 82.2942 135.923 80.1469C135.925 79.5332 136.172 79.3803 136.76 79.3828C141.633 79.4038 146.506 79.3949 151.379 79.3949V79.5209L151.379 79.5207Z' fill='white' />
    <path d='M134.177 112.417C130.049 112.572 126.585 112.153 123.31 110.631C119.499 108.859 116.843 106.124 115.93 101.923C115.311 99.0701 115.602 96.2438 116.333 93.4625C117.488 89.0703 119.443 85.0536 122.282 81.4901C122.796 80.846 123.404 80.2595 124.044 79.7392C124.697 79.2092 125.305 79.3453 125.791 80.0434C126.011 80.3598 126.183 80.7205 126.32 81.0828C128.23 86.1439 130.141 91.2045 132.029 96.274C133.891 101.275 135.727 106.286 137.573 111.292C137.607 111.383 137.635 111.477 137.662 111.57C137.898 112.387 137.886 112.411 137.064 112.415C135.913 112.422 134.762 112.417 134.177 112.417V112.417Z' fill='white' />
    <path d='M121.056 79.3975C119.813 81.0927 118.681 82.6482 117.538 84.1955C114.874 87.801 112.43 91.5334 110.824 95.7478C109.451 99.3532 109.098 103.082 109.562 106.892C109.782 108.699 110.078 110.497 110.347 112.355H102.095C102.159 112.117 102.196 111.912 102.269 111.719C106.264 101.164 110.264 90.6117 114.253 80.0547C114.432 79.58 114.666 79.3723 115.211 79.3832C117.094 79.4201 118.979 79.3975 121.056 79.3975Z' fill='white' />
    <defs>
      <radialGradient id='paint0_radial_1517_17526' cx='0' cy='0' r='1' gradientUnits='userSpaceOnUse' gradientTransform='translate(100.06 100.123) scale(209.003)'>
        <stop stopColor='#7E7E7E' />
        <stop offset='0.382315' stopColor='#2F2F2F' stopOpacity='0' />
      </radialGradient>
    </defs>
  </svg>
);

export default IconSoat;
