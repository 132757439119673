
export const IconScrewdriverWrench = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='79' height='80' viewBox='0 0 79 80' fill='none' {...props}>
    <circle cx='39.5' cy='40.0547' r='39.5' fill='url(#paint0_radial_4485_17623)' />
    <path d='M34.5287 45.0348C34.7212 44.4016 34.4418 43.7684 33.6659 43.3215C32.3064 42.5331 29.9289 42.6324 28.3584 43.5387C27.4583 44.0602 27.0176 44.7306 27.061 45.3576V64.39C27.061 64.4459 27.061 64.5017 27.061 64.5576C27.0921 65.0232 27.3962 65.4701 27.9735 65.8053C29.333 66.5937 31.7105 66.4944 33.281 65.5881C34.3177 64.9859 34.746 64.1851 34.5225 63.4837V45.041L34.5287 45.0348Z' fill='url(#paint1_linear_4485_17623)' />
    <path d='M27.0067 41.7186C27.0067 41.7186 26.839 44.2389 28.2109 44.6548C29.5828 45.0645 32.2086 45.2507 33.7667 44.0092C34.9213 43.0905 34.6792 41.7807 34.5364 40.7812L27.0067 41.7186Z' fill='url(#paint2_linear_4485_17623)' />
    <path d='M33.2322 42.7161C31.6617 43.6224 29.2842 43.7218 27.9247 42.9334C26.5652 42.145 26.7328 40.7732 28.3034 39.8669C29.8739 38.9606 32.2514 38.8612 33.6108 39.6496C34.9703 40.438 34.8027 41.8098 33.2322 42.7161Z' fill='#0172D9' />
    <g style={{ mixBlendMode: 'overlay' }} opacity='0.39'>
      <path d='M31.3078 39.755C30.3332 39.755 29.3586 39.9971 28.6262 40.4192C28.0116 40.773 27.6454 41.2137 27.6454 41.5986C27.6454 41.9835 28.0178 42.2442 28.2413 42.3746C28.7379 42.6601 29.4393 42.8215 30.2277 42.8215C31.2023 42.8215 32.1769 42.5794 32.9094 42.1573C33.5239 41.8035 33.8902 41.3627 33.8902 40.9779C33.8902 40.593 33.5177 40.3323 33.2943 40.2019C32.7976 39.9164 32.0962 39.755 31.3078 39.755ZM31.3078 39.1094C32.1707 39.1094 32.9963 39.2832 33.6108 39.637C34.9703 40.4254 34.8027 41.7973 33.2322 42.7036C32.3755 43.2002 31.2706 43.4547 30.2277 43.4547C29.3649 43.4547 28.5392 43.2809 27.9247 42.927C26.5652 42.1387 26.7328 40.7668 28.3034 39.8605C29.16 39.3639 30.265 39.1094 31.3078 39.1094Z' fill='white' />
    </g>
    <g style={{ mixBlendMode: 'overlay' }} opacity='0.2'>
      <path d='M28.9475 64.4169C28.6309 64.2369 28.3764 63.7465 28.3764 63.3244V46.6322C28.3764 46.2101 28.6309 46.0177 28.9475 46.2039C29.264 46.3839 29.5186 46.8743 29.5186 47.2964V63.9886C29.5186 64.4107 29.264 64.6032 28.9475 64.4169Z' fill='#1D1D1B' />
    </g>
    <g style={{ mixBlendMode: 'overlay' }} opacity='0.2'>
      <path d='M32.6291 64.4169C32.9457 64.2369 33.2002 63.7465 33.2002 63.3244V46.6322C33.2002 46.2101 32.9457 46.0177 32.6291 46.2039C32.3125 46.3839 32.058 46.8743 32.058 47.2964V63.9886C32.058 64.4107 32.3125 64.6032 32.6291 64.4169Z' fill='#1D1D1B' />
    </g>
    <path d='M30.8991 42.0272L31.3398 41.7789L31.1784 19.9282L30.2039 14L29.7693 14.2483L30.6322 38.3337L30.8991 42.0272Z' fill='url(#paint3_linear_4485_17623)' />
    <path d='M31.3359 41.7899L30.1627 41.1132V16.9844L31.3359 17.6672V41.7899Z' fill='url(#paint4_linear_4485_17623)' />
    <path d='M31.3856 14.6828L30.8082 14.3476L30.6903 14.2793L30.1999 14L29.0825 16.8431L30.5103 20.1269L30.6903 20.2262L30.8082 20.2945L30.9821 20.4L32.416 18.7674L31.3856 14.6828Z' fill='url(#paint5_linear_4485_17623)' />
    <path d='M30.8984 42.0337L29.7252 41.357V17.2344L30.8984 17.911V42.0337Z' fill='url(#paint6_linear_4485_17623)' />
    <path d='M30.9481 14.9328L30.3707 14.6038L30.2528 14.5355L29.7686 14.25L28.645 17.0931L30.0728 20.3769L30.2528 20.4824L30.3707 20.5507L30.5508 20.65L31.9785 19.0174L30.9481 14.9328Z' fill='url(#paint7_linear_4485_17623)' />
    <path d='M49.1642 16.7842C50.5349 18.4976 51 20.6332 51 22.7871C51 24.1884 50.6023 25.2409 49.9475 25.8345C49.9659 25.8345 49.9536 25.8345 49.9353 25.8467C49.9475 25.8467 49.9353 25.8467 49.9169 25.859C49.8557 25.9079 49.819 25.9385 49.7823 25.9691C49.4763 26.2812 48.895 27.2297 48.895 29.9894V52.7038C48.895 54.9496 49.2805 56.4365 49.5926 57.2748C49.7333 57.5441 49.8557 57.8256 49.972 58.1071C50.4309 59.233 50.7063 60.4446 50.7063 61.5827C50.7063 63.0452 50.2535 64.11 49.5314 64.6423C48.5034 65.346 48.4911 65.3522 48.485 65.3583C46.6064 66.6984 45.6151 66.7167 44.5259 66.0926C42.3536 64.8382 40.579 61.4542 40.579 58.5537C40.579 57.4156 40.8544 56.5222 41.3133 55.9286C41.4296 55.7818 41.5581 55.6471 41.6927 55.537C41.9987 55.0536 42.3842 54.0072 42.3842 51.7676V29.0532C42.3842 26.3975 41.8457 24.8004 41.5275 24.0905C41.4541 23.9498 41.3806 23.8029 41.3072 23.6622C40.6525 22.3037 40.2608 20.8045 40.2608 19.4032C40.2608 17.6532 40.5729 16.2702 41.4235 15.744C42.4209 15.0647 42.4331 15.0525 42.4454 15.0464C43.8344 14.104 44.067 14.0245 44.3301 14C44.6177 14.1652 44.8808 14.7037 44.8808 15.3523V19.2258L46.3739 21.2635V19.03C46.3739 18.62 46.4779 18.3752 46.6309 18.2895C47.6589 17.5858 47.6712 17.5797 47.6773 17.5736C48.9317 16.7169 49.0419 16.7169 49.1581 16.7781L49.1642 16.7842ZM46.8145 62.4088C46.8145 62.3293 46.8267 62.2497 46.8267 62.1641C46.8267 60.4691 45.7926 58.4926 44.5198 57.7583C44.5014 57.746 44.4831 57.7399 44.4586 57.7277C44.4586 57.8072 44.4464 57.8868 44.4464 57.9724C44.4464 59.6674 45.4805 61.6439 46.7533 62.3782C46.7716 62.3905 46.79 62.3966 46.8145 62.4088Z' fill='url(#paint8_linear_4485_17623)' />
    <path d='M47.7183 59.6366C48.1773 60.7625 48.4526 61.9741 48.4526 63.1123C48.4526 66.0128 46.6842 67.3529 44.5058 66.0924C42.3335 64.8379 40.5589 61.454 40.5589 58.5535C40.5589 57.4153 40.8343 56.5219 41.2932 55.9284C41.4095 55.7815 41.538 55.6469 41.6726 55.5368C41.9785 55.0533 42.3641 54.007 42.3641 51.7673V29.0529C42.3641 26.3972 41.8256 24.8001 41.5074 24.0903C41.4339 23.9495 41.3605 23.8027 41.2871 23.6619C40.6323 22.3035 40.2407 20.8043 40.2407 19.403C40.2407 17.249 40.7058 15.6519 42.0764 15.5234C42.364 15.6887 42.6272 16.2271 42.6272 16.8758V20.7492L44.4935 23.2948L46.3538 22.8971V19.0236C46.3538 18.375 46.6169 18.1363 46.9045 18.3015C48.2752 20.0149 48.7402 22.1505 48.7402 24.3045C48.7402 25.7058 48.3425 26.7583 47.6877 27.3518C47.7061 27.3518 47.6877 27.3518 47.6694 27.3702C47.6204 27.413 47.5715 27.4497 47.5225 27.4864C47.2166 27.7985 46.6352 28.747 46.6352 31.5067V54.2211C46.6352 56.4669 47.0207 57.9538 47.3328 58.7922C47.4736 59.0614 47.5959 59.3429 47.7122 59.6244L47.7183 59.6366ZM46.8127 62.1638C46.8127 60.4688 45.7786 58.4923 44.5058 57.758C43.233 57.0237 42.1988 57.807 42.1988 59.502C42.1988 61.197 43.233 63.1735 44.5058 63.9078C45.7786 64.6421 46.8127 63.8588 46.8127 62.1638Z' fill='url(#paint9_linear_4485_17623)' />
    <defs>
      <radialGradient id='paint0_radial_4485_17623' cx='0' cy='0' r='1' gradientUnits='userSpaceOnUse' gradientTransform='translate(39.5237 40.0547) scale(82.556)'>
        <stop stopColor='#7E7E7E' />
        <stop offset='0.382315' stopColor='#2F2F2F' stopOpacity='0' />
      </radialGradient>
      <linearGradient id='paint1_linear_4485_17623' x1='34.5874' y1='42.7891' x2='20.9254' y2='47.1573' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#0096FB' />
        <stop offset='1' stopColor='#0075C5' />
      </linearGradient>
      <linearGradient id='paint2_linear_4485_17623' x1='34.6602' y1='40.7812' x2='31.1419' y2='47.225' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#0096FB' />
        <stop offset='1' stopColor='#0075C5' />
      </linearGradient>
      <linearGradient id='paint3_linear_4485_17623' x1='29.7693' y1='28.0167' x2='31.3398' y2='28.0167' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#CFD5F7' />
        <stop offset='1' stopColor='#BCC2E6' />
      </linearGradient>
      <linearGradient id='paint4_linear_4485_17623' x1='30.1627' y1='29.3871' x2='31.3359' y2='29.3871' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#CFD5F7' />
        <stop offset='1' stopColor='#BCC2E6' />
      </linearGradient>
      <linearGradient id='paint5_linear_4485_17623' x1='29.0825' y1='17.1969' x2='32.416' y2='17.1969' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#CFD5F7' />
        <stop offset='1' stopColor='#BCC2E6' />
      </linearGradient>
      <linearGradient id='paint6_linear_4485_17623' x1='30.3149' y1='18.7304' x2='30.3149' y2='56.4352' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#D0D4F9' />
        <stop offset='1' stopColor='#636C9E' />
      </linearGradient>
      <linearGradient id='paint7_linear_4485_17623' x1='30.3149' y1='18.7257' x2='30.3149' y2='56.4305' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#D0D4F9' />
        <stop offset='1' stopColor='#636C9E' />
      </linearGradient>
      <linearGradient id='paint8_linear_4485_17623' x1='40.2608' y1='40.2452' x2='51' y2='40.2452' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#CFD5F7' />
        <stop offset='1' stopColor='#BCC2E6' />
      </linearGradient>
      <linearGradient id='paint9_linear_4485_17623' x1='33.3627' y1='41.2852' x2='64.13' y2='40.606' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#D0D4F9' />
        <stop offset='1' stopColor='#636C9E' />
      </linearGradient>
    </defs>
  </svg>
);

export default IconScrewdriverWrench;
