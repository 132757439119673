
export const IconFindFile = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='173' height='213' viewBox='0 0 173 213' fill='none' {...props}>
    <g id='Group'>
      <g id='_x30_'>
        <g id='Group_2'>
          <g id='Group_3'>
            <g id='Group_4'>
              <path id='Vector' d='M70.3174 58.2672L71.9414 57.3235C71.3927 57.0163 70.9099 56.9724 70.5368 57.1919L68.9128 58.1356C69.2639 57.938 69.7687 57.96 70.3174 58.2672Z' fill='#FFDDA6' />
            </g>
            <path id='Vector_2' d='M95.6875 72.94L97.3115 71.9963L71.9412 57.3359L70.3171 58.2796L95.6875 72.94Z' fill='#FFCEA6' />
            <path id='Vector_3' d='M102.557 83.8559L104.181 82.9121L98.7159 73.4531L97.0919 74.3968L102.557 83.8559Z' fill='#0096FB' />
            <g id='Group_5'>
              <path id='Vector_4' d='M97.0913 74.4048L98.7153 73.4611C98.3422 72.8247 97.8594 72.276 97.3107 71.9688L95.6867 72.9125C96.2134 73.2197 96.7182 73.7684 97.0913 74.4048Z' fill='#FFDDA6' />
            </g>
            <g id='Group_6'>
              <path id='Vector_5' d='M103.939 85.3282L105.563 84.3845C105.036 84.0772 104.531 83.5505 104.158 82.9141L102.534 83.8578C102.907 84.4942 103.39 85.0209 103.939 85.3282Z' fill='#40B2FF' />
            </g>
            <path id='Vector_6' d='M140.919 106.688L142.543 105.745L105.563 84.3906L103.939 85.3343L140.919 106.688Z' fill='#0096FB' />
            <g id='Group_7'>
              <path id='Vector_7' d='M142.324 106.84L143.948 105.896C143.575 106.115 143.07 106.049 142.544 105.742L140.92 106.686C141.424 106.993 141.951 107.037 142.324 106.84Z' fill='#40B2FF' />
            </g>
            <g id='Group_8'>
              <path id='Vector_8' d='M145.133 106.17L146.757 105.226C146.231 104.919 145.726 104.875 145.353 105.073L143.729 106.017C144.102 105.797 144.607 105.863 145.133 106.17Z' fill='#40B2FF' />
            </g>
            <path id='Vector_9' d='M170.745 202.855L170.964 123.342L172.588 122.398L172.369 201.911C172.369 202.526 172.149 202.965 171.798 203.184L170.174 204.128C170.525 203.93 170.745 203.491 170.745 202.855Z' fill='#0075C5' />
            <path id='Vector_10' d='M168.989 119.945L170.613 119.001L146.757 105.219L145.133 106.162L168.989 119.945Z' fill='#0096FB' />
            <path id='Vector_11' d='M70.3164 58.2827C69.2191 57.6463 68.3412 58.151 68.3412 59.4239L68.0998 143.611C68.0998 144.862 68.9777 146.399 70.075 147.013L168.769 203.987C169.867 204.623 170.744 204.118 170.744 202.867L170.964 123.355C170.964 122.104 170.086 120.567 168.989 119.953L145.111 106.17C144.584 105.863 144.079 105.819 143.706 106.017L142.302 106.829C141.928 107.048 141.424 106.982 140.897 106.675L103.917 85.321C103.39 85.0138 102.885 84.487 102.512 83.8506L97.0475 74.3916C96.6744 73.7551 96.1916 73.2065 95.6429 72.8992L70.2725 58.2608L70.3164 58.2827Z' fill='#0096FB' />
            <g id='Group_9'>
              <path id='Vector_12' d='M170.964 123.33L172.588 122.386C172.588 121.135 171.711 119.599 170.613 118.984L168.989 119.928C170.086 120.565 170.964 122.079 170.964 123.33Z' fill='#40B2FF' />
            </g>
          </g>
          <path id='Vector_13' d='M83.0464 74.9688L164.491 122L170.921 133.084L170.723 202.874C170.723 204.125 169.824 204.63 168.748 203.993L91.9129 159.639L82.8928 151.738L83.0245 74.9688H83.0464Z' fill='url(#paint0_linear_4355_35319)' />
          <g id='Group_10'>
            <g id='Group_11'>
              <g id='Group_12'>
                <path id='Vector_14' d='M158.097 116.541L164.858 128.172L163.549 128.933L156.788 117.302L158.097 116.541Z' fill='#CABCBB' />
                <path id='Vector_15' d='M156.764 117.276L158.081 116.508L76.615 69.4766L75.3202 70.2447L156.764 117.276Z' fill='#FFF8F8' />
                <path id='Vector_16' d='M75.3202 70.2422L75.1666 147.012L163.436 197.972L163.524 128.906L156.764 117.274L75.3202 70.2422Z' fill='#EADCDB' />
                <path id='Vector_17' d='M78.6123 75.1172L159.354 121.732L163.524 128.908L163.436 197.975L78.4806 148.924L78.6123 75.1172Z' fill='#CABCBB' />
              </g>
              <path id='Vector_18' d='M163.48 152.766L163.524 128.91L164.841 128.164L164.753 197.208L163.436 197.976L163.48 152.766Z' fill='url(#paint1_linear_4355_35319)' />
            </g>
            <g id='Group_13'>
              <g id='Group_14'>
                <path id='Vector_19' d='M154.638 118.532L161.397 130.164L160.088 130.925L153.328 119.293L154.638 118.532Z' fill='#CABCBB' />
                <path id='Vector_20' d='M153.318 119.269L154.635 118.5L73.1687 71.4688L71.8739 72.2369L153.318 119.269Z' fill='#FFF8F8' />
                <path id='Vector_21' d='M71.874 72.2344L71.7203 149.004L159.99 199.964L160.078 130.898L153.318 119.266L71.874 72.2344Z' fill='#EADCDB' />
              </g>
              <path id='Vector_22' d='M160.035 154.749L160.079 130.893L161.396 130.125L161.308 199.191L159.991 199.959L160.035 154.749Z' fill='url(#paint2_linear_4355_35319)' />
            </g>
          </g>
          <g id='Group_15'>
            <g id='Group_16'>
              <path id='Vector_23' d='M64.3027 69.3844L65.9268 68.4407C65.3781 68.1335 64.8953 68.0896 64.5222 68.309L62.8981 69.2527C63.2493 69.0552 63.754 69.0772 64.3027 69.3844Z' fill='#FFDDA6' />
            </g>
            <path id='Vector_24' d='M164.753 206.33L164.972 129.78L166.596 128.836L166.377 205.386C166.377 206.022 166.157 206.461 165.806 206.659L164.182 207.603C164.533 207.405 164.753 206.944 164.753 206.33Z' fill='#0075C5' />
            <path id='Vector_25' d='M162.998 126.37L164.622 125.427L65.9274 68.4531L64.3033 69.3968L162.998 126.37Z' fill='#0096FB' />
            <g id='Group_17'>
              <path id='Vector_26' d='M164.951 129.789L166.575 128.846C166.575 127.595 165.697 126.058 164.6 125.422L162.975 126.366C164.073 127.002 164.951 128.538 164.951 129.789Z' fill='#40B2FF' />
            </g>
            <path id='Vector_27' d='M64.3023 69.3803C63.205 68.7439 62.3271 69.2706 62.3271 70.5216L62.1076 147.072C62.1076 148.344 62.9855 149.859 64.0828 150.495L162.777 207.469C163.874 208.105 164.752 207.6 164.752 206.328L164.972 129.778C164.972 128.527 164.094 126.99 162.996 126.354L64.3023 69.3803Z' fill='#40B2FF' />
          </g>
          <g id='Group_18'>
            <path id='Vector_28' d='M158.608 207.617L160.385 209.505L158.103 210.756L157.225 208.824L158.608 207.617Z' fill='#D3874F' />
            <g id='Group_19'>
              <g id='Group_20'>
                <path id='Vector_29' d='M60.5493 64.0797L62.1733 63.136C61.6247 62.8288 61.1418 62.7849 60.7688 63.0044L59.1447 63.9481C59.4958 63.7286 60.0006 63.7725 60.5493 64.0797Z' fill='#FFDDA6' />
              </g>
              <path id='Vector_30' d='M85.9199 78.7525L87.5439 77.8088L62.1736 63.1484L60.5495 64.0921L85.9199 78.7525Z' fill='#FFCEA6' />
              <path id='Vector_31' d='M92.7676 89.6605L94.4136 88.7168L88.9488 79.2578L87.3248 80.2235L92.7676 89.6605Z' fill='#0096FB' />
              <g id='Group_21'>
                <path id='Vector_32' d='M87.3237 80.2408L88.9478 79.2971C88.5747 78.6606 88.0918 78.1119 87.5432 77.8047L85.9191 78.7484C86.4458 79.0556 86.9506 79.6043 87.3237 80.2408Z' fill='#FFDDA6' />
              </g>
              <g id='Group_22'>
                <path id='Vector_33' d='M94.1718 91.1407L95.7959 90.197C95.2692 89.8897 94.7644 89.363 94.3913 88.7266L92.7673 89.6703C93.1403 90.3067 93.6451 90.8334 94.1718 91.1407Z' fill='#40B2FF' />
              </g>
              <path id='Vector_34' d='M131.152 112.493L132.776 111.549L95.7957 90.1953L94.1717 91.139L131.152 112.493Z' fill='#0096FB' />
              <g id='Group_23'>
                <path id='Vector_35' d='M132.557 112.636L134.181 111.693C133.808 111.912 133.303 111.846 132.776 111.539L131.152 112.483C131.657 112.79 132.184 112.834 132.557 112.636Z' fill='#40B2FF' />
              </g>
              <g id='Group_24'>
                <path id='Vector_36' d='M135.366 111.967L136.99 111.023C136.463 110.716 135.958 110.672 135.585 110.87L133.961 111.813C134.334 111.594 134.839 111.66 135.366 111.967Z' fill='#40B2FF' />
              </g>
              <path id='Vector_37' d='M160.977 208.667L161.197 129.155L162.821 128.211L162.601 207.724C162.601 208.338 162.382 208.777 162.031 208.997L160.407 209.94C160.758 209.743 160.977 209.304 160.977 208.667Z' fill='#0096FB' />
              <path id='Vector_38' d='M158.962 125.843L160.586 124.9L136.73 111.117L135.106 112.061L158.962 125.843Z' fill='#0096FB' />
              <path id='Vector_39' d='M60.5489 64.0796C59.4515 63.4431 58.5737 63.9479 58.5737 65.2208L58.3322 149.408C58.3322 150.659 59.2101 152.195 60.3074 152.81L159.002 209.784C160.099 210.42 160.977 209.915 160.977 208.664L161.196 129.151C161.196 127.901 160.318 126.364 159.221 125.75L135.343 111.967C134.816 111.66 134.312 111.616 133.939 111.814L132.534 112.626C132.161 112.845 131.656 112.779 131.129 112.472L94.1492 91.1179C93.6225 90.8106 93.1177 90.2839 92.7446 89.6475L87.2799 80.1885C86.9068 79.552 86.424 79.0033 85.8753 78.6961L60.505 64.0577L60.5489 64.0796Z' fill='#0096FB' />
              <g id='Group_25'>
                <path id='Vector_40' d='M161.197 129.181L162.821 128.238C162.821 126.987 161.943 125.45 160.846 124.836L159.222 125.78C160.319 126.416 161.197 127.93 161.197 129.181Z' fill='#40B2FF' />
              </g>
            </g>
            <path id='Vector_41' d='M73.3891 80.5766L154.833 127.608L161.154 138.494L160.956 208.701C160.956 209.952 160.056 210.457 158.981 209.821L103.895 178.02L73.2354 157.105L73.3671 80.5547L73.3891 80.5766Z' fill='#0075C5' />
            <g id='Group_26'>
              <g id='Group_27'>
                <g id='Group_28'>
                  <path id='Vector_42' d='M148.078 122.266L154.837 133.898L153.528 134.659L146.769 123.027L148.078 122.266Z' fill='#CABCBB' />
                  <path id='Vector_43' d='M146.779 123.042L148.096 122.274L66.6301 75.2422L65.3133 76.0103L146.779 123.042Z' fill='#FFF8F8' />
                  <path id='Vector_44' d='M65.313 76.0078L65.1813 152.558L153.451 203.496L153.539 134.671L146.779 123.04L65.313 76.0078Z' fill='#EADCDB' />
                  <path id='Vector_45' d='M68.6269 80.8828L149.369 127.498L153.539 134.674L153.451 203.499L68.4952 154.47L68.6269 80.8828Z' fill='url(#paint3_linear_4355_35319)' />
                </g>
                <path id='Vector_46' d='M153.495 158.532L153.539 134.676L154.855 133.93L154.746 202.754L153.451 203.501L153.495 158.532Z' fill='url(#paint4_linear_4355_35319)' />
              </g>
              <g id='Group_29'>
                <g id='Group_30'>
                  <path id='Vector_47' d='M144.651 124.283L151.412 135.914L150.103 136.675L143.342 125.044L144.651 124.283Z' fill='#CABCBB' />
                  <path id='Vector_48' d='M143.333 124.997L144.649 124.25L63.1834 77.2188L61.8666 77.9869L143.333 124.997Z' fill='#FFF8F8' />
                  <path id='Vector_49' d='M61.8667 77.9844L61.735 154.512L150.005 205.473L150.092 136.648L143.333 124.994L61.8667 77.9844Z' fill='#EADCDB' />
                </g>
                <path id='Vector_50' d='M150.048 160.499L150.092 136.643L151.409 135.875L151.299 204.7L150.005 205.468L150.048 160.499Z' fill='url(#paint5_linear_4355_35319)' />
              </g>
            </g>
            <g id='Group_31'>
              <g id='Group_32'>
                <path id='Vector_51' d='M36.9779 88.7648L38.5361 87.3163C37.8997 86.9652 37.4388 86.8993 37.1974 87.1188L35.6392 88.5673C35.8806 88.3478 36.3415 88.3917 36.9779 88.7648Z' fill='#FFDDA6' />
              </g>
              <path id='Vector_52' d='M156.961 211.066L138.175 148.738L139.733 147.289L158.519 209.618C158.651 210.057 158.607 210.364 158.431 210.517L156.873 211.966C157.049 211.812 157.093 211.505 156.961 211.066Z' fill='#0075C5' />
              <path id='Vector_53' d='M135.65 145.742L137.208 144.294L38.5363 87.3203L36.978 88.7688L135.65 145.742Z' fill='#0096FB' />
            </g>
            <path id='Vector_54' d='M138.197 148.73L139.755 147.282C139.448 146.272 138.307 144.933 137.232 144.297L135.673 145.745C136.771 146.382 137.89 147.721 138.197 148.73Z' fill='#40B2FF' />
            <path id='Vector_55' d='M157.312 210.808L162.601 207.758C162.601 208.372 162.382 208.811 162.03 209.031L156.763 212.081C157.027 211.774 157.224 211.247 157.312 210.83V210.808Z' fill='#0075C5' />
            <path id='Vector_56' d='M36.9784 88.7788C35.8811 88.1423 35.2446 88.4715 35.5519 89.4811L54.3382 151.81C54.6455 152.819 55.7867 154.158 56.8621 154.794L155.556 211.768C156.654 212.404 157.29 212.075 156.983 211.066L138.196 148.737C137.889 147.728 136.748 146.389 135.673 145.752L36.9784 88.7788Z' fill='url(#paint6_linear_4355_35319)' />
          </g>
          <path id='Vector_57' d='M165.674 201.316L170.964 198.266L171.556 203.357L164.95 207.176L165.674 201.316Z' fill='#0075C5' />
        </g>
        <g id='Group_33'>
          <path id='Vector_58' d='M35.5753 75.0161L41.6764 81.3367C43.6077 83.3338 45.8463 85.1115 48.3921 86.582C66.6518 97.2041 93.6462 88.8425 108.614 67.9712C121.475 50.0188 121.277 28.533 109.338 16.1331L103.237 9.8125C115.198 22.2124 115.373 43.6982 102.513 61.6505C87.5669 82.5218 60.5506 90.8616 42.291 80.2613C39.7451 78.7909 37.5066 77.0132 35.5753 75.0161Z' fill='url(#paint7_linear_4355_35319)' />
          <path id='Vector_59' d='M100.427 60.4579C86.4033 80.0343 61.1208 87.8473 44.0023 77.8835C26.9059 67.9417 24.382 43.954 38.384 24.3995C52.386 4.84499 77.7124 -2.96803 94.8089 6.97381C111.927 16.9157 114.429 40.9253 100.427 60.4579Z' fill='#5958C4' />
          <path id='Vector_60' d='M37.7254 72.9869L43.8265 79.3075C32.6337 67.6977 32.4362 47.5726 44.5069 30.7395C58.5089 11.185 83.8353 3.37202 100.932 13.3139C103.324 14.6965 105.409 16.3644 107.23 18.2299L101.129 11.9093C99.3077 10.0219 97.2228 8.37586 94.8306 6.99322C77.7341 -2.94862 52.4077 4.88634 38.4057 24.4189C26.335 41.252 26.5326 61.3771 37.7254 72.9869Z' fill='#3B3991' />
          <path id='Vector_61' d='M36.3216 23.2018C21.376 44.0731 24.0534 69.6629 42.3131 80.2851C60.5727 90.9072 87.5671 82.5456 102.535 61.6743C117.48 40.8249 114.803 15.2132 96.5433 4.59099C78.2837 -6.0312 51.2673 2.35243 36.3216 23.2018ZM100.428 60.4672C86.4039 80.0436 61.1214 87.8567 44.003 77.8929C26.9065 67.951 24.3826 43.9633 38.3846 24.4088C52.3866 4.85435 77.7131 -2.95866 94.8095 6.98318C111.928 16.925 114.43 40.9347 100.428 60.4672Z' fill='#FFF8F8' />
          <g id='Group_34'>
            <path id='Vector_62' d='M45.0556 83.2344C45.0556 83.2344 45.7579 83.4319 46.8991 84.0684C47.0747 84.1561 47.2503 84.2659 47.4478 84.3756C48.4134 84.9243 49.4888 85.5827 50.5423 86.263C52.1663 87.3165 53.7465 88.3699 54.7121 89.1161C55.3266 89.577 55.59 89.8403 55.5461 89.9062L15.8665 152.147C15.8665 152.147 15.647 151.818 15.0325 151.357C14.0888 150.611 12.5087 149.557 10.8627 148.504C9.80923 147.823 8.73385 147.165 7.7682 146.616C7.57068 146.507 7.3951 146.397 7.21953 146.309C6.0783 145.673 5.44184 145.387 5.376 145.475L45.0556 83.2344Z' fill='url(#paint8_linear_4355_35319)' />
            <path id='Vector_63' d='M46.8989 84.0703C47.0744 84.1581 47.25 84.2678 47.4475 84.3776C48.4132 84.9262 49.4886 85.5846 50.542 86.265L10.8624 148.506C9.80896 147.825 8.73359 147.167 7.76793 146.618C7.57041 146.509 7.39484 146.399 7.21926 146.311L46.8989 84.0703Z' fill='#FFF8F8' />
          </g>
          <g id='Group_35'>
            <path id='Vector_64' d='M32.9851 94.1997C33.0948 94.0461 34.1922 94.5289 36.1893 95.6482C36.4966 95.8237 36.8038 95.9993 37.155 96.1968C38.801 97.1405 40.6664 98.2818 42.488 99.4449C45.3411 101.267 48.0844 103.132 49.7304 104.405C50.7839 105.217 51.2447 105.678 51.1789 105.788L18.215 157.472C18.2808 157.362 17.82 156.901 16.7665 156.089C15.1205 154.816 12.3772 152.951 9.52413 151.129C7.70256 149.966 5.83709 148.825 4.19109 147.881C3.83994 147.684 3.53268 147.508 3.22543 147.333C1.22828 146.213 0.130951 145.73 0.0212173 145.884L32.9851 94.1997Z' fill='#0096FB' />
            <path id='Vector_65' d='M36.0796 95.5845C36.0796 95.5845 36.1455 95.6064 36.1894 95.6283C36.4966 95.8039 36.8039 95.9795 37.155 96.177C38.801 97.1207 40.6665 98.2619 42.4881 99.4251C42.5758 99.469 42.6417 99.5349 42.7075 99.5787L9.74362 151.263C9.74362 151.263 9.59 151.153 9.50221 151.11C7.68063 149.946 5.81517 148.805 4.16917 147.861C3.81802 147.664 3.51076 147.488 3.20351 147.313C3.11572 147.269 36.0796 95.5625 36.0796 95.5625V95.5845Z' fill='#40B2FF' />
            <path id='Vector_66' d='M4.19106 147.878C8.42677 150.314 14.0451 154.001 16.7665 156.086C19.4879 158.193 18.2808 157.908 14.0451 155.472C9.80941 153.035 4.19105 149.348 1.46967 147.263C-1.25172 145.179 -0.0446415 145.442 4.19106 147.878Z' fill='#2C264A' />
          </g>
        </g>
        <g id='Clip path group'>
          <mask id='mask0_4355_35319' maskUnits='userSpaceOnUse' x='29' y='2' width='81' height='81'>
            <g id='clippath'>
              <path id='Vector_67' d='M100.427 60.4578C86.4033 80.0343 61.1207 87.8473 44.0023 77.8835C26.9059 67.9417 24.382 43.954 38.384 24.3995C52.386 4.84499 77.7124 -2.96803 94.8089 6.97381C111.927 16.9157 114.429 40.9253 100.427 60.4578Z' fill='white' />
            </g>
          </mask>
          <g mask='url(#mask0_4355_35319)'>
            <g id='Group_36'>
              <g id='Group_37'>
                <g id='Group_38'>
                  <g id='Group_39'>
                    <path id='Vector_68' d='M63.2501 21.4878L65.7959 20.0174C64.94 19.5346 64.1718 19.4687 63.6012 19.7979L61.0554 21.2684C61.626 20.9392 62.3942 20.9831 63.2501 21.4878Z' fill='#40B2FF' />
                  </g>
                  <path id='Vector_69' d='M102.885 44.353L105.409 42.8826L65.7735 19.9922L63.2496 21.4846L102.885 44.353Z' fill='#0096FB' />
                  <path id='Vector_70' d='M113.594 61.4437L116.118 59.9732L107.603 45.2031L105.057 46.6736L113.594 61.4437Z' fill='#0096FB' />
                  <g id='Group_40'>
                    <path id='Vector_71' d='M105.058 46.6718L107.604 45.2014C107.033 44.2138 106.243 43.3578 105.431 42.875L102.885 44.3454C103.719 44.8283 104.487 45.6622 105.058 46.6718Z' fill='#40B2FF' />
                  </g>
                  <path id='Vector_72' d='M63.2491 21.4871C61.5373 20.4995 60.1546 21.2896 60.1546 23.2868L59.7816 154.791C59.7816 156.744 61.1422 159.137 62.8541 160.102L217.007 249.096C218.719 250.084 220.102 249.294 220.102 247.34L220.453 123.144C220.453 121.191 219.092 118.799 217.381 117.833L180.093 96.3033C179.259 95.8205 178.491 95.7547 177.899 96.0619L175.704 97.3348C175.133 97.664 174.321 97.5762 173.509 97.0934L115.746 63.7564C114.912 63.2736 114.143 62.4616 113.573 61.474L105.036 46.7039C104.465 45.7163 103.675 44.8603 102.863 44.3775L63.2272 21.4871H63.2491Z' fill='#0186E0' />
                </g>
                <path id='Vector_73' d='M83.1327 47.5312L210.358 120.987L220.409 138.303L220.102 247.334C220.102 249.287 218.719 250.077 217.007 249.089L97.003 179.804L82.9133 167.448L83.1327 47.5312Z' fill='#D3874F' />
                <g id='Group_41'>
                  <g id='Group_42'>
                    <g id='Group_43'>
                      <path id='Vector_74' d='M198.287 113.641L200.328 112.456L73.1028 39L71.0398 40.1851L198.287 113.641Z' fill='#FFF8F8' />
                      <path id='Vector_75' d='M71.0406 40.1875L70.8431 160.104L208.712 239.705L208.844 131.837L198.287 113.643L71.0406 40.1875Z' fill='#EADCDB' />
                      <path id='Vector_76' d='M76.22 47.7969L202.326 120.594L208.844 131.831L208.712 239.699L76.0005 163.083L76.22 47.7969Z' fill='#CABCBB' />
                    </g>
                  </g>
                  <g id='Group_44'>
                    <g id='Group_45'>
                      <path id='Vector_77' d='M192.911 116.741L194.952 115.534L67.7273 42.0781L65.6643 43.2632L192.911 116.741Z' fill='#FFF8F8' />
                      <path id='Vector_78' d='M65.6637 43.2656L65.4442 163.204L203.313 242.805L203.467 134.915L192.91 116.743L65.6637 43.2656Z' fill='#EADCDB' />
                    </g>
                  </g>
                </g>
                <g id='Group_46'>
                  <g id='Group_47'>
                    <path id='Vector_79' d='M53.8565 38.8023L56.4023 37.3319C55.5464 36.8491 54.7783 36.8052 54.2296 37.1344L51.6838 38.6048C52.2325 38.2756 53.0226 38.3195 53.8565 38.8023Z' fill='#FFDDA6' />
                  </g>
                  <path id='Vector_80' d='M208.01 127.822L210.556 126.352L56.3808 37.3359L53.8569 38.8064L208.01 127.822Z' fill='#40B2FF' />
                  <path id='Vector_81' d='M53.8571 38.804C52.1452 37.8164 50.7626 38.6285 50.7626 40.5817L50.4334 160.169C50.4334 162.144 51.7941 164.537 53.5059 165.524L207.659 254.54C209.371 255.527 210.754 254.737 210.754 252.74L211.083 133.153C211.083 131.2 209.722 128.785 208.01 127.798L53.8571 38.804Z' fill='#FDC394' />
                </g>
                <g id='Group_48'>
                  <g id='Group_49'>
                    <g id='Group_50'>
                      <path id='Vector_82' d='M47.9747 30.566L50.5205 29.0955C49.6646 28.6127 48.8965 28.5469 48.3258 28.8761L45.78 30.3465C46.3506 30.0173 47.1188 30.0612 47.9747 30.566Z' fill='#FFDDA6' />
                    </g>
                    <path id='Vector_83' d='M87.6099 53.4404L90.1558 51.97L50.5201 29.1016L47.9743 30.572L87.6099 53.4404Z' fill='#0096FB' />
                    <path id='Vector_84' d='M98.3199 70.5296L100.866 69.0592L92.3504 54.2891L89.8046 55.7595L98.3199 70.5296Z' fill='#0096FB' />
                    <g id='Group_51'>
                      <path id='Vector_85' d='M89.8048 55.7499L92.3506 54.2795C91.78 53.2919 90.9899 52.436 90.1779 51.9531L87.632 53.4236C88.466 53.9064 89.2342 54.7404 89.8048 55.7499Z' fill='#40B2FF' />
                    </g>
                    <g id='Group_52'>
                      <path id='Vector_86' d='M100.514 72.8388L103.06 71.3684C102.226 70.8856 101.458 70.0735 100.887 69.0859L98.3415 70.5564C98.9121 71.544 99.7022 72.3779 100.514 72.8388Z' fill='#FFDDA6' />
                    </g>
                    <path id='Vector_87' d='M158.278 106.181L160.802 104.711L103.06 71.3516L100.514 72.8439L158.278 106.181Z' fill='#FFCEA6' />
                    <path id='Vector_88' d='M47.9738 30.5736C46.2619 29.586 44.8793 30.3761 44.8793 32.3513L44.5062 163.856C44.5062 165.809 45.8669 168.201 47.5787 169.167L201.754 258.183C203.466 259.17 204.849 258.38 204.849 256.427L205.2 132.231C205.2 130.277 203.839 127.885 202.127 126.92L164.84 105.39C164.006 104.907 163.238 104.819 162.645 105.148L160.45 106.421C159.88 106.751 159.068 106.663 158.256 106.18L100.492 72.8429C99.6582 72.3601 98.89 71.548 98.3194 70.5605L89.7822 55.7903C89.2115 54.8027 88.4215 53.9468 87.6095 53.464L47.9738 30.5736Z' fill='#0188E3' />
                  </g>
                  <path id='Vector_89' d='M68.0549 56.2798L195.28 129.735L205.156 146.744L204.849 256.411C204.849 258.365 203.466 259.155 201.754 258.167L115.701 208.48L67.8135 175.823L68.033 56.2578L68.0549 56.2798Z' fill='#D3874F' />
                  <g id='Group_53'>
                    <g id='Group_54'>
                      <g id='Group_55'>
                        <path id='Vector_90' d='M182.683 122.647L184.724 121.44L57.4773 47.9844L55.4363 49.1695L182.683 122.647Z' fill='#FFF8F8' />
                        <path id='Vector_91' d='M55.4361 49.1719L55.2166 168.737L193.086 248.338L193.239 140.821L182.683 122.649L55.4361 49.1719Z' fill='#EADCDB' />
                        <path id='Vector_92' d='M60.6155 56.7812L186.721 129.6L193.239 140.815L193.086 248.332L60.3961 171.716L60.6155 56.7812Z' fill='url(#paint9_linear_4355_35319)' />
                      </g>
                    </g>
                    <g id='Group_56'>
                      <g id='Group_57'>
                        <path id='Vector_93' d='M177.306 125.742L179.347 124.557L52.0999 51.1016L50.0588 52.2867L177.306 125.742Z' fill='#FFF8F8' />
                        <path id='Vector_94' d='M50.0587 52.2891L49.8392 171.855L187.708 251.455L187.862 143.938L177.305 125.745L50.0587 52.2891Z' fill='#EADCDB' />
                      </g>
                    </g>
                  </g>
                  <g id='Group_58'>
                    <path id='Vector_95' d='M165.322 158.112L167.758 155.852L13.5829 66.8359L11.1469 69.0964L165.322 158.112Z' fill='#FFCEA6' />
                  </g>
                  <path id='Vector_96' d='M11.1479 69.1158C9.43602 68.1282 8.44841 68.633 8.93124 70.2131L38.252 167.569C38.7348 169.171 40.4906 171.256 42.2024 172.221L196.378 261.237C198.09 262.225 199.077 261.72 198.594 260.14L169.274 162.784C168.791 161.182 167.035 159.097 165.323 158.132L11.1479 69.1158Z' fill='url(#paint10_linear_4355_35319)' />
                </g>
              </g>
            </g>
          </g>
        </g>
        <g id='Vector_97'>
          <path d='M40.9959 27.5892C37.7258 32.198 37.7039 37.7944 40.93 40.0549C44.1562 42.3154 49.4015 40.428 52.6715 35.8192C55.9416 31.2104 55.9635 25.614 52.7374 23.3535C49.5112 21.093 44.2659 22.9804 40.9959 27.5892Z' fill='white' />
        </g>
        <g id='Vector_98'>
          <path d='M34.3467 45.8926C34.5442 47.9775 35.861 49.5577 37.3095 49.426C38.758 49.2943 39.7895 47.4947 39.592 45.4317C39.3944 43.3468 38.0776 41.7666 36.6292 41.8983C35.1807 42.03 34.1492 43.8296 34.3467 45.8926Z' fill='white' />
        </g>
      </g>
    </g>
    <defs>
      <linearGradient id='paint0_linear_4355_35319' x1='170.921' y1='74.9688' x2='50.6225' y2='156.869' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#0096FB' />
        <stop offset='1' stopColor='#0075C5' />
      </linearGradient>
      <linearGradient id='paint1_linear_4355_35319' x1='169.757' y1='127.44' x2='157.598' y2='204.626' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#B1A2A2' />
        <stop offset='1' stopColor='#CABCBB' />
      </linearGradient>
      <linearGradient id='paint2_linear_4355_35319' x1='166.312' y1='129.401' x2='154.153' y2='206.587' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#B1A2A2' />
        <stop offset='1' stopColor='#CABCBB' />
      </linearGradient>
      <linearGradient id='paint3_linear_4355_35319' x1='115.461' y1='158.311' x2='109.053' y2='138.515' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#B1A2A2' />
        <stop offset='1' stopColor='#CABCBB' />
      </linearGradient>
      <linearGradient id='paint4_linear_4355_35319' x1='159.75' y1='133.205' x2='147.613' y2='210.129' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#B1A2A2' />
        <stop offset='1' stopColor='#CABCBB' />
      </linearGradient>
      <linearGradient id='paint5_linear_4355_35319' x1='156.303' y1='135.151' x2='144.167' y2='212.096' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#B1A2A2' />
        <stop offset='1' stopColor='#CABCBB' />
      </linearGradient>
      <linearGradient id='paint6_linear_4355_35319' x1='157.06' y1='88.4531' x2='33.437' y2='210.021' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#0096FB' />
        <stop offset='1' stopColor='#0075C5' />
      </linearGradient>
      <linearGradient id='paint7_linear_4355_35319' x1='39.6793' y1='128.5' x2='101.064' y2='21.1809' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#EADCDB' />
        <stop offset='1' stopColor='#C1B2B2' />
      </linearGradient>
      <linearGradient id='paint8_linear_4355_35319' x1='37.1109' y1='121.444' x2='25.4133' y2='114.64' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#EADCDB' />
        <stop offset='0.5' stopColor='#F6ECEB' />
        <stop offset='1' stopColor='#FFF8F8' />
      </linearGradient>
      <linearGradient id='paint9_linear_4355_35319' x1='133.764' y1='177.751' x2='123.756' y2='146.807' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#B1A2A2' />
        <stop offset='1' stopColor='#CABCBB' />
      </linearGradient>
      <linearGradient id='paint10_linear_4355_35319' x1='74.2226' y1='87.5291' x2='147.349' y2='279.672' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#E7A470' />
        <stop offset='1' stopColor='#FDC394' />
      </linearGradient>
    </defs>
  </svg>
);

export default IconFindFile;
