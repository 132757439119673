
export const IconMileageRevison = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width='98'
    height='98'
    viewBox='0 0 98 98'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <circle cx='49' cy='49' r='49' fill='url(#paint0_radial_1166_40876)' />
    <g clipPath='url(#clip0_1166_40876)'>
      <path d='M11.1033 68.8207C11.1033 75.4928 13.4513 80.1882 17.258 82.3873L22.3492 85.3029L23.2386 83.7466L51.0198 35.6569L51.9092 34.0994L47.114 31.3325L46.743 31.1102C42.9859 28.9606 37.7715 29.3062 32.0373 32.6181C20.47 39.2903 11.1033 55.5018 11.1033 68.822V68.8207Z' fill='#0075C5' />
      <path d='M22.7179 85.5336C14.5503 80.8187 14.5504 65.5298 22.7181 51.385C30.8858 37.2402 44.1281 29.5957 52.2957 34.3106C60.4633 39.0255 60.4633 54.3143 52.2956 68.4592C44.1279 82.604 30.8855 90.2485 22.7179 85.5336Z' fill='#0096FB' />
      <path d='M49.8428 60.6987L39.7798 61.2894L38.4292 59.9835L40.6768 58.9023L49.8428 60.6987Z' fill='#EF5552' />
      <path d='M37.4965 40.5558C46.7654 35.2048 54.2783 39.542 54.2783 50.2426C54.2783 60.9431 46.7654 73.9533 37.4965 79.3042C28.2493 84.6424 20.7363 80.3053 20.7363 69.6047C20.7363 58.9042 28.2505 45.894 37.4965 40.5558ZM37.4965 77.7467C46.0373 72.8163 52.9506 60.8441 52.9506 51.0086C52.9506 41.1732 46.0373 37.1829 37.4965 42.112C28.9773 47.0297 22.0641 59.0032 22.0641 68.8387C22.0641 78.6741 28.9773 82.6644 37.4965 77.7467Z' fill='#C3C9EC' />
      <path opacity='0.8' d='M37.5008 43.6321C45.297 39.1311 51.6182 42.7796 51.6182 51.7804C51.6182 60.7812 45.2983 71.727 37.5008 76.228C29.7223 80.7189 23.4024 77.0703 23.4024 68.0695C23.4024 59.0687 29.7223 48.1229 37.5008 43.6321ZM37.5008 75.5496C44.9883 71.2277 51.0489 60.7317 51.0489 52.1095C51.0489 43.4873 44.9883 39.9886 37.5008 44.3105C30.0323 48.6222 23.9717 59.1182 23.9717 67.7405C23.9717 76.3627 30.0323 79.8613 37.5008 75.5496Z' fill='#C3C9EC' />
      <path d='M34.2915 65.4877C32.517 64.4633 32.5171 61.1415 34.2918 58.0681C36.0665 54.9948 38.9436 53.3337 40.7181 54.3581C42.4926 55.3825 42.4925 58.7043 40.7179 61.7777C38.9432 64.851 36.066 66.5121 34.2915 65.4877Z' fill='#0096FB' />
      <path d='M34.9316 64.3854C33.5084 63.5638 33.5082 60.8999 34.9313 58.4354C36.3544 55.9709 38.6618 54.639 40.0851 55.4606C41.5083 56.2822 41.5085 58.9461 40.0854 61.4106C38.6623 63.8752 36.3549 65.207 34.9316 64.3854Z' fill='#37474F' />
      <path d='M36.4002 39.8588L38.6136 38.5808C38.9948 38.361 39.3022 37.7715 39.3022 37.2646C39.3022 36.7578 38.9935 36.524 38.6136 36.7438L36.4002 38.0218C36.0191 38.2416 35.7116 38.831 35.7116 39.3379C35.7116 39.8448 36.0203 40.0786 36.4002 39.8588Z' fill='#EEEEF6' />
      <path d='M49.4766 37.5573L50.8908 38.3742C51.2021 38.5533 51.7052 38.2624 52.0165 37.725C52.3278 37.1864 52.3278 36.6045 52.0165 36.4254L50.6024 35.6085C50.2911 35.4294 49.7879 35.7203 49.4766 36.2577C49.1654 36.7963 49.1654 37.3782 49.4766 37.5573Z' fill='#EEEEF6' />
      <path d='M55.4371 48.5597V50.5847C55.4371 51.1704 55.7928 51.4397 56.2325 51.1856C56.6721 50.9316 57.0278 50.2519 57.0278 49.665V47.64C57.0278 47.0543 56.6721 46.785 56.2325 47.0391C55.7928 47.2931 55.4371 47.9728 55.4371 48.5597Z' fill='#EEEEF6' />
      <path d='M50.8903 66.0175L49.4762 68.4668C49.1649 69.0054 49.1649 69.5873 49.4762 69.7664C49.7874 69.9455 50.2906 69.6546 50.6019 69.1172L52.016 66.6679C52.3273 66.1293 52.3273 65.5474 52.016 65.3683C51.7048 65.1892 51.2016 65.4801 50.8903 66.0175Z' fill='#EEEEF6' />
      <path d='M25.5361 82.2867L24.1219 81.4699C23.8106 81.2907 23.3075 81.5816 22.9962 82.119C22.6849 82.6577 22.6849 83.2395 22.9962 83.4186L24.4103 84.2355C24.7216 84.4146 25.2248 84.1237 25.5361 83.5863C25.8474 83.0477 25.8474 82.4658 25.5361 82.2867Z' fill='#EEEEF6' />
      <path d='M19.5801 71.2831V69.2581C19.5801 68.6725 19.2243 68.4031 18.7847 68.6572C18.3451 68.9113 17.9893 69.591 17.9893 70.1779V72.2029C17.9893 72.7886 18.3451 73.0579 18.7847 72.8038C19.2243 72.5497 19.5801 71.8701 19.5801 71.2831Z' fill='#EEEEF6' />
      <path d='M36.9747 44.3339L38.042 43.7178V46.3107L36.9747 46.9268V44.3339Z' fill='#C3C9EC' />
      <path d='M46.8648 42.8445L47.6196 43.2803L46.0314 46.0308L45.2766 45.595L46.8648 42.8445Z' fill='#C3C9EC' />
      <path d='M51.2744 51.3613V52.5936L49.028 53.8907V52.6571L51.2744 51.3613Z' fill='#C3C9EC' />
      <path d='M46.0294 63.9711L47.6172 64.8877L46.8618 66.1958L45.274 65.2792L46.0294 63.9711Z' fill='#C3C9EC' />
      <path d='M28.982 73.8113L29.7368 74.2471L28.1486 76.9976L27.3938 76.5618L28.982 73.8113Z' fill='#C3C9EC' />
      <path d='M23.7402 68.4825V67.2502L25.9854 65.9531V67.1867L23.7402 68.4825Z' fill='#C3C9EC' />
      <path d='M28.151 53.6469L29.7388 54.5635L28.9834 55.8716L27.3956 54.955L28.151 53.6469Z' fill='#C3C9EC' />
      <path d='M24.1239 53.8272L25.538 51.3779C25.8493 50.8392 25.8493 50.2574 25.538 50.0782C25.2267 49.8991 24.7236 50.19 24.4123 50.7274L22.9981 53.1768C22.6868 53.7154 22.6868 54.2972 22.9981 54.4764C23.3094 54.6555 23.8126 54.3646 24.1239 53.8272Z' fill='#EEEEF6' />
      <path d='M37.2768 44.1574L37.7393 43.8906V45.0226L37.2768 45.2893V44.1574Z' fill='#C3C9EC' />
      <path d='M47.0799 42.9656L47.4067 43.1543L46.713 44.3557L46.3862 44.1671L47.0799 42.9656Z' fill='#C3C9EC' />
      <path d='M51.2744 51.71V52.2435L50.2923 52.8101V52.2766L51.2744 51.71Z' fill='#C3C9EC' />
      <path d='M46.7105 64.857L47.4048 65.2578L47.0782 65.8233L46.3839 65.4225L46.7105 64.857Z' fill='#C3C9EC' />
      <path d='M28.2992 75.4803L28.626 75.6689L27.9322 76.8704L27.6054 76.6817L28.2992 75.4803Z' fill='#C3C9EC' />
      <path d='M23.7398 68.1324V67.5988L24.7207 67.0322V67.5658L23.7398 68.1324Z' fill='#C3C9EC' />
      <path d='M27.9365 54.0133L28.6309 54.4141L28.3043 54.9796L27.61 54.5788L27.9365 54.0133Z' fill='#C3C9EC' />
      <path d='M39.9693 42.8607L40.4229 42.7031L40.231 43.924L39.7787 44.0815L39.9693 42.8607Z' fill='#C3C9EC' />
      <path d='M42.5643 42.2177L42.9912 42.1758L42.6164 43.4386L42.1895 43.4818L42.5643 42.2177Z' fill='#C3C9EC' />
      <path d='M44.9661 42.2539L45.3511 42.3289L44.806 43.5853L44.421 43.5103L44.9661 42.2539Z' fill='#C3C9EC' />
      <path d='M48.8283 44.3398L49.085 44.6358L48.2692 45.736L48.0126 45.44L48.8283 44.3398Z' fill='#C3C9EC' />
      <path d='M50.1398 46.3047L50.3164 46.696L49.4105 47.6526L49.2326 47.2613L50.1398 46.3047Z' fill='#C3C9EC' />
      <path d='M50.9664 48.7949L51.0566 49.2662L50.0948 50.0425L50.0046 49.5711L50.9664 48.7949Z' fill='#C3C9EC' />
      <path d='M51.0566 54.9453L50.9664 55.5208L50.0033 55.8549L50.0935 55.2794L51.0566 54.9453Z' fill='#C3C9EC' />
      <path d='M50.314 58.3691L50.1374 58.9637L49.2302 59.0539L49.4068 58.4593L50.314 58.3691Z' fill='#C3C9EC' />
      <path d='M49.082 61.8519L48.8241 62.4439L48.0084 62.2863L48.265 61.6943L49.082 61.8519Z' fill='#C3C9EC' />
      <path d='M45.3467 68.4664L44.9617 68.9848L44.4166 68.3572L44.8016 67.8389L45.3467 68.4664Z' fill='#C3C9EC' />
      <path d='M30.0506 77.5899L29.6669 77.5162L30.212 76.2598L30.5957 76.3335L30.0506 77.5899Z' fill='#C3C9EC' />
      <path d='M26.1882 75.5065L25.9315 75.2105L26.7472 74.1104L27.0039 74.4064L26.1882 75.5065Z' fill='#C3C9EC' />
      <path d='M24.8765 73.5432L24.6999 73.1519L25.6071 72.1953L25.7837 72.5866L24.8765 73.5432Z' fill='#C3C9EC' />
      <path d='M24.0518 71.0522L23.9616 70.5809L24.9235 69.8047L25.0137 70.276L24.0518 71.0522Z' fill='#C3C9EC' />
      <path d='M23.9616 64.9008L24.0518 64.3253L25.0137 63.9912L24.9235 64.5667L23.9616 64.9008Z' fill='#C3C9EC' />
      <path d='M24.7018 61.4806L24.8797 60.8848L25.7856 60.7959L25.609 61.3904L24.7018 61.4806Z' fill='#C3C9EC' />
      <path d='M25.934 57.9924L26.1906 57.4004L27.0063 57.5592L26.7497 58.1512L25.934 57.9924Z' fill='#C3C9EC' />
      <path d='M29.6676 51.3787L30.0526 50.8604L30.5977 51.4867L30.2127 52.005L29.6676 51.3787Z' fill='#C3C9EC' />
      <path d='M32.0271 48.5037L32.454 48.0527L32.8301 48.8823L32.4032 49.3333L32.0271 48.5037Z' fill='#C3C9EC' />
      <path d='M34.5992 46.0631L35.0528 45.6973L35.2446 46.6983L34.791 47.0642L34.5992 46.0631Z' fill='#C3C9EC' />
      <path d='M33.2396 73.2227L41.7754 68.2949V65.2256L33.2396 70.1535V73.2227Z' fill='#ACB2DB' />
      <path d='M33.2396 73.2232L41.7754 68.2953V67.5293L33.2396 72.4572V73.2232Z' fill='#C3C9EC' />
    </g>
    <path d='M68.193 35.1423C68.3687 34.5645 68.1137 33.9866 67.4056 33.5787C66.1648 32.8592 63.995 32.9498 62.5617 33.777C61.7402 34.2529 61.3379 34.8647 61.3776 35.4369V52.807C61.3776 52.858 61.3776 52.909 61.3776 52.96C61.4059 53.3849 61.6835 53.7928 62.2104 54.0987C63.4511 54.8182 65.621 54.7276 67.0543 53.9004C68.0004 53.3509 68.3913 52.62 68.1874 51.9798V35.148L68.193 35.1423Z' fill='url(#paint1_linear_1166_40876)' />
    <path d='M61.327 32.1162C61.327 32.1162 61.174 34.4164 62.4261 34.7959C63.6781 35.1699 66.0746 35.3398 67.4966 34.2067C68.5504 33.3683 68.3294 32.1729 68.1991 31.2607L61.327 32.1162Z' fill='url(#paint2_linear_1166_40876)' />
    <path d='M67.011 33.0092C65.5777 33.8364 63.4079 33.927 62.1671 33.2075C60.9264 32.488 61.0794 31.2359 62.5127 30.4088C63.9461 29.5817 66.1159 29.491 67.3566 30.2105C68.5974 30.93 68.4444 32.1821 67.011 33.0092Z' fill='#0172D9' />
    <g style={{ mixBlendMode: 'overlay' }} opacity='0.39'>
      <path d='M65.2523 30.3216C64.3629 30.3216 63.4734 30.5426 62.8049 30.9278C62.244 31.2507 61.9098 31.653 61.9098 32.0042C61.9098 32.3555 62.2497 32.5934 62.4536 32.7124C62.9069 32.973 63.5471 33.1203 64.2666 33.1203C65.156 33.1203 66.0455 32.8994 66.714 32.5141C67.2749 32.1912 67.6091 31.789 67.6091 31.4377C67.6091 31.0864 67.2692 30.8485 67.0653 30.7295C66.612 30.4689 65.9718 30.3216 65.2523 30.3216ZM65.2523 29.7324C66.0398 29.7324 66.7933 29.8911 67.3542 30.214C68.5949 30.9335 68.4419 32.1855 67.0086 33.0127C66.2268 33.4659 65.2183 33.6982 64.2666 33.6982C63.4791 33.6982 62.7256 33.5396 62.1647 33.2166C60.924 32.4971 61.0769 31.2451 62.5103 30.4179C63.2921 29.9647 64.3005 29.7324 65.2523 29.7324Z' fill='white' />
    </g>
    <g style={{ mixBlendMode: 'overlay' }} opacity='0.2'>
      <path d='M63.0974 52.8375C62.8085 52.6732 62.5762 52.2257 62.5762 51.8404V36.6062C62.5762 36.2209 62.8085 36.0453 63.0974 36.2153C63.3864 36.3796 63.6187 36.8271 63.6187 37.2124V52.4466C63.6187 52.8318 63.3864 53.0075 63.0974 52.8375Z' fill='#1D1D1B' />
    </g>
    <g style={{ mixBlendMode: 'overlay' }} opacity='0.2'>
      <path d='M66.4597 52.8375C66.7487 52.6732 66.981 52.2257 66.981 51.8404V36.6062C66.981 36.2209 66.7487 36.0453 66.4597 36.2153C66.1708 36.3796 65.9385 36.8271 65.9385 37.2124V52.4466C65.9385 52.8318 66.1708 53.0075 66.4597 52.8375Z' fill='#1D1D1B' />
    </g>
    <path d='M64.8829 32.3927L65.2852 32.1661L65.1379 12.2239L64.2484 6.81348L63.8518 7.04009L64.6393 29.0218L64.8829 32.3927Z' fill='url(#paint3_linear_1166_40876)' />
    <path d='M65.2866 32.1672L64.2159 31.5497V9.52832L65.2866 10.1515V32.1672Z' fill='url(#paint4_linear_1166_40876)' />
    <path d='M65.331 7.43569L64.8041 7.12976L64.6965 7.06744L64.2489 6.8125L63.2292 9.40725L64.5322 12.4042L64.6965 12.4949L64.8041 12.5572L64.9628 12.6535L66.2715 11.1635L65.331 7.43569Z' fill='url(#paint5_linear_1166_40876)' />
    <path d='M64.8774 32.3969L63.8067 31.7794V9.76367L64.8774 10.3812V32.3969Z' fill='url(#paint6_linear_1166_40876)' />
    <path d='M64.9238 7.66518L64.3969 7.36492L64.2893 7.3026L63.8474 7.04199L62.8219 9.63674L64.125 12.6337L64.2893 12.73L64.3969 12.7924L64.5612 12.883L65.8643 11.393L64.9238 7.66518Z' fill='url(#paint7_linear_1166_40876)' />
    <path d='M79.4718 14.8131C80.7408 16.3994 81.1714 18.3766 81.1714 20.3708C81.1714 21.6682 80.8031 22.6427 80.1969 23.1922C80.2139 23.1922 80.2026 23.1922 80.1856 23.2035C80.1969 23.2035 80.1856 23.2035 80.1686 23.2149C80.112 23.2602 80.078 23.2885 80.044 23.3168C79.7607 23.6058 79.2225 24.4839 79.2225 27.039V48.0689C79.2225 50.1481 79.5794 51.5248 79.8683 52.301C79.9986 52.5502 80.112 52.8108 80.2196 53.0715C80.6445 54.1139 80.8994 55.2356 80.8994 56.2894C80.8994 57.6434 80.4802 58.6292 79.8117 59.1221C78.8599 59.7736 78.8486 59.7793 78.8429 59.7849C77.1036 61.0257 76.1858 61.0426 75.1774 60.4648C73.1662 59.3034 71.5232 56.1704 71.5232 53.485C71.5232 52.4313 71.7782 51.6041 72.2031 51.0546C72.3107 50.9186 72.4297 50.794 72.5543 50.692C72.8376 50.2444 73.1945 49.2756 73.1945 47.2021V26.1722C73.1945 23.7134 72.696 22.2348 72.4014 21.5776C72.3334 21.4473 72.2654 21.3113 72.1974 21.181C71.5912 19.9233 71.2286 18.5353 71.2286 17.2379C71.2286 15.6176 71.5176 14.3372 72.3051 13.85C73.2285 13.2211 73.2398 13.2098 73.2512 13.2041C74.5372 12.3317 74.7525 12.258 74.9961 12.2354C75.2624 12.3883 75.506 12.8869 75.506 13.4874V17.0736L76.8884 18.9602V16.8923C76.8884 16.5127 76.9847 16.2861 77.1263 16.2068C78.0781 15.5553 78.0894 15.5496 78.0951 15.5439C79.2565 14.7508 79.3585 14.7508 79.4661 14.8074L79.4718 14.8131ZM77.2963 57.0542C77.2963 56.9806 77.3076 56.9069 77.3076 56.8276C77.3076 55.2583 76.3501 53.4284 75.1717 52.7485C75.1547 52.7372 75.1377 52.7315 75.1151 52.7202C75.1151 52.7938 75.1038 52.8675 75.1038 52.9468C75.1038 54.5161 76.0612 56.346 77.2396 57.0259C77.2566 57.0372 77.2736 57.0429 77.2963 57.0542Z' fill='url(#paint8_linear_1166_40876)' />
    <path d='M78.1349 54.4853C78.5598 55.5278 78.8148 56.6495 78.8148 57.7033C78.8148 60.3887 77.1775 61.6294 75.1606 60.4623C73.1494 59.3009 71.5064 56.1679 71.5064 53.4826C71.5064 52.4288 71.7614 51.6016 72.1863 51.0521C72.2939 50.9161 72.4129 50.7915 72.5375 50.6895C72.8208 50.242 73.1777 49.2732 73.1777 47.1996V26.1697C73.1777 23.7109 72.6792 22.2323 72.3846 21.5751C72.3166 21.4448 72.2486 21.3088 72.1806 21.1785C71.5744 19.9208 71.2118 18.5328 71.2118 17.2354C71.2118 15.2412 71.6424 13.7625 72.9114 13.6436C73.1777 13.7965 73.4213 14.2951 73.4213 14.8956V18.4818L75.1493 20.8386L76.8716 20.4703V16.8842C76.8716 16.2836 77.1152 16.0627 77.3814 16.2156C78.6505 17.8019 79.0811 19.7792 79.0811 21.7734C79.0811 23.0708 78.7128 24.0452 78.1066 24.5947C78.1236 24.5947 78.1066 24.5947 78.0896 24.6117C78.0443 24.6514 77.999 24.6854 77.9536 24.7194C77.6704 25.0083 77.1322 25.8865 77.1322 28.4415V49.4715C77.1322 51.5507 77.4891 52.9273 77.778 53.7035C77.9083 53.9528 78.0216 54.2134 78.1293 54.474L78.1349 54.4853ZM77.2965 56.8251C77.2965 55.2558 76.339 53.4259 75.1606 52.7461C73.9822 52.0662 73.0248 52.7914 73.0248 54.3607C73.0248 55.93 73.9822 57.7599 75.1606 58.4398C76.339 59.1196 77.2965 58.3944 77.2965 56.8251Z' fill='url(#paint9_linear_1166_40876)' />
    <defs>
      <radialGradient id='paint0_radial_1166_40876' cx='0' cy='0' r='1' gradientUnits='userSpaceOnUse' gradientTransform='translate(49.0294 49) scale(102.411)'>
        <stop offset='0.11' stopColor='#7E7E7E' />
        <stop offset='0.382315' stopColor='#2F2F2F' stopOpacity='0' />
      </radialGradient>
      <linearGradient id='paint1_linear_1166_40876' x1='68.2466' y1='33.0928' x2='55.7779' y2='37.0794' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#0096FB' />
        <stop offset='1' stopColor='#0075C5' />
      </linearGradient>
      <linearGradient id='paint2_linear_1166_40876' x1='68.312' y1='31.2607' x2='65.101' y2='37.1416' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#0096FB' />
        <stop offset='1' stopColor='#0075C5' />
      </linearGradient>
      <linearGradient id='paint3_linear_1166_40876' x1='63.8518' y1='19.6059' x2='65.2852' y2='19.6059' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#CFD5F7' />
        <stop offset='1' stopColor='#BCC2E6' />
      </linearGradient>
      <linearGradient id='paint4_linear_1166_40876' x1='64.2159' y1='20.8478' x2='65.2866' y2='20.8478' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#CFD5F7' />
        <stop offset='1' stopColor='#BCC2E6' />
      </linearGradient>
      <linearGradient id='paint5_linear_1166_40876' x1='63.2292' y1='9.73017' x2='66.2715' y2='9.73017' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#CFD5F7' />
        <stop offset='1' stopColor='#BCC2E6' />
      </linearGradient>
      <linearGradient id='paint6_linear_1166_40876' x1='64.3449' y1='11.129' x2='64.3449' y2='45.5406' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#D0D4F9' />
        <stop offset='1' stopColor='#636C9E' />
      </linearGradient>
      <linearGradient id='paint7_linear_1166_40876' x1='64.3459' y1='11.1267' x2='64.3459' y2='45.5383' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#D0D4F9' />
        <stop offset='1' stopColor='#636C9E' />
      </linearGradient>
      <linearGradient id='paint8_linear_1166_40876' x1='71.2286' y1='36.5342' x2='81.1714' y2='36.5342' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#CFD5F7' />
        <stop offset='1' stopColor='#BCC2E6' />
      </linearGradient>
      <linearGradient id='paint9_linear_1166_40876' x1='64.8439' y1='37.4948' x2='93.3295' y2='36.866' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#D0D4F9' />
        <stop offset='1' stopColor='#636C9E' />
      </linearGradient>
      <clipPath id='clip0_1166_40876'>
        <rect width='47.3214' height='57.168' fill='white' transform='matrix(-1 0 0 1 58.4243 29.7549)' />
      </clipPath>
    </defs>
  </svg>
);

export default IconMileageRevison;
