import { IconCheckCircle } from '../icons';
import React, { FC, useMemo } from 'react';

interface StepperProps {
  activeStep: number
  steps: number
  width: number
}

const NotCompletedStep = () => (
  <div className='w-[10px] h-[10px] rounded-full bg-complementary-gray-main border-[0.5px] border-complementary-gray-light shadow-[1px_2px_4px_-1px_rgba(0,0,0,0.75)] shadow-dark-dark' />
);

const CompletedStep = () => (
  <div className='w-[13px] h-[13px] rounded-full bg-primary-gradient shadow-[1px_2px_4px_-1px_rgba(0,0,0,0.75)] shadow-dark-dark'>
    <IconCheckCircle width={13} height={13} />
  </div>
);

const CurrentStep = () => (
  <div className='w-[13px] h-[13px] rounded-full bg-white shadow-[1px_2px_4px_-1px_rgba(0,0,0,0.75)] shadow-dark-dark' />
);

export const Stepper: FC<StepperProps> = ({
  activeStep,
  steps,
  width,
}) => {
  const lineWidth = useMemo(() => `${width}px`, [width]);
  const getSegmentWidth = useMemo(() => (width / (steps - 1)) * (activeStep - 1), [activeStep, steps, width]);
  const segmentWidth = useMemo(() => getSegmentWidth < width ? `${getSegmentWidth}px` : lineWidth, [getSegmentWidth, lineWidth, width]);

  // eslint-disable-next-line react/display-name
  const getSteps = useMemo(() => (index: number) => {
    if (activeStep - 1 < index) {
      return (<NotCompletedStep />);
    } else if (activeStep - 1 > index) {
      return (<CompletedStep />);
    } else {
      return <CurrentStep />;
    }
  }, [activeStep]);

  return (
    <div className='flex items-center'>
      <div
        className='relative bg-complementary-gray-light h-[10px] rounded-[10px]'
        style={{ width: lineWidth }}
      >
        <div
          className='flex absolute left-0 h-[10px] bg-primary-gradient rounded-[5px]'
          style={{ width: segmentWidth }}
        />
        <div className='flex flex-row absolute -top-[1.5px] left-0 right-0 justify-between items-center'>
          {
            Array.from({ length: steps }).map((_, index) => (
              <div key={`${index}-step`}>
                {getSteps(index)}
              </div>
            ))
          }
        </div>
      </div>
    </div>
  );
};
