
export const IconAlfredCar = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width='98' height='70' viewBox='0 0 98 70' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g id='graphics'>
      <g id='Group'>
        <g id='Vector' opacity='0.31'>
          <path d='M6.98914 22.4463C6.98914 22.4463 1.073 25.0383 0.22164 27.5273C-0.439926 29.4632 0.22164 32.7114 4.38083 35.824C6.72342 37.581 55.1587 64.7106 55.1587 64.7106C55.1587 64.7106 66.1017 71.9716 81.0954 68.1757C83.5573 67.5521 94.484 62.2107 97.5532 54.9823C98.7353 52.2059 97.7321 47.2116 92.6023 43.8875C82.7059 37.4942 6.98914 22.4463 6.98914 22.4463Z' fill='#231F20' />
        </g>
        <path id='Vector_2' d='M72.6581 21.9596C72.6581 21.9596 73.8131 19.4434 74.7296 19.6658C75.6514 19.8827 75.6406 21.8023 75.6189 22.4042C75.5701 23.7274 74.5398 26.3085 74.5398 26.3085C74.5398 26.3085 75.0549 27.5503 73.1733 25.6632C71.2482 23.7382 72.6527 21.9596 72.6527 21.9596H72.6581Z' fill='#BFC4D1' />
        <path id='Vector_3' d='M17.8603 37.0755C17.356 34.7925 16.792 33.041 14.6772 29.8579C12.0146 25.856 9.07555 24.663 6.94986 25.8234C4.90552 26.9405 5.74061 29.6193 5.74061 29.6193C5.74061 29.6193 5.93583 30.0911 6.9336 29.8416C7.1505 29.7874 7.87714 29.3428 7.87714 29.3428C9.51479 30.5086 14.303 37.352 18.1748 39.1741C18.0935 38.4854 18.0447 37.8997 17.8603 37.0701V37.0755Z' fill='#7D7D7D' />
        <g id='Group_2'>
          <g id='Axonometric Cylinder'>
            <path id='Vector_4' d='M14.893 26.1112C13.451 25.2745 12.1425 25.2122 11.2078 25.773L7.97656 27.6423L15.347 40.2468L18.5782 38.3775C19.4951 37.8256 20.0559 36.6773 20.0559 35.0572C20.0559 31.7637 17.7415 27.758 14.893 26.1112Z' fill='black' />
            <path id='Cylinder face' d='M16.8233 36.9233C16.8233 40.2168 14.5089 41.552 11.6604 39.9052C8.81194 38.2585 6.49756 34.2528 6.49756 30.9593C6.49756 27.6657 8.81194 26.3305 11.6604 27.9773C14.5089 29.624 16.8233 33.6297 16.8233 36.9233Z' fill='black' />
          </g>
          <g id='Group_3'>
            <path id='Vector_5' d='M11.6499 38.1988C9.68264 37.1129 8.06257 34.3712 8.02696 32.0746C7.99136 29.7781 9.55802 28.7989 11.5252 29.8849C13.4925 30.9708 15.1125 33.7125 15.1481 36.0091C15.1837 38.3057 13.6171 39.2848 11.6499 38.1988Z' fill='#D8D8D8' />
            <path id='Vector_6' d='M11.6423 37.6152C9.95099 36.6806 8.56236 34.3217 8.52676 32.3456C8.50005 30.3694 9.84418 29.5327 11.5355 30.4673C13.2267 31.402 14.6154 33.7609 14.651 35.737C14.6777 37.7131 13.3336 38.5499 11.6423 37.6152Z' fill='#A8A8A8' />
            <g id='Group_4'>
              <path id='Vector_7' d='M11.7926 35.9509C10.8401 35.4258 10.0479 34.0905 10.0301 32.9778C10.0123 31.8652 10.7778 31.3845 11.7303 31.9186C12.6827 32.4527 13.475 33.779 13.4928 34.8917C13.5106 36.0043 12.745 36.485 11.7926 35.9509Z' fill='#D8D8D8' />
              <path id='Vector_8' d='M13.6617 32.9256C13.2433 32.169 12.6469 31.5103 11.9882 31.1453C10.742 30.4599 9.75392 31.0741 9.77172 32.5339C9.78952 33.9849 10.8221 35.7207 12.0594 36.415C13.2522 37.0737 14.2136 36.5307 14.267 35.2044C14.267 35.151 14.267 35.0887 14.267 35.0352C14.2581 34.3498 14.0266 33.611 13.6528 32.9345L13.6617 32.9256ZM13.4836 35.0174C13.4391 36.0411 12.7003 36.4506 11.7835 35.9432C10.831 35.418 10.0388 34.0828 10.021 32.9701C10.0032 31.8574 10.7687 31.3767 11.7211 31.9108C12.2285 32.1868 12.6825 32.6942 13.0119 33.2728C13.1543 33.5309 13.27 33.7979 13.3501 34.0739C13.4302 34.3498 13.4747 34.6169 13.4836 34.8839C13.4836 34.9284 13.4836 34.9729 13.4836 35.0174Z' fill='#BEBEBE' />
            </g>
          </g>
        </g>
        <path id='Vector_9' d='M63.1028 14.8203C63.1028 14.8203 53.3257 8.37815 46.5474 5.59632C39.769 2.81991 35.1055 1.37748 33.9939 1.15515C32.8822 0.932824 29.8835 0.151959 26.2178 1.48594C22.552 2.81991 14.3204 7.337 10.9963 9.43015C7.55292 11.5992 6.77206 12.8193 6.11049 13.931C5.4435 15.0426 4.77652 17.9871 4.77652 17.9871C4.77652 17.9871 4.27763 18.486 4.0553 19.82C3.83297 21.154 3.72452 21.9891 3.83297 22.873C3.94142 23.7623 4.27763 24.0931 4.33186 24.8739C4.44031 26.3543 3.84924 27.3738 5.40555 29.3747C5.49231 29.4832 6.35451 29.9712 6.29486 28.8542C6.20268 27.1623 6.96185 24.0768 10.5517 26.3706C12.5526 27.6503 16.2726 32.1457 17.2378 38.0347C17.3897 38.9729 18.2356 39.4609 18.7182 39.7537C30.8975 47.2316 48.6622 56.5857 48.6622 56.5857C48.6622 56.5857 49.2804 56.9816 49.7196 57.0304C50.2727 57.0954 53.2552 55.1758 53.2552 55.1758L62.1592 62.4151C62.1592 62.4151 62.425 62.5506 62.5117 63.2122C62.5876 63.8087 63.0865 65.4518 63.5475 65.8422C63.8999 66.135 64.9573 66.7315 67.4897 67.0298C68.6393 67.1653 72.3213 66.8617 75.3797 66.4712C79.2081 65.9832 84.2024 64.2967 87.0439 62.7513C90.8235 60.6961 92.3744 57.86 93.5999 55.6964C94.9718 53.267 94.3211 52.5295 94.3211 52.5295C94.3211 52.5295 94.7441 51.3528 94.5434 50.6967C94.3211 49.9755 94.5977 48.8096 94.7116 46.4182C94.7495 45.6265 94.0825 42.8555 93.2691 41.4185C91.382 38.0835 88.8822 35.806 85.8834 33.6966C82.8847 31.5872 77.9934 28.0299 77.9934 28.0299C77.9934 28.0299 69.328 19.5868 63.1082 14.8094L63.1028 14.8203Z' fill='#BFC4D1' />
        <g id='Group_5'>
          <g id='Axonometric Cylinder_2'>
            <path id='Vector_10' d='M59.0634 51.4452C57.6214 50.6085 56.3129 50.5462 55.3782 51.107L52.147 52.9763L59.5174 65.5808L62.7486 63.7115C63.6655 63.1596 64.2263 62.0113 64.2263 60.3912C64.2263 57.0977 61.9119 53.092 59.0634 51.4452Z' fill='black' />
            <path id='Cylinder face_2' d='M60.9937 62.2572C60.9937 65.5508 58.6793 66.886 55.8308 65.2392C52.9824 63.5925 50.668 59.5868 50.668 56.2933C50.668 52.9997 52.9824 51.6645 55.8308 53.3113C58.6793 54.958 60.9937 58.9637 60.9937 62.2572Z' fill='black' />
          </g>
          <g id='Group_6'>
            <path id='Vector_11' d='M55.8212 63.5362C53.854 62.4503 52.234 59.7086 52.1983 57.412C52.1627 55.1155 53.7294 54.1363 55.6966 55.2223C57.6639 56.3082 59.2839 59.0499 59.3195 61.3465C59.3551 63.6431 57.7885 64.6222 55.8212 63.5362Z' fill='#D8D8D8' />
            <path id='Vector_12' d='M55.8137 62.9526C54.1224 62.018 52.7338 59.6591 52.6981 57.683C52.6714 55.7068 54.0156 54.8701 55.7068 55.8047C57.3981 56.7394 58.7868 59.0983 58.8224 61.0744C58.8491 63.0505 57.5049 63.8873 55.8137 62.9526Z' fill='#A8A8A8' />
            <g id='Group_7'>
              <path id='Vector_13' d='M55.9664 61.2991C55.014 60.7739 54.2217 59.4387 54.2039 58.326C54.1861 57.2133 54.9516 56.7326 55.9041 57.2667C56.8566 57.8008 57.6488 59.1271 57.6666 60.2398C57.6844 61.3525 56.9189 61.8332 55.9664 61.2991Z' fill='#D8D8D8' />
              <path id='Vector_14' d='M57.8355 58.2737C57.4171 57.5171 56.8207 56.8584 56.162 56.4935C54.9158 55.808 53.9277 56.4222 53.9455 57.8821C53.9634 59.333 54.9959 61.0688 56.2332 61.7631C57.426 62.4218 58.3874 61.8788 58.4408 60.5525C58.4408 60.4991 58.4408 60.4368 58.4408 60.3834C58.4319 59.698 58.2005 58.9592 57.8266 58.2826L57.8355 58.2737ZM57.6575 60.3656C57.613 61.3893 56.8741 61.7987 55.9573 61.2913C55.0048 60.7662 54.2126 59.4309 54.1948 58.3183C54.177 57.2056 54.9425 56.7249 55.895 57.259C56.4024 57.5349 56.8563 58.0423 57.1857 58.6209C57.3281 58.879 57.4438 59.1461 57.5239 59.422C57.6041 59.698 57.6486 59.965 57.6575 60.2321C57.6575 60.2766 57.6575 60.3211 57.6575 60.3656Z' fill='#BEBEBE' />
            </g>
          </g>
        </g>
        <path id='Vector_15' d='M11.6431 9.03503C10.2603 10.1684 8.97516 11.5403 9.1053 12.2669C9.25171 13.0803 9.99462 12.7875 11.9197 11.9687C13.8447 11.1553 14.5876 10.6347 14.5876 10.6347C14.5876 10.6347 14.4412 9.59898 15.4769 7.89626C15.8077 7.354 17.1797 6.18812 18.3401 5.25C15.8077 6.60025 13.3187 8.01014 11.6431 9.0296V9.03503Z' fill='#5B5D5E' />
        <path id='Vector_16' d='M41.7012 25.1526C41.7012 25.1526 50.1443 21.6713 54.4391 19.448C58.7338 17.2247 62.3616 14.855 62.3616 14.855C62.3616 14.855 72.361 22.9293 74.5084 25.2231C76.6558 27.5169 77.8433 28.4822 77.8433 28.4822C77.8433 28.4822 76.1406 31.7412 69.4002 35.2225C63.6305 38.205 56.1418 40.7754 54.2872 41.0736C53.0346 41.2742 52.5845 41.4423 51.7711 40.4825C50.9577 39.5227 48.3603 35.2225 46.5111 32.4082C44.662 29.5938 41.6958 25.1472 41.6958 25.1472L41.7012 25.1526Z' fill='#3D3D3D' />
        <path id='Vector_17' d='M47.551 41.2612C47.551 41.2612 48.7006 41.5595 47.2527 37.948C46.4665 35.9795 42.5892 29.5971 39.8454 26.338C37.1069 23.079 33.4737 21.154 31.7005 20.0423C29.9219 18.9307 29.6291 18.8548 25.9254 17.0815C22.2217 15.3029 17.9269 15.227 16.0724 15.3029C14.2232 15.3788 12.591 15.5252 12.1464 15.6716C11.7017 15.8181 11.4035 16.1163 12.0704 16.7833C12.7374 17.4503 22.4386 24.3371 26.5869 27.2274C30.7353 30.1176 43.1152 38.333 44.6987 39.488C46.0706 40.4858 47.1063 41.1907 47.551 41.2667V41.2612Z' fill='#3D3D3D' />
        <g id='Group_8' opacity='0.67'>
          <path id='Vector_18' d='M94.3252 52.5443C94.3252 52.5443 94.7481 51.3676 94.5475 50.7114C94.3252 49.9902 94.6017 48.8243 94.7156 46.4438C94.5095 47.0891 94.195 47.8211 93.6961 48.6345C91.6193 52.04 89.9382 53.6776 86.9558 55.6732C83.4907 57.9887 75.0855 59.8053 70.4383 59.0786C67.7161 58.6502 66.6207 58.4821 64.7987 55.9606C63.134 53.6559 62.2989 52.2135 62.2989 52.2135C62.2989 52.2135 41.4758 39.5299 34.2148 34.7905C26.9539 30.051 12.4374 20.5722 9.76944 18.4953C7.10148 16.4239 6.73274 15.4586 6.43449 14.7211C6.34231 14.4934 6.27181 14.1355 6.21759 13.7505C6.17963 13.8156 6.13624 13.8806 6.09829 13.9457C5.4313 15.0574 4.76431 18.0019 4.76431 18.0019C4.76431 18.0019 4.26542 18.5007 4.04309 19.8347C3.82076 21.1687 3.71231 22.0038 3.82076 22.8877C3.92922 23.777 4.26542 24.1078 4.31965 24.8887C4.4281 26.369 3.83703 27.3885 5.39334 29.3895C5.4801 29.4979 6.34231 29.986 6.28266 28.8689C6.19047 27.177 6.94965 24.0915 10.5395 26.3853C12.5404 27.6651 16.2604 32.1605 17.2256 38.0495C17.3774 38.9876 18.2234 39.4756 18.706 39.7685C30.8853 47.2463 48.65 56.6004 48.65 56.6004C48.65 56.6004 49.3549 57.0343 49.4526 56.9963C49.9677 56.774 49.3549 56.1233 49.4634 54.3826C49.7942 49.2093 54.1811 50.5379 56.6864 52.8263C60.2708 56.107 61.4638 59.9354 62.0548 61.6056C62.2392 62.1262 62.4887 62.945 62.5754 63.6065C62.6513 64.1976 62.5375 65.2171 63.3726 65.8298C63.7413 66.101 65.059 66.7897 67.4829 67.0499C68.638 67.1747 72.5911 67.0499 75.6495 66.4372C79.1688 65.7322 82.6393 65.2117 86.8148 62.9396C90.5944 60.8844 92.3676 57.8856 93.5931 55.7166C94.965 53.2872 94.3143 52.5497 94.3143 52.5497L94.3252 52.5443Z' fill='#D1D6E2' />
        </g>
        <path id='Vector_19' d='M91.6581 53.2564C91.6581 53.2564 89.2342 55.8539 86.6042 57.2584C83.2693 59.037 81.3659 59.4979 79.6957 59.9968C78.031 60.4957 76.8813 60.5337 77.2121 60.9783C77.5429 61.423 78.0472 62.1984 79.2131 62.1442C80.379 62.09 84.7117 60.9241 86.4903 59.8124C88.269 58.7008 88.2093 58.7604 89.6029 57.3668C90.3784 56.5859 90.6224 56.266 91.2135 55.1977C91.7666 54.2 92.1028 52.8118 91.6581 53.251V53.2564Z' fill='#7D7D7D' />
        <path id='Vector_20' d='M91.3737 44.5361C91.3737 44.5361 88.0984 48.6465 84.7092 50.3709C80.7398 52.3882 73.5439 53.9282 73.5439 53.9282L76.3204 57.0408C76.3204 57.0408 79.3191 56.8727 82.3775 55.8207C85.4304 54.7633 87.0464 54.156 88.8793 52.3231C90.7121 50.4902 92.3227 47.8223 92.3227 47.8223L91.3791 44.547L91.3737 44.5361Z' fill='#7D7D7D' />
        <path id='Vector_21' d='M84.7092 50.3753C80.7398 52.3925 73.5439 53.9325 73.5439 53.9325L74.7966 55.337C76.3963 55.1581 79.639 54.708 82.1009 53.8241C85.143 52.7287 86.7102 52.1593 88.543 50.3265C90.3759 48.4936 91.7099 46.1022 91.7099 46.1022L91.2923 44.6489C90.7826 45.2725 87.7947 48.8081 84.7092 50.3753Z' fill='#DFE0E0' />
        <path id='Vector_22' d='M76.6026 63.3197C76.6026 63.3197 73.4358 63.8782 71.2722 64.0409C69.1031 64.209 67.2702 63.9867 66.7713 63.7101C66.2725 63.4336 66.2182 63.3197 66.6032 62.7124C66.9937 62.0996 67.7691 61.6007 68.4903 61.4923C69.2115 61.3838 73.3762 61.6604 74.2113 61.7146C75.0463 61.7688 75.8218 61.9369 76.7653 62.6581C77.7089 63.3794 78.2078 63.7156 78.2078 63.7156L76.5972 63.3251L76.6026 63.3197Z' fill='#7D7D7D' />
        <path id='Vector_23' d='M93.5993 55.7072C94.9713 53.2778 94.3205 52.5404 94.3205 52.5404C94.3205 52.5404 94.3694 52.3994 94.429 52.1987C94.0928 52.8549 93.6156 53.7333 92.9323 54.9318C90.709 58.8198 87.9869 60.9292 82.7106 62.9302C79.5655 64.1232 69.1105 65.7717 66.2474 65.0938C64.9134 64.7793 63.4981 64.1395 62.5708 63.5267C62.5708 63.5484 62.5816 63.5755 62.5816 63.5972C62.6576 64.1883 62.5437 65.2077 63.3788 65.8205C63.7475 66.0916 65.0164 66.8454 67.4892 67.0406C68.6442 67.1328 72.5973 67.0406 75.6557 66.4278C79.175 65.7229 82.6455 65.2023 86.821 62.9302C90.6006 60.875 92.3738 57.8763 93.5993 55.7072Z' fill='#7D7D7D' />
        <path id='Vector_24' d='M94.6157 47.9224L92.8262 53.703L94.5126 50.5524C94.3987 50.0047 94.5126 49.213 94.6211 47.917L94.6157 47.9224Z' fill='#7D7D7D' />
        <path id='Vector_25' d='M6.63859 13.1069C6.43252 13.3943 6.26984 13.6655 6.10716 13.9312C5.44018 15.0428 4.77319 17.9873 4.77319 17.9873C4.77319 17.9873 4.69727 18.0633 4.59424 18.2314C5.1582 18.2639 5.89568 18.2639 6.66028 18.1175C8.21658 17.8192 8.43891 17.6728 8.66124 17.2282C8.95949 16.6317 8.88357 15.8237 7.84784 14.2674C7.41945 13.6221 6.99106 13.2913 6.63859 13.1124V13.1069Z' fill='white' />
        <g id='Group_9'>
          <path id='Vector_26' d='M44.0105 38.8967L45.5234 38.913C45.5234 38.913 45.6373 38.3816 45.659 37.9803C45.7566 36.1203 46.0874 35.4425 46.3097 35.9522C46.7001 36.8415 46.7001 36.5053 47.5298 38.6202C48.2022 40.3175 48.3378 41.5972 47.3617 41.2881C46.9713 41.1634 45.9627 40.3771 45.9627 40.3771L43.8315 40.2904L44.0051 38.9022L44.0105 38.8967Z' fill='#7D7D7D' />
          <path id='Vector_27' d='M42.6878 37.2374C42.6878 37.2374 38.5503 38.7612 38.5503 39.7047C38.5503 40.6483 39.8517 40.6483 40.4428 40.7676C41.7388 41.0279 45.2202 40.7405 45.2202 40.7405C45.2202 40.7405 46.2505 40.6537 44.8189 38.4033C43.7127 36.6626 42.6932 37.2374 42.6932 37.2374H42.6878Z' fill='#7D7D7D' />
          <path id='Vector_28' d='M44.8164 38.4033C43.7102 36.6626 42.6907 37.2374 42.6907 37.2374C42.6907 37.2374 39.448 38.4304 38.7051 39.3631C38.9437 40.1277 40.115 39.9813 40.6844 40.0193C41.9045 40.1006 44.9195 39.4824 45.3587 39.3902C45.2394 39.1191 45.0713 38.7991 44.8219 38.4087L44.8164 38.4033Z' fill='#BFC4D1' />
        </g>
        <path id='Vector_29' d='M63.0468 52.7047C63.0468 52.7047 62.1033 51.1484 63.101 50.5356C63.421 50.3404 67.2114 51.2569 69.4347 51.7557C71.658 52.2546 74.7652 53.9248 75.7684 55.3672C76.7662 56.8097 76.7119 57.3682 76.045 57.4224C75.378 57.4767 71.3218 57.981 69.7655 57.9213C68.2092 57.8671 66.821 57.4767 65.4328 55.9204C64.0446 54.364 63.0414 52.6993 63.0414 52.6993L63.0468 52.7047Z' fill='white' />
        <path id='Vector_30' d='M89.8653 39.0065C89.5346 38.0087 89.2526 37.2279 89.5346 37.0652C89.8111 36.8971 90.1094 36.9893 91.1614 38.3233C92.2188 39.6572 93.8835 42.1571 93.9974 43.2687C94.1059 44.3804 94.1059 45.2155 93.607 46.2675C93.1081 47.3249 92.1049 48.7673 92.1049 48.7673C92.1049 48.7673 91.7687 49.3204 91.6602 47.9322C91.5464 46.544 91.0529 42.5801 89.8653 39.0065Z' fill='white' />
        <path id='Vector_31' d='M27.1135 27.6452C26.5008 27.1734 26.5496 26.7179 26.6417 26.116C26.9183 24.3374 27.6395 21.9243 28.0299 20.5903C28.3011 19.663 28.48 19.4353 28.5885 19.175C28.9247 18.3399 29.8411 18.9527 29.8411 18.9527L27.0376 17.5645C27.0376 17.5645 27.5582 17.9223 27.4009 18.4538C26.9834 19.842 26.6363 20.6391 26.235 22.1466C25.8338 23.6595 25.4433 25.0315 25.3186 25.6442C25.2102 26.1973 24.6245 25.8937 24.6245 25.8937L27.1244 27.6452H27.1135Z' fill='#CBD0DC' />
        <g id='Group_10' opacity='0.45'>
          <path id='Vector_32' d='M47.1471 54.843C46.941 54.843 46.7566 54.7399 46.7512 54.7345C46.6373 54.6749 43.8826 53.1891 43.438 52.9179C43.2156 52.7824 42.0226 52.1154 40.3687 51.1935C35.754 48.6123 28.0267 44.2904 27.4736 43.797C26.709 43.1083 25.2883 39.0305 24.8816 37.241C23.873 32.751 24.3285 29.0094 24.5508 28.3478C24.7623 27.7133 25.4889 26.8132 25.6082 26.6668C25.7221 26.2275 27.3814 19.9643 27.8098 18.6846C28.043 17.9905 28.2545 17.5024 28.8727 17.5024C29.3336 17.5024 30.0168 17.7736 31.302 18.4731C33.9862 19.9318 39.9512 24.6766 41.1116 26.1299C42.3317 27.6591 48.519 37.2789 48.8172 38.2387C49.2402 39.6107 49.2456 41.3297 48.8281 42.2407C48.6925 42.5389 48.5407 42.7613 48.3889 42.9673C48.134 43.3252 47.9171 43.6397 47.8737 44.2742C47.8032 45.2936 47.7273 53.8832 47.7273 53.9699C47.7164 54.7291 47.3531 54.843 47.1416 54.843H47.1471ZM28.8727 17.7193C28.4334 17.7193 28.2653 18.023 28.0213 18.7497C27.5821 20.0619 25.836 26.6776 25.8197 26.7427L25.798 26.7861C25.798 26.7861 24.9738 27.7784 24.7623 28.4183C24.5454 29.0636 24.1007 32.751 25.0985 37.1922C25.4835 38.9166 26.8988 42.9836 27.62 43.6343C28.1514 44.1115 36.1662 48.5961 40.4718 51.0037C42.1257 51.931 43.3187 52.598 43.5464 52.7336C43.9856 52.9993 46.8271 54.5285 46.8542 54.5447C46.8542 54.5447 46.9952 54.6261 47.1416 54.6261C47.3748 54.6261 47.4995 54.4092 47.505 53.9753C47.505 53.6229 47.5809 45.2936 47.6514 44.2633C47.7002 43.5638 47.9496 43.2168 48.2099 42.8426C48.3509 42.642 48.4973 42.4305 48.6275 42.1485C48.9691 41.4002 49.0504 39.7571 48.6058 38.3038C48.3129 37.3494 42.1582 27.7893 40.9381 26.2655C39.7831 24.8231 33.8615 20.1162 31.1936 18.6629C29.9572 17.9905 29.2848 17.7193 28.8672 17.7193H28.8727Z' fill='#231F20' />
        </g>
      </g>
    </g>
  </svg>);

export default IconAlfredCar;
