import { useEffect, useState } from 'react';
import { Input, InputProps } from './Input';
import { useDebounce } from '../hooks/useDebouncer';

interface InputDebounceProps extends InputProps {
  debouncedValue: (value: string) => void
  debounceTime?: number
}

const InputDebounce = (props: InputDebounceProps) => {
  const [value, setValue] = useState(props.value);
  const debouncedValue = useDebounce(value, props.debounceTime ?? 700);

  useEffect(() => {
    props.debouncedValue(debouncedValue ?? '');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  return (
    <Input {...props} color='alfred' value={value} onChange={(v) => setValue(v.target.value)} />
  );
};

export default InputDebounce;
