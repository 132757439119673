
export const IconVIN = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='19'
    height='20'
    viewBox='0 0 19 20'
    fill='none'
    {...props}
  >
    <path d='M2.771 18.0804C2.45433 18.0804 2.17725 17.9617 1.93975 17.7242C1.70225 17.4867 1.5835 17.2096 1.5835 16.8929V7.3929C1.5835 7.07624 1.70225 6.79915 1.93975 6.56165C2.17725 6.32415 2.45433 6.2054 2.771 6.2054H7.46162V3.43457C7.46162 3.1179 7.58037 2.84082 7.81787 2.60332C8.05537 2.36582 8.33245 2.24707 8.64912 2.24707H10.371C10.6877 2.24707 10.9647 2.36582 11.2022 2.60332C11.4397 2.84082 11.5585 3.1179 11.5585 3.43457V6.2054H16.2293C16.546 6.2054 16.8231 6.32415 17.0606 6.56165C17.2981 6.79915 17.4168 7.07624 17.4168 7.3929V16.8929C17.4168 17.2096 17.2981 17.4867 17.0606 17.7242C16.8231 17.9617 16.546 18.0804 16.2293 18.0804H2.771ZM2.771 16.8929H16.2293V7.3929H11.5585C11.5585 7.76235 11.4364 8.05263 11.1924 8.26374C10.9483 8.47485 10.6415 8.5804 10.272 8.5804H8.72829C8.37204 8.5804 8.07186 8.47485 7.82777 8.26374C7.58367 8.05263 7.46162 7.76235 7.46162 7.3929H2.771V16.8929ZM4.59183 14.7752H9.32204V14.4981C9.32204 14.2606 9.26266 14.0495 9.14391 13.8648C9.02516 13.6801 8.87343 13.5547 8.6887 13.4887C8.26648 13.3436 7.93662 13.2479 7.69912 13.2018C7.46162 13.1556 7.23072 13.1325 7.00641 13.1325C6.75572 13.1325 6.48853 13.1622 6.20485 13.2215C5.92117 13.2809 5.6012 13.37 5.24495 13.4887C5.04704 13.5547 4.8887 13.6801 4.76995 13.8648C4.6512 14.0495 4.59183 14.2606 4.59183 14.4981V14.7752ZM11.2418 13.4492H14.6064V12.4596H11.2418V13.4492ZM7.00641 12.4596C7.30989 12.4596 7.56388 12.354 7.76839 12.1429C7.97291 11.9318 8.07516 11.6811 8.07516 11.3908C8.07516 11.0873 7.97291 10.8334 7.76839 10.6288C7.56388 10.4243 7.30989 10.3221 7.00641 10.3221C6.71614 10.3221 6.46544 10.4243 6.25433 10.6288C6.04322 10.8334 5.93766 11.0873 5.93766 11.3908C5.93766 11.6811 6.04322 11.9318 6.25433 12.1429C6.46544 12.354 6.71614 12.4596 7.00641 12.4596ZM11.2418 11.2127H14.6064V10.2231H11.2418V11.2127ZM8.64912 7.3929H10.371V3.43457H8.64912V7.3929Z' fill='#F8FAFF' />
  </svg>
);

export default IconVIN;
