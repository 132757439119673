export const IconSrMenuDotsVertical = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    version='1.1'
    id='Capa_1'
    x='0px'
    y='0px'
    viewBox='0 0 512 512'
    width='20'
    height='20'
    fill='#fff'
    {...props}
  >
    <g>
      <circle cx='256' cy='42.667' r='42.667' />
      <circle cx='256' cy='256' r='42.667' />
      <circle cx='256' cy='469.333' r='42.667' />
    </g>
  </svg>
);
